import * as React from "react";
import { uniq, debounce, isEmpty, get, find } from "lodash";
import { OptionProps } from "react-select/lib/types";
import { Feature } from "@paralleldrive/react-feature-toggles";
import { isActiveFeatureName } from "@paralleldrive/feature-toggles";
import { remove } from "lodash/fp";

import Modal from "../Modal";
import MenuItem from "../Menu/MenuItem";
import {
  MEMBER_ROLE_LABELS,
  MEMBER_ROLES,
  MESSAGES_EXTRA_AUDIENCE_LABELS,
  MESSAGES_EXTRA_AUDIENCES
} from "../../constants";
import {
  BACKPACK,
  STUDENTS_SMALL,
  STAFF,
  CIRCLE,
  ADD
} from "../../constants/icons";
import Icon from "../Icon";
import {
  MenuWrapper,
  StyledIcon,
  StyledMenu,
  ContentWrapper,
  StyledChip,
  StyledAction,
  FieldWrapper,
  GroupsSelect,
  StyledButton,
  CreateAudienceButton,
  ButtonWrapper,
  StyledLabel,
  StyledHR,
  FileInput,
  ImportCSVAction
} from "./styles";
import { CSV_IMPORT_TOGGLE } from "../../constants/featureToggles";
import {
  AddMoreButton,
  ButtonsWrapper,
  Label,
  MembersContainer,
  ErrorText,
  MembersSelect,
  StyledMemberChip
} from "../CreateChannelModal/styles";
import DefaultAvatar from "../../assets/avatar.svg";
import { MAX_NUMBER_OF_MEMBERS } from "../CreateChannelModal/constants";
import { isGoNativeApp } from "../../utils/helpers";
import { CSV_UPLOAD_WRONG_FORMAT_ERROR } from "./constants";

interface IProps {
  title: string;
  onCloseClick: () => void;
  fetchAudiencesSearch: (value: string) => void;
  removeAudiencesDataFromState: () => void;
  isFetchingAudiences: boolean;
  audiencesOptions: OptionProps;
  onAudiencesConfirmClick: (
    audiences: IAudienceSelection[],
    selectedMembers: IXHQSearchMemberOptionType[],
    CSVFile?: File
  ) => void;
  xhqUserOptionsResult?: IXHQSearchMemberOptionType[];
  showGroupSelector: boolean;
  showCSVSelector: boolean;
  showMembersSelector: boolean;
  filterUserIds?: string[];
  removeXhqUsers?: () => { type: string };
  fetchXHQSearchMembers?: (inputValue: string) => void;
  isFetchingXhqUserOptions?: boolean | null;
}

interface IState {
  showRolesMenu: boolean;
  showGroupsInput: boolean;
  showMemberSelector: boolean;
  selectedRoles: string[];
  selectedGroup?: OptionProps;
  selectedGroups: OptionProps[];
  selectedMembers: IXHQSearchMemberOptionType[];
  selectedMember?: IXHQSearchMemberOptionType;
  CSVFile?: File;
  CSVFileError?: string;
  validationErrors: {
    alreadyExists: boolean;
  };
}

const NOTIFY_DROPDOWN_ITEMS = {
  [MEMBER_ROLES.STUDENT]: BACKPACK,
  [MEMBER_ROLES.PARENT]: STUDENTS_SMALL,
  [MEMBER_ROLES.STAFF]: STAFF
};

class AudienceSelectModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showRolesMenu: false,
      showGroupsInput: false,
      showMemberSelector: false,
      selectedRoles: [],
      selectedGroups: [],
      selectedMembers: [],
      validationErrors: {
        alreadyExists: false
      },
      CSVFileError: ""
    };

    this.handleInputSelectChange = debounce(this.handleInputSelectChange, 500);
    this.handleMembersSelectChange = debounce(
      this.handleMembersSelectChange,
      500
    );
  }

  handleRolesMenuClickOutside = () => {
    this.setState({ showRolesMenu: false });
  };

  handleRolesMenuClick = () => {
    this.setState({ showRolesMenu: true });
  };

  handleGroupsActionClick = () => {
    this.setState({ showGroupsInput: true, selectedGroup: undefined });
  };

  handleGroupCancelClick = () => {
    this.setState({ showGroupsInput: false });
  };

  handleRoleClick = (role: string) => () => {
    this.setState((currentState: IState) => ({
      selectedRoles:
        role !== MESSAGES_EXTRA_AUDIENCES.EVERYONE
          ? uniq([...currentState.selectedRoles, role])
          : [MESSAGES_EXTRA_AUDIENCES.EVERYONE],
      showRolesMenu: false,
      CSVFile: undefined
    }));
  };

  handleRoleRemove = (role: string) => () => {
    this.setState((currentState: IState) => ({
      selectedRoles: currentState.selectedRoles.filter(
        selectedRole => selectedRole !== role
      )
    }));
  };

  handleGroupSelectorChange = (selectedOption: OptionProps) => {
    this.setState(() => ({
      selectedGroup: selectedOption
    }));
  };

  handleAddSelectedGroup = () => {
    this.setState(({ selectedGroups, selectedGroup }: IState) => ({
      selectedGroups: selectedGroup
        ? [...selectedGroups, selectedGroup]
        : selectedGroups,
      showGroupsInput: false,
      CSVFile: undefined
    }));
  };

  handleRemoveSelectedGroup = (group: string) => () => {
    this.setState((currentState: IState) => ({
      selectedGroups: currentState.selectedGroups.filter(
        selectedGroup => selectedGroup.value !== group
      )
    }));
  };

  handleInputSelectChange = (value: string) => {
    if (isEmpty(value)) {
      return;
    }

    this.props.fetchAudiencesSearch(value);
  };

  handleImportCSVClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const file: File = get(event, "target.files.0");
    if (!file) {
      return;
    }
    const csvFormatRegex = /^.*\.csv$/g;
    const isCsvFile =
      Boolean(file.name.match(csvFormatRegex)) ||
      Boolean(file.type.match(csvFormatRegex));

    if (!isCsvFile) {
      this.setState({
        CSVFileError: CSV_UPLOAD_WRONG_FORMAT_ERROR
      });

      return;
    }

    this.setState({
      selectedRoles: [],
      selectedGroups: [],
      CSVFile: file,
      CSVFileError: ""
    });
  };

  handleRemoveSelectedCSV = () => {
    this.setState({ CSVFile: undefined, CSVFileError: "" });
  };

  filterOption = (option: OptionProps) =>
    !this.state.selectedGroups.find(
      selectedGroup => selectedGroup.value === option.value
    );

  getMappedAudience = (selectedGroup: OptionProps) => {
    const { selectedRoles } = this.state;
    const selectedMemberRoles = selectedRoles.includes(
      MESSAGES_EXTRA_AUDIENCES.EVERYONE
    )
      ? [MEMBER_ROLES.PARENT, MEMBER_ROLES.STAFF, MEMBER_ROLES.STUDENT]
      : selectedRoles;
    return selectedMemberRoles.reduce(
      (audiences: IAudienceSelection[], selectedRole: string) => [
        ...audiences,
        {
          audienceId: selectedGroup.value,
          role: selectedRole,
          audienceRolePair: `${selectedGroup.value}-${selectedRole}`,
          audienceName: selectedGroup.label
        }
      ],
      []
    );
  };

  handleAudienceConfirmClick = () => {
    const { onAudiencesConfirmClick } = this.props;
    const { selectedGroups, CSVFile, selectedMembers } = this.state;

    const selectedAudiences = selectedGroups.reduce(
      (audiences: IAudienceSelection[], selectedGroup: OptionProps) => [
        ...audiences,
        ...this.getMappedAudience(selectedGroup)
      ],
      []
    );

    onAudiencesConfirmClick(selectedAudiences, selectedMembers, CSVFile);
  };

  get renderRolesMenu() {
    return (
      <MenuWrapper>
        <StyledMenu onClickOutside={this.handleRolesMenuClickOutside}>
          <MenuItem
            key={MESSAGES_EXTRA_AUDIENCES.EVERYONE}
            onClick={this.handleRoleClick(MESSAGES_EXTRA_AUDIENCES.EVERYONE)}
          >
            <Icon name={CIRCLE} opacity={0.5} />
            {MESSAGES_EXTRA_AUDIENCE_LABELS[MESSAGES_EXTRA_AUDIENCES.EVERYONE]}
          </MenuItem>
          {Object.keys(NOTIFY_DROPDOWN_ITEMS).map(role => (
            <MenuItem key={role} onClick={this.handleRoleClick(role)}>
              {role === MEMBER_ROLES.PARENT ? (
                <StyledIcon name={NOTIFY_DROPDOWN_ITEMS[role]} opacity={0.5} />
              ) : (
                <Icon name={NOTIFY_DROPDOWN_ITEMS[role]} opacity={0.5} />
              )}
              {MEMBER_ROLE_LABELS[role]}
              {role !== MEMBER_ROLES.STAFF ? "s" : ""}
            </MenuItem>
          ))}
        </StyledMenu>
      </MenuWrapper>
    );
  }

  get isConfirmButtonDisabled() {
    const {
      selectedGroups,
      selectedRoles,
      CSVFile,
      selectedMembers
    } = this.state;
    return (
      (selectedRoles.length === 0 || selectedGroups.length === 0) &&
      !CSVFile &&
      selectedMembers.length === 0
    );
  }

  handleRemoveMember = (value: IXHQMember) => () => {
    const { selectedMembers } = this.state;
    const updatedSelectedMembers = remove({ value }, selectedMembers);
    this.setState(() => ({
      selectedMembers: updatedSelectedMembers
    }));
  };

  get showAddButton() {
    return this.state.selectedMembers.length < MAX_NUMBER_OF_MEMBERS;
  }

  handleShowMemberSelector = () => {
    this.setState(() => ({
      showMemberSelector: true
    }));
  };

  handleMembersSelectChange = (value: string) => {
    const { removeXhqUsers, fetchXHQSearchMembers } = this.props;
    if (isEmpty(value) && removeXhqUsers) {
      removeXhqUsers();
      return;
    }

    fetchXHQSearchMembers && fetchXHQSearchMembers(value);
  };

  handleMemberSelectorChange = (selectedOption: IXHQSearchMemberOptionType) => {
    const { selectedMembers, validationErrors } = this.state;
    this.setState(() => ({
      selectedMember: selectedOption,
      validationErrors: {
        ...validationErrors,
        alreadyExists: Boolean(find(selectedMembers, selectedOption))
      }
    }));
  };

  handleAddSelectedMember = () => {
    const { selectedMembers, selectedMember } = this.state;
    if (selectedMember && !find(selectedMembers, selectedMember)) {
      this.setState(() => ({
        showMemberSelector: false,
        selectedMember: undefined,
        selectedMembers: [...selectedMembers, selectedMember]
      }));
    }
  };

  handleCancelMemberSelection = () => {
    this.setState(() => ({
      showMemberSelector: false,
      selectedMember: undefined
    }));
  };

  filterMembersOption = (option: OptionProps) => {
    const { filterUserIds = [] } = this.props;
    return !filterUserIds.includes(option.value.id);
  };

  renderMembersSelector = () => {
    const {
      selectedMembers,
      showMemberSelector,
      selectedMember,
      validationErrors: { alreadyExists }
    } = this.state;
    const { xhqUserOptionsResult, isFetchingXhqUserOptions } = this.props;

    return (
      <>
        <StyledHR />
        <StyledLabel>INDIVIDUALS</StyledLabel>
        <MembersContainer error={false}>
          {selectedMembers.map(({ value, label }) => (
            <StyledMemberChip
              key={value.id}
              avatar={value.avatar || DefaultAvatar}
              value={value}
              label={label}
              onDelete={this.handleRemoveMember(value)}
            />
          ))}
          {!showMemberSelector && this.showAddButton && (
            <AddMoreButton
              iconName={ADD}
              onClick={this.handleShowMemberSelector}
            >
              Add more
            </AddMoreButton>
          )}
        </MembersContainer>
        {showMemberSelector && (
          <FieldWrapper>
            <Label>ADD MORE</Label>
            <MembersSelect
              autoFocus={true}
              placeholder="Find member to add"
              isLoading={isFetchingXhqUserOptions}
              onInputChange={this.handleMembersSelectChange}
              onChange={this.handleMemberSelectorChange}
              filterOption={this.filterMembersOption}
              options={xhqUserOptionsResult}
            />
            <ButtonsWrapper>
              <StyledButton
                disabled={!selectedMember || alreadyExists}
                onClick={this.handleAddSelectedMember}
              >
                Add
              </StyledButton>
              <StyledButton onClick={this.handleCancelMemberSelection}>
                Cancel
              </StyledButton>
            </ButtonsWrapper>
            {alreadyExists && (
              <ErrorText>This member already exists.</ErrorText>
            )}
          </FieldWrapper>
        )}
      </>
    );
  };

  handleOnFocus = () => {
    const { selectedMember } = this.state;
    const { removeAudiencesDataFromState } = this.props;
    !selectedMember && removeAudiencesDataFromState();
  };

  renderGroupSelector = () => {
    const { audiencesOptions, isFetchingAudiences } = this.props;
    const {
      showRolesMenu,
      selectedRoles,
      selectedGroups,
      showGroupsInput
    } = this.state;
    return (
      <>
        <StyledHR />
        <StyledLabel>ROLES</StyledLabel>
        {selectedRoles.map(role => (
          <StyledChip key={role} onClose={this.handleRoleRemove(role)}>
            {role !== MESSAGES_EXTRA_AUDIENCES.EVERYONE
              ? MEMBER_ROLE_LABELS[role]
              : MESSAGES_EXTRA_AUDIENCE_LABELS[role]}
          </StyledChip>
        ))}
        <StyledAction onClick={this.handleRolesMenuClick} iconName={ADD}>
          Add Role
        </StyledAction>
        {showRolesMenu && this.renderRolesMenu}
        <StyledLabel>GROUPS</StyledLabel>
        {selectedGroups.map(({ value, label }) => (
          <StyledChip
            key={value}
            onClose={this.handleRemoveSelectedGroup(value)}
          >
            {label}
          </StyledChip>
        ))}
        {!showGroupsInput && (
          <StyledAction onClick={this.handleGroupsActionClick} iconName={ADD}>
            Add Group
          </StyledAction>
        )}
        {showGroupsInput && (
          <FieldWrapper>
            <GroupsSelect
              autoFocus={true}
              placeholder="Find group to add"
              isLoading={isFetchingAudiences}
              onInputChange={this.handleInputSelectChange}
              onChange={this.handleGroupSelectorChange}
              options={audiencesOptions}
              filterOption={this.filterOption}
              onFocus={this.handleOnFocus}
            />
            <ButtonWrapper>
              <StyledButton onClick={this.handleAddSelectedGroup}>
                ADD
              </StyledButton>
              <StyledButton onClick={this.handleGroupCancelClick}>
                CANCEL
              </StyledButton>
            </ButtonWrapper>
          </FieldWrapper>
        )}
      </>
    );
  };

  renderCSVImportButton = () => {
    const { CSVFile, CSVFileError } = this.state;

    const fileInputProps = {
      type: "file",
      onChange: this.handleImportCSVClick,
      id: "CSVInput",
      value: "",
      ...(!isGoNativeApp && { accept: ".csv" })
    };

    return (
      <div>
        <StyledHR />
        <StyledLabel>CUSTOM</StyledLabel>
        {CSVFile && (
          <StyledChip onClose={this.handleRemoveSelectedCSV}>
            {CSVFile.name}
          </StyledChip>
        )}
        <>
          <FileInput {...fileInputProps} />
          <label htmlFor="CSVInput">
            <ImportCSVAction>
              <Icon name={ADD} />
              <span>Import CSV</span>
            </ImportCSVAction>
          </label>
        </>
        {CSVFileError && <ErrorText>{CSVFileError}</ErrorText>}
      </div>
    );
  };

  render() {
    const {
      title,
      onCloseClick,
      showCSVSelector,
      showGroupSelector,
      showMembersSelector
    } = this.props;
    return (
      <Feature>
        {({ features }) => (
          <Modal title={`Choose ${title}`} onCloseClick={onCloseClick}>
            <ContentWrapper>
              {showMembersSelector && this.renderMembersSelector()}
              {showGroupSelector && this.renderGroupSelector()}
              {isActiveFeatureName(CSV_IMPORT_TOGGLE, features) &&
                showCSVSelector &&
                this.renderCSVImportButton()}
              <StyledHR />
              <CreateAudienceButton
                disabled={this.isConfirmButtonDisabled}
                onClick={this.handleAudienceConfirmClick}
              >
                CONFIRM AUDIENCE
              </CreateAudienceButton>
            </ContentWrapper>
          </Modal>
        )}
      </Feature>
    );
  }
}

export default AudienceSelectModal;
