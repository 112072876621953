import * as React from "react";
import styled from "styled-components";

import Icon from "../Icon";

interface IProps {
  className?: string;
  iconName: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Wrapper = styled.button`
  display: flex;
  background: none;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: 600;
  font-size: 0.875rem;
  border: none;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;

  color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.primary.dark : theme.colors.primary.light};
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "cursor")};

  :focus {
    outline: 0;
  }
`;

const ActionButton = ({
  className,
  iconName,
  children,
  onClick,
  disabled
}: IProps) => {
  return (
    <Wrapper className={className} onClick={onClick} disabled={disabled}>
      <Icon name={iconName} />
      <span>{children}</span>
    </Wrapper>
  );
};

export default ActionButton;
