import {
  PUT_ATTACHMENT,
  REMOVE_ATTACHMENT,
  POST_THREAD_ATTACHMENTS,
  REMOVE_ALL_ATTACHMENTS
} from "../../constants/actions/attachments";

import {
  ATTACHMENTS_MAX_BYTES_SIZE,
  ATTACHMENT_ERROR_MAX_SIZE_MESSAGE
} from "../../constants/attachments";
import { CREATE_THREAD_ATTACHMENTS } from "../../graphql/tags/threadAttachments";
import s3FileUpload from "../../utils/s3FileUpload";

export const putAttachmentToS3 = (file: File, fileKey?: string) => async ({
  dispatch,
  getState
}: IReduxStore) => {
  const {
    attachments: { isUploading }
  } = getState();
  const { size } = file;

  if (isUploading) {
    dispatch({
      type: `${PUT_ATTACHMENT}_ERROR`,
      payload: "Already uploading a file"
    });

    return;
  }

  if (size > ATTACHMENTS_MAX_BYTES_SIZE) {
    dispatch({
      type: `${PUT_ATTACHMENT}_ERROR`,
      payload: ATTACHMENT_ERROR_MAX_SIZE_MESSAGE
    });
    return;
  }

  try {
    dispatch({
      type: `${PUT_ATTACHMENT}_LOADING`,
      payload: true
    });

    const newAttachment = await s3FileUpload()(file, fileKey);

    dispatch({
      type: `${PUT_ATTACHMENT}_SUCCESS`,
      payload: newAttachment
    });
  } catch (error) {
    dispatch({
      type: `${PUT_ATTACHMENT}_ERROR`,
      payload: error.message
    });
  }
};

export const removeAttachmentFromState = (attachment: IAttachment) => ({
  type: REMOVE_ATTACHMENT,
  payload: attachment
});

export const removeAllAttachmentsFromState = () => ({
  type: REMOVE_ALL_ATTACHMENTS
});

export const postThreadAttachments = (thread: IThread) => ({
  getState
}: IReduxStore) => {
  const { attachments, xhqUser } = getState();

  const { id: messageId, parentId: channelId } = thread;
  const data = attachments.data.map(attachment => ({
    messageId,
    channelId,
    name: attachment.name,
    url: attachment.href,
    uploadedById: xhqUser.data!.id
  }));

  return {
    actionType: POST_THREAD_ATTACHMENTS,
    api: "XHQ",
    operationType: "mutation",
    operation: CREATE_THREAD_ATTACHMENTS,
    variables: {
      data
    },
    getter: () => data.map(({ name, url }) => ({ name, url }))
  };
};
