import * as React from "react";
import Scrollbar from "react-custom-scrollbars";
import { OptionProps } from "react-select/lib/types";
import { upperFirst, unionBy } from "lodash";

import * as S from "./styles";
import AudienceSelectModal from "../AudienceSelectModal";
import DefaultAvatar from "../../assets/avatar.svg";
import ActionButton from "../ActionButton";
import { ADD } from "../../constants/icons";
import {
  getCSVRow,
  readUploadedFileAsText,
  formatCSVHeader
} from "../../utils/helpers";

interface IProps {
  title: string;
  fetchAudiencesSearch: (value: string) => void;
  removeAudiencesDataFromState: () => void;
  audiencesState: IAsyncEntityState<IAudiencesData>;
  audiencesOptions: OptionProps;
  showGroupSelector?: boolean;
  showCSVSelector?: boolean;
  showMembersSelector?: boolean;
  removeXhqUsers?: () => { type: string };
  fetchXHQSearchMembers?: (inputValue: string) => void;
  xhqUserOptionsResult?: IXHQSearchMemberOptionType[];
  isFetchingXhqUserOptions?: boolean | null;
  selectedAudiences: IAudienceSelection[];
  selectedMembers: IXHQSearchMemberOptionType[];
  onAudiencesSelectionChange: (value: IAudienceSelectorValue) => void;
  filterUserIds?: string[];
}

interface IState {
  showAudiencesModal: boolean;
  selectedCSVFile?: File;
  csvHeader: string;
  CSVContent: string;
}

class AudienceSelector extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showAudiencesModal: false,
      csvHeader: "",
      CSVContent: ""
    };
  }

  renderAudiencesModal = () => {
    const {
      title,
      fetchAudiencesSearch,
      audiencesState,
      audiencesOptions,
      removeXhqUsers,
      fetchXHQSearchMembers,
      xhqUserOptionsResult,
      isFetchingXhqUserOptions,
      showGroupSelector = false,
      showCSVSelector = false,
      showMembersSelector = false,
      filterUserIds,
      removeAudiencesDataFromState
    } = this.props;
    return (
      <AudienceSelectModal
        title={title}
        onCloseClick={this.handleAudienceSelectToggle}
        fetchAudiencesSearch={fetchAudiencesSearch}
        removeAudiencesDataFromState={removeAudiencesDataFromState}
        isFetchingAudiences={audiencesState.isFetching as boolean}
        audiencesOptions={audiencesOptions}
        onAudiencesConfirmClick={this.handleAudiencesConfirmClick}
        showGroupSelector={showGroupSelector}
        showCSVSelector={showCSVSelector}
        showMembersSelector={showMembersSelector}
        removeXhqUsers={removeXhqUsers}
        fetchXHQSearchMembers={fetchXHQSearchMembers}
        xhqUserOptionsResult={xhqUserOptionsResult}
        isFetchingXhqUserOptions={isFetchingXhqUserOptions}
        filterUserIds={filterUserIds}
      />
    );
  };

  renderSelection = () => {
    const { selectedAudiences } = this.props;
    const { selectedCSVFile } = this.state;
    if (!selectedCSVFile) {
      return selectedAudiences.map(
        ({
          audienceId,
          audienceName,
          role,
          audienceRolePair
        }: IAudienceSelection) => (
          <S.StyledChip
            key={audienceRolePair}
            onClose={this.handleAudienceCloseClick(audienceRolePair)}
          >
            <div>
              <div>{audienceName}</div>
              <S.ChipRole>{upperFirst(role.toLowerCase())}</S.ChipRole>
            </div>
          </S.StyledChip>
        )
      );
    }
    return (
      <S.StyledChip onClose={this.handleCSVCloseClick}>
        <div>
          <div>{selectedCSVFile.name}</div>
          <S.ChipRole>From CSV</S.ChipRole>
        </div>
      </S.StyledChip>
    );
  };

  handleRemoveSelection = () => {
    const {
      selectedAudiences,
      selectedMembers,
      onAudiencesSelectionChange
    } = this.props;
    const { selectedCSVFile, csvHeader, CSVContent } = this.state;
    onAudiencesSelectionChange({
      selectedAudiences,
      selectedMembers,
      selectedCSVFile,
      csvHeader,
      CSVContent
    });
  };

  renderSelectedMembers = () => {
    const { selectedMembers } = this.props;
    return selectedMembers.map(({ value, label }) => (
      <S.StyledMemberChip
        key={value.id}
        avatar={value.avatar || DefaultAvatar}
        value={value}
        label={label}
        onDelete={this.handleRemoveMember(value.id)}
      />
    ));
  };

  handleRemoveMember = (memberId: string) => () => {
    const {
      selectedAudiences,
      selectedMembers,
      onAudiencesSelectionChange
    } = this.props;
    const { selectedCSVFile, csvHeader, CSVContent } = this.state;

    const newSelectedMembers = selectedMembers.filter(
      selectedMember => selectedMember.value.id !== memberId
    );

    onAudiencesSelectionChange({
      selectedAudiences,
      selectedMembers: newSelectedMembers,
      selectedCSVFile,
      csvHeader,
      CSVContent
    });
  };

  handleAudienceCloseClick = (audienceRolePair: string) => () => {
    const {
      selectedAudiences,
      selectedMembers,
      onAudiencesSelectionChange
    } = this.props;
    const { selectedCSVFile, csvHeader, CSVContent } = this.state;

    const newAudiences = selectedAudiences.filter(
      selectedAudience => selectedAudience.audienceRolePair !== audienceRolePair
    );

    onAudiencesSelectionChange({
      selectedAudiences: newAudiences,
      selectedMembers,
      selectedCSVFile,
      csvHeader,
      CSVContent
    });
  };

  handleCSVCloseClick = () => {
    this.setState(
      {
        selectedCSVFile: undefined,
        csvHeader: "",
        CSVContent: ""
      },
      this.handleRemoveSelection
    );
  };

  handleAudiencesConfirmClick = async (
    selectedAudiences: IAudienceSelection[],
    selectedMembers: IXHQSearchMemberOptionType[],
    CSVFile?: File
  ) => {
    const {
      onAudiencesSelectionChange,
      selectedAudiences: currentSelectedAudiences,
      selectedMembers: currentSelectedMembers
    } = this.props;

    const content = CSVFile
      ? ((await readUploadedFileAsText(CSVFile!)) as string).replace(
          /\r\n/g,
          "\n"
        )
      : "";
    const csvHeader = CSVFile
      ? formatCSVHeader(getCSVRow(content as string, 1))
      : "";

    const newState = {
      selectedCSVFile: CSVFile,
      showAudiencesModal: false,
      csvHeader,
      CSVContent: content
    };

    this.setState(newState);

    return onAudiencesSelectionChange({
      selectedAudiences: CSVFile
        ? []
        : unionBy(
            currentSelectedAudiences,
            selectedAudiences,
            "audienceRolePair"
          ),
      selectedMembers: unionBy(
        currentSelectedMembers,
        selectedMembers,
        "value.id"
      ),
      ...newState
    });
  };

  handleAudienceSelectToggle = () => {
    this.setState(({ showAudiencesModal }) => ({
      showAudiencesModal: !showAudiencesModal
    }));
  };

  render() {
    const { title } = this.props;
    const { showAudiencesModal, selectedCSVFile } = this.state;
    return (
      <>
        <S.GroupsWrapper>
          <Scrollbar scrollheight={"3.6rem"}>
            <S.GroupsWrapper>
              {this.renderSelectedMembers()}
              {this.renderSelection()}
              <ActionButton
                disabled={selectedCSVFile !== undefined}
                onClick={this.handleAudienceSelectToggle}
                iconName={ADD}
              >
                Select {title}
              </ActionButton>
            </S.GroupsWrapper>
          </Scrollbar>
        </S.GroupsWrapper>
        {showAudiencesModal && this.renderAudiencesModal()}
      </>
    );
  }
}

export default AudienceSelector;
