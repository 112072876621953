import gql from "graphql-tag";

export const GET_XHQ_USERS_SEARCH = gql`
  query Search($searchAttribute: String!) {
    search(
      data: {
        modelType: "member"
        attributeValuePairs: [
          { key: "searchAttribute", value: $searchAttribute }
        ]
      }
    ) {
      items {
        id
        ... on Member {
          avatar
          firstName
          lastName
          email
          capabilities {
            items {
              id
              name
            }
          }
          roles {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_AUDIENCES_SEARCH = gql`
  query Search($name: String!, $limit: Int) {
    search(
      data: {
        modelType: "audience"
        attributeValuePairs: [{ key: "audienceNameLowercased", value: $name }]
      }
      limit: $limit
    ) {
      items {
        ... on Audience {
          id
          name
        }
      }
    }
  }
`;
