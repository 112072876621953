import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import { get, isEmpty } from "lodash";
import { withRouter, RouteComponentProps, match } from "react-router";

import media from "../../lib/media";
import { FILTERS, NOTIFABLE_STATUS } from "../../constants";
import {
  EDITOR_VIEW_MODE,
  MESSAGES_LABEL_KEYS,
  MOBILE_COMMUNITY_SCREENS
} from "../../constants/community";
import { LoadingPanel } from "../../ui/Loading";
import Thread from "../../ui/Thread";
import CommentList from "../../ui/CommentList";
import CommentInput from "../../ui/CommentInput";
import HorizontalRule from "../../ui/HorizontalRule";
import ScrollableColumn from "../../ui/ScrollableColumn";
import ConfirmationDialog from "../../ui/ConfirmationDialog";
import DefaultAvatar from "../../assets/avatar.svg";
import EmptyScreenPlaceholder from "../../ui/EmptyScreenPlaceholder/EmptyScreenPlaceholder";
import MessagesPlaceholderImage from "../../assets/messages_placeholder.svg";
import {
  getLabelByKey,
  getRoleLabel,
  isChannelModerator,
  isChannelOwner,
  isThreadCreator,
  getThreadChannel,
  isAnnouncementFilter
} from "../../utils/helpers";

type TMatchParams = {
  channelName: string;
  threadId: string;
  filter: string;
};

interface IProps extends RouteComponentProps {
  match: match<TMatchParams>;
  allUserChannelsMap: { [key: string]: IChannel };
  xhqUser: IXHQMember | null;
  onLoadThreadById?: (threadId: string) => void;
  onCommentChange?: (event: React.ChangeEvent<HTMLElement>) => void;
  onCommentSubmit?: (event: React.MouseEvent<HTMLElement>) => void;
  onBackClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onThreadUpdateClick: (thread: IThread) => void;
  onThreadForwardClick: (thread: IThread) => void;
  isUpdatingThreadAsReadOrUnread?: boolean;
  threadData?: {
    data: IThread | null;
    isFetching: boolean | null;
    error: string | null;
  };
  commentData?: {
    data: IThread | null;
    isFetching: boolean | null;
    error: string | null;
  };
  deleteThreadData?: {
    isLoading: boolean;
    error: string | null;
  };
  favouriteThreadData?: {
    isLoading: boolean;
    error: string | null;
  };
  actions?: {
    deleteThreadAndRedirect: (
      threadData: IDeleteThreadData,
      cb: () => void
    ) => void;
    updateThreadAsReadOrUnread: (
      threadId: string,
      threadParentId: string,
      setToRead: boolean
    ) => void;
    updateThreadAsFavourite: (
      threadId: string,
      threadParentId: string,
      xhqUserId: string
    ) => void;
    updateThreadAsNonFavourite: (
      favouriteId: string,
      xhqUserId: string
    ) => void;
    postThreadComment: (threadData: IPostThreadData) => void;
    setMobileFocusedColumn: (
      focusedColumnView: string,
      callback?: () => void
    ) => void;
    setEditorViewMode: (editorViewMode: string) => void;
  };
}

interface IState {
  commentInputValue: string;
  showConfirmDeleteDialog?: boolean;
}

const StyledScrollableColumn = styled(ScrollableColumn)`
  ${media.lessThan("medium")`
    min-height: 100%;

    &>div {
      overflow-y: scroll !important;
      overflow-x: hidden !important;
    }
  `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const ThreadWrapper = styled.div`
  padding: 1.5rem 1.5rem 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${media.greaterThan("large")`
    padding: 2rem 2rem 0;
  `};

  ${media.greaterThan("xlarge")`
    padding: 3rem 3rem 0;
  `};
`;

const HR = styled(HorizontalRule)`
  margin: 2rem 0 1.5rem;
`;

const StyledLoadingPanel = styled(LoadingPanel)`
  display: flex;
  flex: 1;
`;

class ThreadView extends React.PureComponent<IProps, IState> {
  state: IState = {
    commentInputValue: "",
    showConfirmDeleteDialog: false
  };

  handleOnBackClick = (event: React.MouseEvent<HTMLElement>) => {
    const { actions } = this.props;
    actions!.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.THREAD_LIST);
    actions!.setEditorViewMode(EDITOR_VIEW_MODE.READ_THREAD);
  };

  handleThreadUpdateClicks = (event: React.MouseEvent<HTMLElement>) => {
    const { onThreadUpdateClick, threadData } = this.props;

    if (threadData && threadData.data) {
      onThreadUpdateClick(threadData.data);
    }
  };

  handleThreadForwardClick = () => {
    const { onThreadForwardClick, threadData } = this.props;

    if (threadData && threadData.data) {
      onThreadForwardClick(threadData.data);
    }
  };

  handleCommentChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({ commentInputValue: event.target.value });
  };

  handleOnConfirmDeleteThread = () => {
    const { threadData, actions } = this.props;
    if (threadData && threadData.data) {
      const { parentId, id: messageId } = threadData.data;
      this.setState(
        () => ({
          showConfirmDeleteDialog: false
        }),
        () => {
          if (messageId) {
            actions!.deleteThreadAndRedirect(
              {
                parentId,
                messageId
              },
              () => {
                const pathBeforeMessages = this.props.location.pathname.split(
                  "/threads/"
                )[0];
                this.props.history.push(pathBeforeMessages);
              }
            );
          }
        }
      );
    }
  };

  handleDeleteThreadDialog = (
    event: React.MouseEvent<HTMLElement>,
    status: boolean
  ) => {
    event.preventDefault();
    this.setState(() => ({
      showConfirmDeleteDialog: status
    }));
  };

  handleOnCancelDeleteThread = (event: React.MouseEvent<HTMLElement>) => {
    this.handleDeleteThreadDialog(event, false);
  };

  handleOnDeleteThreadClick = (event: React.MouseEvent<HTMLElement>) => {
    this.handleDeleteThreadDialog(event, true);
  };

  handleCommentSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { xhqUser, threadData, actions } = this.props;
    const { commentInputValue } = this.state;
    if (xhqUser && threadData && threadData.data && commentInputValue) {
      actions!.postThreadComment({
        body: commentInputValue,
        excerpt: commentInputValue,
        createdBy: xhqUser.id,
        parent: threadData.data.id as string,
        labels: [
          {
            key: MESSAGES_LABEL_KEYS.ROLE,
            value: getRoleLabel("STAFF")
          }
        ]
      });
      this.setState({ commentInputValue: "" });
    }
  };

  handleFavouritesClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { xhqUser, threadData, favouriteThreadData, actions } = this.props;

    if (!threadData) {
      return;
    }

    const thread = threadData.data;

    if (favouriteThreadData!.isLoading) {
      return;
    }

    if (xhqUser && thread) {
      const { favorite } = thread;
      if (!favorite) {
        actions!.updateThreadAsFavourite(
          thread.id!,
          thread.parentId,
          xhqUser.id
        );
      } else {
        actions!.updateThreadAsNonFavourite(favorite.id, xhqUser.id);
      }
    }
  };

  handleMarkThreadReadClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const {
      xhqUser,
      isUpdatingThreadAsReadOrUnread,
      threadData,
      actions
    } = this.props;

    if (!threadData) {
      return;
    }

    const thread = threadData.data;

    if (isUpdatingThreadAsReadOrUnread) {
      return;
    }

    if (xhqUser && thread && thread.id) {
      const { read } = thread;

      actions!.updateThreadAsReadOrUnread(thread.id, thread.parentId, !read);
    }
  };

  getShouldEnableThreadReplies(threadChannel: IChannel) {
    if (threadChannel) {
      return Boolean(threadChannel.allowThreadReplies);
    }

    return false;
  }

  get getSelectedChannel() {
    const {
      match: {
        params: { channelName }
      },
      allUserChannelsMap
    } = this.props;

    return allUserChannelsMap[`Channel-${channelName}`];
  }

  get isAnnouncement() {
    const {
      match: {
        params: { filter }
      }
    } = this.props;
    return isAnnouncementFilter(filter);
  }

  get showAudienceList() {
    return this.props.match.params.filter === FILTERS.SENT;
  }

  render() {
    const {
      threadData,
      commentData,
      deleteThreadData,
      xhqUser,
      allUserChannelsMap,
      match
    } = this.props;

    const { showConfirmDeleteDialog, commentInputValue } = this.state;

    if (threadData && threadData!.error) {
      return <div>There was an error while loading the thread</div>;
    }

    if (
      (match.params.threadId &&
        threadData &&
        (threadData!.isFetching || threadData.isFetching === null))
    ) {
      return <StyledLoadingPanel />;
    }

    if (!threadData || !threadData.data) {
      return (
        <EmptyScreenPlaceholder
          imageSrc={MessagesPlaceholderImage}
          title={`Select ${
            this.isAnnouncement ? "an announcement" : "a thread"
          } to display`}
        />
      );
    }

    const selectedChannel = getThreadChannel(
      allUserChannelsMap,
      threadData.data!,
      this.getSelectedChannel
    );
    const shouldEnableReplies = this.getShouldEnableThreadReplies(
      selectedChannel!
    );
    const message = threadData!.data!;

    const {
      title,
      body,
      createdBy,
      children,
      createdAt,
      labels,
      favorite,
      notification,
      lastEditedAt,
      read,
      scheduledAt,
      sentAt,
      targets
    } = message;

    const attachments = get(message, "attachments.items", []);

    const { id: memberId = "", firstName = "", lastName = "", avatar = null } =
      createdBy || {};
    const comments = get(children, "items");
    const label = getLabelByKey(MESSAGES_LABEL_KEYS.ROLE, labels);
    const details = get(label, "value");
    const threadTimestamp = sentAt || createdAt!;
    const timestampSanitized = /^\d+$/.test(threadTimestamp)
      ? parseInt(threadTimestamp, 10)
      : threadTimestamp;

    const isCurrentChannelOwner = isChannelOwner(selectedChannel, xhqUser!);
    const isCurrentChannelModerator = isChannelModerator(
      selectedChannel,
      xhqUser!
    );
    const isCurrentThreadCreator = isThreadCreator(message, xhqUser!);
    const isScheduled = !isEmpty(scheduledAt) && isEmpty(sentAt);

    return (
      <StyledScrollableColumn flex={1}>
        <Wrapper>
          <ThreadWrapper>
            <Thread
              xhqUser={xhqUser!}
              selectedChannel={selectedChannel}
              isChannelOwner={isCurrentChannelOwner}
              isChannelModerator={isCurrentChannelModerator}
              isThreadCreator={isCurrentThreadCreator}
              isAnnouncement={this.isAnnouncement}
              showAudienceList={this.showAudienceList}
              targets={targets}
              author={{
                id: memberId,
                firstName,
                lastName,
                details,
                avatar: avatar || DefaultAvatar
              }}
              title={title!}
              content={body!}
              attachments={attachments}
              timestamp={
                !isScheduled
                  ? moment(timestampSanitized).calendar()
                  : moment(scheduledAt!).calendar()
              }
              editedMessage={lastEditedAt ? "(Updated)" : ""}
              isUnread={!read}
              isFavourite={!isEmpty(favorite)}
              isImportant={
                !isEmpty(notification) &&
                get(notification, "status") === NOTIFABLE_STATUS.IMPORTANT
              }
              isScheduled={isScheduled}
              onBackClick={this.handleOnBackClick}
              onUpdateClick={this.handleThreadUpdateClicks}
              onForwardClick={this.handleThreadForwardClick}
              onMarkThreadReadClick={this.handleMarkThreadReadClick}
              onFavouritesClick={this.handleFavouritesClick}
              onDeleteThread={this.handleOnDeleteThreadClick}
            />
            {shouldEnableReplies && comments ? (
              <>
                <HR />
                <CommentList
                  comments={comments}
                  totalCommentsCount={comments.length}
                  favoritesCount={0}
                />
              </>
            ) : null}
          </ThreadWrapper>
          {shouldEnableReplies ? (
            <CommentInput
              placeholder="What's on your mind?"
              avatarSrc={DefaultAvatar}
              value={commentInputValue}
              onChange={this.handleCommentChange}
              onClick={this.handleCommentSubmit}
              disabled={commentData!.isFetching as boolean}
              hasError={!isEmpty(commentData!.error)}
            />
          ) : null}
          {(showConfirmDeleteDialog || deleteThreadData!.isLoading) && (
            <ConfirmationDialog
              title="Are you sure?"
              description="This thread will be archived."
              acceptButtonLabel={
                deleteThreadData!.isLoading ? "DELETING..." : "DELETE"
              }
              acceptButtonDisabled={deleteThreadData!.isLoading}
              onAccept={this.handleOnConfirmDeleteThread}
              onCancel={this.handleOnCancelDeleteThread}
            />
          )}
        </Wrapper>
      </StyledScrollableColumn>
    );
  }
}

export default withRouter(ThreadView as React.ComponentType<IProps>);
