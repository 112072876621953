import gql from "graphql-tag";

import { THREAD_FRAGMENT } from "../fragments/thread";
import { TARGETS_FRAGMENT } from "../fragments/targets";

export const GET_THREADS = gql`
  query Threads($channel: String!, $limit: Int, $pageToken: String) {
    messages(
      where: { parent: $channel }
      limit: $limit
      pageToken: $pageToken
    ) {
      items {
        id
        title
        body
        read
        createdBy {
          id
          firstName
          lastName
          avatar
        }
        parentId
        labels {
          key
          value
        }
        createdAt
        lastEditedAt
      }
      nextPage
    }
  }
`;

export const GET_THREAD = gql`
  ${THREAD_FRAGMENT}

  query Thread(
    $id: ID!
    $channelId: ID
    $memberId: ID!
    $status: String!
    $other: String
  ) {
    message(id: $id, channelId: $channelId, where: { other: $other }) {
      ...ThreadFields
      favorite(memberId: $memberId) {
        id
      }
      attachments {
        items {
          id
          name
          url
        }
      }
      notification(memberId: $memberId, status: $status) {
        id
        status
        mergeFields {
          name
          value
          type
        }
        targets {
          audience {
            id
            name
          }
          role
          fromCSV
        }
      }
    }
  }
`;

export const CREATE_THREADS = gql`
  mutation CreateMessage($data: MessageCreateInput!) {
    createMessage(data: $data) {
      id
      title
      body
      excerpt
      read
      createdBy {
        id
        firstName
        lastName
        avatar
      }
      parentId
      labels {
        key
        value
      }
      createdAt
      lastEditedAt
    }
  }
`;

export const UPDATE_THREAD = gql`
  ${TARGETS_FRAGMENT}
  mutation UpdateMesage(
    $data: MessageUpdateInput!
    $messageId: ID!
    $parentId: ID!
  ) {
    updateMessage(data: $data, messageId: $messageId, parentId: $parentId) {
      id
      title
      body
      read
      excerpt
      createdBy {
        id
        firstName
        lastName
        avatar
      }
      aliasCreatedBy {
        id
      }
      parentId
      labels {
        key
        value
      }
      createdAt
      lastEditedAt
      sentAt
      scheduledAt
      targets {
        ...TargetFields
      }
    }
  }
`;

export const DELETE_THREAD = gql`
  mutation DeleteMessage($messageId: ID!, $parentId: ID!) {
    deleteMessage(messageId: $messageId, parentId: $parentId) {
      id
      parentId
    }
  }
`;

export const THREAD_CREATED_SUBSCRIPTION = gql`
  subscription messageCreated($parent: String!) {
    messageCreated(where: { parent: $parent }) {
      id
      title
      body
      createdBy {
        avatar
      }
      read
      parentId
      createdAt
      lastEditedAt
    }
  }
`;

export const MARK_THREAD_AS_READ = gql`
  mutation MarkAsRead($messageId: ID!) {
    markAsRead(messageId: $messageId)
  }
`;

export const MARK_THREAD_AS_UNREAD = gql`
  mutation MarkAsUnRead($messageId: ID!) {
    markAsUnread(messageId: $messageId)
  }
`;
