import { combineReducers } from "redux";
import { get } from "lodash";

import threadOperations from "./threadsOperations";
import { getChannelNameFromId } from "../../utils/helpers";
import {
  DELETE_THREAD,
  UPDATE_THREAD,
  POST_THREAD,
  UPDATE_THREAD_AS_READ,
  FETCH_THREADS,
  MESSAGE_CREATED_SUBSCRIPTION,
  CLEAR_THREADS,
  SUBSCRIBE_TO_CREATE_MESSAGE
} from "../../constants/actions/threads";
import { NOTIFABLE_STATUS } from "../../constants";

interface IThreadItemsPartial {
  items: IThread[];
}

type SuccessActionWithParam = IActionTypeWithParam<string, IThreadsData>;
type payloads =
  | IDeleteThreadDataResponse
  | IThread
  | IMarkAsReadPayload
  | string;

type ActionTypes =
  | SuccessActionWithParam
  | LoadingActionWithParam
  | ErrorActionWithParam
  | loadingAction
  | errorAction
  | IActionType<string, payloads>;

const initialChannelData = {
  items: [],
  nextPage: null,
  hasBeenFetched: false,
  hasBeenSubscribed: false
};

const initialChannel = {
  data: initialChannelData,
  isFetching: false,
  error: null
};

const initialThreadsByChannelState: IThreadsState = {};

const setSuccess = (
  action: SuccessActionWithParam,
  state: IThreadsState
): IThreadsState => {
  const { payload, typeParam } = action;
  const { withFreshList } = payload;

  return {
    ...state,
    [typeParam]: {
      ...state[typeParam],
      data: {
        ...state[typeParam].data,
        ...payload,
        items: withFreshList
          ? [...payload.items]
          : [...state[typeParam].data.items, ...payload.items],
        withFreshList,
        hasBeenFetched: true
      },
      isFetching: false,
      error: null
    }
  };
};

const setLoading = (
  action: LoadingActionWithParam,
  state: IThreadsState
): IThreadsState => {
  const { payload, typeParam } = action;

  return {
    ...state,
    [typeParam]: {
      data: {
        items: []
      },
      ...state[typeParam],
      isFetching: payload,
      error: null
    }
  };
};

const setError = (
  action: ErrorActionWithParam,
  state: IThreadsState
): IThreadsState => {
  const { payload, typeParam } = action;

  return {
    ...state,
    [typeParam]: {
      data: {
        items: []
      },
      ...state[typeParam],
      isFetching: false,
      error: payload
    }
  };
};

const updateThread = (
  threadChunk: IThread | IMarkAsReadPayload,
  state: IThreadsState
) => {
  const channelName = getChannelNameFromId(threadChunk.parentId);
  const channelItems = (get(state[channelName], "data.items") || []).map(
    (item: IThread) => {
      if (item.id === threadChunk.id) {
        return {
          ...item,
          ...threadChunk
        };
      }
      return item;
    }
  );

  return updateChannelData<IThreadItemsPartial>(
    { items: channelItems },
    channelName,
    state
  );
};

const deleteThread = (id: string, channelId: string, state: IThreadsState) => {
  const channelName = getChannelNameFromId(channelId);
  const channelItems = state[channelName].data.items.filter(
    (item: IThread) => item.id !== id
  );

  return updateChannelData<IThreadItemsPartial>(
    { items: channelItems },
    channelName,
    state
  );
};

const addThread = (thread: IThread, state: IThreadsState): IThreadsState => {
  const channel = getChannelNameFromId(thread.parentId);
  const hasThread = state[channel].data.items.some(
    (item: IThread) => item.id === thread.id
  );

  return updateChannelData<IThreadItemsPartial>(
    {
      items: hasThread
        ? state[channel].data.items
        : [thread, ...state[channel].data.items]
    },
    channel,
    state
  );
};

const updateChannelData = <T extends Partial<IThreadsData>>(
  dataChunk: T,
  channel: string,
  state: IThreadsState
): IThreadsState => {
  const channelState = state[channel] || initialChannel;

  return {
    ...state,
    [channel]: {
      ...channelState,
      data: {
        ...channelState.data,
        ...dataChunk
      }
    }
  };
};

const clearThreads = (action: any, state: IThreadsState) => {
  const channelName = action.channelName;
  const channelState = state[channelName] || initialChannel;

  return {
    ...state,
    [channelName]: {
      data: {
        ...channelState.data,
        items: [],
        nextPage: null,
        hasBeenFetched: false
      }
    }
  };
};

const threadsByChannel = (
  state: IThreadsState = initialThreadsByChannelState,
  action: ActionTypes
): IThreadsState => {
  switch (action.type) {
    case `${FETCH_THREADS}_SUCCESS`:
      return {
        ...setSuccess(action as SuccessActionWithParam, state)
      };
    case `${FETCH_THREADS}_LOADING`:
      return {
        ...setLoading(action as LoadingActionWithParam, state)
      };
    case `${FETCH_THREADS}_ERROR`:
      return {
        ...setError(action as ErrorActionWithParam, state)
      };
    case `${DELETE_THREAD}_SUCCESS`:
      if (
        (action.payload as IDeleteThreadDataResponse).parentId ===
        NOTIFABLE_STATUS.ANNOUNCEMENT
      ) {
        return {
          ...state
        };
      }

      return {
        ...state,
        ...deleteThread(
          (action.payload as IDeleteThreadDataResponse).id,
          (action.payload as IDeleteThreadDataResponse).parentId,
          state
        )
      };
    case `${UPDATE_THREAD}_SUCCESS`:
      return {
        ...state,
        ...updateThread(action.payload as IThread, state)
      };
    case `${POST_THREAD}_SUCCESS`:
      return {
        ...state,
        ...addThread(action.payload as IThread, state)
      };
    case `${MESSAGE_CREATED_SUBSCRIPTION}_SUCCESS`:
      return {
        ...state,
        ...addThread(action.payload as IThread, state)
      };
    case `${SUBSCRIBE_TO_CREATE_MESSAGE}_SUCCESS`:
      return {
        ...state,
        ...updateChannelData(
          { hasBeenSubscribed: true },
          action.payload as string,
          state
        )
      };
    case `${UPDATE_THREAD_AS_READ}_SUCCESS`:
      return {
        ...state,
        ...updateThread(action.payload as IMarkAsReadPayload, state)
      };
    case CLEAR_THREADS:
      return {
        ...state,
        ...clearThreads(action, state)
      };
    default:
      return state;
  }
};

export default combineReducers({ threadsByChannel, threadOperations });
