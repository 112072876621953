import * as React from "react";
import styled from "styled-components";

interface IProps {
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Wrapper = styled.div`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.secondary.main};
  font-size: 0.875rem;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.1rem 3rem 0.1rem 0;

  :hover {
    cursor: pointer;
  }
`;

const MenuItem = ({ children, className, onClick }: IProps) => (
  <Wrapper className={className} onClick={onClick} role="menuitem">
    {children}
  </Wrapper>
);

export default MenuItem;
