import * as React from "react";
import styled, { withTheme } from "styled-components";

import Icon from "../Icon";
import * as Icons from "../../constants/icons";

interface IProps {
  month: string;
  color?: string;
  day: number;
  theme: any;
}

const Wrapper = styled.div`
  position: relative;
  max-width: 2rem;
  max-height: 2rem;
  line-height: 1rem;
`;

const EventIconContentBase = styled.div`
  color: ${props =>
    props.color ? props.color : props.theme.colors.primary.light};
  font-family: ${props => props.theme.fonts.main};
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

const EventMonth = styled(EventIconContentBase)`
  font-size: 0.375rem;
  top: 0;
`;

const EventDay = styled(EventIconContentBase)`
  font-size: 0.75rem;
  top: 0.9rem;
`;

const EventIcon = (props: IProps) => {
  const { month, day, color, theme } = props;

  return (
    <Wrapper>
      <Icon
        name={Icons.BIGCALENDAR}
        color={color ? color : theme.colors.primary.light}
      />
      <EventMonth color={color}>{month.toUpperCase()}</EventMonth>
      <EventDay color={color}>{day}</EventDay>
    </Wrapper>
  );
};

export default withTheme(EventIcon);
