import styled from "styled-components";

import ActionButton from "../../ui/ActionButton";
import Filters from "../../ui/Filters";
import media from "../../lib/media";
import { LoadingPanel } from "../../ui/Loading";

interface IViewProps {
  show: boolean;
}

export const StyledFilters = styled(Filters)<IViewProps>`
  transition: left 0.2s;
  left: ${({ show }: IViewProps) => (show ? "0" : "-100%")};
  background: ${({ theme }: any) => theme.colors.white};
  position: absolute !important;
  z-index: 98;
  width: 100%;
  height: 100% !important;
  min-width: 16.875rem;

  ${media.greaterThan("small")`
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.1);
    transition: left 0.2s;
    left: ${({ show }: IViewProps) => (show ? "0" : "-50%")};
    background: ${({ theme }: any) => theme.colors.white};
    position: absolute !important;
    z-index: 98;
    width: 50%;
    height: 100% !important;
  `};

  ${media.greaterThan("medium")`
    box-shadow: none;
    left: unset;
    position: relative !important;
    width: 11rem;
  `};

  ${media.greaterThan("large")`
    box-shadow: none;
    left: unset;
    position: relative !important;
    width: 13.5rem;
  `};

  ${media.greaterThan("xlarge")`
    box-shadow: none;
    left: unset;
    position: relative !important;
    width: 17.5rem;
  `};
`;

export const StyledViewer = styled.div<IViewProps>`
  display: flex;
  flex: 1;

  ${media.lessThan("medium")`
    transition: right 0.2s;
    right: ${({ show }: IViewProps) => (show ? "0" : "-100%")};
    overflow: hidden;
    background: ${({ theme }: any) => theme.colors.white};
    position: absolute !important;
    z-index: 98;
    width: 100%;
    height: 100% !important;

    &>div {
      overflow: hidden !important;
    }
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${media.greaterThan("small")`
    padding-bottom: 0;
  `};

  ${media.greaterThan("large")`
    flex-direction: row;
  `};

  ${media.lessThan("medium")`
    height: calc(100vh - 9rem) !important;
  `};
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const StyledActionButton = styled(ActionButton)`
  padding: 1.1rem;
`;

export const StyledLoadingPanel = styled(LoadingPanel)`
  display: flex;
  flex: 1;
`;
