import gql from "graphql-tag";

import { THREAD_FRAGMENT } from "../fragments/thread";

export const GET_NOTIFICATIONS = gql`
  query Notifications(
    $memberId: ID!
    $status: String!
    $limit: Int
    $pageToken: String
    $where: NotificationWhereInput
  ) {
    notifications(
      memberId: $memberId
      status: $status
      limit: $limit
      pageToken: $pageToken
      where: $where
    ) {
      items {
        id
        status
        object {
          ... on Message {
            id
            title
            body
            excerpt
            parentId
            read
            createdBy {
              id
              firstName
              lastName
              avatar
            }
            createdAt
            sentAt
            scheduledAt
          }
        }
        mergeFields {
          name
          value
          type
        }
      }
      nextPage
    }
  }
`;

export const GET_ANNOUNCEMENTS_BY_CREATOR_ID = gql`
  query Announcements(
    $creatorId: String
    $withoutSent: Boolean
    $withoutScheduled: Boolean
    $limit: Int
    $pageToken: String
  ) {
    announcements(
      where: {
        creatorId: $creatorId
        withoutSent: $withoutSent
        withoutScheduled: $withoutScheduled
      }
      limit: $limit
      pageToken: $pageToken
    ) {
      items {
        id
        title
        body
        parentId
        read
        createdBy {
          id
          firstName
          lastName
          avatar
        }
        createdAt
        sentAt
        scheduledAt
      }
      nextPage
    }
  }
`;

export const CREATE_NOTIFICATION_FOR_CHANNEL_WITH_ROLE = gql`
  mutation NotifyAllMembersOfChannelWithRole(
    $channelId: ID!
    $role: String
    $objectId: ID!
    $status: String!
    $returnForMemberIdOnly: String
  ) {
    notifyAllMembersOfChannelWithRole(
      channelId: $channelId
      role: $role
      objectId: $objectId
      status: $status
      returnForMemberIdOnly: $returnForMemberIdOnly
    )
  }
`;

export const NOTIFICATION_CREATED = gql`
  subscription notificationCreated($memberId: ID!) {
    notificationCreated(memberId: $memberId) {
      id
      status
      object {
        ... on Message {
          id
          title
          body
          parentId
          read
          createdBy {
            id
            firstName
            lastName
            avatar
          }
          createdAt
          sentAt
          scheduledAt
        }
      }
      mergeFields {
        name
        type
        value
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  ${THREAD_FRAGMENT}

  query Notification($id: ID!, $memberId: ID!) {
    notification(notificationId: $id, memberId: $memberId) {
      id
      status
      object {
        ... on Message {
          ...ThreadFields
          attachments {
            items {
              id
              name
              url
            }
          }

          favorite(memberId: $memberId) {
            id
          }
        }
      }
    }
  }
`;
