import {
  UPDATE_ARCHIVE_CHANNEL,
  SET_DEFAULT_ARCHIVED_CHANNEL_STATE
} from "../../constants/actions/channels";

const SUCCESS = `${UPDATE_ARCHIVE_CHANNEL}_SUCCESS`;
const LOADING = `${UPDATE_ARCHIVE_CHANNEL}_LOADING`;
const ERROR = `${UPDATE_ARCHIVE_CHANNEL}_ERROR`;

type ActionTypes =
  | IActionType<typeof SUCCESS, IChannel>
  | IActionType<typeof LOADING, boolean>
  | IActionType<typeof ERROR, string>;

const initialState: IAsyncEntityState<TChannel> = {
  data: null,
  isFetching: false,
  error: ""
};

const archivedChannel = (
  state: IAsyncEntityState<TChannel> = initialState,
  action: ActionTypes
): IAsyncEntityState<TChannel> => {
  switch (action.type) {
    case SET_DEFAULT_ARCHIVED_CHANNEL_STATE:
      return initialState;
    case SUCCESS:
      return {
        ...state,
        data: {
          ...(action.payload as IChannel)
        },
        isFetching: false,
        error: ""
      };
    case LOADING:
      return {
        ...state,
        isFetching: action.payload as boolean,
        error: ""
      };
    case ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload as string
      };
    default:
      return state;
  }
};

export default archivedChannel;
