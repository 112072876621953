import gql from "graphql-tag";

import { CHANNEL_FRAGMENT } from "../fragments/channel";

export const CREATE_CHANNEL = gql`
  ${CHANNEL_FRAGMENT}

  mutation CreateChannel(
    $name: String!
    $title: String
    $description: String
    $ownerId: ID!
    $type: ChannelType!
    $allowThreadReplies: Boolean
  ) {
    createChannel(
      data: {
        name: $name
        title: $title
        description: $description
        type: $type
        createdBy: $ownerId
        allowThreadReplies: $allowThreadReplies
        owner: $ownerId
      }
    ) {
      ...ChannelFields
    }
  }
`;

export const UPDATE_CHANNEL = gql`
  ${CHANNEL_FRAGMENT}

  mutation UpdateChannel($id: ID!, $data: ChannelUpdateInput!) {
    updateChannel(id: $id, data: $data) {
      ...ChannelFields
    }
  }
`;

export const GET_CHANNELS_BY_TYPE = gql`
  ${CHANNEL_FRAGMENT}

  query ChannelsByType($type: ChannelType!) {
    channelsByType(type: $type) {
      items {
        ...ChannelFields
      }
    }
  }
`;

export const ADD_CHANNEL_MEMBERS = gql`
  mutation AddChannelMembers(
    $channelId: ID!
    $type: ChannelType!
    $members: [MemberChannelRoleInput]!
  ) {
    addChannelMembers(channelId: $channelId, type: $type, members: $members)
  }
`;

export const ARCHIVE_CHANNEL = gql`
  ${CHANNEL_FRAGMENT}

  mutation ArchiveChannel($channelId: ID!) {
    archiveChannel(id: $channelId) {
      ...ChannelFields
    }
  }
`;

export const ADD_CHANNEL_AUDIENCE = gql`
  mutation AddChannelAudience(
    $channelId: ID!
    $type: ChannelType!
    $audiences: [AudienceContextPairInput!]
  ) {
    addAudiencesAsChannelMembers(
      channelId: $channelId
      type: $type
      audiences: $audiences
    )
  }
`;

export const REMOVE_ALL_CHANNEL_MEMBERS = gql`
  mutation RemoveAllChannelMembers($channelId: ID!, $type: ChannelType!) {
    removeAllChannelMembers(channelId: $channelId, type: $type)
  }
`;

export const GET_CHANNEL = gql`
  ${CHANNEL_FRAGMENT}

  query Channel($name: String!, $type: ChannelType!) {
    channel(name: $name, type: $type) {
      ...ChannelFields
      members {
        items {
          id
          firstName
          lastName
          avatar
        }
      }
      audiences {
        audience {
          id
          name
          members {
            items {
              id
            }
          }
        }
        audienceContext
      }
    }
  }
`;
