import { pxToRem } from "styled-media-query";

const BASE_FONT_SIZE = 16;

const BREAKPOINT_SMALL = 576;
const BREAKPOINT_MEDIUM = 906;
const BREAKPOINT_LARGE = 1024;
const BREAKPOINT_XLARGE = 1200;

export const BREAKPOINTS = pxToRem(
  {
    small: `${BREAKPOINT_SMALL}px`,
    medium: `${BREAKPOINT_MEDIUM}px`,
    large: `${BREAKPOINT_LARGE}px`,
    xlarge: `${BREAKPOINT_XLARGE}px`
  },
  BASE_FONT_SIZE
);
