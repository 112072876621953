import { filterXSS, whiteList } from "xss";

export default (htmlContent: string): string =>
  filterXSS(htmlContent, {
    whiteList: {
      ...whiteList,
      p: ["style"],
      figure: ["class"],
      oembed: ["url"]
    }
  } as any);
