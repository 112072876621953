import { isEmpty } from "lodash";
import { FILTERS } from "../constants";

interface ILocalStorageChannel {
  id: string;
  bold: boolean;
}

interface ILocalStorageXhqUser {
  channels: {
    items: ILocalStorageChannel[];
  };
  filters: string[];
  id: string;
}

export const getLocalStorageXhqUser = (id: string): ILocalStorageXhqUser => {
  const users = localStorage.getItem("users");
  const initialUser = getInitialUser();

  if (!users) {
    return initialUser;
  }

  const usersObject = JSON.parse(users);

  return {
    ...initialUser,
    ...usersObject[id]
  };
};

export const saveLocalStorageXhqUserChannels = (
  id: string,
  channels: ILocalStorageChannel[]
): void => {
  const { usersObject, currentUserObject } = getUsersObjects(id);
  const newUsersSet = {
    ...usersObject,
    [id]: {
      ...currentUserObject,
      id,
      channels: { items: [...channels] }
    }
  };
  const usersString = JSON.stringify(newUsersSet);

  localStorage.setItem("users", usersString);
};

export const saveLocalStorageXhqUserFilters = (
  id: string,
  filters: any = []
): void => {
  const { usersObject, currentUserObject } = getUsersObjects(id);
  const newUsersSet = {
    ...usersObject,
    [id]: {
      ...currentUserObject,
      id,
      filters: [...filters]
    }
  };
  const usersString = JSON.stringify(newUsersSet);

  localStorage.setItem("users", usersString);
};

const getUsersObjects = (id: string) => {
  const users = localStorage.getItem("users") || "{}";
  const usersObject = JSON.parse(users);
  const initialUser = getInitialUser();
  const currentUserObject = !isEmpty(usersObject[id])
    ? usersObject[id]
    : initialUser;
  return {
    usersObject,
    currentUserObject
  };
};

const getInitialUser = () => {
  return {
    channels: { items: [] },
    id: "",
    filters: [FILTERS.INBOX, FILTERS.IMPORTANT, FILTERS.FAVOURITES]
  };
};
