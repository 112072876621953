import * as React from "react";
import styled from "styled-components";

interface IProps {
  children?: React.ReactNode;
  divWidth?: string;
  className?: string;
  withShadow?: boolean;
}

interface IWrapperProps {
  withShadow: boolean;
}

const Wrapper = styled<IWrapperProps, "div">("div")`
  background-color: white;
  display: inline-block;
  border-radius: 4px;
  padding: 1rem;
  z-index: 100;
  width: ${(props: any) => props.divWidth || "auto"};
  ${({ withShadow }: IWrapperProps) =>
    withShadow
      ? "box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.05);"
      : ""};
`;

const Panel = ({ withShadow = true, ...props }: IProps) => (
  <Wrapper withShadow={withShadow} {...props}>
    {props.children}
  </Wrapper>
);

export default Panel;
