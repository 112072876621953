import { IS_CLEANING_APOLLO_CACHE } from "../../constants/actions/app";

type ActionTypes = IActionType<typeof IS_CLEANING_APOLLO_CACHE, boolean>;

const initialState: IAppStateChunk = {
  isCleaningApolloCache: null
};

const user = (
  state: IAppStateChunk = initialState,
  action: ActionTypes
): IAppStateChunk => {
  switch (action.type) {
    case IS_CLEANING_APOLLO_CACHE:
      return {
        ...state,
        isCleaningApolloCache: action.payload
      };
    default:
      return state;
  }
};

export default user;
