import {
  ALICE_BLUE,
  BLACK,
  BLUE,
  DARK_NAVY,
  GREEN,
  GREY,
  PEARL,
  LIGHTER_GREY,
  LIGHT_BLUE,
  LIGHT_GREEN,
  LIGHT_GREY,
  LIGHT_ORANGE,
  LIGHT_RED,
  NAVY,
  ORANGE,
  RED,
  WHITE,
  LIGHT_NAVY,
  SAPPHIRE,
  LIGHT_EMERALD,
  MIDNIGHT,
  DARK_ORANGE,
  MIDNIGHT_BLUE,
  SEMI_TRANSPARENT_WHITE
} from "./constants/colors";

const theme = {
  colors: {
    primary: {
      dark: DARK_NAVY,
      main: NAVY,
      light: LIGHT_NAVY
    },
    secondary: {
      light: LIGHT_GREY,
      lighter: LIGHTER_GREY,
      semilight: GREY,
      main: MIDNIGHT
    },
    tertiary: {
      main: ALICE_BLUE
    },
    danger: {
      main: RED,
      light: LIGHT_RED
    },
    warning: {
      dark: DARK_ORANGE,
      main: ORANGE,
      light: LIGHT_ORANGE
    },
    success: {
      main: GREEN,
      semilight: LIGHT_EMERALD,
      light: LIGHT_GREEN
    },
    info: {
      main: BLUE,
      light: LIGHT_BLUE,
      lightest: SAPPHIRE
    },
    black: BLACK,
    white: WHITE,
    transparentWhite: SEMI_TRANSPARENT_WHITE,
    pearl: PEARL,
    midnightBlue: MIDNIGHT_BLUE
  },
  fonts: {
    main: "'Lato', sans-serif",
    secondary: "'Playfair Display', serif"
  },
  name: "DEFAULT"
};

export default theme;
