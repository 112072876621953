import * as React from "react";
import styled, { withTheme } from "styled-components";

import { ALERT_VARIANTS } from "../../constants/alert";
import Box from "../Box";

interface IProps {
  className?: string;
  theme?: any;
  message: string;
  variant?: string;
}

interface ITextVariant {
  color: string;
}

interface IAlertVariantColor {
  backgroundColor: string;
  textColor: string;
}

interface IAlertVariantColorMap {
  [s: string]: IAlertVariantColor;
}

const Text = styled.p<ITextVariant>`
  color: ${({ color }) => color};
`;

class Alert extends React.PureComponent<IProps> {
  alertVariantColorMap: IAlertVariantColorMap;
  defaultAlertVariantColor: IAlertVariantColor;

  constructor(props: IProps) {
    super(props);
    const { theme } = props;

    this.defaultAlertVariantColor = {
      backgroundColor: theme.colors.white,
      textColor: theme.colors.black
    };

    this.alertVariantColorMap = {
      [ALERT_VARIANTS.SUCCESS]: {
        backgroundColor: theme.colors.success.light,
        textColor: theme.colors.success.main
      },
      [ALERT_VARIANTS.INFO]: {
        backgroundColor: theme.colors.info.light,
        textColor: theme.colors.info.main
      },
      [ALERT_VARIANTS.WARNING]: {
        backgroundColor: theme.colors.warning.light,
        textColor: theme.colors.warning.main
      },
      [ALERT_VARIANTS.DANGER]: {
        backgroundColor: theme.colors.danger.light,
        textColor: theme.colors.danger.main
      }
    };
  }

  render() {
    const { className, variant, message } = this.props;
    const variantColor = variant
      ? this.alertVariantColorMap[variant]
      : this.defaultAlertVariantColor;

    return (
      <Box
        className={className}
        borderless={true}
        backgroundColor={variantColor.backgroundColor}
      >
        <Text color={variantColor.textColor}>{message}</Text>
      </Box>
    );
  }
}

export default withTheme(Alert);
