import gql from "graphql-tag";

import { COMMENT_FRAGMENT } from "../fragments/comment";

export const CREATE_COMMENTS = gql`
  ${COMMENT_FRAGMENT}

  mutation CreateMessage($data: MessageCreateInput!) {
    createMessage(data: $data) {
      ...CommentFields
    }
  }
`;
