import * as React from "react";
import styled, { StyledComponentClass } from "styled-components";

import Icon from "../Icon/Icon";
import ReactTooltip from "react-tooltip";

interface IStyledComponentClass extends StyledComponentClass<any, any, any> {
  suppressClassNameWarning?: boolean;
}

const ToolTip: IStyledComponentClass = styled(ReactTooltip)`
  padding: 1rem 0.7rem;
  margin-top: -1.5rem !important;
  transition: opacity 0.3s;
  visibility: visible;
`;

const IconWrapper = styled.a`
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.danger.main};
  }
`;

interface IProps {
  id: string;
  className?: string;
  text: string;
  iconProps: any;
}

const TooltipIcon = (props: IProps) => {
  const { id, className, text, iconProps = {} } = props;

  return (
    <div className={className}>
      <ToolTip
        id={id}
        place="top"
        effect="solid"
        suppressClassNameWarning={true}
      >
        {text}
      </ToolTip>
      <IconWrapper data-tip={true} data-for={id}>
        <Icon {...iconProps} />
      </IconWrapper>
    </div>
  );
};

export default TooltipIcon;
