export const FETCH_THREADS = "FETCH_THREADS";
export const DELETE_THREAD = "DELETE_THREAD";
export const UPDATE_THREAD = "UPDATE_THREAD";
export const POST_THREAD = "POST_THREAD";
export const UPDATE_THREAD_AS_READ = "UPDATE_THREAD_AS_READ";
export const FETCH_THREAD = "FETCH_THREAD";
export const UPDATE_THREAD_AS_FAVOURITE = "UPDATE_THREAD_AS_FAVOURITE";
export const UPDATE_THREAD_AS_NON_FAVOURITE = "UPDATE_THREAD_AS_NON_FAVOURITE";
export const POST_THREAD_COMMENT = "POST_THREAD_COMMENT";
export const REMOVE_THREAD_DRAFT = "REMOVE_THREAD_DRAFT";
export const REMOVE_THREAD_DATA = "REMOVE_THREAD_DATA";
export const CLEAR_THREADS = "CLEAR_THREADS";
export const REMOVE_THREAD_OPERATION_DATA = "REMOVE_THREAD_OPERATION_DATA";
export const SUBSCRIBE_TO_CREATE_MESSAGE = "SUBSCRIBE_TO_CREATE_MESSAGE";
export const MESSAGE_CREATED_SUBSCRIPTION = "MESSAGE_CREATED_SUBSCRIPTION";
