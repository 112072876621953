import styled from "styled-components";

import media from "../../lib/media";
import Panel from "../Panel";

interface ITimeValue {
  isSelected?: boolean;
}

export const Wrapper = styled.div`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.main};
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.tertiary.main};
  cursor: pointer;
  position: relative;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.secondary.semilight};
  font-size: 0.6875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.secondary.main};
  font-size: 1.5rem;
  font-weight: normal;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
`;

export const StyledPanel = styled(Panel)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 18.15rem;
  padding: 1.875rem;

  ${media.lessThan("small")`
    right: -3rem;
  `};
`;

export const Divider = styled.div`
  margin: 0.125rem 0 1.6875rem;
  border-bottom: 0.125rem solid
    ${({ theme }) => theme.colors.secondary.semilight}1A;
`;

export const TimeValueContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const TimeValue = styled.div<ITimeValue>`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isSelected, theme }) =>
    isSelected
      ? `
    color: ${theme.colors.white};
    background-color: ${theme.colors.info.main};
    border-radius: 50px;
  `
      : ""}
`;

export const MeridianContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PickerValue = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.secondary.main};
`;

export const PlaceHolder = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.secondary.light};
`;
