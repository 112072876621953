import * as React from "react";
import styled from "styled-components";

import AttachmentItem from "../AttachmentItem";
import Chip from "../Chip";
import media from "../../lib/media";

interface IProps {
  className?: string;
  attachments: IAttachment[];
  inChips?: boolean;
  onRemoveAttachment?: (attachment: IAttachment) => void;
}

const Wrapper = styled.div`
  font-family: ${props => props.theme.fonts.main};
  font-size: 0.87rem;
  overflow: hidden;

  ${media.lessThan("medium")`
    width: 100%;
  `};
`;

const CountLabel = styled.span`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const AttachmentChip = styled(Chip)`
  margin-bottom: 0.5rem;
  display: flex;
  max-width: 22.56rem;
`;

class Attachment extends React.PureComponent<IProps> {
  onRemoveAttachment = (attachment: IAttachment) => (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    const { onRemoveAttachment } = this.props;

    if (onRemoveAttachment) {
      onRemoveAttachment(attachment);
    }
  };

  render() {
    const { className, attachments, inChips, onRemoveAttachment } = this.props;
    const count = attachments && attachments.length;
    return (
      <Wrapper className={className}>
        <CountLabel>{`${count} attachment${count > 1 ? "s" : ""}`}</CountLabel>
        {attachments.map((attachment: IAttachment, index: number) =>
          inChips ? (
            <AttachmentChip
              key={index}
              onClose={
                onRemoveAttachment
                  ? this.onRemoveAttachment(attachment)
                  : undefined
              }
            >
              <AttachmentItem name={attachment.name} href={attachment.url} />
            </AttachmentChip>
          ) : (
            <AttachmentItem
              key={index}
              name={attachment.name}
              href={attachment.url}
            />
          )
        )}
      </Wrapper>
    );
  }
}

export default Attachment;
