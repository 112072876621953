import * as React from "react";
import styled from "styled-components";

import media from "../../lib/media";
import Icon from "../Icon";
import { ATTACHMENT } from "../../constants/icons";

interface IProps {
  name: string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const FilesWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.87rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin-left: -0.6rem;
  cursor: pointer;

  ${media.lessThan("small")`
    font-size: 0.6875rem;
  `};

  ${media.greaterThan("small")`
    font-size: 0.75rem;
  `};
`;

const AttachmentItem = ({ name, onClick, href }: IProps) => {
  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <FilesWrapper key={name} onClick={onClick}>
          <Icon name={ATTACHMENT} opacity={0.5} />
          <span>{name}</span>
        </FilesWrapper>
      </a>
    );
  }
  return (
    <FilesWrapper key={name} onClick={onClick}>
      <Icon name={ATTACHMENT} opacity={0.5} />
      <span>{name}</span>
    </FilesWrapper>
  );
};

export default AttachmentItem;
