import * as React from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";

import Icon from "../Icon";
import media from "../../lib/media";
import * as Icons from "../../constants/icons";

interface IProps {
  onCloseClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
  title: React.ReactNode;
  fullScreen?: boolean;
  navigationItems?: { [s: string]: IModalNavigationItem };
  onNavigationItemClick?: (item: IModalNavigationItem) => void;
  selectedNavigationItem?: string;
  className?: string;
  modalRootId?: string;
}

interface IContentWrapperProps {
  fullScreen?: boolean;
}

interface ITitleProps {
  fullScreen?: boolean;
}

const Wrapper = styled.div`
  z-index: 9999;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.white},
    rgba(255, 255, 255, 0)
  );
  overflow-y: scroll;

  ${media.greaterThan("medium")`
    flex-direction: row;
  `};
`;

const ContentWrapper = styled.div<IContentWrapperProps>`
  width: ${({ fullScreen }) => (fullScreen ? "100%" : "auto")};
  height: ${({ fullScreen }) => (fullScreen ? "100%" : "auto")};
  margin: auto;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 2rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;

  ${media.lessThan("medium")`
     width: 100%;
     padding: 1.5rem;
     margin: initial;
     flex: 1 0 100%;
  `};
`;

const Title = styled.div<ITitleProps>`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.secondary.main};
  height: ${({ fullScreen }) => (fullScreen ? "2.8rem" : "auto")};
`;

const CenterTitle = styled(Title)`
  text-align: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: -1.5rem;
  top: -1.5rem;
`;

interface IModalNavigationElement {
  selected?: boolean;
}

const ModalNavigationItem = styled.div<IModalNavigationElement>`
  margin: 1rem 1rem 0 1rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.main};
  font-family: ${({ theme }) => theme.fonts.main};
  ${({ selected }) => (selected ? "opacity: 1" : "opacity: 0.5")};
  cursor: pointer;
`;

const ModalNavigation = styled.div`
  display: flex;
  flex-direction: row;
`;

class Modal extends React.Component<IProps> {
  el: any;

  constructor(props: IProps) {
    super(props);
    this.el = document.createElement("div");
  }

  get rootElement() {
    const { modalRootId = "modal-root" } = this.props;
    return document.getElementById(modalRootId);
  }

  componentDidMount() {
    const modalRoot = this.rootElement;
    if (modalRoot && this.el) {
      modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    const modalRoot = this.rootElement;
    if (modalRoot && this.el) {
      modalRoot.removeChild(this.el);
    }
  }

  handleNavigationItemClick = (navigationItem: IModalNavigationItem) => (
    event: React.MouseEvent<HTMLElement>
  ) => {
    const { onNavigationItemClick } = this.props;

    if (onNavigationItemClick) {
      return onNavigationItemClick(navigationItem);
    }
  };

  renderModalNavigation = (navigationItems: {
    [s: string]: IModalNavigationItem;
  }) => {
    const { selectedNavigationItem } = this.props;

    return (
      <ModalNavigation>
        {Object.keys(navigationItems).map((key, index) => (
          <ModalNavigationItem
            key={index}
            onClick={this.handleNavigationItemClick(navigationItems[key])}
            role="button"
            selected={selectedNavigationItem === key}
          >
            {navigationItems[key].name}
          </ModalNavigationItem>
        ))}
      </ModalNavigation>
    );
  };

  get renderPortal() {
    const {
      title,
      onCloseClick,
      children,
      fullScreen,
      navigationItems,
      className
    } = this.props;
    return createPortal(
      <Wrapper className={className}>
        <ContentWrapper fullScreen={fullScreen}>
          <HeaderWrapper>
            {onCloseClick ? (
              <>
                <Title>{title}</Title>
                <CloseIconWrapper role="button" onClick={onCloseClick}>
                  <Icon name={Icons.CLOSE} opacity={0.5} />
                </CloseIconWrapper>
              </>
            ) : (
              <CenterTitle>{title}</CenterTitle>
            )}
          </HeaderWrapper>
          {navigationItems && this.renderModalNavigation(navigationItems)}
          {children}
        </ContentWrapper>
      </Wrapper>,
      this.el
    );
  }

  render() {
    return <div>{this.renderPortal}</div>;
  }
}

export default Modal;
