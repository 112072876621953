import React, { ReactElement, useEffect, useState } from "react";
import { FeatureToggles } from "@paralleldrive/react-feature-toggles";

import { get } from "../utils/restHelper";
import getEnvConfig from "../utils/getEnvConfig";

interface IProps {
  children: ReactElement;
}

interface IFeature {
  enabled: boolean;
  name: string;
  id: string;
  updatedAt: string;
}

const FeatureTogglesProvider = ({ children }: IProps) => {
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const { Items: items } = await get(
          getEnvConfig("REACT_APP_FEATURE_TOGGLES_URL")
        );
        const featureNames = items
          .filter((feature: IFeature) => feature.enabled)
          .map((feature: IFeature) => feature.name);

        setFeatures(featureNames);
      } catch (error) {
        setFeatures([]);
      }
    };

    fetchFeatures();
  }, []);

  return <FeatureToggles features={features}>{children}</FeatureToggles>;
};

export default FeatureTogglesProvider;
