import * as React from "react";
import styled from "styled-components";

interface IProps {
  id?: string;
  role?: string;
  className?: string;
  backgroundColor?: string;
  borderless?: boolean;
  paddingless?: boolean;
  maxWidth?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Wrapper = styled.div`
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: ${(props: IProps) => (props.paddingless ? "0" : "1rem 1rem")};
  ${(props: any) => props.maxWidth && `max-width: ${props.maxWidth}`};
  font-family: ${(props: any) => props.theme.fonts.main};
  ${(props: any) =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`};
  ${(props: any) =>
    props.borderless
      ? null
      : `border: 0.0625rem solid ${props.theme.colors.pearl}`};
  ${(props: any) => props.borderless && `margin: 0.0625rem`};
`;

const Box = (props: IProps) => {
  const { children } = props;
  return <Wrapper {...props}>{children}</Wrapper>;
};

export default Box;
