import { connect } from "react-redux";

import EditorView from "./EditorView";
import { getEditorViewMode } from "../../selectors/community";

export const mapStateToProps = (state: IAppState) => {
  return {
    editorViewMode: getEditorViewMode(state)
  };
};

export default connect(mapStateToProps)(EditorView);
