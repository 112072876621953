import { createSelector } from "reselect";

const getXhqUsersFromState = (state: IAppState) => state.xhqUsers;

export const getXhqUsersLoadingState = createSelector(
  getXhqUsersFromState,
  (xhqUsers: IAsyncEntityState<TXHQMemberItems>) => xhqUsers.isFetching
);
export const getXhqUserOptionsResult = createSelector(
  getXhqUsersFromState,
  (xhqUsers: IAsyncEntityState<TXHQMemberItems>) => {
    return xhqUsers.data.items.map(xhqMember => {
      const { firstName, lastName, roles } = xhqMember;
      return {
        value: xhqMember,
        label: `${firstName} ${lastName}`,
        roles
      };
    });
  }
);
