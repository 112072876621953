import styled from "styled-components";

import media from "../../lib/media";
import Alert from "../Alert";

interface IWrapper {
  isButtonHidden?: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  max-width: 24rem;
  padding: 1.5rem 0.7rem 0.5rem;
  ${({ isButtonHidden }: IWrapper) =>
    isButtonHidden ? "padding: 1.5rem 0.7rem 2.9375rem 0.7rem;" : ""};

  ${media.lessThan("large")`
    max-width: 100%;
  `};
`;

export const MoreInfo = styled.div`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.secondary.semilight};
  text-align: center;
  font-size: 0.87rem;

  ${media.greaterThan("medium")`
    text-align: left;
  `};
`;

export const NewThreadButton = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary.main};
  font-family: ${props => props.theme.fonts.main};
  font-size: 0.87rem;
  font-weight: bold;
  cursor: pointer;
`;

export const ThreadListInfo = styled.div<{ isRelative: boolean }>`
  margin-bottom: 1rem;
  ${({ isRelative }) => (isRelative ? "position: relative;" : "")}

  ${media.greaterThan("medium")`
    margin-left: 0.7rem;
  `};
`;

export const StyledAlert = styled(Alert)`
  p {
    margin: 0;
    font-size: 0.8rem;
  }
`;
