import gql from "graphql-tag";

export const FAVORITE_FRAGMENT = gql`
  fragment FavoriteFields on Favorite {
    id
    status
    object {
      ... on Message {
        id
        title
        body
        parentId
        read
        createdBy {
          id
          firstName
          lastName
          avatar
        }
        createdAt
      }
    }
  }
`;
