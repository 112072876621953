import * as React from "react";
import { Subscription } from "react-apollo";

import { getApolloXHQClientInstance } from "../../../lib/apollo";

const apolloXHQClientInstance = getApolloXHQClientInstance();

const XhqSubscription = (props: any) => (
  <Subscription {...props} client={apolloXHQClientInstance}>
    {props.children}
  </Subscription>
);

export default XhqSubscription;
