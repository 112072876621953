import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import reducer from "./reducers";
import APIHandlerMiddleware from "./middlewares/apiHandlerMiddleware";

const appliedMiddlewares =
  process.env.NODE_ENV !== "production"
    ? composeWithDevTools(applyMiddleware(...[APIHandlerMiddleware]))
    : applyMiddleware(...[APIHandlerMiddleware]);

const store = createStore(reducer, appliedMiddlewares);

export default store;
