import styled from "styled-components";
import Chip from "../Chip/Chip";
import MemberChip from "../MemberChip";

export const GroupsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 3.6rem;
  min-height: 3.6rem;
`;

export const StyledChip = styled(Chip)`
  margin: 0 0.5rem 0.5rem;
`;

export const ChipRole = styled.div`
  font-weight: normal;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.secondary.semilight};
`;

export const StyledMemberChip = styled(MemberChip)`
  margin: 0 0.5rem 0.5rem;
`;
