import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Routes from "./Routes";
import { fetchXHQUser, setFetchXhqUserLoading } from "../actions/xhqUser";
import { getIsXHQUserFetching } from "../selectors/xhqUser";

export const mapStateToProps = (state: IAppState) => ({
  isXhqUserFetching: getIsXHQUserFetching(state)
});

export const mapDispatchToProps = (dispatch: IThunkDispatch<{}, {}, any>) => ({
  actions: {
    fetchXHQUser: () => dispatch(fetchXHQUser()),
    setFetchXhqUserLoading: () => dispatch(setFetchXhqUserLoading())
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes) as React.ComponentType<any>);
