import * as React from "react";
import styled, { withTheme } from "styled-components";

import TooltipIcon from "../TooltipIcon";

interface IState {
  checked: boolean;
}

interface ISliderProps extends IToggleSwitchProps {
  checked: boolean;
}

interface ISwitchWrapperProps extends IToggleSwitchProps {
  disabled?: boolean;
}

interface ILabelProps extends IToggleSwitchProps {
  isBolded?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0.5rem 1rem 0.25rem;
  padding-left: 3px;
`;

const SwitchWrapper = styled.div<ISwitchWrapperProps>`
  ${({ disabled }: ISwitchWrapperProps) =>
    disabled &&
    `
    opacity: 0.3;
  `};
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  min-width: 3.25rem;
  height: 1rem;
  margin-right: 1.5rem;
  margin-top: 0.1875rem;
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span<ISliderProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12rem;
  transition: background-color 0.7s;
  ${({ checked, theme }: ISliderProps) =>
    checked
      ? `background-color: ${theme.colors.info.main};`
      : `background-color: ${theme.colors.pearl};`};

  &:before {
    background-color: ${({ theme }: ISliderProps) =>
        theme.colors.secondary.light}
      ${({ theme, checked }: ISliderProps) =>
        checked && `${theme.colors.info.main}`};
    transition: background-color 0.7s;
    position: absolute;
    content: "";
    height: 0.5rem;
    width: 0.5rem;
    left: -0.25rem;
    bottom: -0.5rem;
    border: 12px solid ${({ theme }: IToggleSwitchProps) => theme.colors.white};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 3px 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    transition: 0.2s;
    ${({ checked }: ISliderProps) =>
      checked && `transform: translateX(1.75rem);`};
  }
`;

const Label = styled.div<ILabelProps>`
  font-size: ${({ isBolded }: ILabelProps) => (isBolded ? "1rem" : "0.875rem")};
  ${({ isBolded }: ILabelProps) => (isBolded ? "font-weight: bold;" : "")}
  color: ${({ theme }: ILabelProps) => theme.colors.secondary.main};
`;

const Description = styled.div`
  font-size: 0.76rem;
  color: ${({ theme }: IToggleSwitchProps) => theme.colors.secondary.semilight}
  margin-top: 0.4rem;
`;

const ContentWrapper = styled.span`
  font-family: ${({ theme }: IToggleSwitchProps) => theme.fonts.main};
`;

class ToggleSwitch extends React.PureComponent<IToggleSwitchProps, IState> {
  state = {
    checked: this.props.defaultValue || false
  };

  toggle = (event: React.ChangeEvent<HTMLElement>) => {
    const { onCheckedChange, value } = this.props;

    if (value !== undefined && onCheckedChange) {
      return onCheckedChange(!value);
    } else {
      this.setState(
        (currentState: IState) => ({ checked: !currentState.checked }),
        () => {
          if (onCheckedChange) {
            onCheckedChange(this.state.checked);
          }
        }
      );
    }
  };

  render() {
    const {
      disabled,
      label,
      tooltip,
      className,
      description,
      value
    } = this.props;
    const { checked } = this.state;

    const toggleValue = value !== undefined ? value : checked;
    return (
      <Wrapper className={className}>
        <SwitchWrapper disabled={disabled}>
          <Switch>
            <Checkbox
              type="checkbox"
              checked={toggleValue}
              disabled={disabled}
              onChange={this.toggle}
              data-status={toggleValue}
              data-testid={label}
            />
            <Slider checked={toggleValue} />
          </Switch>
        </SwitchWrapper>
        <ContentWrapper>
          {label && <Label isBolded={!!description}>{label}</Label>}
          {description && <Description>{description}</Description>}
        </ContentWrapper>
        {tooltip && (
          <TooltipIcon
            id={label || tooltip.text}
            text={tooltip.text}
            iconProps={{
              name: tooltip.iconName,
              inverted: tooltip.inverted,
              color: tooltip.color
            }}
          />
        )}
      </Wrapper>
    );
  }
}

export default withTheme(ToggleSwitch);
