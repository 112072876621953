import moment from "moment";
import { DATE_FORMAT_LONG } from "./constants";

export default () => {
  moment.updateLocale("en", {
    calendar: {
      lastDay: "[Yesterday,] LT",
      sameDay: "[Today,] LT",
      nextDay: "[Tomorrow,] LT",
      nextWeek: DATE_FORMAT_LONG,
      lastWeek: DATE_FORMAT_LONG,
      sameElse: DATE_FORMAT_LONG
    }
  });
};
