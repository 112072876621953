import {
  DELETE_THREAD,
  UPDATE_THREAD,
  POST_THREAD,
  UPDATE_THREAD_AS_READ,
  UPDATE_THREAD_AS_FAVOURITE,
  UPDATE_THREAD_AS_NON_FAVOURITE,
  REMOVE_THREAD_OPERATION_DATA
} from "../../constants/actions/threads";
import { POST_ANNOUNCEMENT } from "../../constants/actions/announcements";
import { POST_THREAD_ATTACHMENTS } from "../../constants/actions/attachments";

type payloads =
  | IDeleteThreadDataResponse
  | IThread
  | IMarkAsReadPayload
  | INotifiable
  | IUpdateAsNonFavouritePayload
  | string;

type ActionTypes =
  | LoadingActionWithParam
  | ErrorActionWithParam
  | IActionTypeWithParam<string, payloads>;

const initialThreadState: IThreadOperationState = {
  delete: {
    data: null,
    isLoading: false,
    error: null
  },
  update: {
    data: null,
    isLoading: false,
    error: null
  },
  create: {
    data: null,
    isLoading: false,
    error: null
  },
  updateAsRead: {
    data: {
      id: "",
      read: null,
      parentId: ""
    },
    isLoading: false,
    error: null
  },
  notification: {
    data: null,
    isLoading: false,
    error: null
  },
  threadAttachment: {
    isLoading: false
  }
};

const threadOperations = (
  state: IThreadOperationState = initialThreadState,
  action: ActionTypes
): IThreadOperationState => {
  switch (action.type) {
    case `${DELETE_THREAD}_SUCCESS`:
    case `${UPDATE_THREAD}_SUCCESS`:
    case `${POST_THREAD}_SUCCESS`:
    case `${POST_ANNOUNCEMENT}_SUCCESS`:
    case `${UPDATE_THREAD_AS_READ}_SUCCESS`:
    case `${UPDATE_THREAD_AS_FAVOURITE}_SUCCESS`:
    case `${UPDATE_THREAD_AS_NON_FAVOURITE}_SUCCESS`:
      return {
        ...state,
        [action.typeParam]: {
          data: action.payload,
          isLoading: false,
          error: null
        }
      };
    case `${DELETE_THREAD}_LOADING`:
    case `${UPDATE_THREAD}_LOADING`:
    case `${POST_THREAD}_LOADING`:
    case `${POST_ANNOUNCEMENT}_LOADING`:
    case `${UPDATE_THREAD_AS_READ}_LOADING`:
    case `${UPDATE_THREAD_AS_FAVOURITE}_LOADING`:
    case `${UPDATE_THREAD_AS_NON_FAVOURITE}_LOADING`:
      return {
        ...state,
        [action.typeParam]: {
          data: null,
          isLoading: true,
          error: null
        }
      };
    case `${DELETE_THREAD}_ERROR`:
    case `${UPDATE_THREAD}_ERROR`:
    case `${POST_THREAD}_ERROR`:
    case `${POST_ANNOUNCEMENT}_ERROR`:
    case `${UPDATE_THREAD_AS_READ}_ERROR`:
    case `${UPDATE_THREAD_AS_FAVOURITE}_ERROR`:
    case `${UPDATE_THREAD_AS_NON_FAVOURITE}_ERROR`:
      return {
        ...state,
        [action.typeParam]: {
          data: null,
          isLoading: false,
          error: action.payload as string
        }
      };

    case `${POST_THREAD_ATTACHMENTS}_SUCCESS`:
    case `${POST_THREAD_ATTACHMENTS}_ERROR`:
      return {
        ...state,
        threadAttachment: {
          isLoading: false
        }
      };

    case `${POST_THREAD_ATTACHMENTS}_LOADING`:
      return {
        ...state,
        threadAttachment: {
          isLoading: true
        }
      };
    case REMOVE_THREAD_OPERATION_DATA:
      return {
        ...state,
        create: {
          data: null,
          isLoading: false,
          error: null
        }
      };
    default:
      return state;
  }
};

export default threadOperations;
