import * as React from "react";
import styled from "styled-components";

import LoadingSpinner from "./LoadingSpinner";

interface IProps {
  className?: string;
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const LoadingPanel = ({ className }: IProps) => (
  <Wrapper className={className}>
    <LoadingSpinner />
  </Wrapper>
);

export default LoadingPanel;
