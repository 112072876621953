import { connect } from "react-redux";

import ChannelEditor from "./ChannelEditor";
import {
  fetchAudiencesSearch,
  removeAudiencesDataFromState
} from "../../actions/audiences";
import {
  getAudiencesState,
  getAudiencesOptions
} from "../../selectors/audiences";
import {
  setEditorViewMode,
  setMobileFocusedColumn
} from "../../actions/community";
import { fetchXHQSearchMembers, removeXhqUsers } from "../../actions/xhqUsers";
import {
  getXhqUserOptionsResult,
  getXhqUsersLoadingState
} from "../../selectors/xhqSearchUsers";
import {
  postChannelWithMembersAndAudiences,
  updateChannelWithCallback,
  updateChannelWithMembersAndAudiences,
  fetchChannel
} from "../../actions/channels";
import { getXHQUserData } from "../../selectors/xhqUser";
import {
  getErrorAddingChannelWithMembersAndAudience,
  getErrorUpdatingChannelWithMembersAndAudience,
  getIsFetchingCreatedChannelWithMembersAndAudience,
  getIsFetchingUpdatedChannelWithMembersAndAudience,
  getSelectedChannelData
} from "../../selectors/channels";
import { getEditorViewMode } from "../../selectors/community";
import {
  getChannelState,
  getChannelIndividualMembersOptions,
  getAudiencesFromSelectedChannel
} from "../../selectors/channel";

export const mapStateToProps = (state: IAppState): IChannelEditorStateProps => {
  return {
    xhqUser: getXHQUserData(state),
    audiencesState: getAudiencesState(state),
    audiencesOptions: getAudiencesOptions(state),
    xhqUserOptionsResult: getXhqUserOptionsResult(state),
    isFetchingXhqUserOptions: getXhqUsersLoadingState(state),
    isFetchingCreatedChannel: getIsFetchingCreatedChannelWithMembersAndAudience(
      state
    ),
    errorAddingChannelWithMembersAndAudience: getErrorAddingChannelWithMembersAndAudience(
      state
    ),
    isFetchingUpdatedChannel: getIsFetchingUpdatedChannelWithMembersAndAudience(
      state
    ),
    errorUpdatingChannelWithMembersAndAudience: getErrorUpdatingChannelWithMembersAndAudience(
      state
    ),
    editorViewMode: getEditorViewMode(state),
    updatingChannelState: getSelectedChannelData(state),
    channelState: getChannelState(state),
    individualMembers: getChannelIndividualMembersOptions(state),
    channelAudiences: getAudiencesFromSelectedChannel(state)
  };
};

export const mapDispatchToProps = (
  dispatch: IThunkDispatch<{}, {}, any>
): IChannelEditorActions => {
  return {
    actions: {
      fetchAudiencesSearch: value => dispatch(fetchAudiencesSearch(value)),
      removeAudiencesDataFromState: () =>
        dispatch(removeAudiencesDataFromState()),
      setMobileFocusedColumn: (focusedColumnView, callback) =>
        dispatch(setMobileFocusedColumn(focusedColumnView, callback)),
      setEditorViewMode: editorViewMode =>
        dispatch(setEditorViewMode(editorViewMode)),
      removeXhqUsers: () => dispatch(removeXhqUsers()),
      fetchXHQSearchMembers: searchTerm =>
        dispatch(fetchXHQSearchMembers(searchTerm)),
      postChannelWithMembersAndAudiences: (
        channel,
        members,
        channelAudiences,
        callback?
      ) =>
        dispatch(
          postChannelWithMembersAndAudiences(
            channel,
            members,
            channelAudiences,
            callback
          )
        ),
      updateChannelWithMembersAndAudiences: (
        channel,
        members,
        channelAudiences,
        callback?
      ) =>
        dispatch(
          updateChannelWithMembersAndAudiences(
            channel,
            members,
            channelAudiences,
            callback
          )
        ),
      updateChannelWithCallback: (channel, callback) =>
        dispatch(updateChannelWithCallback(channel, callback)),
      fetchChannel: (name, type) => dispatch(fetchChannel(name, type))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelEditor);
