import React, { SyntheticEvent } from "react";

import * as Icons from "../../constants/icons";
import Icon from "../Icon";
import { CAULFIELD_LIFE_STRING, FILTERS } from "../../constants";
import * as S from "./styles";

interface ITitleProps {
  title: string;
  onTitleClick: ((event: SyntheticEvent) => void) | null;
  searchPhrase: string;
}

interface IProps extends ITitleProps {
  onMenuClick: (event: React.MouseEvent<HTMLElement>) => void;
  isAnnouncement: boolean;
  shouldShowMenu: boolean;
  filter: string;
  onSettingsClick: (event: React.MouseEvent<HTMLElement>) => void;
  menu: React.ReactElement | null;
  onSearchClick: (event: SyntheticEvent) => void;
}

const Title = ({ title, onTitleClick, searchPhrase }: ITitleProps) => (
  <S.Title {...(onTitleClick ? { onClick: onTitleClick } : {})}>
    {title === CAULFIELD_LIFE_STRING ? (
      <>
        Caulfield<S.Italic>Life</S.Italic>
      </>
    ) : (
      title
    )}
    {searchPhrase ? `: ${searchPhrase}` : ""}
  </S.Title>
);

const TitleWithActions = ({
  onMenuClick,
  isAnnouncement,
  filter,
  searchPhrase,
  onTitleClick,
  title,
  shouldShowMenu,
  onSettingsClick,
  menu: Menu,
  onSearchClick
}: IProps) => {
  return (
    <S.TopWrapper>
      <S.MobileMenuButton role="button" onClick={onMenuClick}>
        <Icon name={Icons.HAMBURGUER} />
      </S.MobileMenuButton>
      <Title
        title={title}
        onTitleClick={onTitleClick}
        searchPhrase={searchPhrase}
      />
      {shouldShowMenu && (
        <div>
          <S.ThreadSettingsButton role="button" onClick={onSettingsClick}>
            <Icon name={Icons.MORE} />
          </S.ThreadSettingsButton>
          {Menu}
        </div>
      )}
      {isAnnouncement && filter === FILTERS.INBOX && (
        <S.StyledSearchIcon
          name={searchPhrase ? Icons.CLOSE : Icons.SEARCH}
          onClick={onSearchClick}
        />
      )}
    </S.TopWrapper>
  );
};

export default TitleWithActions;
