import {
  POST_CHANNEL_MEMBERS,
  SET_DEFAULT_STATE_CHANNEL_MEMBERS
} from "../../constants/actions/channels";

const SUCCESS = `${POST_CHANNEL_MEMBERS}_SUCCESS`;
const POSTING = `${POST_CHANNEL_MEMBERS}_POSTING`;
const ERROR = `${POST_CHANNEL_MEMBERS}_ERROR`;

type ActionTypes =
  | IActionType<typeof SUCCESS, boolean[]>
  | IActionType<typeof POSTING, boolean>
  | IActionType<typeof ERROR, string | null>;

const initialState: IAsyncEntityState<TChannelMembers> = {
  data: null,
  isFetching: false,
  error: null
};

const addChannelMembers = (
  state: IAsyncEntityState<TChannelMembers> = initialState,
  action: ActionTypes
): IAsyncEntityState<TChannelMembers> => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        data: action.payload as boolean[],
        isFetching: false,
        error: null
      };
    case POSTING:
      return {
        ...state,
        data: null,
        isFetching: action.payload as boolean,
        error: null
      };
    case SET_DEFAULT_STATE_CHANNEL_MEMBERS:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: null
      };
    case ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload as string | null
      };
    default:
      return state;
  }
};

export default addChannelMembers;
