import { ApolloQueryResult } from "apollo-client";
import { get } from "lodash";

import {
  FETCH_PUBLIC_CHANNELS,
  POST_CHANNEL,
  UPDATE_CHANNEL,
  UPDATE_ARCHIVE_CHANNEL,
  POST_CHANNEL_MEMBERS,
  SET_DEFAULT_STATE_CHANNEL_MEMBERS,
  SET_DEFAULT_STATE_POST_CHANNEL,
  SET_DEFAULT_SELECTED_CHANNEL_STATE,
  SET_DEFAULT_ARCHIVED_CHANNEL_STATE,
  POST_CHANNEL_AUDIENCE,
  POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE,
  UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE,
  DELETE_ALL_CHANNEL_MEMBERS,
  FETCH_CHANNEL,
  REMOVE_CHANNEL_DATA
} from "../../constants/actions/channels";
import {
  CREATE_CHANNEL,
  UPDATE_CHANNEL as UPDATE_CHANNEL_MUTATION,
  ADD_CHANNEL_MEMBERS,
  GET_CHANNELS_BY_TYPE,
  ARCHIVE_CHANNEL,
  ADD_CHANNEL_AUDIENCE,
  REMOVE_ALL_CHANNEL_MEMBERS,
  GET_CHANNEL
} from "../../graphql/tags/channel";
import { CHANNEL_TYPES } from "../../constants/channels";

import * as actions from "./";

export const postChannel = (channel: ICreateChannelProps) => () => ({
  actionType: POST_CHANNEL,
  api: "XHQ",
  operationType: "mutation",
  operation: CREATE_CHANNEL,
  variables: {
    type: CHANNEL_TYPES.PRV,
    ...channel
  },
  getter: (response: ApolloQueryResult<ICreateChannelData>) =>
    get(response, "data.createChannel")
});

export const updateChannel = (channelData: IUpdateChannelProps) => () => ({
  actionType: UPDATE_CHANNEL,
  api: "XHQ",
  operationType: "mutation",
  operation: UPDATE_CHANNEL_MUTATION,
  variables: {
    id: channelData.id,
    data: {
      title: channelData.title,
      description: channelData.description,
      allowThreadReplies: channelData.allowThreadReplies
    }
  },
  getter: (response: ApolloQueryResult<IUpdateChannelData>) =>
    get(response, "data.updateChannel")
});

export const updateChannelWithCallback = (
  channel: IUpdateChannelProps,
  callback: () => void
) => async ({ dispatch }: IReduxStore) => {
  const updatedChannel = await dispatch(actions.updateChannel(channel));

  if (updatedChannel.type === `${POST_CHANNEL}_ERROR`) {
    return;
  }

  callback();
};

export const postChannelMembers = (
  channelWithMembers: IAddChannelMembersProps
) => () => ({
  actionType: POST_CHANNEL_MEMBERS,
  api: "XHQ",
  operationType: "mutation",
  operation: ADD_CHANNEL_MEMBERS,
  variables: {
    ...channelWithMembers,
    type: channelWithMembers.type || CHANNEL_TYPES.PRV
  },
  getter: (response: ApolloQueryResult<IAddChannelMembersData>) =>
    get(response, "data.addChannelMembers")
});

export const postChannelWithMembers = (
  channel: ICreateChannelProps,
  members: IMemberChannel[]
) => async ({ dispatch }: IReduxStore) => {
  const createdChannel = await dispatch(actions.postChannel(channel));

  if (createdChannel.type === `${POST_CHANNEL}_ERROR`) {
    return;
  }

  dispatch(
    actions.postChannelMembers({
      channelId: createdChannel.payload.id,
      members
    })
  );
};

export const setDefaultStateChannelWithMember = () => ({
  type: SET_DEFAULT_STATE_CHANNEL_MEMBERS
});

export const setDefaultStatePostChannel = () => ({
  type: SET_DEFAULT_STATE_POST_CHANNEL
});

export const setDefaultSelectedChannelState = () => ({
  type: SET_DEFAULT_SELECTED_CHANNEL_STATE
});

export const setDefaultArchivedChannelState = () => ({
  type: SET_DEFAULT_ARCHIVED_CHANNEL_STATE
});

export const fetchPublicChannels = () => () => ({
  actionType: FETCH_PUBLIC_CHANNELS,
  api: "XHQ",
  operationType: "query",
  operation: GET_CHANNELS_BY_TYPE,
  variables: { type: CHANNEL_TYPES.PUB },
  getter: (response: ApolloQueryResult<IPublicChannelData>) =>
    get(response, "data.channelsByType")
});

export const archiveChannel = (channelId: string) => () => ({
  actionType: UPDATE_ARCHIVE_CHANNEL,
  api: "XHQ",
  operationType: "mutation",
  operation: ARCHIVE_CHANNEL,
  variables: {
    channelId
  },
  getter: (response: ApolloQueryResult<IArchiveChannelData>) =>
    get(response, "data.archiveChannel")
});

export const deleteAllChannelMembers = (
  channelId: string,
  type?: string
) => () => ({
  actionType: DELETE_ALL_CHANNEL_MEMBERS,
  api: "XHQ",
  operationType: "mutation",
  operation: REMOVE_ALL_CHANNEL_MEMBERS,
  variables: {
    channelId,
    type: type || CHANNEL_TYPES.PRV
  },
  getter: (response: ApolloQueryResult<IRemoveAllChannelMembers>) =>
    get(response, "data.removeAllChannelMembers")
});

export const postChannelAudiences = (
  channelId: string,
  audiences: IChannelAudience[],
  type?: string
) => () => ({
  actionType: POST_CHANNEL_AUDIENCE,
  api: "XHQ",
  operationType: "mutation",
  operation: ADD_CHANNEL_AUDIENCE,
  variables: {
    channelId,
    type: type || CHANNEL_TYPES.PRV,
    audiences
  },
  getter: (
    response: ApolloQueryResult<{ addAudienceAsChannelMembers: number }>
  ) => get(response, "data.addAudienceAsChannelMembers")
});

export const postChannelWithMembersAndAudiences = (
  channel: ICreateChannelProps,
  members: IMemberChannel[],
  audiences: IChannelAudience[],
  callback?: () => void
) => async ({ dispatch }: IReduxStore) => {
  dispatch({
    type: `${POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_LOADING`
  });

  const createdChannel = await dispatch(actions.postChannel(channel));

  if (createdChannel.type === `${POST_CHANNEL}_ERROR`) {
    return dispatch({
      type: `${POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_ERROR`,
      payload: createdChannel.payload
    });
  }

  const postChannelMembersResponse = await dispatch(
    actions.postChannelMembers({
      channelId: createdChannel.payload.id,
      members
    })
  );

  if (postChannelMembersResponse.type === `${POST_CHANNEL_MEMBERS}_ERROR`) {
    return dispatch({
      type: `${POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_ERROR`,
      payload: postChannelMembersResponse.payload
    });
  }

  const postChannelAudienceResponse = await dispatch(
    actions.postChannelAudiences(createdChannel.payload.id, audiences)
  );

  if (postChannelAudienceResponse.type === `${POST_CHANNEL_AUDIENCE}_ERROR`) {
    return dispatch({
      type: `${POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_ERROR`,
      payload: postChannelAudienceResponse.payload
    });
  }

  if (callback) {
    callback();
  }

  const payloadAudiences = audiences.map(audience => ({
    audience: {
      id: audience.audienceId,
      name: audience.audienceId.replace("Audience-", "")
    },
    audienceContext: audience.audienceContext
  }));

  dispatch({
    type: `${POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_SUCCESS`,
    payload: {
      channelId: createdChannel.payload.id,
      audiences: payloadAudiences
    }
  });
};

export const updateChannelWithMembersAndAudiences = (
  channel: IUpdateChannelProps,
  members: IMemberChannel[],
  audiences: IChannelAudience[],
  callback?: TVoidFunc
) => async ({ dispatch }: IReduxStore) => {
  dispatch({
    type: `${UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_LOADING`
  });

  const updatedChannel = await dispatch(actions.updateChannel(channel));

  if (updatedChannel.type === `${UPDATE_CHANNEL}_ERROR`) {
    return dispatch({
      type: `${UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_ERROR`,
      payload: updatedChannel.payload
    });
  }

  const deletedMembersResponse = await dispatch(
    actions.deleteAllChannelMembers(channel.id, channel.type)
  );
  if (deletedMembersResponse.type === `${DELETE_ALL_CHANNEL_MEMBERS}_ERROR`) {
    return dispatch({
      type: `${UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_ERROR`,
      payload: deletedMembersResponse.payload
    });
  }

  if (members.length) {
    const postChannelMembersResponse = await dispatch(
      actions.postChannelMembers({
        channelId: updatedChannel.payload.id,
        type: channel.type,
        members
      })
    );

    if (postChannelMembersResponse.type === `${POST_CHANNEL_MEMBERS}_ERROR`) {
      return dispatch({
        type: `${UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_ERROR`,
        payload: postChannelMembersResponse.payload
      });
    }
  }

  if (audiences.length) {
    const postChannelAudienceResponse = await dispatch(
      actions.postChannelAudiences(
        updatedChannel.payload.id,
        audiences,
        channel.type
      )
    );

    if (postChannelAudienceResponse.type === `${POST_CHANNEL_AUDIENCE}_ERROR`) {
      return dispatch({
        type: `${UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_ERROR`,
        payload: postChannelAudienceResponse.payload
      });
    }
  }

  if (callback) {
    callback();
  }

  const payloadAudiences = audiences.map(audience => ({
    audience: {
      id: audience.audienceId,
      name: audience.audienceId.replace("Audience-", "")
    },
    audienceContext: audience.audienceContext
  }));

  dispatch({
    type: `${UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_SUCCESS`,
    payload: {
      channelId: channel.id,
      audiences: payloadAudiences
    }
  });
};

export const fetchChannel = (name: string, type: string) => () => ({
  actionType: FETCH_CHANNEL,
  api: "XHQ",
  operationType: "query",
  operation: GET_CHANNEL,
  variables: { name, type },
  getter: (response: ApolloQueryResult<IFetchChannelData>) => {
    const channel = get(response, "data.channel");
    if (!channel) {
      return channel;
    }
    return {
      ...channel,
      members:
        channel.members && channel.members.items
          ? {
              items: channel.members.items.filter(
                (member: IXHQMember) => member.firstName && member.lastName
              )
            }
          : channel.members
    };
  }
});

export const removeChannelDataFromState = () => ({
  type: REMOVE_CHANNEL_DATA
});
