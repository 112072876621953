import * as React from "react";

import Modal from "../Modal";
import * as S from "./styles";

interface IProps {
  onCloseClick: () => void;
  text: string;
  title: string;
  contentTitle: string;
}

const PreviewModal = ({ contentTitle, text, title, onCloseClick }: IProps) => {
  return (
    <Modal title={title} onCloseClick={onCloseClick}>
      <S.ContentWrapper>
        <S.StyledHR />
        <S.Label>TITLE</S.Label>
        <S.Title>{contentTitle}</S.Title>
        <S.Label>MESSAGE</S.Label>
        <S.Content dangerouslySetInnerHTML={{ __html: text }} />
      </S.ContentWrapper>
    </Modal>
  );
};

export default PreviewModal;
