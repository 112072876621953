import { BREAKPOINTS } from "../../constants/mediaBreakpoints";

const inputStyles = (props: any) => `
  .SingleDatePickerInput,
  .DateRangePickerInput {
    border: 0;
  }

  .DateRangePickerInput,
  .SingleDatePickerInput {
    display: flex;
    min-width: 24.06rem;
    border-bottom: 0.0625rem solid ${props.theme.colors.tertiary.main};
    padding-right: 2.5rem;

    @media screen and (max-width: ${BREAKPOINTS.small}) {
      min-width: 16rem;
    }
  }

  .DateInput {
    flex: 1;

    &_input {
      background-color: ${props.theme.colors.white};
      font-family: ${props.theme.fonts.main};
      border: 0;
      cursor: text;
      display: block;
      padding: 0.7rem 0;
      font-size: 0.875rem;
      line-height: normal;
      font-weight: normal;

      ::placeholder {
        color: ${props.theme.colors.secondary.semilight}66;
      }

      :focus {
        outline: 0;
      }
    }

    &_fang {
      display: none;
    }
  }
`;

export default (withInputStyles: boolean, props: any) => `
  .CalendarMonth {
    &_caption {
      font-size: 1rem;
      color: ${props.theme.colors.secondary.main};
    }

    &_caption {
      padding-top: 1.75rem;
    }

    &_table {
      margin-top: 1.5rem;
    }
  }

  .CalendarDay {
    width: 2.4375rem !important;
    height: 2.4375rem !important;
    border-radius: 50%;

    &__selected {
      background: ${props.theme.colors.info.main};
      color: ${props.theme.colors.white};
      :hover {
        background: ${props.theme.colors.info.main};
        color: ${props.theme.colors.white};
      }
    }

    &_value__wrapper,
    &_value {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__selected_start,
    &__selected_end {
      border-radius: 0;
      border-radius: 50%;
      background: none;

      .CalendarDay_value {
        background: ${props.theme.colors.info.main};
        border-radius: 50%;
      }
    }

    &__selected_start {
      .CalendarDay_value__wrapper {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }

    &__selected_end {
      .CalendarDay_value__wrapper {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }

    &__hovered_span {
      background: ${props.theme.colors.info.light};
      color: ${props.theme.colors.secondary.main};
      border-radius: 0;
    }

    &__default {
      border: 0;
      padding: 0;

      :hover {
        border: 0;
        background: ${props.theme.colors.info.light};
      }
    }
  }

  ${withInputStyles ? inputStyles(props) : ""}

  .DayPicker {
    &_transitionContainer {
      padding-bottom: 1.8rem;
      box-sizing: initial;
    }

    &_weekHeader {
      margin-top: 1rem;
    }

    &_weekHeader_li {
      font-size: 0.68rem;
      font-weight: bold;
      letter-spacing: 0.4px;
      color: ${props.theme.colors.secondary.semilight};
    }

    &__withBorder {
      border-radius: 0.25rem;
      box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.08);
    }

    .CalendarDay {
      &__selected, &__selected:hover, &__selected:active {
        border: 0;
      }

      &__selected:focus {
        outline: 0;
      }
    }
  }

  .DayPickerNavigation_button {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border-color: ${props.theme.colors.pear};
    display: flex;
    justify-content: center;
    align-items: center;

    :focus {
      outline: 0;
    }

    .DayPickerNavigation_svg__horizontal {
      fill: ${props.theme.colors.primary.main};
      width: 0.875rem;
    }
  }
`;
