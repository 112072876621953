import styled from "styled-components";

import SingleDatePicker from "../DatePicker/SingleDatePicker";
import TimePicker from "../TimePicker";

export const StyledSingleDatePicker = styled(SingleDatePicker)`
  min-width: 11.25rem !important;
  margin: 1rem;

  .SingleDatePickerInput {
    min-width: 11.25rem !important;
  }

  .SingleDatePicker_picker {
    top: unset !important;
    bottom: 0 !important;
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  width: 11.25rem;
  margin: 1rem;
`;
