import React from "react";
import * as ReactDOM from "react-dom";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { unregister as unregisterServiceWorker } from "./registerServiceWorker";

import initMomentLocale from "./initMomentLocale";

import "./styles.css";

import App from "./App";

initMomentLocale();

/**
 * Main App renderer
 */
ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);

unregisterServiceWorker();
