import React from "react";

import * as S from "./styles";
import Icon from "../Icon";
import TimePickerPanel from "./TimePickerPanel";
import { CLOCK } from "../../constants/icons";

interface IProps {
  className?: string;
  label: string;
  onApplyClick?: (value: string) => void;
  placeholder?: string;
  defaultValue?: string;
}

interface IState {
  showPanel: boolean;
  value?: string;
}

class TimePicker extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      value: props.defaultValue,
      showPanel: false
    };
  }

  handleApplyClick = (value: string) => {
    this.setState(
      () => ({
        value,
        showPanel: false
      }),
      () => {
        const { onApplyClick } = this.props;
        if (onApplyClick) {
          onApplyClick(value);
        }
      }
    );
  };

  handlePanelToggle = () => {
    this.setState(({ showPanel }) => ({ showPanel: !showPanel }));
  };

  render() {
    const { className, label, placeholder, defaultValue } = this.props;
    const { value, showPanel } = this.state;
    return (
      <S.Wrapper className={className}>
        <S.Label>{label}</S.Label>
        <S.ContentWrapper onClick={this.handlePanelToggle}>
          {value ? (
            <S.PickerValue>{value}</S.PickerValue>
          ) : (
            <S.PlaceHolder>{placeholder}</S.PlaceHolder>
          )}

          <Icon name={CLOCK} />
        </S.ContentWrapper>
        {showPanel && (
          <TimePickerPanel
            defaultValue={defaultValue}
            onCancelClick={this.handlePanelToggle}
            onCloseClick={this.handlePanelToggle}
            onApplyClick={this.handleApplyClick}
          />
        )}
      </S.Wrapper>
    );
  }
}

export default TimePicker;
