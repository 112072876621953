export default function getEnvConfig(
  configName: string,
  defaultValue: any = ""
): string {
  if (configName in process.env) {
    return process.env[configName] || defaultValue;
  }
  return defaultValue;
}

export function getEnvUrlConfig(
  urlName: string,
  defaultValue: any = ""
): string {
  const url = process.env[urlName] || defaultValue;

  if (process.env.NODE_ENV === "development") {
    return url;
  }

  if (window.location.protocol === "https:") {
    return url.replace("http:", "https:").replace("ws:", "wss:");
  }

  return url.replace("https:", "http:").replace("wss:", "ws:");
}
