import * as React from "react";

import {
  TopNavigationBar,
  BottomNavigationBar
} from "../../containers/NavigationBar";

class NavigationBarWrapper extends React.PureComponent<any> {
  render() {
    const { match, location, history } = this.props;
    const routerProps = {
      match,
      location,
      history
    };

    return (
      <>
        <TopNavigationBar
          {...routerProps}
        />
        {this.props.children}
        <BottomNavigationBar {...routerProps} />
      </>
    );
  }
}

export default NavigationBarWrapper;
