import { combineReducers } from "redux";

import {
  FETCH_NOTIFICATIONS,
  NOTIFICATION_CREATED
} from "../../constants/actions/notifications";
import {
  DELETE_THREAD,
  UPDATE_THREAD,
  UPDATE_THREAD_AS_READ,
  UPDATE_THREAD_AS_FAVOURITE,
  UPDATE_THREAD_AS_NON_FAVOURITE
} from "../../constants/actions/threads";
import { UPDATE_ARCHIVE_CHANNEL } from "../../constants/actions/channels";

const SUCCESS_UPDATE_ARCHIVE_CHANNEL = `${UPDATE_ARCHIVE_CHANNEL}_SUCCESS`;
const UPDATE_THREAD_AS_READ_SUCCESS = `${UPDATE_THREAD_AS_READ}_SUCCESS`;

const getActionType = (status: INBOX | IMPORTANT | FAVOURITES, type: string) =>
  `${FETCH_NOTIFICATIONS}_${status.toUpperCase()}_${type}`;
const SUCCESS_INBOX = getActionType("inbox", "SUCCESS");
const LOADING_INBOX = getActionType("inbox", "LOADING");
const ERROR_INBOX = getActionType("inbox", "ERROR");

const SUCCESS_IMPORTANT = getActionType("important", "SUCCESS");
const LOADING_IMPORTANT = getActionType("important", "LOADING");
const ERROR_IMPORTANT = getActionType("important", "ERROR");

const SUCCESS_FAVOURITES = getActionType("favourites", "SUCCESS");
const LOADING_FAVOURITES = getActionType("favourites", "LOADING");
const ERROR_FAVOURITES = getActionType("favourites", "ERROR");
const DELETE_THREAD_SUCCESS = `${DELETE_THREAD}_SUCCESS`;
const UPDATE_THREAD_SUCCESS = `${UPDATE_THREAD}_SUCCESS`;
const UPDATE_THREAD_AS_FAVOURITE_SUCCESS = `${UPDATE_THREAD_AS_FAVOURITE}_SUCCESS`;
const UPDATE_THREAD_AS_NON_FAVOURITE_SUCCESS = `${UPDATE_THREAD_AS_NON_FAVOURITE}_SUCCESS`;

type ActionTypes =
  | IActionType<
      | typeof SUCCESS_INBOX
      | typeof SUCCESS_IMPORTANT
      | typeof SUCCESS_FAVOURITES,
      INotificationObject
    >
  | IActionType<
      | typeof LOADING_INBOX
      | typeof LOADING_IMPORTANT
      | typeof LOADING_FAVOURITES,
      boolean
    >
  | IActionType<
      typeof ERROR_INBOX | typeof ERROR_IMPORTANT | typeof ERROR_FAVOURITES,
      string | null
    >
  | IActionType<typeof DELETE_THREAD_SUCCESS, IDeleteThreadDataResponse | null>
  | IActionType<typeof UPDATE_THREAD_AS_READ_SUCCESS, IMarkAsReadPayload>
  | IActionType<typeof UPDATE_THREAD_AS_FAVOURITE_SUCCESS, INotifiable>
  | IActionType<typeof SUCCESS_UPDATE_ARCHIVE_CHANNEL, IChannel>
  | IActionType<
      typeof UPDATE_THREAD_AS_NON_FAVOURITE_SUCCESS,
      IUpdateAsNonFavouritePayload
    >;

const initialState: IAsyncEntityState<INotificationObject> = {
  data: {
    items: [],
    nextPage: null,
    hasBeenFetched: false,
    searchPhrase: ""
  },
  isFetching: null,
  error: null
};

const updateNotification = (
  notificationChunk: IThread | IMarkAsReadPayload,
  state: IAsyncEntityState<INotificationObject>
) => {
  return state.data.items.map((item: INotification) => {
    if (item.object && item.object.id === notificationChunk.id) {
      return {
        ...item,
        object: {
          ...item.object,
          ...notificationChunk
        }
      };
    }
    return item;
  });
};

const addNotification = (
  notification: INotification,
  state: IAsyncEntityState<INotificationObject>
) => {
  const hasNotification = state.data.items.some(
    (item: INotification) => item.id === notification.id
  );

  return !hasNotification
    ? [notification, ...state.data.items]
    : state.data.items;
};

const notifications = (status: INBOX | IMPORTANT | FAVOURITES) => (
  state: IAsyncEntityState<INotificationObject> = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case getActionType(status, "SUCCESS"):
      const payload = action.payload as INotificationObject;
      return {
        data: {
          ...state.data,
          ...payload,
          items: [...state.data.items, ...payload.items],
          hasBeenFetched: true
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    case getActionType(status, "LOADING"):
      return {
        ...state,
        isFetching: action.payload as boolean,
        error: null
      };
    case getActionType(status, "ERROR"):
      return {
        ...state,
        isFetching: false,
        error: action.payload as string | null
      };
    case DELETE_THREAD_SUCCESS:
      const actionPayload: IDeleteThreadDataResponse = action.payload as IDeleteThreadDataResponse;
      const notificationItems = state.data.items.filter(
        (item: INotification) =>
          item.object && item.object.id !== actionPayload.id
      );
      return {
        data: {
          ...state.data,
          items: notificationItems
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    case SUCCESS_UPDATE_ARCHIVE_CHANNEL:
      return {
        data: {
          ...state.data,
          items: state.data.items.filter(
            (item: INotification) =>
              item.object &&
              item.object.parentId !== (action.payload as IChannel).id
          )
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    case UPDATE_THREAD_SUCCESS:
      return {
        data: {
          ...state.data,
          items: updateNotification(action.payload as IThread, state)
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    case UPDATE_THREAD_AS_READ_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: updateNotification(action.payload as IMarkAsReadPayload, state)
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    case UPDATE_THREAD_AS_FAVOURITE_SUCCESS:
      if (status !== "favourites") {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          items: [action.payload as INotifiable, ...state.data.items]
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    case UPDATE_THREAD_AS_NON_FAVOURITE_SUCCESS:
      if (status !== "favourites") {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.filter(
            item =>
              item.id !== (action.payload as IUpdateAsNonFavouritePayload).id
          )
        } as INotificationObject,
        isFetching: false,
        error: null
      };

    case `${NOTIFICATION_CREATED}_IMPORTANT_SUCCESS`:
      if (status !== "important") {
        return { ...state };
      }

      return {
        ...state,
        data: {
          ...state.data,
          items: [...addNotification(action.payload as INotification, state)]
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    default:
      return state;
  }
};

export default combineReducers({
  inbox: notifications("inbox"),
  important: notifications("important"),
  favourites: notifications("favourites")
});
