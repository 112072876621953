import { createSelector } from "reselect";

import {
  nameAndValueToArrays,
  replaceTagsWithCSVValues
} from "../utils/helpers";

const getThreadFromState = (state: IAppState) => state.thread;

export const getThreadData = createSelector(
  getThreadFromState,
  (thread): IAsyncEntityState<TThread> => {
    const { data } = thread;

    if (!data || !data.notification) {
      return thread;
    }

    const {
      body = "",
      notification: { mergeFields = [] }
    } = data;

    if (!mergeFields.length) {
      return thread;
    }

    const [tags, values] = nameAndValueToArrays(mergeFields!);
    const enhancedBody = replaceTagsWithCSVValues(body, tags, values);

    return { ...thread, data: { ...data, body: enhancedBody } };
  }
);

const getItems = (messagesData: IAsyncEntityState<IThreadsData>) =>
  messagesData.data.items;

export const getSelectedThread = (threadId: string) =>
  createSelector(
    getItems,
    items => items.find(({ id }: IThread) => id === threadId)
  );
