import * as React from "react";
import * as H from "history";
import { withRouter, RouterProps, RouteProps } from "react-router";
import { Feature } from "@paralleldrive/react-feature-toggles";

import * as Icons from "../../constants/icons";
import { ALERT_VARIANTS } from "../../constants/alert";
import {
  XHQ_CHANNEL_CAPABILITIES,
  XHQ_ROLE_CAPABILITIES
} from "../../constants/community";
import Icon from "../Icon";
import {
  ComponentNoop,
  containsXhqCapability,
  userContainsChannelCapability
} from "../../utils/helpers";
import { Menu, MenuItem } from "../Menu";
import ConfirmationDialog from "../ConfirmationDialog";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import SearchInput from "../SearchInput";
import TitleWithActions from "../TitleWithActions";
import * as S from "./styles";
import { EDIT_CHANNEL_TOGGLE } from "../../constants/featureToggles";
import { CHANNEL_TYPES } from "../../constants/channels";

interface IProps extends RouterProps, RouteProps {
  title: string;
  moreInfo?: string;
  className?: string;
  filter: string;
  onUpdateChannelClick?: TVoidFunc;
  onNewThreadClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFilterMenuClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onArchiveChannel: (channelId: string) => void;
  onSetDefaultArchiveChannel: () => void;
  xhqUser?: IXHQMember;
  hideNewThreadButton: boolean;
  allowReplies: boolean;
  isUpdating?: boolean;
  errorUpdating?: string | null;
  selectedChannel?: IChannel;
  archivedChannelError?: string;
  archiveChannelSuccess?: boolean;
  onRepliesConfirmationAccept?: (callback: TVoidFunc) => void;
  onRepliesConfirmationCancel?: TVoidFunc;
  isAnnouncement: boolean;
  fetchAnnouncements: (
    xhqUser: IXHQMember,
    filter: "inbox",
    withFreshList: boolean
  ) => void;
  setAnnouncementSearchPhrase: (searchPhrase: string) => void;
  searchPhrase: string;
}

interface IState {
  showSettingsMenu: boolean;
  showConfirmArchiveChannel: boolean;
  showAllowRepliesConfirmation: boolean;
  showAlertBoxMessage: boolean;
  isSearchBoxShown: boolean;
}

const redirectToSelectedCategory = (
  location: H.Location,
  history: H.History
) => {
  if (location!.pathname.includes("threads")) {
    const redirectPath = location!.pathname.split("/threads/")[0];
    history.push(redirectPath);
  }
};

class ThreadListHeader extends React.PureComponent<IProps, IState> {
  state = {
    showSettingsMenu: false,
    showConfirmArchiveChannel: false,
    showAllowRepliesConfirmation: false,
    showAlertBoxMessage: true,
    isSearchBoxShown: false
  };
  alertBoxTimeout: number = 0;
  searchInputRef = React.createRef<HTMLInputElement>();

  componentWillUnmount() {
    clearTimeout(this.alertBoxTimeout);
  }

  get canUpdateChannel() {
    const { selectedChannel, xhqUser } = this.props;
    if (
      !xhqUser ||
      !selectedChannel ||
      selectedChannel.type === CHANNEL_TYPES.PUB
    ) {
      return false;
    }
    const canUpdate = userContainsChannelCapability(
      selectedChannel!,
      xhqUser,
      XHQ_CHANNEL_CAPABILITIES.UPDATE_CHANNEL
    );

    const canAnyUpdate = containsXhqCapability(
      xhqUser,
      XHQ_ROLE_CAPABILITIES.UPDATE_ANY_CHANNEL
    );
    return canUpdate || canAnyUpdate;
  }

  get canArchiveChannel() {
    const { selectedChannel, xhqUser } = this.props;
    if (!xhqUser || !selectedChannel) {
      return false;
    }
    return (
      userContainsChannelCapability(
        selectedChannel!,
        xhqUser,
        XHQ_CHANNEL_CAPABILITIES.ARCHIVE_CHANNEL
      ) ||
      containsXhqCapability(xhqUser, XHQ_ROLE_CAPABILITIES.ARCHIVE_ANY_CHANNEL)
    );
  }

  get showHeaderMenu() {
    return this.canUpdateChannel || this.canArchiveChannel;
  }

  get renderMenu() {
    const { allowReplies } = this.props;
    return (
      <Menu onClickOutside={this.handleClickOutside}>
        {this.canUpdateChannel && (
          <>
            <Feature
              name={EDIT_CHANNEL_TOGGLE}
              activeComponent={this.renderEditChannelMenuItem}
              inactiveComponent={ComponentNoop}
            />
            <MenuItem onClick={this.handleRepliesToggleClick}>
              <Icon name={Icons.READ} opacity={0.5} inverted={allowReplies} />
              {allowReplies ? "Turn replies off" : "Turn replies on"}
            </MenuItem>
          </>
        )}
        {this.canArchiveChannel && (
          <MenuItem onClick={this.handleOnArchiveChannel}>
            <Icon name={Icons.ARCHIVE} opacity={0.5} />
            Archive Channel
          </MenuItem>
        )}
      </Menu>
    );
  }

  renderEditChannelMenuItem = () => (
    <MenuItem onClick={this.handleUpdateChannelClick}>
      <Icon name={Icons.PENCIL} opacity={0.5} />
      Edit Channel
    </MenuItem>
  );

  handleRepliesToggleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      showSettingsMenu: false,
      showAllowRepliesConfirmation: true
    });
  };

  handleUpdateChannelClick = () => {
    const { onUpdateChannelClick } = this.props;
    this.setState({ showSettingsMenu: false }, () => {
      if (onUpdateChannelClick) {
        onUpdateChannelClick();
      }
    });
  };

  handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({ showSettingsMenu: true });
  };

  handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({ showSettingsMenu: false });
  };

  handleNewThreadClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { onNewThreadClick } = this.props;
    if (onNewThreadClick) {
      onNewThreadClick(event);
    }
  };

  handleRepliesConfirmationAccept = () => {
    const { onRepliesConfirmationAccept } = this.props;

    onRepliesConfirmationAccept &&
      onRepliesConfirmationAccept(() =>
        this.setState({ showAllowRepliesConfirmation: false })
      );
  };

  handleRepliesConfirmationCancel = () => {
    const { onRepliesConfirmationCancel } = this.props;
    this.setState(
      { showAllowRepliesConfirmation: false },
      () => onRepliesConfirmationCancel && onRepliesConfirmationCancel()
    );
  };

  handleOnArchiveChannel = () => {
    this.props.onSetDefaultArchiveChannel();
    this.setState(() => ({
      showConfirmArchiveChannel: true
    }));
  };

  handleOnConfirmArchiveChannel = () => {
    const { selectedChannel } = this.props;
    if (selectedChannel) {
      this.props.onArchiveChannel(selectedChannel.id);
    }
  };

  handleOnCancelConfirmation = () => {
    this.setState(() => ({
      showConfirmArchiveChannel: false
    }));
  };

  get renderErrorMessage() {
    return (
      <ErrorMessage>There was an error while updating the channel</ErrorMessage>
    );
  }

  get renderAllowRepliesConfirmation() {
    const { allowReplies, isUpdating, errorUpdating } = this.props;
    const title = allowReplies ? "Turning off replies" : "Turning on replies";
    const description = allowReplies
      ? "Are you sure you want to turn replies off?"
      : "Are you sure you want to turn replies on?";

    return (
      <ConfirmationDialog
        title={title}
        description={!errorUpdating ? description : this.renderErrorMessage}
        acceptButtonLabel={"YES"}
        declineButtonLabel={!errorUpdating ? "NO" : "CANCEL"}
        onAccept={this.handleRepliesConfirmationAccept}
        onCancel={this.handleRepliesConfirmationCancel}
        acceptButtonDisabled={isUpdating || Boolean(errorUpdating)}
        declineButtonDisabled={isUpdating}
      />
    );
  }

  get renderConfirmationDialog() {
    const { showConfirmArchiveChannel } = this.state;
    const { archiveChannelSuccess, archivedChannelError } = this.props;
    if (
      archiveChannelSuccess ||
      !showConfirmArchiveChannel ||
      archivedChannelError
    ) {
      return null;
    }

    return (
      <ConfirmationDialog
        title="Archive Channel"
        description="Are you sure you want to archive this channel?"
        acceptButtonLabel="Yes"
        declineButtonLabel="No"
        onAccept={this.handleOnConfirmArchiveChannel}
        onCancel={this.handleOnCancelConfirmation}
      />
    );
  }

  get renderAlertBox() {
    const { archivedChannelError, history } = this.props;
    const message = archivedChannelError
      ? `Unable to archive this channel. ${archivedChannelError}`
      : "Archived successfully";

    if (!archivedChannelError) {
      this.alertBoxTimeout = window.setTimeout(() => {
        this.setState({ showAlertBoxMessage: false });

        // TODO: Instead of directly pushing and reloading - simulate `important` click by invoking correct action
        history.push("/community/important");
        window.location.reload();
      }, 2000);
    }

    return (
      <S.StyledAlert
        message={archivedChannelError ? message : "Archived successfully"}
        variant={
          archivedChannelError ? ALERT_VARIANTS.DANGER : ALERT_VARIANTS.SUCCESS
        }
      />
    );
  }

  onAnnouncementSearchShow = (event: React.SyntheticEvent) => {
    event.preventDefault();

    this.setState({ isSearchBoxShown: true }, () => {
      if (this.searchInputRef.current) {
        this.searchInputRef.current.focus();
      }
    });
  };

  onAnnouncementSearchClick = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    const { fetchAnnouncements, xhqUser, location, history } = this.props;

    redirectToSelectedCategory(location!, history);

    await fetchAnnouncements(xhqUser!, "inbox", true);

    this.setState({ isSearchBoxShown: false });
  };

  onAnnouncementSearchOverlayClick = (event: React.SyntheticEvent) => {
    event.preventDefault();

    this.setState({ isSearchBoxShown: false });
  };

  onAnnouncementSearchChange = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;

    this.props.setAnnouncementSearchPhrase(value);
  };

  clearSearchAnnouncementInput = () => {
    const { fetchAnnouncements, xhqUser, location, history } = this.props;

    redirectToSelectedCategory(location!, history);

    this.props.setAnnouncementSearchPhrase("");

    fetchAnnouncements(xhqUser!, "inbox", true);
  };

  renderTitleWithMenu = () => {
    const {
      title,
      onFilterMenuClick,
      isAnnouncement,
      searchPhrase,
      filter,
      xhqUser
    } = this.props;
    const { showSettingsMenu } = this.state;

    return (
      <TitleWithActions
        filter={filter}
        onMenuClick={onFilterMenuClick!}
        onTitleClick={
          isAnnouncement && searchPhrase ? this.onAnnouncementSearchShow : null
        }
        title={title}
        shouldShowMenu={Boolean(xhqUser && this.showHeaderMenu)}
        onSettingsClick={this.handleSettingsClick}
        menu={showSettingsMenu ? this.renderMenu : null}
        onSearchClick={
          searchPhrase
            ? this.clearSearchAnnouncementInput
            : this.onAnnouncementSearchShow
        }
        isAnnouncement={isAnnouncement}
        searchPhrase={searchPhrase}
      />
    );
  };

  render() {
    const {
      moreInfo,
      className,
      hideNewThreadButton,
      archiveChannelSuccess,
      archivedChannelError,
      isAnnouncement,
      searchPhrase
    } = this.props;
    const {
      showAllowRepliesConfirmation,
      showAlertBoxMessage,
      isSearchBoxShown
    } = this.state;

    return (
      <S.Wrapper className={className}>
        <S.ThreadListInfo isRelative={isSearchBoxShown}>
          {(archiveChannelSuccess || archivedChannelError) &&
            showAlertBoxMessage &&
            this.renderAlertBox}
          {!isSearchBoxShown && this.renderTitleWithMenu()}
          {isSearchBoxShown && (
            <SearchInput
              onClick={this.onAnnouncementSearchClick}
              onEnter={this.onAnnouncementSearchClick}
              onOverlayClick={this.onAnnouncementSearchOverlayClick}
              onChange={this.onAnnouncementSearchChange}
              inputRef={this.searchInputRef}
              value={searchPhrase}
            />
          )}
          {moreInfo && <S.MoreInfo>{moreInfo}</S.MoreInfo>}
        </S.ThreadListInfo>
        {!hideNewThreadButton && (
          <S.NewThreadButton onClick={this.handleNewThreadClick}>
            <Icon name={Icons.EDIT} />
            <div>Start New {!isAnnouncement ? "Thread" : "Announcement"}</div>
          </S.NewThreadButton>
        )}
        {showAllowRepliesConfirmation && this.renderAllowRepliesConfirmation}
        {this.renderConfirmationDialog}
      </S.Wrapper>
    );
  }
}

export default withRouter(ThreadListHeader as React.ComponentType<any>);
