import ApolloClient from "apollo-client";

import { getItemFromLocalStorage, setItemToLocalStorage } from "./helpers";
import {
  getApolloClientInstance,
  getApolloXHQClientInstance
} from "../lib/apollo";

const TOKEN_KEYS = {
  COMMUNITY: "jwt",
  HASH: "hash",
  XHQ: "xhqToken"
};

const clearCacheByInstance = (apolloInstance: ApolloClient<any>) => {
  if (!apolloInstance) {
    return null;
  }
  return apolloInstance.cache.reset();
};

export const setCurrentXHQToken = (token: string): void => {
  setItemToLocalStorage(TOKEN_KEYS.XHQ, token);
};

export const getCurrentToken = (): string | null => {
  return getItemFromLocalStorage(TOKEN_KEYS.COMMUNITY);
};

export const getCurrentXHQToken = (): string | null => {
  return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlblR5cGUiOiJNRU1CRVIiLCJpZCI6Ik1lbWJlci0wMURWTkNCQVpRNEgwWFBQUEowNDVYSDVSRyIsImNvbW11bml0eU5hbWUiOiJjb21tdW5pdHkteGhxLWRlbW8iLCJpYXQiOjE1NzU4OTY4NTN9.EmuRsKm5Gxh9nwxxYDhgdZQ81wSQJxIiRRFIYx8mwto";
};

export const removeCurrentTokens = (): void => {
  if (window.localStorage) {
    window.localStorage.removeItem(TOKEN_KEYS.COMMUNITY);
    window.localStorage.removeItem(TOKEN_KEYS.HASH);
    window.localStorage.removeItem(TOKEN_KEYS.XHQ);
  }
};

export const clearCache = async () => {
  const apolloCommunityClient = getApolloClientInstance();
  const apolloXHQClient = getApolloXHQClientInstance();
  await clearCacheByInstance(apolloCommunityClient);
  await clearCacheByInstance(apolloXHQClient);
};
