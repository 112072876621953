import * as React from "react";
import styled from "styled-components";
import { get } from "lodash";

import media from "../../lib/media";
import ThreadListItem from "../ThreadListItem";
import DefaultAvatar from "../../assets/avatar.svg";
import { FILTERS } from "../../constants";
import CustomLink from "../CustomLink/CustomLink";

interface IProps {
  filter?: string;
  channelName?: string;
  hasDraft?: boolean;
  threadItems: IThread[];
  selectedThreadId?: string;
  onThreadItemClick?: (
    threadId?: string
  ) => (event: React.MouseEvent<HTMLElement>) => void;
}

const Wrapper = styled.div`
  max-width: 24rem;

  ${media.lessThan("large")`
    width: 100%;
    max-width: 100%;
  `};
`;

const ItemWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.secondary.light}22;
  :hover {
    cursor: pointer;
  }
`;

class ThreadList extends React.PureComponent<IProps> {
  get getDraftThread() {
    return {
      id: "Draft",
      title: "Thread:",
      body: "Me:",
      isDraft: true
    } as IThread;
  }

  renderThread = (threadItem: IThread) => {
    const {
      selectedThreadId,
      onThreadItemClick,
      filter = FILTERS.INBOX,
      channelName,
      hasDraft
    } = this.props;
    const { id, isDraft, type } = threadItem;

    const isSelected =
      (hasDraft && isDraft) || (!hasDraft && id === selectedThreadId);
    const avatar =
      isDraft && get(threadItem, "createdBy.avatar", DefaultAvatar);
    return (
      <ItemWrapper
        role="button"
        key={threadItem.id}
        onClick={onThreadItemClick && onThreadItemClick(id)}
      >
        <CustomLink
          isDisabled={isDraft}
          route={`/community/${filter}${
            channelName ? `/${channelName}` : ``
          }/threads/${id}`}
        >
          <ThreadListItem
            {...threadItem}
            avatar={avatar || DefaultAvatar}
            highlighted={isSelected}
            badge={type === "event" ? "RSVP" : undefined}
            isDraft={isDraft}
          />
        </CustomLink>
      </ItemWrapper>
    );
  };

  render() {
    const { threadItems, hasDraft } = this.props;

    const threads = hasDraft
      ? [this.getDraftThread, ...threadItems]
      : threadItems;

    return (
      <Wrapper>
        {threads && threads.map(threadItem => this.renderThread(threadItem))}
      </Wrapper>
    );
  }
}

export default ThreadList;
