import * as React from "react";
import styled from "styled-components";

interface IProps {
  className?: string;
  title: string;
  description?: React.ReactNode;
  imageSrc: string;
  children?: React.ReactNode;
}

interface IPlaceholderImage {
  imageSrc: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 50vh;
  margin: auto;
`;

const PlaceholderImage = styled.div<IPlaceholderImage>`
  background-image: url(${({ imageSrc }) => imageSrc});
  background-position: center;
  background-repeat: no-repeat;
  width: 15.6rem;
  height: 9.375rem;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 2rem;
  line-height: 2.5rem;
  color: ${({ theme }) => theme.colors.secondary.main};
  margin: 2.5rem 0 1rem;
`;

const Description = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.secondary.semilight};
`;

const EmptyScreenPlaceholder = ({
  className,
  imageSrc,
  title,
  description,
  children
}: IProps) => {
  return (
    <Wrapper className={className}>
      <PlaceholderImage imageSrc={imageSrc} />
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {children}
    </Wrapper>
  );
};

export default EmptyScreenPlaceholder;
