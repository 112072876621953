import React, { ComponentType } from "react";
import { Route } from "react-router-dom";

import NavigationBarWrapper from "../ui/NavigationBarWrapper";

interface IProps {
  component: ComponentType<any>;
  redirectTo?: string;
  componentProps?: object;
  exact?: boolean;
  path: string;
}

const CgsRoute = ({
  component: Component,
  redirectTo,
  componentProps = {},
  ...rest
}: IProps) => {
  const Render = (props: any) => {
    const { match, location, history } = props;
    const routerProps = {
      match,
      location,
      history
    };
    return (
      <NavigationBarWrapper {...routerProps }>
        <Component {...props} {...componentProps} />
      </NavigationBarWrapper>
    );
  };

  return <Route {...rest} render={Render} />;
};

export default CgsRoute;
