import gql from "graphql-tag";

import { ME_XHQ_FRAGMENT } from "../fragments/me";

export const GET_XHQ_ME = gql`
  ${ME_XHQ_FRAGMENT}

  query XHQ_Me {
    me {
      ...XhqMeFields
    }
  }
`;

export const XHQ_MEMBER_UPDATED_SUBSCRIPTION = gql`
  ${ME_XHQ_FRAGMENT}

  subscription MemberUpdated($memberId: ID!) {
    memberUpdated(memberId: $memberId) {
      ...XhqMeFields
    }
  }
`;
