import * as React from "react";
import Scrollbar from "react-custom-scrollbars";
import styled from "styled-components";

import media from "../../lib/media";

interface IProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  flex?: number;
  onScrollToBottom?: () => void;
  scrollHeight?: string;
  wrapperHeight?: string;
  height?: number;
  stickyTop?: boolean;
}

interface IScrollbar {
  scrollheight: string;
  wrapperheight?: string;
}

const ScrollbarWrapper = styled(Scrollbar)<IScrollbar>`
  box-sizing: border-box;
  height: ${({ wrapperheight }: any) =>
    wrapperheight ? wrapperheight : `calc(100vh - 9rem)`} !important;
  & > div:last-child {
    opacity: 0;
    transition: opacity 200ms ease;
  }

  > div:first-child {
    overflow: scroll scroll !important;
  }

  &:hover > div:last-child {
    opacity: 1;
  }

  ${media.greaterThan("medium")`
    height: ${({ scrollheight }: any) => scrollheight} !important;
  `};
`;

class ScrollableColumn extends React.PureComponent<IProps> {
  fullHeight: number = 0;
  scrollbarRef: any;

  componentDidMount() {
    this.fullHeight = this.props.height || window.innerHeight;
  }

  handleScroll = (elem: any) => {
    const { onScrollToBottom } = this.props;
    if (onScrollToBottom && this.isScrolledToBottom(elem.target)) {
      onScrollToBottom();
    }
  };

  handleScrollbarRef = (ref: any) => {
    this.scrollbarRef = ref;
  };

  isScrolledToBottom = (targetElement: HTMLElement) => {
    return (
      targetElement.getBoundingClientRect().bottom >=
      targetElement.scrollHeight - targetElement.scrollTop
    );
  };

  render() {
    const {
      flex,
      scrollHeight = "calc(100vh - 4rem)",
      children,
      className,
      wrapperHeight,
      stickyTop = false
    } = this.props;
    let scrollableChildren = children;
    let stickyChild = null;
    if (
      stickyTop &&
      children &&
      Array.isArray(children) &&
      children.length > 1
    ) {
      stickyChild = children[0];
      scrollableChildren = children.slice(1);
    }

    return (
      <>
        {stickyTop && stickyChild}
        <ScrollbarWrapper
          ref={this.handleScrollbarRef}
          className={className}
          style={{
            flex,
            width: flex ? "100%" : undefined,
            borderRight: "0.08rem solid rgba(215, 219, 231, 0.4)"
          }}
          onScroll={this.handleScroll}
          scrollheight={scrollHeight}
          wrapperheight={wrapperHeight}
        >
          {scrollableChildren}
        </ScrollbarWrapper>
      </>
    );
  }
}

export default ScrollableColumn;
