import moment from "moment";
import { get } from "lodash";
import { SubscriptionResult } from "react-apollo";
import { ObservableQuery, ApolloError } from "apollo-client";

import {
  getChannelNameFromId,
  getMessagesDataByChannel
} from "../../utils/helpers";
import {
  FETCH_THREADS,
  DELETE_THREAD,
  UPDATE_THREAD,
  POST_THREAD,
  UPDATE_THREAD_AS_READ,
  FETCH_THREAD,
  UPDATE_THREAD_AS_FAVOURITE,
  UPDATE_THREAD_AS_NON_FAVOURITE,
  POST_THREAD_COMMENT,
  REMOVE_THREAD_DRAFT,
  REMOVE_THREAD_DATA,
  REMOVE_THREAD_OPERATION_DATA,
  SUBSCRIBE_TO_CREATE_MESSAGE,
  MESSAGE_CREATED_SUBSCRIPTION,
  CLEAR_THREADS
} from "../../constants/actions/threads";
import {
  GET_THREADS,
  GET_THREAD,
  DELETE_THREAD as DELETE_THREAD_MUTATION,
  UPDATE_THREAD as UPDATE_THREAD_MUTATION,
  CREATE_THREADS as CREATE_THREAD_MUTATION,
  MARK_THREAD_AS_READ,
  MARK_THREAD_AS_UNREAD,
  THREAD_CREATED_SUBSCRIPTION
} from "../../graphql/tags/threads";
import {
  NOTIFABLE_STATUS,
  THREAD_LIST_ITEMS_PER_PAGE,
  FILTERS
} from "../../constants";
import {
  CREATE_FAVORITE,
  DELETE_FAVORITE,
  GET_FAVORITE
} from "../../graphql/tags/favorites";

import { CREATE_COMMENTS } from "../../graphql/tags/comments";
import { getThreadData } from "../../selectors/thread";
import { fetchNotification } from "../notifications";

import * as actions from "./";

export const fetchThreads = (
  channelName: string,
  isLoadingMore: boolean = false
) => ({ getState }: IReduxStore): TFetchThreads | undefined => {
  const {
    threads: { threadsByChannel }
  } = getState();

  const threadsByChannelEntity = getMessagesDataByChannel(
    threadsByChannel,
    channelName
  );

  const {
    data: { nextPage, hasBeenFetched },
    isFetching
  } = threadsByChannelEntity;

  if (
    isFetching ||
    (!isLoadingMore && hasBeenFetched) ||
    (isLoadingMore && !nextPage)
  ) {
    return;
  }

  return {
    actionType: FETCH_THREADS,
    actionTypeParam: channelName,
    api: "XHQ",
    operationType: "query",
    operation: GET_THREADS,
    variables: {
      channel: `Channel-${channelName}`,
      limit: THREAD_LIST_ITEMS_PER_PAGE,
      pageToken: isLoadingMore ? nextPage! : null
    },
    getter: response => get(response, "data.messages")
  };
};

export const deleteThread = (
  threadData: IDeleteThreadData
) => (): TDeleteThread => ({
  actionType: DELETE_THREAD,
  actionTypeParam: "delete",
  api: "XHQ",
  operationType: "mutation",
  operation: DELETE_THREAD_MUTATION,
  variables: {
    parentId: threadData.parentId,
    messageId: threadData.messageId
  },
  getter: response => get(response, "data.deleteMessage")
});

export const clearThreads = (channelName: string) => ({
  type: CLEAR_THREADS,
  channelName
});

export const deleteThreadAndRedirect = (
  threadData: IDeleteThreadData,
  callback: () => void
) => async ({ dispatch }: IReduxStore) => {
  const updatedThread = await dispatch(actions.deleteThread(threadData));

  if (updatedThread.type === `${DELETE_THREAD}_ERROR`) {
    return;
  }

  callback();
};

export const updateThread = (
  threadData: IUpdateThreadData
) => (): TUpdateThread => ({
  actionType: UPDATE_THREAD,
  actionTypeParam: "update",
  api: "XHQ",
  operationType: "mutation",
  operation: UPDATE_THREAD_MUTATION,
  variables: {
    data: {
      title: threadData.title,
      body: threadData.body,
      excerpt: threadData.excerpt,
      scheduledAt: threadData.scheduledAt,
      targets: threadData.targets
    },
    messageId: threadData.id,
    parentId: threadData.parentId
  },
  getter: response => ({
    ...get(response, "data.updateMessage", {}),
    parentId: threadData.parentId
  })
});

export const postThread = ({
  title,
  body,
  excerpt,
  createdBy,
  parent,
  labels
}: IPostThreadData) => (): TPostThread => ({
  actionType: POST_THREAD,
  actionTypeParam: "create",
  api: "XHQ",
  operationType: "mutation",
  operation: CREATE_THREAD_MUTATION,
  variables: {
    data: {
      title,
      body,
      excerpt,
      createdBy,
      parent,
      labels: [...labels]
    }
  },
  getter: response => get(response, "data.createMessage")
});

export const updateThreadAndRedirect = (
  threadData: IUpdateThreadData,
  callback: (threadId: string) => void
) => async ({ dispatch }: IReduxStore) => {
  const updatedThread = await dispatch(actions.updateThread(threadData));

  if (updatedThread.type === `${UPDATE_THREAD}_ERROR`) {
    return;
  }

  callback(updatedThread.payload.id);
};

export const postThreadAndRedirect = (
  threadData: IPostThreadData,
  callback: (thread: IThread) => void
) => async ({ dispatch }: IReduxStore) => {
  const createdThread = await dispatch(actions.postThread(threadData));

  if (createdThread.type === `${POST_THREAD}_ERROR`) {
    return;
  }

  // TODO: here we are triggering create notification action on await as well!

  callback(createdThread.payload);
};

export const updateThreadAsRead = (
  threadId: string,
  threadParentId: string,
  setToRead: boolean
) => (): TUpdateThreadAsRead => ({
  actionType: UPDATE_THREAD_AS_READ,
  actionTypeParam: "updateAsRead",
  api: "XHQ",
  operationType: "mutation",
  operation: setToRead ? MARK_THREAD_AS_READ : MARK_THREAD_AS_UNREAD,
  variables: {
    messageId: `${threadId}#${threadParentId}`
  },
  getter: () => ({
    id: threadId,
    read: setToRead ? moment().toISOString() : null,
    parentId: threadParentId
  })
});

export const fetchThread = (
  threadId: string,
  channelId: string,
  xhqUser: IXHQMember,
  variables?: IFetchThreadVariables | IFetchAnnouncementThreadVariables
) => (): TFetchThread => ({
  actionType: FETCH_THREAD,
  api: "XHQ",
  operationType: "query",
  operation: GET_THREAD,
  variables: variables || {
    id: threadId,
    channelId: channelId,
    memberId: xhqUser.id,
    status: NOTIFABLE_STATUS.IMPORTANT
  },
  getter: response => get(response, "data.message")
});

export const fetchFavorite = (
  favoriteId: string,
  xhqUser: IXHQMember
) => (): TFetchFavorite => ({
  actionType: FETCH_THREAD,
  api: "XHQ",
  operationType: "query",
  operation: GET_FAVORITE,
  variables: {
    id: favoriteId,
    memberId: xhqUser.id
  },
  getter: response => {
    const message = get(response, "data.favorite", { object: {} });
    return { ...message.object, favorite: { id: message.id } };
  }
});

export const fetchAnnouncementThread = (
  threadId: string,
  xhqUser: IXHQMember
) => {
  const variables = {
    id: threadId as string,
    memberId: xhqUser.id,
    other: NOTIFABLE_STATUS.ANNOUNCEMENT,
    status: NOTIFABLE_STATUS.ANNOUNCEMENT
  };
  return actions.fetchThread(threadId, "", xhqUser, variables);
};

const fetchMessageByFilter = (
  filter: string,
  threadId: string,
  channelId: string,
  xhqMember: IXHQMember
): ((...args: any[]) => any) => {
  switch (filter) {
    case FILTERS.INBOX:
    case FILTERS.SENT:
    case FILTERS.SCHEDULED:
      return actions.fetchAnnouncementThread(threadId, xhqMember);
    case FILTERS.FAVOURITES:
      return actions.fetchFavorite(threadId, xhqMember);
    case FILTERS.IMPORTANT:
      return fetchNotification(threadId, xhqMember);
    default:
      return actions.fetchThread(threadId, channelId, xhqMember);
  }
};

export const fetchThreadAndUpdateAsRead = (
  threadId: string,
  filter: string,
  channelId: string
) => async ({ dispatch, getState }: IReduxStore) => {
  if (!threadId) {
    return;
  }

  const state = getState();
  const {
    xhqUser: { data }
  } = state;
  const fetchedThreadData = getThreadData(state);

  if (fetchedThreadData.isFetching) {
    return;
  }

  const isAlreadyFetched =
    fetchedThreadData.data && fetchedThreadData.data!.id === threadId;

  if (!isAlreadyFetched) {
    const response = await dispatch(
      fetchMessageByFilter(filter, threadId, channelId, data as IXHQMember)
    );

    if (response.type === `${FETCH_THREAD}_ERROR`) {
      return;
    }

    if (response.payload && !response.payload.read) {
      const { id, parentId } = response.payload;
      return await dispatch(actions.updateThreadAsRead(id, parentId, true));
    }

    return;
  }

  if (fetchedThreadData.data && !fetchedThreadData.data.read) {
    const { id, parentId } = fetchedThreadData.data;
    return await dispatch(actions.updateThreadAsRead(id!, parentId, true));
  }
};

export const updateThreadAsFavourite = (
  threadId: string,
  threadParentId: string,
  xhqUserId: string
) => (): TUpdateThreadAsFavourite => ({
  actionType: UPDATE_THREAD_AS_FAVOURITE,
  actionTypeParam: "notification",
  api: "XHQ",
  operationType: "mutation",
  operation: CREATE_FAVORITE,
  variables: {
    memberId: xhqUserId,
    data: {
      memberId: xhqUserId,
      status: NOTIFABLE_STATUS.INBOX,
      objectId: `${threadId}#${threadParentId}`
    }
  },
  getter: response => get(response, "data.createFavorite")
});

export const updateThreadAsNonFavourite = (
  favouriteId: string,
  xhqUserId: string
) => (): TUpdateThreadAsNonFavourite => ({
  actionType: UPDATE_THREAD_AS_NON_FAVOURITE,
  actionTypeParam: "notification",
  api: "XHQ",
  operationType: "mutation",
  operation: DELETE_FAVORITE,
  variables: {
    favoriteId: favouriteId,
    memberId: xhqUserId
  },
  getter: () => ({ id: favouriteId })
});

export const postThreadComment = (
  threadData: IPostThreadData
) => (): TPostThread => ({
  actionType: POST_THREAD_COMMENT,
  api: "XHQ",
  operationType: "mutation",
  operation: CREATE_COMMENTS,
  variables: {
    data: threadData
  },
  getter: response => get(response, "data.createMessage")
});

export const removeThreadDraftFromState = () => ({
  type: REMOVE_THREAD_DRAFT
});

export const removeThreadDataFromState = () => ({
  type: REMOVE_THREAD_DATA
});

export const removeThreadOperationDataFromState = () => ({
  type: REMOVE_THREAD_OPERATION_DATA
});

export const subscribeToCreateMessage = (id: string) => ({
  dispatch,
  getState
}: IReduxStore) => {
  const {
    threads: { threadsByChannel }
  } = getState();
  const channelId = getChannelNameFromId(id);
  if (
    !threadsByChannel[channelId] ||
    threadsByChannel[channelId].isFetching ||
    threadsByChannel[channelId].data.hasBeenSubscribed
  ) {
    return;
  }

  return {
    actionType: SUBSCRIBE_TO_CREATE_MESSAGE,
    api: "XHQ",
    operationType: "subscribe",
    operation: THREAD_CREATED_SUBSCRIPTION,
    variables: {
      parent: id
    },
    getter: (observable: ObservableQuery) => {
      observable.subscribe({
        next: ({ data: { messageCreated } }: SubscriptionResult) => {
          dispatch({
            type: `${MESSAGE_CREATED_SUBSCRIPTION}_SUCCESS`,
            payload: messageCreated
          });
        },
        error: (error: ApolloError) => {
          dispatch({
            type: `${MESSAGE_CREATED_SUBSCRIPTION}_ERROR`,
            payload: error
          });
        }
      });

      return channelId;
    }
  };
};
