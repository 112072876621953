import { createSelector } from "reselect";

const getAudiencesFromState = (state: IAppState) => state.audiences;

export const getAudiencesState = createSelector(
  getAudiencesFromState,
  audiences => audiences
);

export const getAudiencesOptions = createSelector(
  getAudiencesFromState,
  audiences => {
    return audiences.data.items.map(audience => {
      const { id, name } = audience;
      return {
        value: id,
        label: name
      };
    });
  }
);
