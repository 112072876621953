import * as React from "react";
import styled, { keyframes } from "styled-components";

import media from "../../lib/media";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: 7.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary.light}22;
  max-height: 7.5rem;
  padding: 1rem;

  ${media.greaterThan("large")`
    padding: 1.5rem;
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const animation = keyframes`
  0% { background-position: 100% 0% }
  100% { background-position: 0% 100% }
`;

const Base = styled.div`
  animation: ${animation} 1.8s linear infinite;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;

  position: relative;
`;

const Avatar = styled(Base)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.tertiary.main};
  margin-right: 1rem;
`;

const ShorterLine = styled(Base)`
  background-color: ${({ theme }) => theme.colors.tertiary.main};
  height: 1rem;
  max-width: 5.4rem;
`;

const ShortLine = styled(Base)`
  background-color: ${({ theme }) => theme.colors.tertiary.main};
  height: 1rem;
  max-width: 9.3rem;
  margin-bottom: 0.8rem;
`;

const MediumLine = styled(Base)`
  background-color: ${({ theme }) => theme.colors.tertiary.main};
  height: 1rem;
  max-width: 10.8rem;
  margin-bottom: 1rem;
`;

const LargeLine = styled(Base)`
  background-color: ${({ theme }) => theme.colors.tertiary.main};
  height: 1rem;
  max-width: 17.6rem;
  margin-bottom: 0.4rem;
`;

const ThreadListLoading = () => (
  <Wrapper>
    <Avatar />
    <ContentWrapper>
      <ShortLine />
      <LargeLine />
      <MediumLine />
      <ShorterLine />
    </ContentWrapper>
  </Wrapper>
);

export default ThreadListLoading;
