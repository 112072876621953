import * as React from "react";
import * as S from "./styles";

interface IProps {
  actionsHeaderContent: React.ReactNode;
  topWrapperContent: React.ReactNode;
  middleWrapperContent: React.ReactNode;
  bottomWrapperContent: React.ReactNode;
}

const CommunityEditorLayout = (props: IProps) => (
  <S.StyledScrollableColumn flex={1}>
    <S.Wrapper>
      <S.ContentWrapper>
        <S.ActionsHeader>{props.actionsHeaderContent}</S.ActionsHeader>
        <S.TopWrapper>{props.topWrapperContent}</S.TopWrapper>
        <S.MiddleWrapper>{props.middleWrapperContent}</S.MiddleWrapper>
        <S.BottomWrapper>{props.bottomWrapperContent}</S.BottomWrapper>
      </S.ContentWrapper>
    </S.Wrapper>
  </S.StyledScrollableColumn>
);

export default CommunityEditorLayout;
