import * as React from "react";
import styled from "styled-components";

import { BUTTON_VARIANTS } from "../../constants/button";
import { WHITE } from "../../constants/colors";

interface IProps {
  className?: string;
  children: any;
  variant?: string;
  foregroundColor?: string;
  backgroundColor?: string;
  outlined?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const BaseButton = styled.button`
  display: inline-block;
  border: none;
  border-radius: 0.2rem;
  margin: 0;
  padding: 1rem 3rem;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  font-family: ${props => props.theme.fonts.main};
  font-weight: bold;
  font-size: 0.7rem;
  -webkit-appearance: none;
  -moz-appearance: none;

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: 0;
  }
`;

const DefaultButton = styled(BaseButton)`
  background-color: ${(props: any) =>
    props.backgroundColor || props.theme.colors.primary.main};
  color: ${(props: any) => props.foregroundColor || WHITE};

  :hover {
    background-color: ${(props: any) =>
      props.backgroundColor || props.theme.colors.primary.main}BF;
  }

  ${(props: any) =>
    props.disabled &&
    `
      color: ${props.foregroundColor ? `${props.foregroundColor}33` : WHITE};
      background-color: ${
        props.backgroundColor
          ? `${props.backgroundColor}BF`
          : props.theme.colors.tertiary.main
      };

      :hover {
        cursor: default
        background-color: ${
          props.backgroundColor
            ? `${props.backgroundColor}BF`
            : props.theme.colors.tertiary.main
        };
      }
    `};

  ${(props: any) =>
    props.outlined &&
    `
      background-color: ${WHITE};
      color: ${
        !props.disabled
          ? props.foregroundColor || props.theme.colors.primary.main
          : `${props.foregroundColor || props.theme.colors.primary.main}66`
      };
      border: 0.1rem solid ${props.theme.colors.primary.main}${
      !props.disabled ? "18" : "10"
    };

      :hover {
        background-color: ${
          !props.disabled
            ? `${props.foregroundColor || props.theme.colors.primary.main}18`
            : WHITE
        };
      }
    `};
`;

const RoundedButton = styled(DefaultButton)`
  border-radius: 2rem;
`;

const CircleButton = styled(DefaultButton)`
  border-radius: 50%;
  width: 2.3rem;
  height: 2.3rem;
  padding: 0;
`;

const variantToButtonMapper = {
  [BUTTON_VARIANTS.DEFAULT]: DefaultButton,
  [BUTTON_VARIANTS.CIRCLE]: CircleButton,
  [BUTTON_VARIANTS.ROUNDED]: RoundedButton
};

const Button = (props: IProps) => {
  const { variant = BUTTON_VARIANTS.DEFAULT } = props;

  const SelectedButton = variantToButtonMapper[variant];
  return <SelectedButton {...props} />;
};

export default Button;
