import * as React from "react";
import styled from "styled-components";

import media from "../../lib/media";
import { LEFT_ANGLE } from "../../constants/icons";
import { MEMBER_COMPONENT_SIZES } from "../../constants";
import Member from "../Member";
import ThreadActions from "../ThreadActions";
import Icon from "../Icon";

interface IProps extends IThreadActions {
  xhqUser: IXHQMember;
  selectedChannel?: IChannel;
  isChannelOwner?: boolean;
  isChannelModerator?: boolean;
  isThreadCreator?: boolean;
  isAnnouncement: boolean;
  isFavourite?: boolean;
  isImportant?: boolean;
  isUnread?: boolean;
  onBackClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMarkThreadReadClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFavouritesClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onUpdateClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onForwardClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onDeleteThread?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Wrapper = styled.div`
  font-family: ${props => props.theme.fonts.main};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.between("small", "large")`
    margin-bottom: 1rem;
  `};
`;

const StyledMember = styled(Member)`
  ${media.lessThan("medium")`
    display: none;
  `};
`;

const GoBackButton = styled.div`
  position: relative;
  left: -20px;

  ${media.between("small", "large")`
    left: 0;
  `};

  ${media.greaterThan("medium")`
    display: none;
  `};
`;

const GoBackIcon = styled(Icon)``;

const ThreadHeader = (props: IProps) => {
  const {
    isFavourite = false,
    isImportant = false,
    isUnread = false,
    onMarkThreadReadClick,
    onFavouritesClick,
    xhqUser,
    onBackClick,
    onUpdateClick,
    onForwardClick,
    onDeleteThread,
    isChannelOwner,
    isChannelModerator,
    isThreadCreator,
    selectedChannel,
    isAnnouncement
  } = props;
  return (
    <Wrapper>
      <GoBackButton onClick={onBackClick}>
        <GoBackIcon name={LEFT_ANGLE} />
      </GoBackButton>
      <StyledMember {...xhqUser} size={MEMBER_COMPONENT_SIZES.TINY} />
      <ThreadActions
        xhqUser={xhqUser}
        selectedChannel={selectedChannel}
        isChannelOwner={isChannelOwner}
        isChannelModerator={isChannelModerator}
        isThreadCreator={isThreadCreator}
        isAnnouncement={isAnnouncement}
        isFavourite={isFavourite}
        isImportant={isImportant}
        isUnread={isUnread}
        onMarkThreadReadClick={onMarkThreadReadClick}
        onFavouritesClick={onFavouritesClick}
        onUpdateClick={onUpdateClick}
        onForwardClick={onForwardClick}
        onDeleteThread={onDeleteThread}
      />
    </Wrapper>
  );
};

export default ThreadHeader;
