import axios from "axios";

export const get = (endpoint: string, config?: any): any => {
  return axios
    .get(endpoint, config)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error);
    });
};

export const put = (endpoint: string, data?: any, config?: any): any => {
  return axios
    .put(endpoint, data, config)
    .then(result => result.data)
    .catch(error => {
      throw new Error(error);
    });
};
