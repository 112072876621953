import { POST_THREAD_COMMENT } from "../../constants/actions/threads";

const POST_THREAD_COMMENT_SUCCESS = `${POST_THREAD_COMMENT}_SUCCESS`;
const POST_THREAD_COMMENT_LOADING = `${POST_THREAD_COMMENT}_LOADING`;
const POST_THREAD_COMMENT_ERROR = `${POST_THREAD_COMMENT}_ERROR`;

type ActionTypes =
  | IActionType<typeof POST_THREAD_COMMENT_SUCCESS, IThread | null>
  | IActionType<typeof POST_THREAD_COMMENT_LOADING, boolean>
  | IActionType<typeof POST_THREAD_COMMENT_ERROR, string | null>;

const initialState: IAsyncEntityState<TThread> = {
  data: null,
  isFetching: false,
  error: null
};

const comment = (
  state: IAsyncEntityState<TThread> = initialState,
  action: ActionTypes
): IAsyncEntityState<TThread> => {
  switch (action.type) {
    case POST_THREAD_COMMENT_SUCCESS:
      return {
        ...state,
        data: action.payload as IThread,
        isFetching: false,
        error: null
      };
    case POST_THREAD_COMMENT_LOADING:
      return {
        ...state,
        data: null,
        isFetching: true,
        error: null
      };
    case POST_THREAD_COMMENT_ERROR:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.payload as string
      };
    default:
      return state;
  }
};

export default comment;
