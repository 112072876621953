import { get } from "lodash";

import {
  FETCH_THREAD,
  DELETE_THREAD,
  UPDATE_THREAD,
  UPDATE_THREAD_AS_READ,
  UPDATE_THREAD_AS_FAVOURITE,
  UPDATE_THREAD_AS_NON_FAVOURITE,
  POST_THREAD,
  POST_THREAD_COMMENT,
  REMOVE_THREAD_DATA
} from "../../constants/actions/threads";

import { POST_NOTIFICATION } from "../../constants/actions/notifications";
import { NOTIFABLE_STATUS } from "../../constants";

import { POST_THREAD_ATTACHMENTS } from "../../constants/actions/attachments";
import {
  POST_ANNOUNCEMENT,
  FETCH_ANNOUNCEMENTS
} from "../../constants/actions/announcements";

const FETCH_THREAD_SUCCESS = `${FETCH_THREAD}_SUCCESS`;
const FETCH_THREAD_LOADING = `${FETCH_THREAD}_LOADING`;
const FETCH_THREAD_ERROR = `${FETCH_THREAD}_ERROR`;
const UPDATE_THREAD_SUCCESS = `${UPDATE_THREAD}_SUCCESS`;
const UPDATE_THREAD_AS_READ_SUCCESS = `${UPDATE_THREAD_AS_READ}_SUCCESS`;
const DELETE_THREAD_SUCCESS = `${DELETE_THREAD}_SUCCESS`;
const UPDATE_THREAD_AS_FAVOURITE_SUCCESS = `${UPDATE_THREAD_AS_FAVOURITE}_SUCCESS`;
const UPDATE_THREAD_AS_NON_FAVOURITE_SUCCESS = `${UPDATE_THREAD_AS_NON_FAVOURITE}_SUCCESS`;
const POST_THREAD_SUCCESS = `${POST_THREAD}_SUCCESS`;
const POST_ANNOUNCEMENT_SUCCESS = `${POST_ANNOUNCEMENT}_SUCCESS`;
const POST_THREAD_COMMENT_SUCCESS = `${POST_THREAD_COMMENT}_SUCCESS`;
const POST_NOTIFICATION_SUCCESS = `${POST_NOTIFICATION}_SUCCESS`;
const POST_THREAD_ATTACHMENTS_SUCCESS = `${POST_THREAD_ATTACHMENTS}_SUCCESS`;
const FETCH_ANNOUNCEMENTS_INBOX_SUCCESS = `${FETCH_ANNOUNCEMENTS}_INBOX_SUCCESS`;

type ActionTypes =
  | IActionType<typeof FETCH_THREAD_SUCCESS, IThread | null>
  | IActionType<typeof FETCH_THREAD_LOADING, boolean>
  | IActionType<typeof FETCH_THREAD_ERROR, string | null>
  | IActionType<typeof DELETE_THREAD_SUCCESS, IDeleteThreadDataResponse | null>
  | IActionType<typeof UPDATE_THREAD_SUCCESS, IThread | null>
  | IActionType<typeof UPDATE_THREAD_AS_READ_SUCCESS, IMarkAsReadPayload | null>
  | IActionType<typeof UPDATE_THREAD_AS_FAVOURITE_SUCCESS, INotifiable | null>
  | IActionType<
      typeof UPDATE_THREAD_AS_NON_FAVOURITE_SUCCESS,
      IUpdateAsNonFavouritePayload
    >
  | IActionType<typeof POST_NOTIFICATION_SUCCESS, IThread | null>
  | IActionType<typeof POST_THREAD_SUCCESS, IThread | null>
  | IActionType<typeof POST_THREAD_ATTACHMENTS_SUCCESS, IAttachment[]>
  | IActionType<typeof POST_THREAD_COMMENT_SUCCESS, IThread | null>
  | IActionType<typeof FETCH_ANNOUNCEMENTS_INBOX_SUCCESS, INotificationObject>;

const initialState: IAsyncEntityState<TThread> = {
  data: null,
  isFetching: null,
  error: null
};

const updateFavourite = (
  state: IAsyncEntityState<TThread>,
  condition: boolean,
  favorite?: { id: string }
): IThread | null => {
  return condition
    ? ({
        ...state.data,
        favorite
      } as IThread)
    : state.data;
};

const thread = (
  state: IAsyncEntityState<TThread> = initialState,
  action: ActionTypes
): IAsyncEntityState<TThread> => {
  switch (action.type) {
    case FETCH_THREAD_SUCCESS:
      return {
        ...state,
        data: action.payload as IThread,
        isFetching: false,
        error: null
      };
    case `${FETCH_ANNOUNCEMENTS}_INBOX_SUCCESS`:
      return (action.payload as INotificationObject).withFreshList
        ? {
            ...state,
            data: null
          }
        : { ...state };
    case FETCH_THREAD_LOADING:
      return {
        ...state,
        data: null,
        isFetching: true,
        error: null
      };
    case FETCH_THREAD_ERROR:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.payload as string
      };
    case UPDATE_THREAD_SUCCESS:
      const updateThreadPayload = action.payload as IThread;
      return {
        ...state,
        data:
          updateThreadPayload.id === get(state, "data.id")
            ? { ...state.data, ...updateThreadPayload }
            : state.data,
        isFetching: false,
        error: null
      };
    case UPDATE_THREAD_AS_READ_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          read: (action.payload as IMarkAsReadPayload).read
        } as IThread,
        isFetching: false,
        error: null
      };
    case DELETE_THREAD_SUCCESS:
      const deleteActionPayload = action.payload as IDeleteThreadDataResponse;
      return {
        ...state,
        data:
          state.data && deleteActionPayload.id === state.data.id
            ? null
            : state.data,
        isFetching: false,
        error: null
      };
    case UPDATE_THREAD_AS_FAVOURITE_SUCCESS:
      const favouritePayload = action.payload as INotification;
      return {
        ...state,
        data: updateFavourite(
          state,
          favouritePayload.object!.id === get(state, "data.id"),
          { id: favouritePayload.id }
        ),
        isFetching: false,
        error: null
      };
    case UPDATE_THREAD_AS_NON_FAVOURITE_SUCCESS:
      const nonFavouritePayload = action.payload as IUpdateAsNonFavouritePayload;
      return {
        ...state,
        data: updateFavourite(
          state,
          nonFavouritePayload.id === get(state, "data.favorite.id"),
          undefined
        ),
        isFetching: false,
        error: null
      };
    case POST_THREAD_COMMENT_SUCCESS:
      const commentPayload = action.payload as IThread;
      const commentStateData =
        state.data && commentPayload.parentId === state.data.id
          ? {
              ...state.data,
              children: {
                items: [
                  ...(get(state, "data.children.items") || []),
                  commentPayload
                ]
              }
            }
          : state.data;
      return {
        ...state,
        data: commentStateData,
        isFetching: false,
        error: null
      };
    case POST_THREAD_SUCCESS:
    case POST_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        data: action.payload as IThread,
        error: null,
        isFetching: false
      };
    case POST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...(action.payload as IThread),
          notification: {
            status: NOTIFABLE_STATUS.IMPORTANT
          }
        } as IThread,
        isFetching: false,
        error: null
      };
    case POST_THREAD_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...(state.data as IThread),
          attachments: {
            items: [
              ...((state.data as IThread).attachments
                ? (state.data as IThread).attachments!.items
                : []),
              ...(action.payload as IAttachment[])
            ]
          }
        }
      };
    case REMOVE_THREAD_DATA:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: null
      };
    default:
      return state;
  }
};

export default thread;
