import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { Feature } from "@paralleldrive/react-feature-toggles";
import { isActiveFeatureName } from "@paralleldrive/feature-toggles";

import Community from "../containers/Community";
import NotFoundPage from "../containers/NotFoundPage";
import {
  ANNOUNCEMENTS_TOGGLE,
  SENT_ANNOUNCEMENTS_TOGGLE,
  SCHEDULED_ANNOUNCEMENTS_TOGGLE
} from "../constants/featureToggles";
import CgsRoute from "./CgsRoute";
import { LoadingPanel } from "../ui/Loading/";



export const Routes = (props: any) => {
  React.useEffect(() => {
    props.actions.fetchXHQUser();
  }, [props.actions]);

  if(props.isXhqUserFetching || props.isXhqUserFetching === null) {
    return <LoadingPanel />;
  }

  // TODO FIX MEDICAL FORM ROUTES. RIGHT NOW THE INLINE METHOD CALLS CAUSE A RE-RENDER IF UPPER COMPONENT CHANGES
  return (
    <Feature>
      {({ features }) => {
        const filters = `${
          isActiveFeatureName(ANNOUNCEMENTS_TOGGLE, features) ? "inbox|" : ""
        }${
          isActiveFeatureName(SENT_ANNOUNCEMENTS_TOGGLE, features)
            ? "sent|"
            : ""
        }${
          isActiveFeatureName(SCHEDULED_ANNOUNCEMENTS_TOGGLE, features)
            ? "scheduled|"
            : ""
        }important|favourites`;


        return (
          <Switch>
            <CgsRoute
              exact={true}
              path={`/community/:filter(${filters})`}
              component={Community}
            />
            <CgsRoute
              exact={true}
              path={`/community/:filter(${filters})/threads/:threadId`}
              component={Community}
            />
            <CgsRoute
              exact={true}
              path="/community/:filter(channel)/:channelName"
              component={Community}
            />
            <CgsRoute
              exact={true}
              path="/community/:filter(channel)/:channelName/threads/:threadId"
              component={Community}
            />
            <Route component={NotFoundPage} />
          </Switch>
        );
      }}
    </Feature>
  );
};

export default Routes;
