import * as React from "react";
import styled from "styled-components";
import { FaPaperPlane } from "react-icons/fa";

import media from "../../lib/media";
import Avatar from "../Avatar";
import Button from "../Button";
import TextField from "../TextField";
import { BUTTON_VARIANTS } from "../../constants/button";
import {
  COMMENTS_INPUT_MIN_LENGTH,
  COMMENTS_INPUT_MAX_LENGTH
} from "../../constants/community";
import ErrorMessage from "../ErrorMessage";
import { REPLY_ERROR_MESSAGE } from "../../constants";

interface IProps {
  hasError?: boolean;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  avatarSrc: string;
  attachments?: IAttachment[];
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Wrapper = styled.div`
  padding: 0.5rem;
  overflow: hidden;
  box-shadow: 0 3px 24px 0 ${props => props.theme.colors.secondary.main}14;

  ${media.greaterThan("large")`
    padding: 1rem 2rem;
  `};
`;

const CommentWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const CommentAvatar = styled(Avatar)`
  width: 2rem;
  height: 2rem;
`;

const CommentTextAreaField = styled(TextField)`
  border-bottom: none;
  padding: 0.7rem;
  font-size: 1rem;
  flex: 1;

  textarea {
    min-width: 100% !important;
  }
`;

const CommentButton = styled(Button)`
  min-width: 2.5rem;
  min-height: 2.5rem;
  margin-left: 0.5rem;
`;

const ButtonIcon = styled(FaPaperPlane)`
  padding-right: 0.1rem;
`;

const CommentInput = (props: IProps) => {
  const {
    onChange,
    onClick,
    value,
    placeholder,
    disabled,
    avatarSrc,
    hasError
  } = props;
  return (
    <Wrapper>
      {hasError && <ErrorMessage>{REPLY_ERROR_MESSAGE}</ErrorMessage>}
      <CommentWrapper>
        <CommentAvatar src={avatarSrc} />
        <CommentTextAreaField
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          borderBottom={false}
          multiline={true}
          minLength={COMMENTS_INPUT_MIN_LENGTH}
          maxLength={COMMENTS_INPUT_MAX_LENGTH}
        />
        <CommentButton
          variant={BUTTON_VARIANTS.CIRCLE}
          onClick={onClick}
          disabled={disabled}
        >
          <ButtonIcon />
        </CommentButton>
      </CommentWrapper>
    </Wrapper>
  );
};

export default CommentInput;
