import styled, { keyframes, css, Keyframes } from "styled-components";

import Input from "../Input";

const easeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const easeInSearchIcon = keyframes`
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
`;

const animation = (animationKeyframes: Keyframes) => css`
  animation: ${animationKeyframes} 200ms ease-in;
`;

export const StyledInput = styled(Input)`
  position: relative;
  z-index: 100;

  input {
    border: 0;
    width: calc(100% - 40px);
    font-size: 1.5rem;
    font-family: ${({ theme }) => theme.fonts.secondary};
    box-sizing: border-box;
    height: 2.5rem;

    &::placeholder {
      color: ${({ theme }) => theme.colors.secondary.light};
    }

    &:focus {
      outline: 0;
    }
  }

  svg {
    ${animation(easeInSearchIcon)}
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
`;

export const SearchInputWrapper = styled.div`
  ${animation(easeIn)}
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  height: 4.9rem;
  left: -23px;
  right: -11px;
  top: -23px;
  z-index: 99;
`;

export const SearchInputOverlay = styled.div`
  ${animation(easeIn)}
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;
