import * as React from "react";
import styled from "styled-components";

interface IProps {
  text?: string | number;
  className?: string;
}

const Badge = styled.div`
  font-size: 0.62rem;
  font-family: ${props => props.theme.fonts.main};
  font-weight: 800;
  background: ${props => props.theme.colors.info.light};
  border-radius: 0.8rem;
  color: ${props => props.theme.colors.primary.main};
  display: inline-block;
  padding: 0.3rem 0.5rem;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
`;

const BadgeWrapper = (props: IProps) => {
  const { text, className } = props;
  if (!text) {
    return null;
  }

  return <Badge className={className}>{text}</Badge>;
};

export default BadgeWrapper;
