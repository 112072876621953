import * as React from "react";
import { Link } from "react-router-dom";

const ConditionalLink = (props: any) => {
  const { children, enabled, ...otherProps } = props;

  if (enabled) {
    return <Link {...otherProps}>{children}</Link>;
  }

  return children;
};

export default ConditionalLink;
