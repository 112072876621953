import * as React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { History, Location } from "history";
import { Feature } from "@paralleldrive/react-feature-toggles";
import { isActiveFeatureName } from "@paralleldrive/feature-toggles";

import Icon from "../../ui/Icon";
import { PEOPLE } from "../../constants/icons";
import media from "../../lib/media";
import { ANNOUNCEMENTS_TOGGLE } from "../../constants/featureToggles";

interface IProps {
  className?: string;
  location: Location;
  match: any;
  history: History;
}

const Wrapper = styled.div`
  display: flex;
  flex: unset;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  z-index: 99;
  width: 100%;
  overflow: hidden;

  ${media.greaterThan("medium")`
    display: none;
  `};
`;

const NavigationItem = styled.div`
  padding: 0.625rem 1.5rem;
`;

const BottomNavigationBar = ({
  className,
  location: { pathname }
}: IProps) => (
  <Wrapper className={className}>
    <Feature>
      {({ features }) => (
        <NavLink
          to={{
            pathname: `/community/${
              isActiveFeatureName(ANNOUNCEMENTS_TOGGLE, features)
                ? "inbox"
                : "important"
            }`
          }}
        >
          <NavigationItem>
            <Icon
              name={PEOPLE}
              opacity={0.8}
              inverted={pathname.includes("community")}
            />
          </NavigationItem>
        </NavLink>
      )}
    </Feature>
  </Wrapper>
);

export default BottomNavigationBar;
