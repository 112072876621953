import * as React from "react";
import styled, { withTheme } from "styled-components";

import Icon from "../Icon";
import ConditionalLink from "../ConditionalLink";

interface IProps {
  theme?: any;
  text: string;
  selected?: boolean;
  leftPadded?: boolean;
  icon?: string;
  color?: string;
  bold?: boolean;
  opacity?: number;
  iconColor?: string;
  path?: string;
  fontSize?: string;
  onItemClick?: () => void;
}

interface IInnerWrapper {
  opacity?: number;
  bold?: boolean;
}

const Wrapper = styled.div`
  ${(props: any) =>
    props.selected
      ? `
      border-left: .3rem solid ${props.theme.colors.info.main};
      padding: 0 ${props.leftPadded ? ".8rem" : "0"};

      background-image: linear-gradient(to right, ${
        props.theme.colors.white
      }, ${props.theme.colors.info.lightest});
    `
      : `
      padding: 0 ${props.leftPadded ? "1.1rem" : "0"};
    `};
`;

const InnerWrapper = styled.div<IInnerWrapper>`
  display: flex;
  max-width: 20rem;
  flex-direction: row;
  align-items: center;
  opacity: ${({ opacity, bold }) => (bold ? 1 : opacity || 1)};
`;

const Text = styled.span`
  font-family: ${(props: any) => props.theme.fonts.main};
  font-size: ${(props: any) => props.fontSize || "0.875rem"};
  ${(props: any) => props.bold && "font-weight: 600"};
  ${(props: any) => props.color && `color: ${props.color}`};
  ${(props: any) => props.opacity && `opacity: ${props.opacity}`};
`;

class NavbarItem extends React.PureComponent<IProps> {
  render() {
    const {
      selected,
      leftPadded,
      icon,
      theme,
      onItemClick,
      iconColor,
      text,
      bold,
      color,
      opacity,
      path
    } = this.props;
    const wrapperProps = { selected, leftPadded, theme };
    const textProps = { theme, bold, color, opacity };

    return (
      <Wrapper {...wrapperProps} role="button" onClick={onItemClick}>
        <ConditionalLink enabled={path} to={{ pathname: path }}>
          <InnerWrapper bold={bold} opacity={opacity}>
            {icon && (
              <Icon
                name={icon}
                color={
                  selected
                    ? theme.colors.primary.dark
                    : iconColor || theme.colors.primary.light
                }
                inverted={selected}
              />
            )}
            <Text {...textProps}>{text}</Text>
          </InnerWrapper>
        </ConditionalLink>
      </Wrapper>
    );
  }
}

export default withTheme(NavbarItem);
