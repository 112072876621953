import * as React from "react";
import styled, { withTheme } from "styled-components";

import NavbarAction from "../NavbarAction";
import NavbarItem from "../NavbarItem";
import { NavbarListLoading } from "../Loading";

interface IProps {
  navbarItems: INavbarItem[];
  fontSize?: string;
  action?: {
    text: string;
    onActionClick?: () => void;
  };
  itemColor?: string;
  hasItemOpacity?: boolean;
  title?: string;
  allBold?: boolean;
  loading?: boolean | null;
  onItemClick?: (navbarItem: any) => void;
  theme: any;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const ActionWrapper = styled.div`
  padding: 0.8rem 1.75rem;
`;

const Title = styled.p`
  text-transform: uppercase;
  font-family: ${(props: any) => props.theme.fonts.main};
  font-size: 0.7rem;
  letter-spacing: 0.02rem;
  margin-left: 1.5rem;
  color: ${(props: any) => props.theme.colors.secondary.semilight};
`;

class NavbarList extends React.PureComponent<IProps> {
  onNavbarItemClick = (navbarItem: any) => () => {
    const { onItemClick } = this.props;

    if (onItemClick) {
      onItemClick(navbarItem);
    }
  };

  renderNavbarItem = (navbarItem: INavbarItem) => {
    const { allBold, hasItemOpacity, itemColor, fontSize, theme } = this.props;
    if (navbarItem.hidden) {
      return;
    }
    return (
      <NavbarItem
        key={navbarItem.id || navbarItem.name}
        leftPadded={true}
        color={itemColor || theme.colors.secondary.main}
        bold={allBold || navbarItem.bold}
        icon={navbarItem.icon}
        selected={navbarItem.selected}
        text={navbarItem.title || navbarItem.name}
        opacity={hasItemOpacity && !navbarItem.selected ? 0.5 : 1}
        onItemClick={this.onNavbarItemClick(navbarItem)}
        path={navbarItem.path}
        fontSize={fontSize}
      />
    );
  };

  render() {
    const { title, action, loading, navbarItems } = this.props;

    return (
      <Wrapper>
        {title && <Title>{title}</Title>}

        {!loading && navbarItems.map(this.renderNavbarItem)}

        {loading && <NavbarListLoading />}

        {action && (
          <ActionWrapper>
            <NavbarAction
              text={action.text}
              onActionClick={action.onActionClick}
            />
          </ActionWrapper>
        )}
      </Wrapper>
    );
  }
}

export default withTheme(NavbarList);
