import gql from "graphql-tag";

export const CHANNEL_FRAGMENT = gql`
  fragment ChannelFields on Channel {
    id
    name
    title
    description
    type
    allowThreadReplies
    isArchived
    owner {
      id
      firstName
      lastName
      email
    }
    createdBy {
      id
    }
    moderators {
      items {
        id
        firstName
        lastName
        email
      }
    }
    capabilities {
      GEN
      MOD
      OWN
    }
    audiences {
      audience {
        id
        name
      }
      audienceContext
    }
  }
`;
