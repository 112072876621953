export const BLACK = "#2A2F39";
export const MIDNIGHT = "#383C4C";
export const GREY = "#797E95";
export const LIGHT_GREY = "#B4B8C7";
export const LIGHTER_GREY = "#EAECF4";
export const PEARL = "#E3E6F0";
export const LIGHT_NAVY = "#7FA4C6";
export const NAVY = "#00498C";
export const DARK_NAVY = "#053679";
export const WHITE = "#FFFFFF";
export const LIGHT_RED = "#FDECEC";
export const RED = "#E64545";
export const ALICE_BLUE = "#D7DBE7";
export const GREEN = "#0DB700";
export const LIGHT_EMERALD = "#86DB7F";
export const LIGHT_GREEN = "#E7F8E6";
export const BLUE = "#009AFF";
export const LIGHT_BLUE = "#E6F5FF";
export const SAPPHIRE = "#F2FAFF";
export const DARK_ORANGE = "#E67511";
export const ORANGE = "#FFBE2A";
export const LIGHT_ORANGE = "#FFF9EA";
export const MIDNIGHT_BLUE = "#002D72";
export const SEMI_TRANSPARENT_WHITE = "rgba(255, 255, 255, 0.88)";
