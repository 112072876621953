import { get } from "lodash";

import { GET_AUDIENCES_SEARCH } from "../../graphql/tags/search";
import {
  FETCH_AUDIENCES,
  REMOVE_AUDIENCES_DATA
} from "../../constants/actions/audiences";
import { AUDIENCES_SEARCH_QUERY_LIMIT } from "../../constants";

export const fetchAudiencesSearch = (name: string) => (): TFetchAudiences => ({
  actionType: FETCH_AUDIENCES,
  api: "XHQ",
  operationType: "query",
  operation: GET_AUDIENCES_SEARCH,
  variables: {
    name: name.toLowerCase(),
    limit: AUDIENCES_SEARCH_QUERY_LIMIT
  },
  getter: response => get(response, "data.search", [])
});

export const removeAudiencesDataFromState = () => ({
  type: REMOVE_AUDIENCES_DATA
});
