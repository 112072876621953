import styled from "styled-components";

import media from "../../lib/media";
import TextField from "../../ui/TextField";
import Icon from "../../ui/Icon";
import Chip from "../../ui/Chip";
import Button from "../../ui/Button";
import LoadingPanel from "../../ui/Loading/LoadingPanel";

interface IActionButton {
  disabled?: boolean;
}

export const HeaderLabel = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.secondary.secondary};
  font-size: 1.1rem;
`;

export const TopActionButton = styled.div<IActionButton>`
  display: flex;
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: 0.8rem;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

export const TopPostActionButton = styled(TopActionButton)`
  ${media.between("small", "large")`
    opacity: 0;
  `};
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 1.75rem;

  input {
    width: 100%;
  }

  ${media.lessThan("medium")`
    input {
      border: 0;
      box-sizing: border-box;
    }
  `};

  ${media.lessThan("small")`
    input {
      font-size: 1.5rem;
    }
  `};
`;

export const StyledBodyTextField = styled(StyledTextField)`
  flex: 1;

  ${media.lessThan("small")`
    input {
      font-size: 0.875rem;
    }
  `};
`;

export const StyledIcon = styled(Icon)`
  padding: 0.6875rem;
`;

export const MarginedButton = styled(Button)`
  margin-right: 0.8rem;
`;

export const BelowBodyActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.lessThan("medium")`
    flex-wrap: wrap;
  `};
`;

export const StyledChip = styled(Chip)`
  margin: 0 0.5rem 0.5rem;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.secondary.semilight};
  font-size: 0.6875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const StyledLoadingPanel = styled(LoadingPanel)`
  display: flex;
  flex: 1;
`;
