import * as React from "react";
import styled from "styled-components";

import Avatar from "../Avatar";
import { MEMBER_COMPONENT_SIZES } from "../../constants";
import DefaultAvatar from "../../assets/avatar.svg";

interface IProps {
  className?: string;
  vertically?: boolean;
  size?: string;
  info?: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  details?: string;
  avatar?: string;
}

interface IMemberComponentSize {
  size?: string;
}

const Wrapper = styled.div`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.secondary.main}
  display: flex;
  align-items: center;
`;

const VerticalWrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div<IMemberComponentSize>`
  display: flex;
  flex-direction: column;
  margin-left: ${props =>
    props.size === MEMBER_COMPONENT_SIZES.SMALL ? "0" : "0.7rem"};
`;

const MemberName = styled.span<IMemberComponentSize>`
  font-weight: bold;
  font-size: ${props =>
    props.size === MEMBER_COMPONENT_SIZES.SMALL ? ".875rem" : "1rem"};
  margin-left: 0.6rem;
`;

const MemberRole = styled.span<IMemberComponentSize>`
  color: ${props => props.theme.colors.secondary.light}
  font-size: 1rem;
  margin-left: 0.6rem;
`;

const VerticalAvatar = styled(Avatar)<IMemberComponentSize>`
  height: ${({ size }) =>
    size === MEMBER_COMPONENT_SIZES.SMALL
      ? "2rem"
      : size === MEMBER_COMPONENT_SIZES.TINY
      ? "1.5rem"
      : "3rem"};
  width: ${({ size }) =>
    size === MEMBER_COMPONENT_SIZES.SMALL
      ? "2rem"
      : size === MEMBER_COMPONENT_SIZES.TINY
      ? "1.5rem"
      : "3rem"};
`;

const StyledAvatar = styled(Avatar)<IMemberComponentSize>`
  height: ${({ size }) =>
    size === MEMBER_COMPONENT_SIZES.SMALL
      ? "2rem"
      : size === MEMBER_COMPONENT_SIZES.TINY
      ? "1.5rem"
      : "2.5rem"};
  width: ${({ size }) =>
    size === MEMBER_COMPONENT_SIZES.SMALL
      ? "2rem"
      : size === MEMBER_COMPONENT_SIZES.TINY
      ? "1.5rem"
      : "2.5rem"};
`;

const StyledMemberName = styled(MemberName)`
  margin-bottom: ${props =>
    props.size === MEMBER_COMPONENT_SIZES.SMALL ? "0.2rem" : "0.6rem"};
`;

const Member = ({
  className,
  firstName,
  lastName,
  preferredName,
  details,
  avatar,
  vertically,
  size,
  info
}: IProps) => (
  <Wrapper className={className}>
    {vertically ? (
      <VerticalWrapper>
        <VerticalAvatar src={avatar || DefaultAvatar} size={size} />
        <DetailsWrapper size={size}>
          <StyledMemberName size={size}>
            {preferredName ? preferredName : `${firstName} ${lastName}`}
          </StyledMemberName>
          <MemberRole size={size}>{info || details}</MemberRole>
        </DetailsWrapper>
      </VerticalWrapper>
    ) : (
      <>
        <StyledAvatar src={avatar || DefaultAvatar} size={size} />
        <MemberName size={size}>
          {preferredName ? preferredName : `${firstName} ${lastName}`}
        </MemberName>
        <MemberRole size={size}>{details}</MemberRole>
      </>
    )}
  </Wrapper>
);

export default Member;
