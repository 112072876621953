import styled from "styled-components";
import ReactSelect from "react-select";

import MemberChip from "../MemberChip";
import ActionButton from "../ActionButton";
import Button from "../Button";
import TextField from "../TextField";
import HorizontalRule from "../HorizontalRule";

interface IMemberSelectorProps {
  error: boolean;
}

export const ContentWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  width: 100%;
  max-width: 33.25rem;
`;

export const StyledHR = styled(HorizontalRule)`
  margin-top: 2rem;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.danger.main}
  margin-top: .25rem;
  font-size: .75rem;
  line-height: 1.25rem;
`;

export const ChannelNameField = styled(TextField)`
  input {
    font-size: 2.4rem;
    font-family: ${({ theme }) => theme.fonts.secondary};
    width: 100%;
  }
`;

export const FieldWrapper = styled.div`
  margin: 2rem 0;
`;

export const StyledMemberChip = styled(MemberChip)`
  margin: 0 0.5rem 0.5rem;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.secondary.semilight}
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: .6875rem;
  font-weight: bold;
  margin-bottom: .5rem;
`;

export const MembersContainer = styled.div<IMemberSelectorProps>`
  display: flex;
  flex-wrap: wrap;
  border: ${({ theme, error }) =>
    error ? `1px solid ${theme.colors.danger.main}` : "0"};
`;

export const AddMoreButton = styled(ActionButton)`
  display: inline-flex;
`;

export const MembersSelect = styled(ReactSelect)`
  font-family: ${({ theme }) => theme.fonts.main};
`;

export const ButtonsWrapper = styled.div`
  margin: 0.2rem 0;
`;

export const StyledButton = styled(Button)`
  margin-right: 0.2rem;
`;
