import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import logo from "../../assets/cgs_logo_vertical.png";
import media from "../../lib/media";
import { BUTTON_VARIANTS } from "../../constants/button";
import Button from "../../ui/Button";

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%
  padding: 1rem;
`;

const Logo = styled.img`
  margin-bottom: 2rem;
  width: 8.5rem;

  ${media.greaterThan("medium")`
    margin-bottom: 4rem;
    width: 10rem;
  `};
`;

const LabelNumber = styled.p`
  color: ${({ theme }) => theme.colors.primary.main};
  text-align: center;
  font-size: 5rem;
  line-height: 5rem;
  margin: 0 0 0.5rem;

  ${media.greaterThan("small")`
    font-size: 7rem;
    line-height: 7rem;
  `};
`;

const LabelNotFound = styled.p`
  color: ${({ theme }) => theme.colors.secondary.main};
  text-align: center;
  font-size: 2rem;
  margin: 0;

  ${media.greaterThan("small")`
    font-size: 3rem;
  `};
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.secondary.main};
  text-align: center;
  font-size: 0.8rem;
  margin-top: 2rem;
  line-height: 1.6rem;
  width: 90%;

  ${media.greaterThan("small")`
    width: 80%;
    font-size: 1rem;
    line-height: 1.6;
  `};

  ${media.greaterThan("medium")`
  width: 35rem;
  `};
`;

const StyledButton = styled(Button)`
  width: 13rem;
  margin-top: 1.5rem;

  ${media.greaterThan("small")`
    font-size: 0.8rem;
    width: 15rem;
  `};
`;

const NotFoundPage = () => (
  <Wrapper>
    <Logo src={logo} alt="Caulfield Life Logo" />
    <LabelNumber>404</LabelNumber>
    <LabelNotFound>Page not found</LabelNotFound>
    <Description>
      We are sorry, something went wrong. The link you clicked may be broken or
      the page may have been removed.
    </Description>
    <Link to="/" replace={true} role="button">
      <StyledButton variant={BUTTON_VARIANTS.ROUNDED}>GO BACK</StyledButton>
    </Link>
  </Wrapper>
);

export default NotFoundPage;
