import { connect } from "react-redux";

import ThreadView from "./ThreadView";
import {
  deleteThreadAndRedirect,
  updateThreadAsRead,
  updateThreadAsFavourite,
  updateThreadAsNonFavourite,
  postThreadComment
} from "../../actions/threads";
import {
  getDeleteThreadData,
  getUpdateAsReadThreadLoading,
  getFavouriteThreadData
} from "../../selectors/threads";
import { getCommentData } from "../../selectors/comment";
import { getXHQUserData } from "../../selectors/xhqUser";
import { getAllUserChannelsMap } from "../../selectors/channels";
import { getThreadData } from "../../selectors/thread";
import {
  setEditorViewMode,
  setMobileFocusedColumn
} from "../../actions/community";

export const mapStateToProps = (state: IAppState) => {
  return {
    xhqUser: getXHQUserData(state),
    allUserChannelsMap: getAllUserChannelsMap(state),
    threadData: getThreadData(state),
    commentData: getCommentData(state),
    deleteThreadData: getDeleteThreadData(state),
    favouriteThreadData: getFavouriteThreadData(state),
    isUpdatingThreadAsReadOrUnread: getUpdateAsReadThreadLoading(state)
  };
};

export const mapDispatchToProps = (dispatch: IThunkDispatch<{}, {}, any>) => {
  return {
    actions: {
      deleteThreadAndRedirect: (
        threadData: IDeleteThreadData,
        cb: () => void
      ) => dispatch(deleteThreadAndRedirect(threadData, cb)),
      updateThreadAsReadOrUnread: (
        threadId: string,
        threadParentId: string,
        setToRead: boolean
      ) => dispatch(updateThreadAsRead(threadId, threadParentId, setToRead)),
      updateThreadAsFavourite: (
        threadId: string,
        threadParentId: string,
        xhqUserId: string
      ) =>
        dispatch(updateThreadAsFavourite(threadId, threadParentId, xhqUserId)),
      updateThreadAsNonFavourite: (favouriteId: string, xhqUserId: string) =>
        dispatch(updateThreadAsNonFavourite(favouriteId, xhqUserId)),
      postThreadComment: (threadData: IPostThreadData) =>
        dispatch(postThreadComment(threadData)),
      setMobileFocusedColumn: (
        focusedColumnView: string,
        callback?: () => void
      ) => dispatch(setMobileFocusedColumn(focusedColumnView, callback)),
      setEditorViewMode: (editorViewMode: string) =>
        dispatch(setEditorViewMode(editorViewMode))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreadView);
