import getEnvConfig from "../utils/getEnvConfig";

export const XHQ_S3_PRESIGN_ENDPOINT = `${getEnvConfig(
  "REACT_APP_XHQ_URI"
)}/s3/presign`;

export const ATTACHMENTS_MAX_BYTES_SIZE = 5242880;
export const ATTACHMENTS_PATH = "xhq";
export const ATTACHMENT_ERROR_MAX_SIZE_MESSAGE = `Last attachment size exceeds maximum limit (${(
  ATTACHMENTS_MAX_BYTES_SIZE /
  1024 /
  1024
).toFixed(0)} MB)`;
