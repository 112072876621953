import { POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE } from "../../constants/actions/channels";

const SUCCESS = `${POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_SUCCESS`;
const LOADING = `${POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_LOADING`;
const ERROR = `${POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_ERROR`;

type ActionTypes =
  | IActionType<typeof SUCCESS, boolean | null>
  | IActionType<typeof LOADING, boolean>
  | IActionType<typeof ERROR, string | null>;

const initialState: IAsyncEntityState<TChannelWithMembersAndAudience> = {
  data: null,
  isFetching: false,
  error: ""
};

const addChannelWithMembersAndAudience = (
  state: IAsyncEntityState<TChannelWithMembersAndAudience> = initialState,
  action: ActionTypes
): IAsyncEntityState<TChannelWithMembersAndAudience> => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        data: action.payload as TChannelWithMembersAndAudience,
        isFetching: false,
        error: ""
      };
    case LOADING:
      return {
        ...state,
        data: null,
        isFetching: true,
        error: ""
      };
    case ERROR:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.payload as string
      };
    default:
      return state;
  }
};

export default addChannelWithMembersAndAudience;
