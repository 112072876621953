import * as React from "react";
import styled from "styled-components";
import { SingleDatePicker as LegacySingleDatePicker } from "react-dates";
import moment from "moment";

import { DATE_FORMAT } from "../../constants";
import { CALENDAR } from "../../constants/icons";
import DatePickerWrapper from "./DatePickerWrapper";
import Icon from "../Icon";

interface IElement {
  theme?: any;
  error?: boolean;
}

const CalendarIcon = styled(Icon)`
  position: absolute;
  right: 0;
`;

const TextFieldLabel = styled.div<IElement>`
  color: ${({ theme, error }) =>
    error ? theme.colors.danger.main : theme.colors.secondary.semilight};
  font-size: 0.6875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.div`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.danger.main};
  font-family: ${({ theme }) => theme.fonts.main};
`;

interface IProps {
  className?: string;
  label?: string;
  name: string;
  error?: boolean;
  errorMessage?: string;
  value: moment.Moment | null;
  onDateChange?: (date: moment.Moment | null) => void;
  isOutsideRange?: (day: moment.Moment) => boolean;
}

interface IState {
  focused: boolean | null;
}

class SingleDatePicker extends React.PureComponent<IProps, IState> {
  state = {
    focused: false
  };

  handleDateChange = (date: moment.Moment | null) => {
    const { onDateChange } = this.props;
    if (onDateChange) {
      onDateChange(date);
    }
  };

  handleFocusChange = (arg: { focused: boolean | null }) => {
    this.setState({
      focused: arg.focused
    });
  };

  handleOutsideRange = (day: moment.Moment) => {
    const { isOutsideRange } = this.props;
    if (isOutsideRange) {
      return isOutsideRange(day);
    }
    return false;
  };

  render() {
    const { className, name, label, value, error, errorMessage } = this.props;
    const { focused } = this.state;
    return (
      <DatePickerWrapper className={className}>
        {label && <TextFieldLabel error={error}>{label}</TextFieldLabel>}
        <LegacySingleDatePicker
          id={name}
          onDateChange={this.handleDateChange}
          onFocusChange={this.handleFocusChange}
          isOutsideRange={this.handleOutsideRange}
          displayFormat={DATE_FORMAT}
          date={value}
          focused={focused}
          firstDayOfWeek={0}
          hideKeyboardShortcutsPanel={true}
          numberOfMonths={1}
        />
        <CalendarIcon name={CALENDAR} />
        {error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </DatePickerWrapper>
    );
  }
}

export default SingleDatePicker;
