import styled from "styled-components";

import media from "../../lib/media";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  justify-content: space-around;
  padding: 1.5rem;

  ${media.greaterThan("medium")`
    padding:0;
    justify-content: center;
  `};
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.primary.main};
  margin: 2.5rem 0 1rem;
  font-weight: bold;

  ${media.greaterThan("medium")`
    font-size: 7rem;
    margin: 6rem 0 1rem;
  `};
`;

export const Header = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 1.5rem;
  margin: 2rem 0;
  font-weight: normal;

  ${media.greaterThan("medium")`
    font-size: 3rem;
    margin: 0rem 0 2.4rem;
  `};
`;

export const Logo = styled.img`
  width: 8.25rem;
`;

export const Footer = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.secondary.semilight};
  margin-top: 1.5rem;

  ${media.greaterThan("medium")`
    margin-top: 8rem;
  `};
`;

export const TextBlock = styled.div`
  max-width: 30rem;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.main};
  margin-bottom: 1.5rem;
  line-height: 1.5rem;

  a {
    color: ${({ theme }) => theme.colors.info.main};
  }
`;
