import * as React from "react";
import { debounce, find, isEmpty } from "lodash";
import { remove } from "lodash/fp";
import { OptionProps } from "react-select/lib/types";

import { MAX_NUMBER_OF_MEMBERS } from "./constants";
import DefaultAvatar from "../../assets/avatar.svg";
import { ADD } from "../../constants/icons";
import { ALERT_VARIANTS } from "../../constants/alert";
import { XHQ_CHANNEL_MEMBER_ROLES } from "../../constants";
import Modal from "../Modal";
import Alert from "../Alert";
import ToggleSwitch from "../ToggleSwitch";

import {
  ContentWrapper,
  StyledHR,
  ErrorText,
  ChannelNameField,
  FieldWrapper,
  StyledMemberChip,
  Label,
  MembersContainer,
  AddMoreButton,
  MembersSelect,
  ButtonsWrapper,
  StyledButton
} from "./styles";

interface IProps {
  xhqUser: IXHQMember;
  error?: string | null;
  xhqUserOptionsResult: IXHQSearchMemberOptionType[];
  onCreateChannel: TCreateChannelFunction;
  fetchXHQSearchMembers: (inputValue: string) => void;
  onCloseClick?: (event: React.MouseEvent<HTMLElement>) => void;
  removeXhqUsers: () => { type: string };
  isFetchingXhqUserOptions: boolean | null;
}

interface IState {
  channelName: string;
  showMemberSelector: boolean;
  allowReplies: boolean;
  selectedMember?: IXHQSearchMemberOptionType;
  selectedMembers: IXHQSearchMemberOptionType[];
  validationErrors: {
    invalidChannelName: boolean;
    invalidMemberSelection: boolean;
    alreadyExists: boolean;
  };
}

class CreateChannelModal extends React.PureComponent<IProps, IState> {
  state: IState = {
    channelName: "",
    showMemberSelector: false,
    allowReplies: false,
    selectedMembers: [],
    validationErrors: {
      invalidChannelName: false,
      invalidMemberSelection: false,
      alreadyExists: false
    }
  };

  constructor(props: IProps) {
    super(props);
    this.handleInputSelectChange = debounce(this.handleInputSelectChange, 500);
  }

  handleAllowReplyChange = (value: boolean) => {
    this.setState(() => ({
      allowReplies: value
    }));
  };

  handleRemoveMember = (value: IXHQMember) => () => {
    const { selectedMembers, validationErrors } = this.state;
    const updatedSelectedMembers = remove({ value }, selectedMembers);
    this.setState(() => ({
      selectedMembers: updatedSelectedMembers,
      validationErrors: {
        ...validationErrors,
        invalidMemberSelection: isEmpty(updatedSelectedMembers)
      }
    }));
  };

  handleShowMemberSelector = () => {
    this.setState(() => ({
      showMemberSelector: true
    }));
  };

  handleMemberSelectorChange = (selectedOption: any) => {
    const { selectedMembers, validationErrors } = this.state;
    this.setState(() => ({
      selectedMember: selectedOption,
      validationErrors: {
        ...validationErrors,
        alreadyExists: Boolean(find(selectedMembers, selectedOption))
      }
    }));
  };

  handleAddSelectedMember = () => {
    const { selectedMembers, selectedMember, validationErrors } = this.state;
    if (selectedMember && !find(selectedMembers, selectedMember)) {
      this.setState(() => ({
        showMemberSelector: false,
        selectedMember: undefined,
        validationErrors: {
          ...validationErrors,
          invalidMemberSelection: false
        },
        selectedMembers: [...selectedMembers, selectedMember]
      }));
    }
  };

  handleCancelMemberSelection = () => {
    this.setState(() => ({
      showMemberSelector: false,
      selectedMember: undefined
    }));
  };

  handleInputSelectChange = (value: string) => {
    if (isEmpty(value)) {
      this.props.removeXhqUsers();
      return;
    }

    this.props.fetchXHQSearchMembers(value);
  };

  handleChannelNameChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const channelName = event.target.value;
    this.setState(({ validationErrors }) => ({
      channelName,
      validationErrors: {
        ...validationErrors,
        invalidChannelName: isEmpty(channelName)
      }
    }));
  };

  handleValidate = (): boolean => {
    const { channelName, selectedMembers, validationErrors } = this.state;
    const emptyName = isEmpty(channelName);
    const emptyMembers = isEmpty(selectedMembers);
    if (emptyMembers || emptyName) {
      this.setState(() => ({
        validationErrors: {
          ...validationErrors,
          invalidChannelName: emptyName,
          invalidMemberSelection: emptyMembers
        }
      }));
      return false;
    }
    return true;
  };

  handleCreateChannel = () => {
    const { xhqUser, onCreateChannel } = this.props;
    const { channelName, allowReplies, selectedMembers } = this.state;

    if (!this.handleValidate()) {
      return;
    }

    const channelToCreate: ICreateChannelProps = {
      name: channelName,
      allowThreadReplies: allowReplies,
      ownerId: xhqUser.id
    };

    const membersToAdd: IMemberChannel[] = selectedMembers.map(member => ({
      memberId: member.value.id,
      role: XHQ_CHANNEL_MEMBER_ROLES.GENERAL
    }));

    onCreateChannel(channelToCreate, membersToAdd);
  };

  get showAddButton() {
    return this.state.selectedMembers.length < MAX_NUMBER_OF_MEMBERS;
  }

  // ReactSelect will filter options via `value` parameter.
  // So if searched value doesn't match option label - it wont show this option.
  // We are supplying options via API call so we want to filter options according to our business logic.
  filterOption = (option: OptionProps) =>
    option.value.id !== this.props.xhqUser.id;

  render() {
    const {
      error,
      onCloseClick,
      xhqUserOptionsResult,
      isFetchingXhqUserOptions
    } = this.props;
    const {
      channelName,
      showMemberSelector,
      selectedMembers,
      selectedMember,
      allowReplies,
      validationErrors: {
        invalidChannelName,
        invalidMemberSelection,
        alreadyExists
      }
    } = this.state;
    return (
      <Modal title={"Create a new channel"} onCloseClick={onCloseClick}>
        <ContentWrapper>
          <StyledHR />
          {error && <Alert message={error} variant={ALERT_VARIANTS.DANGER} />}
          <FieldWrapper>
            <ChannelNameField
              label="CHANNEL NAME"
              bottomLabel="Names must be shorter than 32 characters"
              placeholder="Channel name"
              value={channelName}
              maxLength={32}
              onChange={this.handleChannelNameChange}
              error={invalidChannelName}
              errorMessage="A name for the channel is required."
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label>REPLIES</Label>
            <ToggleSwitch
              label="Allow Replies"
              description="Channel members will be able to see and create replies to threads in this channel"
              onCheckedChange={this.handleAllowReplyChange}
              defaultValue={allowReplies}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label>MEMBERS</Label>
            <MembersContainer error={invalidMemberSelection}>
              {selectedMembers.map(({ value, label }) => (
                <StyledMemberChip
                  key={value.id}
                  avatar={value.avatar || DefaultAvatar}
                  value={value}
                  label={label}
                  onDelete={this.handleRemoveMember(value)}
                />
              ))}
              {!showMemberSelector && this.showAddButton && (
                <AddMoreButton
                  iconName={ADD}
                  onClick={this.handleShowMemberSelector}
                >
                  Add more
                </AddMoreButton>
              )}
            </MembersContainer>
            {invalidMemberSelection && (
              <ErrorText>
                At least one member should be added to the channel.
              </ErrorText>
            )}
          </FieldWrapper>
          {showMemberSelector && (
            <FieldWrapper>
              <Label>ADD MORE</Label>
              <MembersSelect
                autoFocus={true}
                placeholder="Find member to add"
                isLoading={isFetchingXhqUserOptions}
                onInputChange={this.handleInputSelectChange}
                onChange={this.handleMemberSelectorChange}
                filterOption={this.filterOption}
                options={xhqUserOptionsResult}
              />
              <ButtonsWrapper>
                <StyledButton
                  disabled={!selectedMember || alreadyExists}
                  onClick={this.handleAddSelectedMember}
                >
                  Add
                </StyledButton>
                <StyledButton onClick={this.handleCancelMemberSelection}>
                  Cancel
                </StyledButton>
              </ButtonsWrapper>
              {alreadyExists && (
                <ErrorText>This member already exists.</ErrorText>
              )}
            </FieldWrapper>
          )}
        </ContentWrapper>
        <StyledButton onClick={this.handleCreateChannel}>
          Create Channel
        </StyledButton>
      </Modal>
    );
  }
}

export default CreateChannelModal;
