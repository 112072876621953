import { createSelector } from "reselect";

const getThreadsFromState = (state: IAppState) => state.threads;

export const getThreadsByChannel = createSelector(
  getThreadsFromState,
  (threads: { threadsByChannel: IThreadsState }): IThreadsState =>
    threads.threadsByChannel
);

export const getHasAccessToThreadsByChannels = createSelector(
  getThreadsFromState,
  (threads): IChannelViewAccess => {
    const { threadsByChannel } = threads;
    const channels = Object.keys(threadsByChannel);
    if (!channels.length) {
      return {};
    }
    return channels.reduce((listOfChannels, channelName) => {
      const { error } = threadsByChannel[channelName];
      return {
        ...listOfChannels,
        [channelName]: !(error && error.includes("is not a member"))
      };
    }, {});
  }
);

export const getDeleteThreadData = createSelector(
  getThreadsFromState,
  ({ threadOperations }) => threadOperations.delete
);

export const getUpdateThreadData = createSelector(
  getThreadsFromState,
  ({ threadOperations }) => threadOperations.update
);

export const getCreateThreadData = createSelector(
  getThreadsFromState,
  ({ threadOperations }) => threadOperations.create
);

export const getUpdateAsReadThreadData = createSelector(
  getThreadsFromState,
  ({ threadOperations }) => threadOperations.updateAsRead
);

export const getUpdateAsReadThreadLoading = createSelector(
  getUpdateAsReadThreadData,
  ({ isLoading }) => isLoading
);

export const getFavouriteThreadData = createSelector(
  getThreadsFromState,
  ({ threadOperations }) => threadOperations.notification
);

const getThreadAttachments = createSelector(
  getThreadsFromState,
  ({ threadOperations }) => threadOperations.threadAttachment
);

export const getIsPostingThreadAttachments = createSelector(
  getThreadAttachments,
  threadAttachment => threadAttachment.isLoading
);
