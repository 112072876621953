import { get } from "lodash";

import { FETCH_XHQ_USER } from "../../constants/actions/xhqUser";
import { GET_XHQ_ME } from "../../graphql/tags/me";
import { setCurrentXHQToken } from "../../utils/auth";


export const fetchXHQUser = (/* any argument */) => ({
  getState
}: IReduxStore): TFetchXHQUser => {

  setCurrentXHQToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlblR5cGUiOiJNRU1CRVIiLCJpZCI6Ik1lbWJlci0wMURWTkNCQVpRNEgwWFBQUEowNDVYSDVSRyIsImNvbW11bml0eU5hbWUiOiJjb21tdW5pdHkteGhxLWRlbW8iLCJpYXQiOjE1NzU4OTY4NTN9.EmuRsKm5Gxh9nwxxYDhgdZQ81wSQJxIiRRFIYx8mwto");

  return {
    actionType: FETCH_XHQ_USER,
    api: "XHQ",
    operation: GET_XHQ_ME,
    operationType: "query",
    getter: response => {
      return get(response, "data.me", null);
    }
  };
};

export const setFetchXhqUserLoading = () => ({
  type: `${FETCH_XHQ_USER}_LOADING`,
  payload: true
});


