import styled from "styled-components";
import ReactSelect from "react-select";
import Scrollbar from "react-custom-scrollbars";

import media from "../../lib/media";

import TextField from "../../ui/TextField";
import ActionButton from "../../ui/ActionButton";
import LoadingPanel from "../../ui/Loading/LoadingPanel";
import Icon from "../../ui/Icon";
import Chip from "../../ui/Chip";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";

interface IActionButton {
  disabled?: boolean;
}

interface IAttachmentsWrapper {
  hasAttachments: boolean;
}

export const AddMoreButton = styled(ActionButton)`
  display: inline-flex;
`;

export const StyledScrollable = styled(Scrollbar)<{ scrollheight: string }>`
  ${media.greaterThan("medium")`
      min-height: ${({ scrollheight }: any) => scrollheight} !important;
    `};
`;

export const AttachmentsWrapper = styled.div<IAttachmentsWrapper>`
  margin-bottom: 3rem;
  min-height: ${({ hasAttachments }) => (hasAttachments ? "6rem" : "0.5rem")};
`;

export const HeaderLabel = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.secondary.secondary};
  font-size: 1.1rem;
`;

export const TopActionButton = styled.div<IActionButton>`
  display: flex;
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: 0.8rem;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

export const TopPostActionButton = styled(TopActionButton)`
  ${media.between("small", "large")`
    opacity: 0;
  `};
`;

export const BottomButtonsWrapper = styled.div`
  display: flex;
`;

export const FileInput = styled.input`
  display: none;
`;

export const AttachmentIcon = styled(Icon)`
  cursor: pointer;
`;

export const StyledLoadingPanel = styled(LoadingPanel)`
  display: flex;
  flex: 1;
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 1.75rem;

  input {
    width: 100%;
  }

  ${media.lessThan("medium")`
    input {
      border: 0;
      box-sizing: border-box;
    }
  `};

  ${media.lessThan("small")`
    input {
      font-size: 1.5rem;
    }
  `};
`;

export const NoteHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.main};
  font-family: ${({ theme }) => theme.fonts.main};
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
`;

export const SelectMemberChip = styled(Chip)`
  margin-left: 0.5rem;
`;

export const StyledIcon = styled(Icon)`
  padding: 0.6875rem;
`;

export const ChipWrapper = styled.div`
  ${media.lessThan("medium")`
    margin: 0.6875rem 0;
  `};
`;

export const FileUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary.main};
  font-family: ${({ theme }) => theme.fonts.main};
`;

export const MarginedButton = styled(Button)`
  margin-right: 0.8rem;
`;

export const MembersSelect = styled(ReactSelect)`
  font-family: ${({ theme }) => theme.fonts.main};
  margin-top: 1.5rem;
`;

export const StyledModal = styled(Modal)`
  > div {
    min-width: 20rem;
  }
`;

export const BelowBodyActionsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 0 auto;
  max-height: 10rem;

  ${media.lessThan("medium")`
    flex-wrap: wrap;
  `};
`;
interface ICreateThreadAsWrapper {
  withMargin: boolean;
}
export const CreateThreadAsWrapper = styled.div<ICreateThreadAsWrapper>`
  display: flex;
  margin-left: ${({ withMargin }) => (withMargin ? "2rem" : "")};
  flex-wrap: wrap;

  ${media.lessThan("medium")`
    width 100%;
    margin-left: 0rem;
    margin-top: 0.5rem;
  `};
`;

export const SelectMemberButton = styled(AddMoreButton)`
  padding: 0.125rem;
`;

export const StyledNoteHeader = styled(NoteHeader)`
  width: 100%;
  margin-bottom: 1rem;
`;

export const WordCounter = styled.div<{ isRed: boolean }>`
  color: ${({ theme, isRed }) =>
    isRed ? theme.colors.danger.main : theme.colors.secondary.semilight};
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.75rem;
  margin-top: -1rem;
  z-index: 1;
  text-align: right;
  margin-right: 0.1875rem;
`;

export const CSVHeaders = styled.span`
  color: ${({ theme }) => theme.colors.secondary.main};
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.75rem;
  margin-top: 1rem;
`;

export const SchedulerWrapper = styled.div`
  display: block;
  min-height: 5.31rem;
  margin-top: 32px;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.secondary.semilight};
  font-size: 0.6875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const SchedulerContent = styled.div`
  display: flex;
  align-items: center;
`;

export const SchedulerTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.secondary.main};
  font-weight: bold;
  line-height: 1.5rem;
`;

export const SchedulerDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.secondary.semilight};
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

export const CKEditorWrapper = styled.div`
  .ck-content a {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
