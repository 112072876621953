import gql from "graphql-tag";

export const COMMENT_FRAGMENT = gql`
  fragment CommentFields on Message {
    id
    title
    body
    createdBy {
      id
      firstName
      lastName
      avatar
    }
    parentId
    labels {
      key
      value
    }
    createdAt
  }
`;
