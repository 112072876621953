import * as React from "react";
import styled, { withTheme } from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { History, Location, UnregisterCallback } from "history";
import { Feature } from "@paralleldrive/react-feature-toggles";
import { isActiveFeatureName } from "@paralleldrive/feature-toggles";

import Icon from "../../ui/Icon";
import NotificationsDropdown from "../../ui/NotificationsDropdown";
import { ANNOUNCEMENTS_TOGGLE } from "../../constants/featureToggles";

import {
  BELL,
  SEARCH,
  PEOPLE
} from "../../constants/icons";
import media from "../../lib/media";

import logo from "../../assets/logo.fc570556.svg";
import {
  NAVIGATION_ACTIONS_DISABLED
} from "../../constants/togglableFeatures";

interface IProps {
  theme: any;
  className?: string;
  location: Location;
  match: any;
  history: History;
}

interface IState {
  isSnoozed: boolean;
  showNotificationsMenu: boolean;
}

interface INavigationItem {
  isSelected?: boolean;
}

interface IWrapper {
  isHiddenForMobile?: boolean;
}

const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex: unset;
  padding: 0 1.5rem 0 1rem;
  justify-content: flex-end;
  border-bottom: none;
  min-height: 3.5rem;
  ${({ isHiddenForMobile }: IWrapper) =>
    !isHiddenForMobile ? "display: none;" : ""};

  ${media.between("small", "large")`
    min-height: 5rem;
  `};

  ${media.greaterThan("medium")`
    display: flex;
    min-height: 4rem;

    justify-content: space-between;
    border-bottom: 0.08rem solid ${({ theme }: any) =>
      theme.colors.tertiary.main}66;
  `};
`;

const Logo = styled.img`
  width: 100%;
  max-width: 9rem;
`;

const LogoWrapper = styled(Link)`
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;

  :hover {
    cursor: pointer;
  }

  ${media.greaterThan("medium")`
    display: flex;
  `};
`;

const TopMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-left: 1rem;
  display: none;

  ${media.greaterThan("medium")`
    display: block;
  `};
`;

const NotificationsIconWrapper = styled.div`
  position: relative;
  display: none;

  :hover {
    cursor: pointer;
  }

  ${media.greaterThan("medium")`
    display: block;
  `};
`;

const StyledNotificationsDropdown = styled(NotificationsDropdown)`
  min-width: 15.62rem;
  left: -15.8rem;
`;

const NavigationWrapper = styled.div`
  display: none;
  align-items: center;

  ${media.greaterThan("medium")`
    display: flex;
  `};
`;

const NavigationItem = styled.div<INavigationItem>`
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: bold;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  align-items: center;
  margin: 0 0.75rem;
  ${props => (props.isSelected ? "opacity: 1;" : "opacity: 0.5;")} :hover {
    cursor: pointer;
  }
`;

class TopNavigationBar extends React.PureComponent<IProps, IState> {
  historyUnlisten?: UnregisterCallback;
  state = {
    isSnoozed: false,
    showNotificationsMenu: false,
  };

  get getActions() {
    const { theme } = this.props;
    const { showNotificationsMenu, isSnoozed } = this.state;

    if (NAVIGATION_ACTIONS_DISABLED) {
      return null;
    }

    return (
      <>
        <NotificationsIconWrapper>
          <div role="button" onClick={this.handleNotificationsClick}>
            <Icon name={BELL} color={theme.colors.primary.light} />
          </div>
          {showNotificationsMenu && (
            <StyledNotificationsDropdown
              onClickOutside={this.handleNotificationsClickOutside}
              onToggleSnooze={this.handleToggleSnooze}
              isSnoozed={isSnoozed}
              snoozedUntil="30 Oct at 01:44 PM"
            />
          )}
        </NotificationsIconWrapper>
        <IconWrapper>
          <Icon name={SEARCH} color={theme.colors.primary.light} />
        </IconWrapper>
      </>
    );
  }

  handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({ showNotificationsMenu: true });
  };

  handleNotificationsClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({ showNotificationsMenu: false });
  };

  handleToggleSnooze = (snoozeTime: number) => (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    this.setState(currentState => ({
      isSnoozed: !currentState.isSnoozed,
      showNotificationsMenu: false
    }));
  };

  render() {
    const {
      className,
      location: { pathname }
    } = this.props;

    return (
      <Feature>
        {({ features }: { features: ReadonlyArray<string>}) => {
          const param = isActiveFeatureName(ANNOUNCEMENTS_TOGGLE, features)
          ? "inbox"
          : "important";

          return (
            <Wrapper
              className={className}
              isHiddenForMobile={pathname.includes("dashboard")}
            >
              <LogoWrapper to={{
                pathname: `/community/${param}`
              }}>
                <Logo src={logo} alt="Caulfield Life Logo" />
              </LogoWrapper>
              <NavigationWrapper>
                <NavLink
                  to={{
                    pathname: `/community/${param}`
                  }}
                >
                  <NavigationItem isSelected={pathname.includes("community")}>
                    <Icon name={PEOPLE} />
                    Community
                  </NavigationItem>
                </NavLink>
              </NavigationWrapper>
              <TopMenuWrapper>
                {this.getActions}
              </TopMenuWrapper>
            </Wrapper>
          )
        }}
      </Feature>
    );
  }
}

export default withTheme<IProps>(TopNavigationBar);
