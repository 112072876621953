import * as React from "react";
import styled from "styled-components";

import { CLOSE } from "../../constants/icons";
import Icon from "../Icon";

interface IProps {
  className?: string;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
}

interface IWrapper {
  hasCloseButton: boolean;
}

const Wrapper = styled.div<IWrapper>`
  display: inline-flex;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.tertiary.main};
  align-items: center;
  ${({ hasCloseButton }: IWrapper) =>
    hasCloseButton
      ? "padding: 0.5rem 0.5rem 0.5rem 0.75rem;"
      : "padding: 0.5rem 0.75rem;"};
`;

const ContentWrapper = styled.span`
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 1.5rem;
`;

const CloseIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
`;

const CloseButton = styled.div`
  margin-left: 0.5rem;
  display: inline-block;
  line-height: 0;

  :hover {
    cursor: pointer;
  }
`;

const Chip = ({ className, children, onClose }: IProps) => (
  <Wrapper className={className} hasCloseButton={Boolean(onClose)}>
    <ContentWrapper>{children}</ContentWrapper>
    {onClose && (
      <CloseButton role="button" onClick={onClose}>
        <CloseIcon name={CLOSE} opacity={0.5} />
      </CloseButton>
    )}
  </Wrapper>
);

export default Chip;
