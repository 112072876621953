import React, { SyntheticEvent, Ref } from "react";

import * as Icons from "../../constants/icons";
import Icon from "../Icon";
import * as S from "./styles";

interface IProps {
  onClick: (event: SyntheticEvent) => any;
  onEnter?: (event: SyntheticEvent) => any;
  onOverlayClick?: (event: SyntheticEvent) => any;
  onChange: (event: SyntheticEvent<HTMLInputElement>) => any;
  inputRef?: Ref<HTMLInputElement>;
  value: string;
}

const SearchInput = ({
  onClick,
  onOverlayClick,
  onEnter,
  onChange,
  inputRef,
  value
}: IProps) => {
  return (
    <>
      <S.SearchInputOverlay onClick={onOverlayClick || onClick} />
      <S.SearchInputWrapper />
      <S.StyledInput
        value={value}
        onChange={onChange}
        onEnter={onEnter}
        placeholder="Search announcements"
        inputRef={inputRef}
        icon={<Icon name={Icons.SEARCH} onClick={onClick} />}
      />
    </>
  );
};

export default SearchInput;
