import {
  PUT_ATTACHMENT,
  REMOVE_ATTACHMENT,
  REMOVE_ALL_ATTACHMENTS
} from "../../constants/actions/attachments";
import { REMOVE_THREAD_DRAFT } from "../../constants/actions/threads";

const SUCCESS = `${PUT_ATTACHMENT}_SUCCESS`;
const LOADING = `${PUT_ATTACHMENT}_LOADING`;
const ERROR = `${PUT_ATTACHMENT}_ERROR`;

type ActionTypes =
  | IActionType<typeof SUCCESS, IAttachment>
  | IActionType<typeof LOADING, boolean>
  | IActionType<typeof ERROR, string>
  | IActionType<typeof REMOVE_ATTACHMENT, IAttachment>
  | IActionType<typeof REMOVE_THREAD_DRAFT, boolean>;

const initialState: IAttachmentsState = {
  data: [],
  isUploading: false,
  error: null
};

const removeAttachment = (
  state: IAttachmentsState,
  attachmentToDelete: IAttachment
) => {
  return state.data.filter(
    (attachment: IAttachment) => attachment.href !== attachmentToDelete.href
  );
};

const attachments = (
  state: IAttachmentsState = initialState,
  action: ActionTypes
): IAttachmentsState => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload as IAttachment],
        isUploading: false,
        error: null
      };
    case LOADING:
      return {
        ...state,
        isUploading: action.payload as boolean,
        error: null
      };
    case ERROR:
      return {
        ...state,
        isUploading: false,
        error: action.payload as string
      };

    case REMOVE_ATTACHMENT:
      return {
        ...state,
        data: [...removeAttachment(state, action.payload as IAttachment)]
      };
    case REMOVE_ALL_ATTACHMENTS:
      return {
        ...state,
        data: []
      };
    case REMOVE_THREAD_DRAFT:
      return {
        ...state,
        data: [],
        isUploading: false,
        error: null
      };
    default:
      return state;
  }
};

export default attachments;
