import gql from "graphql-tag";

export const TARGETS_FRAGMENT = gql`
  fragment TargetFields on NotificationTarget {
    audience {
      id
      name
    }
    role
  }
`;
