import {
  ATTACHMENTS_PATH,
  XHQ_S3_PRESIGN_ENDPOINT
} from "../constants/attachments";
import * as RestHelper from "./restHelper";
import getEnvConfig from "./getEnvConfig";

export default (
  progressCallback?: (progressEvent: ProgressEvent) => void
) => async (file: File, fileKey?: string) => {
  const { name, type } = file;
  const params = {
    objectName: name,
    path: ATTACHMENTS_PATH,
    contentType: type
  };

  const data = await RestHelper.get(XHQ_S3_PRESIGN_ENDPOINT, { params });

  const presignedUrl = data.signedUrl;
  const options = {
    headers: { "Content-Type": type },
    ...(progressCallback && { onUploadProgress: progressCallback })
  };
  const publicUrl = `${getEnvConfig("REACT_APP_XHQ_STATIC_ASSETS_URI")}${
    data.publicUrl
  }`;

  await RestHelper.put(presignedUrl, file, options);

  return {
    name,
    href: publicUrl,
    ...(fileKey && { key: fileKey })
  };
};
