import { createSelector } from "reselect";
import { get } from "lodash";

import {
  nameAndValueToArrays,
  replaceTagsWithCSVValues
} from "../utils/helpers";

const getAnnouncementsFromState = (filter: INBOX | SENT | SCHEDULED) => (
  state: IAppState
) => get(state, `announcements.${filter}`);

export const getAnnouncements = (filter: INBOX | SENT | SCHEDULED) =>
  createSelector(
    getAnnouncementsFromState(filter),
    (
      announcements: IAsyncEntityState<INotificationObject>
    ): IAsyncEntityState<IThreadsData> => {
      const {
        data: { items }
      } = announcements;
      const mappedBodyNotifications = items.map(
        (notification: INotification) => {
          const { object, mergeFields = [] } = notification;

          if (!mergeFields.length || !object) {
            return { ...notification.object };
          }

          const { body = "" } = object;

          const [tags, values] = nameAndValueToArrays(mergeFields!);
          const enhancedBody = replaceTagsWithCSVValues(body, tags, values);

          return { ...notification.object, body: enhancedBody };
        }
      ) as IThread[];

      return {
        ...announcements,
        data: { ...announcements.data, items: mappedBodyNotifications }
      };
    }
  );
