import React from "react";
import moment from "moment";

import * as S from "./styles";
import {
  ISO_DATE_FORMAT,
  TIME_FORMAT_WITH_MERIDIEM,
  TIME_FORMAT_WITHOUT_MERIDIEM
} from "../../constants";
import ErrorMessage from "../ErrorMessage";

interface IProps {
  className?: string;
  defaultValue?: string;
  errorMessage?: string | null;
  onDateTimeChange: (value: string | undefined) => void;
}

interface IState {
  selectedScheduleDate: moment.Moment | null;
  selectedScheduleTime?: string;
}

class DateTimePicker extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { defaultValue } = props;
    const defaultMomentValue = moment(defaultValue);
    this.state = {
      selectedScheduleTime: defaultValue
        ? defaultMomentValue.format(TIME_FORMAT_WITH_MERIDIEM)
        : undefined,
      selectedScheduleDate: defaultValue ? defaultMomentValue : null
    };
  }

  handleScheduleDateChange = (selectedScheduleDate: moment.Moment | null) => {
    const { onDateTimeChange } = this.props;
    this.setState({ selectedScheduleDate }, () =>
      onDateTimeChange(this.getDateTimeValue)
    );
  };

  handleApplyTimeClick = (selectedScheduleTime: string) => {
    const { onDateTimeChange } = this.props;
    this.setState({ selectedScheduleTime }, () =>
      onDateTimeChange(this.getDateTimeValue)
    );
  };

  handleOutsideRange = (day: moment.Moment): boolean => {
    return (
      moment()
        .startOf("day")
        .isAfter(day) ||
      moment()
        .endOf("year")
        .isBefore(day)
    );
  };

  get getDateTimeValue() {
    const { selectedScheduleDate, selectedScheduleTime } = this.state;
    if (!selectedScheduleTime || !selectedScheduleDate) {
      return undefined;
    }
    const formattedTime = moment(selectedScheduleTime, [
      TIME_FORMAT_WITH_MERIDIEM
    ]).format(TIME_FORMAT_WITHOUT_MERIDIEM);
    const selectedDate = selectedScheduleDate!.format(ISO_DATE_FORMAT);
    return moment(`${selectedDate}T${formattedTime}`).toISOString();
  }

  render() {
    const { className, errorMessage } = this.props;
    const { selectedScheduleDate, selectedScheduleTime } = this.state;
    return (
      <div className={className}>
        <div>
          <S.StyledSingleDatePicker
            label="DATE"
            name="scheduleDate"
            value={selectedScheduleDate}
            onDateChange={this.handleScheduleDateChange}
            isOutsideRange={this.handleOutsideRange}
          />
          <S.StyledTimePicker
            label="TIME"
            placeholder="Time"
            onApplyClick={this.handleApplyTimeClick}
            defaultValue={selectedScheduleTime}
          />
        </div>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  }
}

export default DateTimePicker;
