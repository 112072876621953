import { createSelector } from "reselect";

export const getXHQUserFromState = (state: IAppState) => state.xhqUser;

export const getXHQUserData = createSelector(
  getXHQUserFromState,
  xhqUser => {
    if (!xhqUser.data) {
      return null;
    }
    return {
      ...xhqUser.data,
      channels: {
        ...xhqUser.data!.channels,
        items: (xhqUser.data!.channels!.items || []).filter(
          channel => !channel.isArchived
        )
      }
    } as IXHQMember;
  }
);

export const getIsXHQUserFetching = createSelector(
  getXHQUserFromState,
  xhqUser => xhqUser.isFetching
);

export const getXHQUserError = createSelector(
  getXHQUserFromState,
  xhqUser => xhqUser.error
);
