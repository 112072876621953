import gql from "graphql-tag";

import { CHANNEL_FRAGMENT } from "./channel";

export const ME_XHQ_FRAGMENT = gql`
  ${CHANNEL_FRAGMENT}

  fragment XhqMeFields on Member {
    id
    firstName
    lastName
    avatar
    email
    channels {
      items {
        ...ChannelFields
      }
    }
    lastLoggedAt
    capabilities {
      items {
        id
        name
      }
    }
    roles {
      items {
        id
        name
      }
    }
  }
`;
