import { Dispatch, AnyAction } from "redux";
import { ApolloClient } from "apollo-client";

import { OPERATIONS } from "../constants";
import {
  getApolloClientInstance,
  getApolloXHQClientInstance
} from "../lib/apollo";

type ApolloClientInstance = (opotions: any) => ApolloClient<{}>;

const operationMethodMap = {
  [OPERATIONS.QUERY]: "query",
  [OPERATIONS.MUTATION]: "mutate",
  [OPERATIONS.SUBSCRIBE]: "subscribe"
};

const APIHandlerMiddleware = (store: IReduxStore) => (
  next: Dispatch<AnyAction>
) => async (action: AnyAction | TAction) => {
  if (typeof action !== "function") {
    return next(action);
  }

  const thunkVariables = { getState: store.getState, dispatch: store.dispatch };
  const actionResult = await action(thunkVariables);

  if (
    actionResult === undefined ||
    !actionResult.actionType ||
    typeof actionResult.actionType !== "string"
  ) {
    return actionResult;
  }

  const {
    operationType = "query",
    operation = "",
    actionType,
    actionTypeParam,
    variables = {},
    api = "COMMUNITY",
    getter = (x: any) => x,
    options = {}
  } = actionResult;

  const allowedActionTypes = ["FETCH", "POST", "UPDATE", "DELETE", "SUBSCRIBE"];
  const isActionTypeAllowed = allowedActionTypes.some(
    allowedActionType => allowedActionType === actionType.split("_")[0]
  );

  if (!isActionTypeAllowed) {
    return actionResult;
  }

  try {
    next({
      type: `${actionType}_LOADING`,
      payload: true,
      ...{ typeParam: actionTypeParam }
    });

    if (api !== "COMMUNITY" && api !== "XHQ") {
      throw new Error(
        "Only two APIs are available: COMMUNITY and XHQ. Please use one of them."
      );
    }

    const getInstance: ApolloClientInstance =
      api === "COMMUNITY"
        ? getApolloClientInstance
        : getApolloXHQClientInstance;

    const apolloClient = getInstance(api === "XHQ" || undefined);

    const method = operationMethodMap[operationType];
    if (!method) {
      throw new Error(
        `No method definition found for the operation "${operationType}".`
      );
    }
    const queryOperationType =
      operationType === "subscribe" ? "query" : operationType;

    const response = await apolloClient[method]({
      [queryOperationType]: operation,
      variables,
      fetchPolicy: "no-cache",
      ...options
    });

    return next({
      type: `${actionType}_SUCCESS`,
      payload: getter(response),
      ...{ typeParam: actionTypeParam }
    });
  } catch (error) {
    return next({
      type: `${actionType}_ERROR`,
      payload: error.message,
      ...{ typeParam: actionTypeParam }
    });
  }
};

export default APIHandlerMiddleware;
