import gql from "graphql-tag";
import { TARGETS_FRAGMENT } from "../fragments/targets";

export const CREATE_ANNOUNCEMENT = gql`
  ${TARGETS_FRAGMENT}

  mutation CreateAnnouncement(
    $data: AnnouncementInput!
    $targets: [AnnouncementTarget!]!
  ) {
    createAnnouncement(data: $data, targets: $targets) {
      id
      title
      body
      excerpt
      read
      createdBy {
        id
        firstName
        lastName
        avatar
      }
      aliasCreatedBy {
        id
      }
      parentId
      labels {
        key
        value
      }
      createdAt
      lastEditedAt
      sentAt
      scheduledAt
      targets {
        ...TargetFields
      }
    }
  }
`;
