import { createSelector } from "reselect";
import { get, uniqBy } from "lodash";

const getNotificationsFromState = (status: INBOX | IMPORTANT | FAVOURITES) => (
  state: IAppState
) => get(state, `notifications.${status}`);

export const getNotifications = (status: INBOX | IMPORTANT | FAVOURITES) =>
  createSelector(
    getNotificationsFromState(status),
    (
      notifications: IAsyncEntityState<INotificationObject>
    ): IAsyncEntityState<IThreadsData> => {
      const notificationsItems = notifications.data.items.filter(
        (notification: INotification) => {
          return !!notification.object;
        }
      );
      const filteredNotificationsItems = uniqBy(
        notificationsItems,
        "object.id"
      ).map(({ object, id }: INotification) => ({
        ...object,
        id,
        object: { id: object!.id }
      }));

      return {
        ...notifications,
        data: { ...notifications.data, items: filteredNotificationsItems }
      } as IAsyncEntityState<IThreadsData>;
    }
  );
