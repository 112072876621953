import * as React from "react";
import { get } from "lodash";
import ApolloClient from "apollo-client";

import XhqSubscription from "../graphql/components/XHQ/XhqSubscription";
import {
  XHQ_MEMBER_UPDATED_SUBSCRIPTION,
  GET_XHQ_ME
} from "../graphql/tags/me";
import { getCachedQueryData } from "../utils/helpers";

interface IXHQMemberSubscriptionData {
  data: {
    memberUpdated: IXHQMember;
  };
}

interface IXHQMemberSubscription {
  client: ApolloClient<object>;
  subscriptionData: IXHQMemberSubscriptionData;
}

const getUpdatedUser = (
  current: IXHQMember,
  updated: IXHQMember
): IXHQMember => {
  return {
    ...current,
    channels: {
      ...current.channels,
      items: [...(get(updated, "channels.items") || [])]
    }
  };
};

const withXhqMemberUpdateSubscription = (WrappedComponent: any) =>
  class WithXhqMemberUpdateSubscription extends React.Component<any> {
    handleMemberUpdatedSubscription = ({
      client,
      subscriptionData: updatedMemberData
    }: IXHQMemberSubscription) => {
      const { xhqUser } = this.props;

      const updatedMember = get(updatedMemberData, "data.memberUpdated");
      if (!updatedMember) {
        return;
      }

      const cacheData = getCachedQueryData(client, {
        query: GET_XHQ_ME,
        variables: {
          memberId: xhqUser.id
        }
      });

      const currentMember: IXHQMember = get(cacheData, "me", {});

      client.writeQuery({
        query: GET_XHQ_ME,
        variables: {
          memberId: xhqUser.id
        },
        data: {
          me: getUpdatedUser(currentMember, updatedMember)
        }
      });
    };

    render() {
      const { xhqUser } = this.props;

      const variables = {
        memberId: xhqUser ? xhqUser.id : undefined
      };

      return (
        <XhqSubscription
          fetchPolicy="no-cache"
          subscription={XHQ_MEMBER_UPDATED_SUBSCRIPTION}
          variables={variables}
          onSubscriptionData={this.handleMemberUpdatedSubscription}
        >
          {({ data }: IXHQMemberSubscriptionData) => {
            if (xhqUser === null) {
              return <WrappedComponent {...this.props} />;
            }
            const updatedUser: IXHQMember = getUpdatedUser(
              xhqUser,
              get(data, "memberUpdated", null) || xhqUser
            );
            return <WrappedComponent {...this.props} xhqUser={updatedUser} />;
          }}
        </XhqSubscription>
      );
    }
  };

export default withXhqMemberUpdateSubscription;
