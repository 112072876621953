import { combineReducers } from "redux";

import publicChannels from "./publicChannels";
import selectedChannel from "./selectedChannel";
import createdChannel from "./createdChannel";
import addedChannelMembers from "./addChannelMembers";
import archivedChannel from "./archivedChannel";
import addChannelWithMembersAndAudience from "./addChannelWithMembersAndAudience";
import updateChannelWithMembersAndAudience from "./updateChannelWithMembersAndAudience";

export default combineReducers({
  publicChannels,
  selectedChannel,
  createdChannel,
  archivedChannel,
  addedChannelMembers,
  addChannelWithMembersAndAudience,
  updateChannelWithMembersAndAudience
});
