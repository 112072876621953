import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, split } from "apollo-link";
import { WebSocketLink as ApolloWebSocketLink } from "apollo-link-ws";
import { Client, WebSocketLink } from "aws-lambda-ws-link";
import { getMainDefinition } from "apollo-utilities";
import { get } from "lodash";

import getEnvConfig, { getEnvUrlConfig } from "../utils/getEnvConfig";
import { getCurrentToken, getCurrentXHQToken } from "../utils/auth";

let apolloClientInstance: ApolloClient<{}>;
let apolloXHQClientInstance: ApolloClient<{}>;

/**
 * Set a global variable for debug purposes since
 * Apollo Client devTool do not suppor multiple cache instances.
 *
 * @param apolloInstance Apollo instance with the `cache` instance that is gonna be debugged.
 * @param cacheInstanceName Name of the global variable. i.e __APOLLO_CLIENT_CUSTOM__
 */
const setDebugWindowCache = (
  apolloInstance: ApolloClient<any>,
  cacheInstanceName: string
) => {
  if (process.env.NODE_ENV !== "development") {
    return;
  }
  window[cacheInstanceName] = apolloInstance.cache;
};

const getAuthHeaders = () => ({
  Authorization: `Bearer ${getEnvConfig("REACT_APP_API_TOKEN", "")}`,
  "X-Community-Token": getCurrentToken()
});

const getXHQAuthHeaders = () => ({
  Authorization: getCurrentXHQToken()
});

const getLink = (options?: IApolloLinkOptions) => {
  const httpLink = new HttpLink({
    uri: getEnvUrlConfig("REACT_APP_API_HTTP_URI", ""),
    credentials: "same-origin",
    headers: {
      ...getAuthHeaders(),
      ...get(options, "headers", {})
    }
  });

  return httpLink;
};

const getXHQLink = () => {
  const wsApiUri = getEnvConfig("REACT_APP_XHQ_API_WS_URI", false);
  const httpLink = new HttpLink({
    uri: getEnvUrlConfig("REACT_APP_XHQ_API_HTTP_URI", ""),
    credentials: "same-origin",
    headers: {
      ...getXHQAuthHeaders()
    }
  });

  if (!wsApiUri) {
    return httpLink;
  }

  const wsClient = new Client({
    uri: getEnvUrlConfig("REACT_APP_XHQ_API_WS_URI", ""),
    options: {
      reconnect: true
    }
  });

  const wsLink = new WebSocketLink(wsClient);

  return getSelectedLink(httpLink, wsLink);
};

const getSelectedLink = (
  httpLink: HttpLink,
  wsLink: WebSocketLink | ApolloWebSocketLink
) => {
  return split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation }: any = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink
  );
};

const getClientInstance = (connectionLinks: any) => {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(error => {
            if (error) {
              const { message, locations, path } = error;
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              );
            }
          });
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      connectionLinks
    ]),
    cache: new InMemoryCache(),
    queryDeduplication: true
  });
};

export const getApolloClientInstance = (options?: IApolloInstanceOptions) => {
  const instanceOptions: IApolloInstanceOptions = {
    forceUpdateInstance: false,
    ...options
  };

  if (apolloClientInstance && !instanceOptions.forceUpdateInstance) {
    return apolloClientInstance;
  }

  const linksGetter = getLink(instanceOptions.linkOptions);
  apolloClientInstance = getClientInstance(linksGetter);
  setDebugWindowCache(apolloClientInstance, "__APOLLO_CLIENT_COMMUNITY__");

  return apolloClientInstance;
};

export const getApolloXHQClientInstance = (forceUpdateInstance?: boolean) => {
  if (apolloXHQClientInstance && !forceUpdateInstance) {
    return apolloXHQClientInstance;
  }

  const links = getXHQLink();
  apolloXHQClientInstance = getClientInstance(links);
  setDebugWindowCache(apolloXHQClientInstance, "__APOLLO_CLIENT_XHQ__");

  return apolloXHQClientInstance;
};
