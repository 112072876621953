import { connect } from "react-redux";
import { withRouter, match } from "react-router";
import { History, Location } from "history";
import { ComponentType } from "react";

import Community from "./Community";
import {
  setEditorViewMode,
  setMobileFocusedColumn
} from "../../actions/community";
import { fetchNotifications } from "../../actions/notifications";
import {
  fetchPublicChannels,
  postChannelWithMembers,
  setDefaultStateChannelWithMember,
  setDefaultStatePostChannel
} from "../../actions/channels";
import {
  fetchThreadAndUpdateAsRead,
  fetchThreads,
  removeThreadDraftFromState,
  removeThreadOperationDataFromState,
  updateThreadAsRead,
  removeThreadDataFromState,
  clearThreads
} from "../../actions/threads";
import {
  getEditorViewMode,
  getFocusedColumnView
} from "../../selectors/community";
import {
  getPublicChannelsData,
  getWereChannelMembersAdded,
  getErrorAddingChannel,
  getAllUserChannels,
  getAllUserChannelsMap
} from "../../selectors/channels";
import { getNotifications } from "../../selectors/notifications";
import {
  getHasAccessToThreadsByChannels,
  getUpdateAsReadThreadLoading,
  getThreadsByChannel
} from "../../selectors/threads";
import { getSelectedThread } from "../../selectors/thread";
import {
  getXHQUserData,
  getIsXHQUserFetching,
  getXHQUserError
} from "../../selectors/xhqUser";
import {
  getXhqUserOptionsResult,
  getXhqUsersLoadingState
} from "../../selectors/xhqSearchUsers";
import { fetchXHQSearchMembers, removeXhqUsers } from "../../actions/xhqUsers";
import {
  fetchAnnouncements,
  fetchAnnouncementsByType,
  clearAnnouncements
} from "../../actions/announcements";
import { getAnnouncements } from "../../selectors/announcements";
import { FILTERS } from "../../constants";
import { getMessagesDataByChannel } from "../../utils/helpers";

export interface IOwnProps {
  history: History;
  match: match<IParams>;
  location: Location;
}

const getMessageList = (
  filter: string,
  channelName: string,
  state: IAppState
): IAsyncEntityState<IThreadsData> => {
  const filtersToSelectorsMap = {
    [FILTERS.FAVOURITES]: getNotifications(FILTERS.FAVOURITES),
    [FILTERS.IMPORTANT]: getNotifications(FILTERS.IMPORTANT),
    [FILTERS.INBOX]: getAnnouncements(FILTERS.INBOX),
    [FILTERS.SENT]: getAnnouncements(FILTERS.SENT),
    [FILTERS.SCHEDULED]: getAnnouncements(FILTERS.SCHEDULED)
  };

  if (filter === FILTERS.CHANNEL) {
    const threads = getThreadsByChannel(state);
    const channelState = getMessagesDataByChannel(threads, channelName);

    return channelState;
  }

  return filtersToSelectorsMap[filter](state);
};

export const mapStateToProps = (
  state: IAppState,
  ownProps: IOwnProps
): ICommunityDataProps => {
  const {
    match: {
      params: { filter, channelName, threadId }
    }
  } = ownProps;
  const messagesData = getMessageList(filter, channelName, state);

  return {
    xhqUser: getXHQUserData(state),
    isXhqUserFetching: getIsXHQUserFetching(state),
    xhqUserError: getXHQUserError(state),
    focusedColumnView: getFocusedColumnView(state),
    editorViewMode: getEditorViewMode(state),
    publicChannelsData: getPublicChannelsData(state),
    viewAccessByChannel: getHasAccessToThreadsByChannels(state),
    xhqUserOptionsResult: getXhqUserOptionsResult(state),
    isFetchingXhqUserOptions: getXhqUsersLoadingState(state),
    errorAddingChannel: getErrorAddingChannel(state),
    addedChannelWithMembers: getWereChannelMembersAdded(state),
    isMarkingThreadAsRead: getUpdateAsReadThreadLoading(state),
    allUserChannels: getAllUserChannels(state),
    allUserChannelsMap: getAllUserChannelsMap(state),
    messagesData,
    selectedThread: getSelectedThread(threadId)(messagesData)
  };
};

export const mapDispatchToProps = (
  dispatch: IThunkDispatch<{}, {}, any>
): ICommunityActions => ({
  actions: {
    fetchThreadAndUpdateAsRead: (
      threadId: string,
      filter: string,
      channelId: string
    ) => dispatch(fetchThreadAndUpdateAsRead(threadId, filter, channelId)),
    setMobileFocusedColumn: (focusedColumnView, callback) =>
      dispatch(setMobileFocusedColumn(focusedColumnView, callback)),
    setEditorViewMode: editorViewMode =>
      dispatch(setEditorViewMode(editorViewMode)),
    fetchPublicChannels: () => dispatch(fetchPublicChannels()),
    fetchXHQSearchMembers: (searchTerm: string) =>
      dispatch(fetchXHQSearchMembers(searchTerm)),
    fetchNotifications: (xhqUser, status, isLoadingMore) =>
      dispatch(fetchNotifications(xhqUser, status, isLoadingMore)),
    fetchThreads: (channelId, isLoadingMore, withFreshList) =>
      dispatch(fetchThreads(channelId, isLoadingMore)),
    fetchAnnouncements: (xhqUser, filter, withFreshList, isLoadingMore) =>
      dispatch(
        fetchAnnouncements(xhqUser, filter, withFreshList, isLoadingMore)
      ),
    clearAnnouncements: (filter: string) =>
      dispatch(clearAnnouncements(filter)),
    clearThreads: (channelName: string) => dispatch(clearThreads(channelName)),
    postChannel: (channel, members) =>
      dispatch(postChannelWithMembers(channel, members)),
    resetChannelCreation: () => {
      dispatch(setDefaultStateChannelWithMember());
      dispatch(setDefaultStatePostChannel());
    },
    removeXhqUsers: () => dispatch(removeXhqUsers()),
    removeThreadDraftFromState: () => dispatch(removeThreadDraftFromState()),
    removeThreadDataFromState: () => dispatch(removeThreadDataFromState()),
    removeThreadOperationDataFromState: () =>
      dispatch(removeThreadOperationDataFromState()),
    updateThreadAsRead: (thread: IThread) => {
      return dispatch(updateThreadAsRead(thread.id!, thread.parentId, true));
    },
    fetchAnnouncementsByType: (
      xhqUser,
      status: SENT | SCHEDULED,
      withFreshList,
      isLoadingMore
    ) =>
      dispatch(
        fetchAnnouncementsByType(xhqUser, status, withFreshList, isLoadingMore)
      )
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Community) as ComponentType<any>);
