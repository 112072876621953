import * as React from "react";

import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";

import theme from "../src/theme";
import Routes from "../src/Routes";

import MaintenancePage from "./pages/Maintenance";
import FeatureTogglesProvider from "./components/FeatureTogglesProvider";

import AppContainer from "./containers/App";

import store from "./store";

const getApp = () => {
  if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
    return (
      <ThemeProvider theme={theme}>
        <MaintenancePage />
      </ThemeProvider>
    );
  }

  return (
    <FeatureTogglesProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppContainer>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </AppContainer>
        </ThemeProvider>
      </Provider>
    </FeatureTogglesProvider>
  );
};

export default getApp;
