import * as Icons from "./icons";

/**
 * Needs to be changed every time the UAT is deployed.
 * Please follow the semantic versioning.
 * @see https://semver.org/
 */
export const VERSION_NUMBER = "1.14.1";

export const THREAD_LIST_ITEMS_PER_PAGE = 10;
export const THREAD_LIST_LOADING_PLACEHOLDERS_AMOUNT = 3;

export const THREAD_ITEM_TITLE_MAX_LENGTH = 30;
export const THREAD_ITEM_CONTENT_MAX_LENGTH = 85;

export const WEEKLY_PLANNER_EVENT_TRUNCATE_SIZE = 110;

export const DATE_FORMAT = "DD / MM / YYYY";
export const DATE_FORMAT_LONG = "dddd, DD MMMM, hh:mm a";
export const ISO_DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT_WITHOUT_MERIDIEM = "HH:mm";
export const TIME_FORMAT_WITH_MERIDIEM = "h:mm A";
export const TIME_FORMAT_THREAD_LIST = "MMM D, h:mm A";

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line */
export const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/; /* eslint-disable-line */

export const SNOOZE_OPTIONS = [
  {
    hours: 0.5,
    icon: Icons.COFFEE
  },
  {
    hours: 1,
    icon: Icons.CLOCK_ONE
  },
  {
    hours: 2,
    icon: Icons.CLOCK_TWO
  },
  {
    hours: 4,
    icon: Icons.CLOCK_FOUR
  },
  {
    hours: 8,
    icon: Icons.CLOCK_EIGHT
  }
];

export const FILTERS: {
  INBOX: INBOX;
  IMPORTANT: IMPORTANT;
  FAVOURITES: FAVOURITES;
  EVENTS: EVENTS;
  CHANNEL: CHANNEL;
  SENT: SENT;
  SCHEDULED: SCHEDULED;
} = {
  INBOX: "inbox",
  IMPORTANT: "important",
  FAVOURITES: "favourites",
  EVENTS: "events",
  CHANNEL: "channel",
  SENT: "sent",
  SCHEDULED: "scheduled"
};

export const MEMBER_COMPONENT_SIZES = {
  TINY: "tiny",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "medium"
};

export const MEMBER_ROLES = {
  PARENT: "PARENT",
  STUDENT: "STUDENT",
  PRINCIPAL: "PRINCIPAL",
  MODERATOR: "MODERATOR",
  STAFF: "STAFF"
};

export const XHQ_CHANNEL_MEMBER_ROLES = {
  OWNER: "OWN",
  MODERATOR: "MOD",
  GENERAL: "GEN"
};

export const XHQ_SEND_AS_ROLES = {
  SUBJECT: "Role-SendAS_Subject",
  OBJECT: "Role-SendAS_Object"
};

export const MEMBER_ROLE_LABELS = {
  [MEMBER_ROLES.PARENT]: "Parent",
  [MEMBER_ROLES.STUDENT]: "Student",
  [MEMBER_ROLES.PRINCIPAL]: "Principal",
  [MEMBER_ROLES.MODERATOR]: "Moderator",
  [MEMBER_ROLES.STAFF]: "Staff"
};

export const MESSAGES_EXTRA_AUDIENCES = {
  EVERYONE: "EVERYONE"
};

export const MESSAGES_EXTRA_AUDIENCE_LABELS = {
  [MESSAGES_EXTRA_AUDIENCES.EVERYONE]: "Everyone"
};

export const NOTIFABLE_STATUS = {
  INBOX: "inbox",
  IMPORTANT: "important",
  ANNOUNCEMENT: "ANNOUNCEMENT"
};

export const PROFILE_HELP_URL =
  "https://caulfieldlifesupport.caulfieldlife.com.au/";

export const OPERATIONS = {
  QUERY: "query",
  MUTATION: "mutation",
  SUBSCRIBE: "subscribe"
};

export const MESSAGE_XHQ_CHANNELS_NOT_FOUND =
  "Oops! We can't find any community channels for you. Please contact support to fix this for you.";

export const REPLY_ERROR_MESSAGE =
  "There was an error while creating the reply.";

export const CAULFIELD_LIFE_STRING = "CaulfieldLife";

export const AUDIENCES_SEARCH_QUERY_LIMIT = 20;
