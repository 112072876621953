import * as React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";

import media from "../../lib/media";
import { TEXTFIELD_VARIANTS } from "../../constants/textField";

interface IProps {
  className?: string;
  name?: string;
  multiline?: boolean;
  variant?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  error?: boolean;
  errorMessage?: string;
  bottomLabel?: string;
  minLength?: number;
  maxLength?: number;
  borderBottom?: boolean;
  fullWidth?: boolean;
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  type?: string;
  rows?: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface IState {
  rows: number;
}

interface ITextFieldBase {
  borderBottom?: boolean;
  error?: boolean;
  theme?: any;
  fullWidth?: boolean;

  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
`;

const BaseStyle = `
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  cursor: text;
  display: block;
  padding: 0.7rem 0;
  font-size: 0.875rem;
  min-width: 24.06rem;
`;

const BaseTextField = styled.input<ITextFieldBase>`
  ${BaseStyle}
  font-family: ${({ theme }) => theme.fonts.main};
  ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "")}
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ borderBottom, error, theme }: ITextFieldBase) =>
    borderBottom
      ? `1px solid ${
          !error ? theme.colors.tertiary.main : theme.colors.danger.main
        }`
      : `none`};
  min-width: 24.06rem;
  color: ${({ error, theme }: ITextFieldBase) =>
    !error ? theme.colors.secondary.main : theme.colors.danger.main};
  cursor: ${({ disabled }) => (!disabled ? "text" : "default")}
  ${({ disabled }) => disabled && `opacity: 0.5`};

  ::placeholder {
    color: ${({ error, theme }: ITextFieldBase) =>
      !error ? theme.colors.secondary.semilight : theme.colors.danger.main}66;
  }

  :focus {
    outline: 0;
  }

  ${media.lessThan("small")`
    min-width: 16rem;
  `}
`;

const BaseTextArea = styled.textarea<ITextFieldBase>`
  ${BaseStyle}
  font-family: ${({ theme }) => theme.fonts.main};
  ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "")}
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ borderBottom, error, theme }: ITextFieldBase) =>
    borderBottom
      ? `1px solid ${
          !error ? theme.colors.tertiary.main : theme.colors.danger.main
        }`
      : `none`};
  color: ${({ error, theme }: ITextFieldBase) =>
    !error ? theme.colors.secondary.main : theme.colors.danger.main};
  cursor: ${({ disabled }) => (!disabled ? "text" : "default")}
  resize: none;

  ::placeholder {
    color: ${({ error, theme }: ITextFieldBase) =>
      !error ? theme.colors.secondary.semilight : theme.colors.danger.main}66;
  }

  :focus {
    outline: 0;
  }

  ${media.lessThan("small")`
    min-width: 16rem !important;
  `}
`;

const LargeTextField = styled(BaseTextField)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  border-bottom: none;
  font-size: 2rem;
  padding: 0.5rem;
  border-left: 1px solid
    ${({ error, theme }: ITextFieldBase) =>
      !error ? theme.colors.tertiary.main : theme.colors.danger.main};

  ${media.lessThan("small")`
    font-size: 1.7rem;
  `};
`;

const TextFieldLabel = styled.div<ITextFieldBase>`
  color: ${({ error, theme }: ITextFieldBase) =>
    !error ? theme.colors.secondary.semilight : theme.colors.danger.main}
  font-size: .6875rem;
  font-weight: bold;
  margin-bottom: .5rem;
`;

const BaseBottomText = styled.div`
  color: ${({ theme }) => theme.colors.danger.main}
  margin-top: .25rem;
  font-size: .75rem;
  line-height: 1.25rem;
`;

const ErrorMessage = styled(BaseBottomText)`
  color: ${({ theme }) => theme.colors.danger.main};
`;

const BottomLabel = styled(BaseBottomText)`
  color: ${({ theme }) => theme.colors.secondary.semilight};
`;

const variantToTextFieldMapper = {
  [TEXTFIELD_VARIANTS.DEFAULT]: BaseTextField,
  [TEXTFIELD_VARIANTS.LARGE]: LargeTextField
};

class TextField extends React.PureComponent<IProps, IState> {
  elem?: HTMLElement;
  initialScrollHeight: number = 0;

  state = {
    rows: 1
  };

  componentDidMount() {
    const { multiline } = this.props;
    if (multiline && this.elem) {
      this.initialScrollHeight = this.elem.scrollHeight;
    }
  }

  setElemRef = (element: any) => {
    this.elem = element;
  };

  handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { onChange, multiline } = this.props;
    if (multiline && this.elem) {
      if (
        this.elem.scrollHeight > this.initialScrollHeight &&
        this.elem.scrollHeight <= this.initialScrollHeight * 3
      ) {
        this.elem.style.cssText = `
          height: auto; padding: 0;
          height: ${this.elem.scrollHeight}px;
        `;
      }
    }

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      className,
      variant = TEXTFIELD_VARIANTS.DEFAULT,
      label,
      error,
      errorMessage,
      bottomLabel,
      multiline,
      placeholder,
      value,
      minLength,
      maxLength,
      name,
      rows = 1,
      disabled,
      type = "text",
      fullWidth,
      onKeyDown,
      borderBottom = true
    } = this.props;

    const SelectedTextField = variantToTextFieldMapper[variant];
    if (isEmpty(value) && multiline && this.elem) {
      this.elem.style.cssText = `${this.initialScrollHeight} px`;
    }
    return (
      <Wrapper className={className}>
        {label && <TextFieldLabel error={error}>{label}</TextFieldLabel>}
        {multiline && variant === TEXTFIELD_VARIANTS.DEFAULT ? (
          <BaseTextArea
            ref={this.setElemRef}
            name={name}
            rows={rows}
            error={error}
            onChange={this.handleChange}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            minLength={minLength}
            maxLength={maxLength}
            borderBottom={borderBottom}
            fullWidth={fullWidth}
          />
        ) : (
          <SelectedTextField
            type={type}
            error={error}
            name={name}
            onChange={this.handleChange}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            minLength={minLength}
            maxLength={maxLength}
            borderBottom={borderBottom}
            fullWidth={fullWidth}
            onKeyDown={onKeyDown}
          />
        )}
        {error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {!error && bottomLabel && <BottomLabel>{bottomLabel}</BottomLabel>}
      </Wrapper>
    );
  }
}

export default TextField;
