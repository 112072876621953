export const NAME_INPUT_LABEL = "Names must be shorter than 32 characters";
export const NAME_INPUT_PLACEHOLDER = "Channel name";
export const NAME_INPUT_MAX_LENGTH = 32;

export const DESCRIPTION_LABEL = "SHORT DESCRIPTION";
export const DESCRIPTION_BOTTOM_LABEL =
  "Description must be shorter than 256 characters";
export const DESCRIPTION_PLACEHOLDER = "Describe the purpose of the channel";
export const DESCRIPTION_MAX_LENGTH = 256;

export const REPLIES_TOGGLE_LABEL = "Allow Replies";
export const REPLIES_TOGGLE_DESCRIPTION =
  "Channel members will be able to see and create replies to threads in this channel";
