import { SUBSCRIBE_TO_CREATE_NOTIFICATION } from "../../constants/actions/notifications";

const SUCCESS = `${SUBSCRIBE_TO_CREATE_NOTIFICATION}_SUCCESS`;
const ERROR = `${SUBSCRIBE_TO_CREATE_NOTIFICATION}_ERROR`;

type ActionTypes =
  | IActionType<typeof SUCCESS, boolean>
  | IActionType<typeof ERROR, TStringOrNull>;

const initialState: ISubscriptions = {
  hasBeenSubscribed: false,
  error: null
};

const subscriptions = (
  state: ISubscriptions = initialState,
  action: ActionTypes
): ISubscriptions => {
  switch (action.type) {
    case SUCCESS:
      return {
        hasBeenSubscribed: true,
        error: null
      };
    case ERROR:
      return {
        ...state,
        hasBeenSubscribed: false,
        error: action.payload as TStringOrNull
      };
    default:
      return state;
  }
};

export default subscriptions;
