import styled from "styled-components";
import Scrollbar from "react-custom-scrollbars";

import media from "../../lib/media";
import Attachment from "../Attachment";
import Chip from "../Chip";

export const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
`;

export const ThreadInfoWrapper = styled.div`
  display: none;
  justify-content: space-between;
  align-items: flex-start;

  ${media.lessThan("medium")`
    display: flex;
  `};
`;

export const Title = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 2rem;
  margin: 1.4rem 0 0.4rem;
  color: ${({ theme }) => theme.colors.secondary.main};

  ${media.lessThan("medium")`
    margin: 0 0 0.4rem;
  `};
`;

export const Content = styled.p`
  float: left;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary.main};
  line-height: 1.5;
  white-space: pre-wrap;

  a {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  figure.image {
    img {
      max-width: 100%;
    }

    &.image-style-side {
      max-width: 50%;
      float: right;
      clear: both;
    }
  }
`;

export const Timestamp = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.secondary.semilight};
`;

export const DesktopTimestamp = styled(Timestamp)`
  ${media.lessThan("medium")`
    display: none;
  `};
`;

export const EditedMessage = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.secondary.semilight};

  ${media.lessThan("medium")`
    display: none;
  `};
`;

export const AttachmentWrapper = styled(Attachment)`
  margin-top: 1.3rem;

  ${media.lessThan("medium")`
    margin-bottom: 1.3rem;
  `};
`;

export const StyledChip = styled(Chip)`
  margin: 0 1rem 0.5rem 0;
  border: 0.125rem solid ${({ theme }) => theme.colors.secondary.lighter};
`;

export const ChipRole = styled.div`
  font-weight: normal;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.secondary.semilight};
`;

export const AudiencesWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const DesktopAudiencesWrapper = styled.div`
  display: block;

  ${media.lessThan("medium")`
    display: none;
  `};
`;

export const StyledScrollableWrapper = styled(Scrollbar)`
  display: none;
  height: 7.5rem !important;

  ${media.lessThan("medium")`
    display: block;
  `};
`;

export const TimeStampContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
