import { get } from "lodash";
import { ObservableQuery, ApolloError } from "apollo-client";
import { SubscriptionResult } from "react-apollo";

import {
  FETCH_NOTIFICATIONS,
  POST_NOTIFICATION,
  SUBSCRIBE_TO_CREATE_NOTIFICATION,
  NOTIFICATION_CREATED
} from "../../constants/actions/notifications";
import { GET_FAVORITES } from "../../graphql/tags/favorites";
import {
  CREATE_NOTIFICATION_FOR_CHANNEL_WITH_ROLE,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION,
  NOTIFICATION_CREATED as NOTIFICATION_CREATED_QUERY
} from "../../graphql/tags/notifications";
import { NOTIFABLE_STATUS, THREAD_LIST_ITEMS_PER_PAGE } from "../../constants";
import { notificationsGetter } from "../../utils/helpers";
import { FETCH_THREAD } from "../../constants/actions/threads";

export const fetchNotifications = (
  xhqUser: IXHQMember,
  status: IMPORTANT | FAVOURITES,
  isLoadingMore: boolean = false
) => ({ getState }: IReduxStore): TFetchNotifications | undefined => {
  const {
    notifications: {
      [status]: {
        data: { hasBeenFetched, nextPage },
        isFetching
      }
    }
  } = getState();

  if (
    isFetching ||
    (!isLoadingMore && hasBeenFetched) ||
    (isLoadingMore && !nextPage)
  ) {
    return;
  }

  return {
    actionType: `${FETCH_NOTIFICATIONS}_${status.toUpperCase()}`,
    api: "XHQ",
    operationType: "query",
    operation: status === "favourites" ? GET_FAVORITES : GET_NOTIFICATIONS,
    variables: {
      memberId: get(xhqUser, "id"),
      status: status === "favourites" ? "inbox" : status,
      limit: THREAD_LIST_ITEMS_PER_PAGE,
      pageToken: nextPage
    },
    getter: notificationsGetter(
      status === "favourites" ? "favorites" : "notifications"
    )
  };
};

export const postNotification = (
  thread: IThread,
  selectedChannelId: string
) => (): TPostNotification => ({
  actionType: POST_NOTIFICATION,
  api: "XHQ",
  operationType: "mutation",
  operation: CREATE_NOTIFICATION_FOR_CHANNEL_WITH_ROLE,
  variables: {
    channelId: selectedChannelId,
    status: NOTIFABLE_STATUS.IMPORTANT,
    objectId: `${thread.id}#${thread.parentId}`
  },
  getter: () => thread
});

export const fetchNotification = (
  notificationId: string,
  xhqUser: IXHQMember
) => (): TFetchFavorite => ({
  actionType: FETCH_THREAD,
  api: "XHQ",
  operationType: "query",
  operation: GET_NOTIFICATION,
  variables: {
    id: notificationId,
    memberId: xhqUser.id
  },
  getter: response => {
    const message = get(response, "data.notification", { object: {} });
    return { ...message.object, notification: { status: message.status } };
  }
});

export const subscribeToCreateNotification = (xhqUser: IXHQMember) => ({
  dispatch,
  getState
}: IReduxStore) => {
  const { subscriptions } = getState();

  if (subscriptions.hasBeenSubscribed) {
    return;
  }

  return {
    actionType: SUBSCRIBE_TO_CREATE_NOTIFICATION,
    api: "XHQ",
    operationType: "subscribe",
    operation: NOTIFICATION_CREATED_QUERY,
    variables: {
      memberId: xhqUser.id
    },
    getter: (observable: ObservableQuery) => {
      observable.subscribe({
        next: ({ data: { notificationCreated } }: SubscriptionResult) => {
          if (!notificationCreated) {
            return;
          }

          dispatch({
            type: `${NOTIFICATION_CREATED}_${notificationCreated.status.toUpperCase()}_SUCCESS`,
            payload: notificationCreated
          });
        },
        error: (error: ApolloError) => {
          dispatch({
            type: `${NOTIFICATION_CREATED}_ERROR`,
            payload: error
          });
        }
      });
    }
  };
};
