import { get } from "lodash";

import {
  FETCH_ANNOUNCEMENTS,
  POST_ANNOUNCEMENT,
  SET_INBOX_ANNOUNCEMENT_SEARCH_PHRASE,
  CLEAR_ANNOUNCEMENTS
} from "../../constants/actions/announcements";
import { NOTIFABLE_STATUS, FILTERS } from "../../constants";
import {
  GET_ANNOUNCEMENTS_BY_CREATOR_ID,
  GET_NOTIFICATIONS
} from "../../graphql/tags/notifications";
import { THREAD_LIST_ITEMS_PER_PAGE } from "../../constants";
import { CREATE_ANNOUNCEMENT } from "../../graphql/tags/announcements";
import { notificationsGetter } from "../../utils/helpers";

import * as actions from "./";

export const fetchAnnouncements = (
  xhqUser: IXHQMember,
  filter: INBOX,
  withFreshList: boolean = false,
  isLoadingMore: boolean = false
) => ({ getState }: IReduxStore): TFetchAnnouncements | undefined => {
  const {
    announcements: {
      inbox: {
        data: { searchPhrase, nextPage, hasBeenFetched },
        isFetching
      }
    }
  } = getState();

  if (
    isFetching ||
    (!isLoadingMore && hasBeenFetched && !withFreshList) ||
    (isLoadingMore && !nextPage)
  ) {
    return;
  }

  return {
    actionType: `${FETCH_ANNOUNCEMENTS}_${filter.toUpperCase()}`,
    api: "XHQ",
    operationType: "query",
    operation: GET_NOTIFICATIONS,
    variables: {
      memberId: xhqUser.id,
      status: "ANNOUNCEMENT",
      limit: THREAD_LIST_ITEMS_PER_PAGE,
      pageToken: withFreshList ? null : nextPage,
      ...(searchPhrase ? { where: { search: searchPhrase.toLowerCase() } } : {})
    },
    getter: notificationsGetter("notifications", withFreshList)
  };
};

export const clearAnnouncements = (filter: string) => ({
  type: CLEAR_ANNOUNCEMENTS,
  payload: filter
});

export const fetchAnnouncementsByType = (
  xhqUser: IXHQMember,
  status: SENT | SCHEDULED,
  withFreshList: boolean = false,
  isLoadingMore: boolean = false
) => ({ getState }: IReduxStore): TFetchAnnouncementsByType | undefined => {
  const {
    announcements: {
      [status]: {
        data: { nextPage, hasBeenFetched },
        isFetching
      }
    }
  } = getState();

  if (
    isFetching ||
    (!isLoadingMore && hasBeenFetched) ||
    (isLoadingMore && !nextPage)
  ) {
    return;
  }

  return {
    actionType: `${FETCH_ANNOUNCEMENTS}_${status.toUpperCase()}`,
    api: "XHQ",
    operationType: "query",
    operation: GET_ANNOUNCEMENTS_BY_CREATOR_ID,
    variables: {
      withoutScheduled: status !== FILTERS.SCHEDULED,
      withoutSent: status !== FILTERS.SENT,
      creatorId: xhqUser.id,
      memberId: xhqUser.id,
      limit: THREAD_LIST_ITEMS_PER_PAGE,
      pageToken: nextPage
    },
    getter: response => {
      const announcements = get(response, `data.announcements`);

      if (!announcements) {
        return { items: [] };
      }

      const mappedAnnouncements = announcements.items.map((item: IThread) => {
        return {
          id: item.id,
          status: NOTIFABLE_STATUS.ANNOUNCEMENT,
          object: {
            ...item,
            notification: undefined
          }
        };
      });
      return {
        ...announcements,
        items: [...mappedAnnouncements],
        withFreshList
      };
    }
  };
};

export const postAnnouncement = ({
  title,
  body,
  excerpt,
  createdBy,
  targets,
  labels,
  scheduledAt
}: IPostAnnouncementData) => (): TPostAnnouncement => ({
  actionType: POST_ANNOUNCEMENT,
  actionTypeParam: "create",
  api: "XHQ",
  operationType: "mutation",
  operation: CREATE_ANNOUNCEMENT,
  variables: {
    data: {
      title,
      body,
      excerpt,
      createdBy,
      labels,
      scheduledAt
    },
    targets
  },
  getter: response => get(response, "data.createAnnouncement")
});

export const postAnnouncementAndRedirect = (
  {
    title,
    body,
    excerpt,
    createdBy,
    targets,
    labels,
    scheduledAt
  }: IPostAnnouncementData,
  callback: (thread: IThread) => void
) => async ({ dispatch }: IReduxStore) => {
  const createdThread = await dispatch(
    actions.postAnnouncement({
      title,
      body,
      excerpt,
      createdBy,
      targets,
      labels,
      scheduledAt
    })
  );

  if (createdThread.type === `${POST_ANNOUNCEMENT}_ERROR`) {
    return;
  }

  callback(createdThread.payload);
};

export const setAnnouncementSearchPhrase = (
  searchPhrase: string
): TSetAnnouncementSearchPhrase => ({
  type: SET_INBOX_ANNOUNCEMENT_SEARCH_PHRASE,
  payload: searchPhrase
});
