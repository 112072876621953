import * as React from "react";
import styled from "styled-components";

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.danger.main};
  margin: 0.625rem;
`;

const ErrorMessage = ({ className, children }: IProps) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default ErrorMessage;
