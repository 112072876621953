import gql from "graphql-tag";
import { FAVORITE_FRAGMENT } from "../fragments/favorite";
import { THREAD_FRAGMENT } from "../fragments/thread";

export const GET_FAVORITES = gql`
  ${FAVORITE_FRAGMENT}

  query($memberId: ID!, $status: String!, $limit: Int, $pageToken: String) {
    favorites(
      memberId: $memberId
      status: $status
      limit: $limit
      pageToken: $pageToken
    ) {
      items {
        ...FavoriteFields
      }
      nextPage
    }
  }
`;

export const CREATE_FAVORITE = gql`
  ${FAVORITE_FRAGMENT}

  mutation CreateFavorite($data: FavoriteInput!) {
    createFavorite(data: $data) {
      ...FavoriteFields
    }
  }
`;

export const DELETE_FAVORITE = gql`
  mutation DeleteFavorite($favoriteId: ID!, $memberId: ID!) {
    deleteFavorite(favoriteId: $favoriteId, memberId: $memberId)
  }
`;

export const GET_FAVORITE = gql`
  ${THREAD_FRAGMENT}

  query Favorite($id: ID!, $memberId: ID!) {
    favorite(favoriteId: $id, memberId: $memberId) {
      id
      status
      object {
        ... on Message {
          ...ThreadFields
          attachments {
            items {
              id
              name
              url
            }
          }
          notification(memberId: $memberId, status: "important") {
            status
          }
        }
      }
    }
  }
`;
