import { createSelector } from "reselect";
import { getXHQUserFromState } from "./xhqUser";

const getChannelFromState = (state: IAppState) => state.channel;

export const getChannelState = createSelector(
  getChannelFromState,
  (channel: IAsyncEntityState<TChannel>) => channel
);

export const getChannelIndividualMembersOptions = createSelector(
  [getChannelFromState, getXHQUserFromState],
  (
    channelState: IAsyncEntityState<TChannel>,
    xhqUser: IAsyncEntityState<TXHQMember>
  ) => {
    const channel = channelState.data;
    if (!channel || !channel.members) {
      return [];
    }
    const audienceMembers = channel.audiences.reduce(
      (members: IXHQMember[], audience: IXHQChannelAudience) => [
        ...members,
        ...(audience.audience.members ? audience.audience.members.items : [])
      ],
      []
    );

    const filteredMembers = channel.members.items.filter(member => {
      return !audienceMembers.find(
        audienceMember => audienceMember.id === member.id
      );
    });

    return filteredMembers
      .filter(member => member.id !== xhqUser.data!.id)
      .map(member => ({
        value: member,
        label: `${member.firstName} ${member.lastName}`
      }));
  }
);

export const getAudiencesFromSelectedChannel = createSelector(
  getChannelFromState,
  (channelState: IAsyncEntityState<TChannel>) => {
    if (!channelState || !channelState.data) {
      return [];
    }
    const editingChannel = channelState.data;

    return editingChannel.audiences.map(audience => ({
      audienceId: audience.audience.id,
      role: audience.audienceContext.toUpperCase(),
      audienceRolePair: `${
        audience.audience.id
      }-${audience.audienceContext.toUpperCase()}`,
      audienceName: audience.audience.name
    }));
  }
);
