import styled from "styled-components";
import ReactSelect from "react-select";

import { Menu } from "../Menu";
import media from "../../lib/media";
import Icon from "../Icon";
import ActionButton from "../ActionButton";
import Chip from "../Chip";
import Button from "../Button";
import HorizontalRule from "../HorizontalRule";

export const MenuWrapper = styled.div`
  position: relative;
`;

export const StyledMenu = styled(Menu)`
  min-width: 13rem;

  ${media.lessThan("medium")`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
  `};
`;

export const StyledIcon = styled(Icon)`
  padding: 0.6875rem;
`;

export const StyledAction = styled(ActionButton)`
  margin: 1rem 0;
  color: ${({ theme }) => theme.colors.primary.dark};

  ${media.lessThan("medium")`
    margin: 0.6875rem 0 0.6875rem 0.75rem;
  `};
`;

export const ContentWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  width: 100%;
  max-width: 33.25rem;
  min-width: 24.06rem;

  ${media.lessThan("medium")`
    min-width: 100%;
  `};
`;

export const StyledChip = styled(Chip)`
  margin: 0 0.5rem 0;

  ${media.lessThan("medium")`
    margin: 0.6875rem 0 0.6875rem 0.5rem;
  `};
`;

export const FieldWrapper = styled.div`
  margin: 2rem 0.5rem;
`;

export const StyledButton = styled(Button)`
  margin: 1rem 0.5rem 0 0;
`;

export const CreateAudienceButton = styled(Button)`
  margin-left: 0.5rem;
`;

export const GroupsSelect = styled(ReactSelect)`
  font-family: ${({ theme }) => theme.fonts.main};
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.secondary.semilight}
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: .6875rem;
  font-weight: bold;
  margin-bottom: .5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const StyledLabel = styled(Label)`
  margin: 1rem 0.5rem;
`;

export const StyledHR = styled(HorizontalRule)`
  margin-top: 2rem;
`;

export const FileInput = styled.input`
  display: none;
`;

export const ImportCSVAction = styled.div`
  margin: 1rem 0;
  color: ${({ theme }) => theme.colors.primary.dark};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;

  ${media.lessThan("medium")`
    margin: 0.6875rem 0 0.6875rem 0.75rem;
  `};
`;
