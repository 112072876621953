import * as React from "react";
import { ApolloProvider } from "react-apollo";
import { createGlobalStyle } from "styled-components";

import media from "../../lib/media";
import { getApolloClientInstance } from "../../lib/apollo";

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: hidden;
  }

  #root {
    ${media.lessThan("large")`
      display: flex;
      flex-direction: column;
    `};
  }
`;

class App extends React.PureComponent<any> {
  render() {
    return (
      <>
        <GlobalStyle />
        <ApolloProvider client={getApolloClientInstance()}>
          {this.props.children}
        </ApolloProvider>
      </>
    );
  }
}

export default App;
