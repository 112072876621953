import React from "react";
import { times } from "lodash";
import moment from "moment";
import enhanceWithClickOutside from "react-click-outside";

import * as Icons from "../../constants/icons";
import * as S from "./styles";
import Icon from "../Icon";
import Button from "../Button";
import { BUTTON_VARIANTS } from "../../constants/button";
import { TIME_FORMAT_WITH_MERIDIEM } from "../../constants";

interface IProps {
  onApplyClick: (value: string) => void;
  onCloseClick: () => void;
  onCancelClick: () => void;
  defaultValue?: string;
}

interface IState {
  selectedHour: number;
  selectedMinute: number;
  selectedMeridian: string;
}

const meridians = ["AM", "PM"];

class TimePickerPanel extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { defaultValue } = props;
    const defaultMomentValue = moment(defaultValue, TIME_FORMAT_WITH_MERIDIEM);
    this.state = {
      selectedHour: defaultValue
        ? parseInt(defaultMomentValue.format("h"))
        : 12,
      selectedMinute: defaultValue
        ? parseInt(defaultMomentValue.format("mm"))
        : 0,
      selectedMeridian: defaultValue ? defaultMomentValue.format("A") : "AM"
    };
  }

  handleHourClick = (selectedHour: number) => () => {
    this.setState({ selectedHour });
  };

  handleMinuteClick = (selectedMinute: number) => () => {
    this.setState({ selectedMinute });
  };

  handleMeridianClick = (selectedMeridian: string) => () => {
    this.setState({ selectedMeridian });
  };

  handleApplyClick = () => {
    this.props.onApplyClick(this.getTimeValue);
  };

  handleClickOutside = () => {
    this.props.onCloseClick();
  };

  get getTimeValue() {
    const { selectedHour, selectedMinute, selectedMeridian } = this.state;
    return `${selectedHour}:${
      selectedMinute < 10 ? `0${selectedMinute}` : selectedMinute
    } ${selectedMeridian}`;
  }

  render() {
    const { onCloseClick, onCancelClick } = this.props;
    const { selectedHour, selectedMinute, selectedMeridian } = this.state;
    return (
      <S.StyledPanel>
        <div>
          <S.TitleWrapper>
            <S.Title>Choose a time</S.Title>
            <Icon name={Icons.CLOSE} opacity={0.5} onClick={onCloseClick} />
          </S.TitleWrapper>
          <S.Divider />
          <div>
            <S.TimeValueContainer>
              {times(12, (index: number) => {
                const hour = index + 1;
                return (
                  <S.TimeValue
                    key={index}
                    role="button"
                    isSelected={selectedHour === hour}
                    onClick={this.handleHourClick(hour)}
                  >
                    {hour}
                  </S.TimeValue>
                );
              })}
            </S.TimeValueContainer>
            <S.TimeValueContainer>
              {times(12, (index: number) => {
                const minute = index * 5;
                return (
                  <S.TimeValue
                    key={index}
                    role="button"
                    isSelected={selectedMinute === minute}
                    onClick={this.handleMinuteClick(minute)}
                  >
                    {minute < 10 ? `0${minute}` : minute}
                  </S.TimeValue>
                );
              })}
            </S.TimeValueContainer>
            <S.MeridianContainer>
              {meridians.map(meridian => (
                <S.TimeValue
                  key={meridian}
                  role="button"
                  isSelected={selectedMeridian === meridian}
                  onClick={this.handleMeridianClick(meridian)}
                >
                  {meridian}
                </S.TimeValue>
              ))}
            </S.MeridianContainer>
          </div>
          <S.ButtonContainer>
            <Button
              variant={BUTTON_VARIANTS.ROUNDED}
              outlined={true}
              onClick={onCancelClick}
            >
              CANCEL
            </Button>
            <Button
              variant={BUTTON_VARIANTS.ROUNDED}
              onClick={this.handleApplyClick}
            >
              APPLY
            </Button>
          </S.ButtonContainer>
        </div>
      </S.StyledPanel>
    );
  }
}

export default enhanceWithClickOutside(TimePickerPanel);
