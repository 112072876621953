import { combineReducers } from "redux";

import attachments from "./attachments";
import app from "./app";
import channel from "./channel";
import channels from "./channels";
import pages from "./pages";
import xhqUser from "./xhqUser";
import notifications from "./notifications";
import thread from "./thread";
import threads from "./threads";
import xhqUsers from "./xhqUsers";
import comment from "./comment";
import announcements from "./announcements";
import audiences from "./audiences";
import subscriptions from "./subscriptions";

export default combineReducers({
  attachments,
  app,
  channel,
  channels,
  pages,
  xhqUser,
  notifications,
  thread,
  threads,
  xhqUsers,
  comment,
  announcements,
  audiences,
  subscriptions
});
