import {
  POST_CHANNEL,
  SET_DEFAULT_STATE_POST_CHANNEL
} from "../../constants/actions/channels";

const SUCCESS = `${POST_CHANNEL}_SUCCESS`;
const LOADING = `${POST_CHANNEL}_LOADING`;
const ERROR = `${POST_CHANNEL}_ERROR`;

type ActionTypes =
  | IActionType<typeof SUCCESS, IChannel>
  | IActionType<typeof LOADING, boolean>
  | IActionType<typeof ERROR, string>;

const initialState: IAsyncEntityState<TChannel> = {
  data: null,
  isFetching: false,
  error: ""
};

const createdChannel = (
  state: IAsyncEntityState<TChannel> = initialState,
  action: ActionTypes
): IAsyncEntityState<TChannel> => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        data: {
          ...(action.payload as IChannel)
        },
        isFetching: false,
        error: ""
      };
    case LOADING:
      return {
        ...state,
        isFetching: action.payload as boolean,
        error: ""
      };
    case ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload as string
      };
    case SET_DEFAULT_STATE_POST_CHANNEL:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: ""
      };
    default:
      return state;
  }
};

export default createdChannel;
