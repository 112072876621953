import { upperFirst } from "lodash";

import { HIDE_COMMUNITY_INBOX } from "./togglableFeatures";
import * as Icons from "./icons";
import { FILTERS } from ".";

const filters: ICommunityFilter[] = [
  {
    name: upperFirst(FILTERS.INBOX),
    route: FILTERS.INBOX,
    icon: Icons.INBOX,
    selected: true,
    hidden: HIDE_COMMUNITY_INBOX
  },
  {
    name: upperFirst(FILTERS.IMPORTANT),
    route: FILTERS.IMPORTANT,
    icon: Icons.IMPORTANT
  },
  {
    name: upperFirst(FILTERS.FAVOURITES),
    route: FILTERS.FAVOURITES,
    icon: Icons.FAVOURITES
  }
];

export default filters;
