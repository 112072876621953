import * as React from "react";

import { StyledSpinner } from "./SpinnerStyles";

interface IProps {
  className?: string;
}

const LoadingSpinner = ({ className }: IProps) => (
  <div className={className}>
    <StyledSpinner viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
      />
    </StyledSpinner>
  </div>
);

export default LoadingSpinner;
