import * as React from "react";
import styled, { withTheme } from "styled-components";
import { Feature } from "@paralleldrive/react-feature-toggles";

import media from "../../lib/media";
import { FILTERS } from "../../constants";
import filters from "../../constants/filters";
import announcementFilters from "../../constants/announcementFilters";
import * as Icons from "../../constants/icons";
import NavbarList from "../../ui/NavbarList";
import Icon from "../../ui/Icon";
import ScrollableColumn from "../../ui/ScrollableColumn";
import { ComponentNoop, containsXhqCapability } from "../../utils/helpers";
import {
  ANNOUNCEMENTS_TOGGLE,
  SENT_ANNOUNCEMENTS_TOGGLE,
  SCHEDULED_ANNOUNCEMENTS_TOGGLE
} from "../../constants/featureToggles";
import { XHQ_ROLE_CAPABILITIES } from "../../constants/community";
import { isActiveFeatureName } from "@paralleldrive/feature-toggles";

const Wrapper = styled.div`
  padding: 0;
  margin: 1rem 0;
`;

const StyledScrollableColumn = styled(ScrollableColumn)`
  width: 17.5rem;
`;

const FilterHeader = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.secondary.main};
  text-align: center;
  padding: 1rem;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;

  ${media.greaterThan("medium")`
    display: none;
  `};
`;

const CloseFiltersButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  right: 1.5rem;
  top: 0.875rem;
  bottom: 0;
  opacity: 0.7;

  ${media.greaterThan("medium")`
    display: none;
  `};
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.325rem;
  margin: 1rem 1rem 1.5625rem;
`;

interface IProps {
  className?: string;
  boldFilters?: string[];
  channels: IChannel[];
  isHeaderDisabled: boolean;
  isLoadingChannels?: boolean | null;
  errorLoadingChannels?: any;
  theme: any;
  title?: string;
  filter?: string;
  selectable: boolean;
  onCloseFilters?: (event: React.MouseEvent<HTMLElement>) => void;
  onItemClick?: (navbarItem: any) => void;
  onFilterItemClick?: (filterItem: any) => void;
  scrollHeight?: string;
  wrapperHeight?: string;
  actionButton?: React.ReactNode;
  xhqUser: IXHQMember;
}

class Filters extends React.PureComponent<IProps> {
  formatFilters = (originalFilters: ICommunityFilter[]) => {
    const { filter = FILTERS.INBOX, selectable, boldFilters = [] } = this.props;

    const formattedFilters = originalFilters.map(filterItem => {
      return {
        ...filterItem,
        selected: selectable && filter === filterItem.route,
        path: `/community/${filterItem.route}`,
        bold: boldFilters.includes(filterItem.name.toLowerCase())
      };
    });

    return formattedFilters;
  };

  renderAnnouncementNavigationItem = (features: ReadonlyArray<string>) => {
    const { theme, onFilterItemClick, xhqUser } = this.props;
    const announcementFiltersItems = this.formatFilters(
      announcementFilters
    ).filter(announcementFilter => {
      if (announcementFilter.name.toLowerCase() === FILTERS.SENT) {
        return (
          isActiveFeatureName(SENT_ANNOUNCEMENTS_TOGGLE, features) &&
          containsXhqCapability(
            xhqUser,
            XHQ_ROLE_CAPABILITIES.CREATE_ANNOUNCEMENT
          )
        );
      }

      if (announcementFilter.name.toLowerCase() === FILTERS.SCHEDULED) {
        return (
          isActiveFeatureName(SCHEDULED_ANNOUNCEMENTS_TOGGLE, features) &&
          containsXhqCapability(
            xhqUser,
            XHQ_ROLE_CAPABILITIES.CREATE_ANNOUNCEMENT
          )
        );
      }
      return true;
    });

    return (
      <NavbarList
        title="Announcements"
        navbarItems={announcementFiltersItems}
        itemColor={theme.colors.primary.dark}
        hasItemOpacity={true}
        onItemClick={onFilterItemClick}
      />
    );
  };

  render() {
    const {
      className,
      isLoadingChannels,
      theme,
      channels,
      onCloseFilters,
      isHeaderDisabled,
      title,
      onItemClick,
      actionButton,
      onFilterItemClick,
      wrapperHeight,
      scrollHeight
    } = this.props;
    const formattedFilters = this.formatFilters(filters);

    return (
      <StyledScrollableColumn
        className={className}
        scrollHeight={scrollHeight}
        wrapperHeight={wrapperHeight}
      >
        <Wrapper>
          {title && <Title>Community</Title>}
          {!isHeaderDisabled ? (
            <FilterHeader>
              Community
              <CloseFiltersButton role="button" onClick={onCloseFilters}>
                <Icon name={Icons.CLOSE_SMALL} />
              </CloseFiltersButton>
            </FilterHeader>
          ) : null}
          <Feature>
            {({ features }) =>
              isActiveFeatureName(ANNOUNCEMENTS_TOGGLE, features) ? (
                this.renderAnnouncementNavigationItem(features)
              ) : (
                <ComponentNoop />
              )
            }
          </Feature>
          <NavbarList
            title="Channels"
            navbarItems={formattedFilters}
            itemColor={theme.colors.primary.dark}
            hasItemOpacity={true}
            onItemClick={onFilterItemClick}
          />
          <NavbarList
            navbarItems={channels}
            loading={isLoadingChannels}
            onItemClick={onItemClick}
          />
          {actionButton && <div>{actionButton}</div>}
        </Wrapper>
      </StyledScrollableColumn>
    );
  }
}

export default withTheme(Filters);
