import * as React from "react";
import styled from "styled-components";

import Comment from "../Comment/Comment";
import Badge from "../Badge";
import Icon from "../Icon";
import * as Icons from "../../constants/icons";

interface IProps {
  comments: IComment[];
  totalCommentsCount: number;
  favoritesCount?: number;
}

const Wrapper = styled.div`
  font-family: ${props => props.theme.fonts.main};
`;

const CountersWrapper = styled.div`
  display: flex;
  margin-bottom: 1.8rem;
`;

const CommentsCounter = styled.div`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 1.5rem;
  flex: 1;
  align-items: center;
  display: flex;
`;

const CommentsCount = styled(Badge)`
  background: ${props => props.theme.colors.info.light};
  color: ${props => props.theme.colors.info.main};
  margin-left: 0.5rem;
`;

const CommentContainer = styled.div`
  margin-bottom: 1.2rem;
`;

const FavoritesCounter = styled.div`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.secondary.semilight};
  font-weight: bold;
  font-size: 0.62rem;
  align-items: center;
  display: flex;
`;

const FavoritesIcon = styled(Icon)`
  margin-right: 0.4rem;
  vertical-align: middle;
`;

const FavoritesCoun = styled.span`
  vertical-align: middle;
`;

const CommentList = (props: IProps) => {
  const { comments, totalCommentsCount, favoritesCount } = props;
  return (
    <Wrapper>
      <CountersWrapper>
        <CommentsCounter>
          Replies <CommentsCount text={totalCommentsCount} />
        </CommentsCounter>
        {Boolean(favoritesCount) && (
          <FavoritesCounter>
            <FavoritesIcon name={Icons.HEART} opacity={0.3} />
            <FavoritesCoun>{favoritesCount}</FavoritesCoun>
          </FavoritesCounter>
        )}
      </CountersWrapper>
      <div>
        {comments.map(comment => (
          <CommentContainer key={comment.id}>
            <Comment {...comment} />
          </CommentContainer>
        ))}
      </div>
    </Wrapper>
  );
};

export default CommentList;
