import styled from "styled-components";

import media from "../../lib/media";
import HorizontalRule from "../HorizontalRule";

export const ContentWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  width: 100%;
  min-width: 40rem;
  color: ${({ theme }) => theme.colors.secondary.main};

  ${media.lessThan("medium")`
    min-width: 100%;
  `};
`;

export const StyledHR = styled(HorizontalRule)`
  margin-top: 2rem;
`;

export const Label = styled.div`
  font-size: 0.75rem;
  margin: 0 0 1.75rem;
  padding: 0.5rem 0 0;
`;

export const Title = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin: 0 0 2rem;
  line-height: 1.5rem;
`;

export const Content = styled.div`
  line-height: 1.6rem;

  a {
    color: ${({ theme }) => theme.colors.info.main};
  }
`;
