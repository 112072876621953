import moment from "moment";

import { isRequired } from "./validators";
import { DataProxy } from "apollo-cache";
import {
  get,
  isEmpty,
  toLower,
  upperFirst,
  pickBy,
  identity,
  uniq
} from "lodash";
import ApolloClient, { ApolloQueryResult } from "apollo-client";
import { truncate } from "lodash/fp";

import {
  MEMBER_ROLES,
  MESSAGES_EXTRA_AUDIENCES,
  EMAIL_REGEX,
  URL_REGEX,
  FILTERS,
  WEEKLY_PLANNER_EVENT_TRUNCATE_SIZE
} from "../constants";

import { InMemoryCache } from "apollo-cache-inmemory";
import * as Icons from "../constants/icons";

export const addLeadingZero = (value: number | string): string => {
  value = value.toString();

  while (value.length < 2) {
    value = "0" + value;
  }

  return value;
};

export const getWeekdayAbbreviation = (date: string | moment.Moment) => {
  if (typeof date === "string") {
    date = moment(date);
  }

  return moment.weekdaysShort(date.weekday());
};

export const getMedicalToggleFormValidators = (
  data: any,
  keysToIgnore?: string[]
) => {
  const ignore = keysToIgnore || [];
  let validators = {};

  Object.keys(data).forEach(key => {
    if (data[key] && !ignore.includes(key)) {
      validators = { ...validators, [key]: [{ rule: isRequired }] };
    }
  });

  return validators;
};

export const getMedicalToggleFormDefaultData = (data: any, fields: any) => {
  let checks = {};
  let details = {};
  let files = {};

  if (!isEmpty(data)) {
    data.forEach((value: any) => {
      checks = {
        ...checks,
        [value.type]: Boolean(value.enabled)
      };

      details = {
        ...details,
        [value.type]: value.description
      };

      files = {
        ...files,
        [value.type]: value.attachedFile
      };
    });
  } else {
    Object.keys(fields).forEach((key: string) => {
      checks = {
        ...checks,
        [key]: false
      };

      details = {
        ...details,
        [key]: ""
      };
    });
  }

  return {
    checks,
    details,
    files
  };
};

export const getMedicalToggleFormData = (
  checks: { [key: string]: boolean },
  details: { [key: string]: string },
  localFiles?: IAttachment[],
  remoteFiles?: { [key: string]: string }
) => {
  let keyValueLocalFiles;
  if (localFiles) {
    keyValueLocalFiles = localFiles.reduce(
      (acc, value) => ({
        ...acc,
        ...(value.key && { [value.key]: value.href })
      }),
      {}
    );
  }
  const files = {
    ...pickBy(remoteFiles, identity), // removes falsey values
    ...keyValueLocalFiles
  };
  return Object.keys(checks).map(key => ({
    type: key,
    enabled: checks[key],
    description: checks[key] ? details[key] : "",
    ...(!isEmpty(files) && {
      attachedFile: checks[key] ? files[key] || "" : ""
    })
  }));
};

export const getRoleLabel = (
  role?: string,
  defaultLabel?: string,
  pluralize?: boolean
) => {
  if (!role) {
    return defaultLabel || "";
  }
  const roleLabel = upperFirst(toLower(role));
  if (!pluralize) {
    return roleLabel;
  }
  return role === MEMBER_ROLES.STAFF ||
    role === MESSAGES_EXTRA_AUDIENCES.EVERYONE
    ? roleLabel
    : `${roleLabel}s`;
};

export const containsCapability = (user: IXHQMember, capability: string) => {
  const aggregateCapabilities = get(user, "aggregateCapabilities", []) || [];

  return aggregateCapabilities.includes(capability);
};

export const containsXhqCapability = (user: IXHQMember, capability: string) => {
  const capabilities: IXHQRoleCapability[] =
    get(user, "capabilities.items", []) || [];
  return capabilities.some(({ name }) => name === capability);
};

export const containsOneOfXhqCapabilities = (
  user: IXHQMember,
  capabilities: string[]
) => {
  const userCapabilities: IXHQRoleCapability[] =
    get(user, "capabilities.items", []) || [];
  return userCapabilities.some(({ name }) =>
    capabilities.some(cabability => name === cabability)
  );
};

export const getMedicalSubmitButtonLabel = (
  isProcessing?: boolean,
  hasChanges?: boolean
) => {
  if (isProcessing) {
    return "SAVING";
  }
  if (hasChanges) {
    return "SAVE & CONTINUE";
  }
  return "NEXT STEP";
};

export const containsXhqRole = (roles: IXhqRole[], role: string) => {
  return roles.some((xhqRole: IXhqRole) => xhqRole.id === role);
};

export const isWeekend = (date: moment.Moment) => {
  return date.weekday() === 0 || date.weekday() === 6;
};

export const getLabelByKey = (labelKey: string, labels?: IMessageLabel[]) => {
  return labels && labels.find(({ key }: IMessageLabel) => key === labelKey);
};

export const getCachedQueryData = (
  cache: InMemoryCache | ApolloClient<object> | DataProxy,
  options: any
) => {
  try {
    return cache.readQuery(options);
  } catch (e) {
    return {};
  }
};

export const checkChannelRoleForXhqUser = (
  channel: IChannel,
  xhqUser: IXHQMember,
  role: string
) => {
  switch (role) {
    case "OWN":
      return isChannelOwner(channel, xhqUser);
    case "MOD":
      return isChannelModerator(channel, xhqUser);
    default:
      return null;
  }
};

export const userContainsChannelCapability = (
  channel: IChannel,
  xhqUser: IXHQMember,
  capability: string
) => {
  const allChannelCapabilities = channel.capabilities || { GEN: [] };

  const derivedChannelCapabilities = uniq(
    ["OWN", "MOD"].reduce(
      (acc: string[], role: string) => {
        const hasRole = checkChannelRoleForXhqUser(channel, xhqUser, role);

        if (!hasRole) {
          return acc;
        }

        const specialCapabilities = allChannelCapabilities![role] || [];
        return [...acc, ...specialCapabilities];
      },
      [...allChannelCapabilities!.GEN]
    )
  );

  return derivedChannelCapabilities.some(
    derivedChannelCapability => derivedChannelCapability === capability
  );
};

export const isChannelOwner = (
  selectedChannel?: IChannel,
  user?: IXHQMember
) => {
  if (!selectedChannel || !user) {
    return false;
  }
  return get(selectedChannel, "owner.id") === user.id;
};

export const isThreadCreator = (thread: IThread, user?: IXHQMember) => {
  if (!user) {
    return;
  }

  return (
    get(thread, "createdBy.id") === user.id ||
    get(thread, "aliasCreatedBy.id") === user.id
  );
};

export const isChannelModerator = (
  selectedChannel?: IChannel,
  user?: IXHQMember
) => {
  if (!selectedChannel || !user) {
    return false;
  }
  const moderators = get(selectedChannel, "moderators.items", []);
  return (
    moderators.filter((moderator: IXHQMember) => moderator.id === user.id)
      .length > 0
  );
};

export const mergeChannels = (
  prevChannels: Array<{ id: string; bold: boolean }>,
  channels: IChannel[]
): IChannelWithBold[] => {
  return channels.map((channel: IChannel) => {
    const prevChannel = prevChannels.find(
      (prevChannelItem: { id: string; bold: boolean } | IChannelWithBold) =>
        prevChannelItem.id === channel.id
    );

    if (!prevChannel) {
      return { ...channel, bold: true };
    }

    return { ...channel, bold: prevChannel.bold };
  });
};

export const setItemToLocalStorage = (key: string, value: string) => {
  if (window.localStorage) {
    window.localStorage.setItem(key, value);
  }
};

export const getItemFromLocalStorage = (key: string) => {
  if (window.localStorage) {
    return window.localStorage.getItem(key);
  }
  return null;
};

const normalizeText = (text: string) =>
  truncate({ length: 30, separator: " " }, text).replace(/[�]{1,}/g, " ");

export const formatChannels = (channels: IChannel[], channelName?: string) => {
  const formattedChannels = channels
    .filter(channel => !isEmpty(channel))
    .map(channel => {
      return {
        ...channel,
        icon: Icons.HASHTAG,
        name: normalizeText(channel.name),
        title: channel.title ? normalizeText(channel.title) : undefined,
        sticky: channel.name === "Mind for life",
        selected: channel.id === `Channel-${channelName}`,
        path: `/community/channel/${getChannelNameFromId(channel.id)}`,
        route: "channel"
      };
    });

  return formattedChannels;
};

export const getChannelNameFromId = (channelId: string) => {
  const [, ...channelArrayName] = channelId.split("-");
  return channelArrayName.join("-");
};

export const getThreadChannel = (
  channelsMap: { [key: string]: IChannel },
  thread?: IThread,
  selectedChannel?: IChannel
) => {
  if (selectedChannel) {
    return selectedChannel;
  }

  if (!thread) {
    return;
  }

  return channelsMap[thread.parentId];
};

export const isGoNativeApp = navigator.userAgent.includes("gonative");

export const sortChannelsByDisplayName = (channels: IChannel[]) => {
  return channels.sort((a, b) =>
    (a.title || a.name).localeCompare(b.title || b.name)
  );
};

export const ComponentNoop = () => null;

export const readUploadedFileAsText = (inputFile: File) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new Error("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsText(inputFile);
  });
};

export const nameAndValueToArrays = (
  objects: { name: string; value: string; type: string }[]
) => {
  return objects.reduce(
    (acc: string[][], pair: { name: string; value: string; type: string }) => {
      const pairType =
        pair.type.trim().toLowerCase() === "string"
          ? ""
          : `:${pair.type.trim()}`;
      return [
        [...acc[0], `${pair.name.trim()}${pairType}`],
        [...acc[1], pair.value.trim()]
      ];
    },
    [[], []]
  );
};

export const isEmail = (text: string) => EMAIL_REGEX.test(text);
export const isURL = (text: string) => URL_REGEX.test(text);

export const replaceTagsWithCSVValues = (
  body: string,
  tags: string[],
  values: string[]
): string => {
  const getLink = (url: string, text: string) => `<a href="${url}">${text}</a>`;
  const getTagValueByTagName = (tag: string, tagValue: string) => {
    const [tagText, tagType] = tag.split(":");

    if (!tagType || !tagText) {
      return tagValue;
    }

    const tagTypeLowerCased = tagType.toLowerCase();

    if (tagTypeLowerCased === "email") {
      const isValidEmail = isEmail(tagValue);

      return isValidEmail ? getLink(`mailto:${tagValue}`, tagText) : tagValue;
    }

    if (tagTypeLowerCased === "url") {
      const isValidUrl = isURL(tagValue);

      return isValidUrl ? getLink(tagValue, tagText) : tagValue;
    }

    return tagValue;
  };

  return values.reduce((acc: string, tag: string, index: number) => {
    if (!tag) {
      return acc;
    }

    const regexp = new RegExp(`\\[${tags[index]}\\]`, "g");

    return acc.replace(regexp, getTagValueByTagName(tags[index], tag));
  }, body);
};

export const removeHTMLTags = (text: string): string => {
  const HTMLTagsRegex = /(<([^>]+)>)/gi;
  const whitespaceRegex = /\s\s+/g;

  return text.replace(HTMLTagsRegex, " ").replace(whitespaceRegex, " ");
};

export const getSelectedAudienceFromThread = (thread: IThread) => {
  if (!thread.targets) {
    return [];
  }

  return thread.targets
    .filter(
      target =>
        !target.fromCSV && !isEmpty(target.audience) && !isEmpty(target.role)
    )
    .map(target => {
      const role = target.role.toUpperCase();
      return {
        audienceId: target.audience.id,
        audienceName: target.audience.name,
        audienceRolePair: `${target.audience.id}-${role}`,
        role
      };
    });
};

export const filterNotificationsByObject = (notifications: INotification[]) =>
  notifications.filter((item: INotification) => item.object);

export const notificationsGetter = (
  entityName: string,
  withFreshList: boolean = false
) => (response: ApolloQueryResult<{ [key: string]: INotifiableObject }>) => {
  const notifications = get(response, `data.${entityName}`, {});
  const { items = [] } = notifications;

  return {
    ...{ ...notifications, items: filterNotificationsByObject(items) },
    withFreshList
  };
};

export const getCSVRow = (content: string, index: number): string[] => {
  const row = content.split(/\n/)[index - 1];

  return row.split(",").map((tag: string) => tag.trim());
};

export const formatCSVHeader = (header: string[]) => {
  return header.map((header: string) => `[${header}]`).join(" ");
};

export const isAnnouncementFilter = (filter: string) => {
  return (
    filter === FILTERS.INBOX ||
    filter === FILTERS.SENT ||
    filter === FILTERS.SCHEDULED
  );
};

export const getMessagesDataByChannel = (
  messages: IThreadsState,
  channel: string
): IAsyncEntityState<IThreadsData> => {
  const initialChannelData = {
    items: [],
    nextPage: null,
    hasBeenFetched: false,
    hasBeenSubscribed: false
  };

  const initialChannel = {
    data: initialChannelData,
    isFetching: false,
    error: null
  };

  return get(messages, `["${channel}"]`, initialChannel);
};

export const truncateEventDescription = (description?: string) =>
  truncate(
    {
      length: WEEKLY_PLANNER_EVENT_TRUNCATE_SIZE,
      separator: " "
    },
    description || ""
  );
