import { createSelector } from "reselect";

const getPages = (state: IAppState) => state.pages;

const getCommunity = createSelector(
  getPages,
  (pages: IStatePages) => pages.community
);

export const getFocusedColumnView = createSelector(
  getCommunity,
  (community: ICommunity): TFocusColumnView => community.focusedColumnView
);

export const getEditorViewMode = createSelector(
  getCommunity,
  (community: ICommunity): TEditorViewMode => community.editorViewMode
);
