import * as React from "react";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 1.5rem;
`;

const animation = keyframes`
  0% { background-position: 100% 0% }
  100% { background-position: 0% 100% }
`;

const Base = styled.div`
  animation: ${animation} 1.8s linear infinite;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Icon = styled(Base)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.tertiary.main};
  margin-right: 1rem;
`;

const ShortLine = styled(Base)`
  background-color: ${({ theme }) => theme.colors.tertiary.main};
  height: 0.75rem;
  width: 6rem;
`;

const NavbarListLoading = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Icon />
        <ShortLine />
      </ContentWrapper>

      <ContentWrapper>
        <Icon />
        <ShortLine />
      </ContentWrapper>

      <ContentWrapper>
        <Icon />
        <ShortLine />
      </ContentWrapper>
    </Wrapper>
  );
};

export default NavbarListLoading;
