import * as React from "react";
import { Link } from "react-router-dom";

interface IProps {
  className?: string;
  isExternal?: boolean;
  children: any;
  route: string;
  isDisabled?: boolean;
  sameTab?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLElement>,
    isExternal?: boolean
  ) => void;
}

class CustomLink extends React.PureComponent<IProps> {
  onLinkClick = (e: React.MouseEvent<HTMLElement>) => {
    const { onClick, isDisabled, isExternal } = this.props;
    if (isDisabled === true) {
      e.preventDefault();
    }
    if (onClick) {
      onClick(e, isExternal);
    }
  };

  render() {
    const { className, isExternal, children, route, sameTab } = this.props;

    if (isExternal) {
      return (
        <a
          href={route}
          target={sameTab ? "_self" : "_blank"}
          rel="noopener"
          className={className}
          onClick={this.onLinkClick}
        >
          {children}
        </a>
      );
    }
    return (
      <Link
        className={className}
        to={{ pathname: route }}
        onClick={this.onLinkClick}
      >
        {children}
      </Link>
    );
  }
}

export default CustomLink;
