import { combineReducers } from "redux";

import {
  SET_EDITOR_VIEW_MODE,
  SET_FOCUSED_COLUMN_VIEW
} from "../../constants/actions/community";
import {
  EDITOR_VIEW_MODE,
  MOBILE_COMMUNITY_SCREENS
} from "../../constants/community";

type ActionType =
  | IActionType<typeof SET_FOCUSED_COLUMN_VIEW, TFocusColumnView>
  | IActionType<typeof SET_EDITOR_VIEW_MODE, TEditorViewMode>;

const initialState: ICommunity = {
  focusedColumnView: MOBILE_COMMUNITY_SCREENS.FILTERS,
  editorViewMode: EDITOR_VIEW_MODE.READ_THREAD
};

const community = (state: ICommunity = initialState, action: ActionType) => {
  switch (action.type) {
    case SET_FOCUSED_COLUMN_VIEW:
      return {
        ...state,
        focusedColumnView: action.payload
      };
    case SET_EDITOR_VIEW_MODE:
      return {
        ...state,
        editorViewMode: action.payload
      };
    default:
      return state;
  }
};

export default combineReducers({
  community
});
