import {
  FETCH_AUDIENCES,
  REMOVE_AUDIENCES_DATA
} from "../../constants/actions/audiences";

const SUCCESS = `${FETCH_AUDIENCES}_SUCCESS`;
const LOADING = `${FETCH_AUDIENCES}_LOADING`;
const ERROR = `${FETCH_AUDIENCES}_ERROR`;

type ActionTypes =
  | IActionType<typeof SUCCESS, IAudiencesData>
  | IActionType<typeof LOADING, boolean>
  | IActionType<typeof ERROR, string | null>
  | IActionType<typeof REMOVE_AUDIENCES_DATA, null>;

const initialState: IAsyncEntityState<IAudiencesData> = {
  data: {
    items: []
  },
  isFetching: false,
  error: null
};

const searchAudiences = (
  state: IAsyncEntityState<IAudiencesData> = initialState,
  action: ActionTypes
): IAsyncEntityState<IAudiencesData> => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        data: action.payload as IAudiencesData,
        isFetching: false,
        error: null
      };
    case LOADING:
      return {
        ...state,
        isFetching: action.payload as boolean,
        error: null
      };
    case ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload as string | null
      };
    case REMOVE_AUDIENCES_DATA:
      return {
        ...state,
        data: {
          items: []
        },
        isFetching: false,
        error: null
      };
    default:
      return state;
  }
};

export default searchAudiences;
