import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";

import ThreadView from "../ThreadView";
import ChannelEditor from "../ChannelEditor";
import ThreadEditor from "../ThreadEditor";
import { EDITOR_VIEW_MODE } from "../../constants/community";

interface IProps extends RouteComponentProps {
  editorViewMode?: TEditorViewMode;
  editingThread?: IThread;
  selectedChannel?: IChannel;
  onThreadUpdateClick: (thread: IThread) => void;
  onThreadForwardClick: (thread: IThread) => void;
}

const EditorView = (props: IProps) => {
  const {
    editorViewMode,
    match,
    editingThread,
    history,
    onThreadUpdateClick,
    onThreadForwardClick,
    selectedChannel
  } = props;

  switch (editorViewMode) {
    case EDITOR_VIEW_MODE.READ_THREAD:
      return (
        <ThreadView
          onThreadUpdateClick={onThreadUpdateClick}
          onThreadForwardClick={onThreadForwardClick}
        />
      );
    case EDITOR_VIEW_MODE.CREATE_THREAD:
      return <ThreadEditor history={history} match={match} />;
    case EDITOR_VIEW_MODE.UPDATE_THREAD:
      return (
        <ThreadEditor
          match={match}
          history={history}
          editingMode={true}
          editedThread={editingThread}
        />
      );
    case EDITOR_VIEW_MODE.FORWARD_THREAD:
      return (
        <ThreadEditor
          match={match}
          history={history}
          editedThread={editingThread}
        />
      );
    case EDITOR_VIEW_MODE.CREATE_CHANNEL:
    case EDITOR_VIEW_MODE.UPDATE_CHANNEL:
      return <ChannelEditor selectedChannel={selectedChannel} />;
    default:
      return null;
  }
};

export default withRouter(EditorView);
