import * as React from "react";
import styled from "styled-components";

import Icon from "../Icon";
import * as Icons from "../../constants/icons";

interface IProps {
  text: string;
  onActionClick?: () => void | undefined;
}

const Wrapper = styled.div`
  display: flex
  max-width: 20rem;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.span`
  font-family: ${(props: any) => props.theme.fonts.main};
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(props: any) => props.theme.colors.primary.dark};
`;

const NavbarAction = ({ text, onActionClick }: IProps) => (
  <Wrapper role="button" onClick={onActionClick}>
    <Icon name={Icons.CIRCLE} />
    <Text>{text}</Text>
  </Wrapper>
);

export default NavbarAction;
