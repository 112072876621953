import * as React from "react";
import styled, { StyledComponentClass } from "styled-components";
import { uniq, isEmpty } from "lodash";
import ReactTooltip from "react-tooltip";

import media from "../../lib/media";
import {
  ComponentNoop,
  containsXhqCapability,
  userContainsChannelCapability
} from "../../utils/helpers";
import { Menu, MenuItem } from "../Menu";
import Icon from "../Icon";
import * as Icons from "../../constants/icons";
import {
  XHQ_CHANNEL_CAPABILITIES,
  XHQ_ROLE_CAPABILITIES
} from "../../constants/community";
import { THREADS_MENU_ITEMS } from "../../constants/threads";
import { FORWARD_ANNOUNCEMENTS_TOGGLE } from "../../constants/featureToggles";
import { Feature } from "@paralleldrive/react-feature-toggles";

interface IProps extends IThreadActions {
  onMarkThreadReadClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFavouritesClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isChannelOwner?: boolean;
  isChannelModerator?: boolean;
  isThreadCreator?: boolean;
  isAnnouncement: boolean;
  xhqUser: IXHQMember;
  selectedChannel?: IChannel;
}

interface IState {
  showSettingsMenu: boolean;
}

interface IStyledComponentClass extends StyledComponentClass<any, any, any> {
  suppressClassNameWarning?: boolean;
}

interface IProps extends IThreadActions {
  onDeleteThread?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Wrapper = styled.div`
  display: inline-block;
  line-height: 0;
`;

const MenuWrapper = styled.div`
  position: relative;
`;

const StyledMenu = styled(Menu)`
  left: -50%;
  min-width: 13rem;

  ${media.lessThan("medium")`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  `};
`;

const Action = styled.a`
  display: inline-block;
  cursor: pointer;

  ${media.between("medium", "large")`
    width: 2rem;
    height: 2rem;
  `};
`;

const ToolTip: IStyledComponentClass = styled(ReactTooltip)`
  padding: 1rem 0.7rem;
  margin-top: -1.5rem !important;
  transition: opacity 0.3s;
  visibility: visible;
`;

const StyledIcon = styled(Icon)`
  ${media.between("medium", "large")`
    transform: scale(0.8);
  `};
`;

class ThreadActions extends React.PureComponent<IProps, IState> {
  state = {
    showSettingsMenu: false
  };

  getPermissionByType = (capability: string) => {
    const {
      isAnnouncement,
      selectedChannel,
      xhqUser,
      isThreadCreator
    } = this.props;

    return isAnnouncement
      ? isThreadCreator
      : selectedChannel &&
          userContainsChannelCapability(selectedChannel, xhqUser, capability);
  };

  get shouldShowForwardAction() {
    const { isAnnouncement, isThreadCreator } = this.props;
    return isAnnouncement && isThreadCreator;
  }

  get getAllowedMenuItemsForMember() {
    const { isAnnouncement, xhqUser } = this.props;
    const threadItems: string[] = [];

    if (this.getPermissionByType(XHQ_CHANNEL_CAPABILITIES.UPDATE_MESSAGE)) {
      threadItems.push(THREADS_MENU_ITEMS.EDIT);
    }
    if (this.getPermissionByType(XHQ_CHANNEL_CAPABILITIES.DELETE_MESSAGE)) {
      threadItems.push(THREADS_MENU_ITEMS.DELETE);
    }
    if (
      isAnnouncement &&
      containsXhqCapability(
        xhqUser,
        XHQ_ROLE_CAPABILITIES.DELETE_ANY_ANNOUNCEMENT
      )
    ) {
      threadItems.push(THREADS_MENU_ITEMS.DELETE);
    }
    if (this.shouldShowForwardAction) {
      threadItems.push(THREADS_MENU_ITEMS.FORWARD);
    }

    return uniq(threadItems);
  }

  renderForwardMenuItem = () => {
    const { onForwardClick } = this.props;
    return (
      <MenuItem key={THREADS_MENU_ITEMS.FORWARD} onClick={onForwardClick}>
        <Icon name={Icons.RIGHT_ANGLE} opacity={0.5} />
        Forward
      </MenuItem>
    );
  };

  renderMenuItems = (memberAllowedItems: string[]) => {
    const { onUpdateClick } = this.props;

    const menuItemsMapper = {
      [THREADS_MENU_ITEMS.FORWARD]: (
        <Feature
          key={THREADS_MENU_ITEMS.FORWARD}
          name={FORWARD_ANNOUNCEMENTS_TOGGLE}
          inactiveComponent={ComponentNoop}
          activeComponent={this.renderForwardMenuItem}
        />
      ),
      [THREADS_MENU_ITEMS.EDIT]: (
        <MenuItem key={THREADS_MENU_ITEMS.EDIT} onClick={onUpdateClick}>
          <Icon name={Icons.PENCIL} opacity={0.5} />
          Edit Thread
        </MenuItem>
      ),
      [THREADS_MENU_ITEMS.GET_EMAIL]: (
        <MenuItem key={THREADS_MENU_ITEMS.GET_EMAIL}>
          <Icon name={Icons.INBOX} opacity={0.5} />
          Get email to thread
        </MenuItem>
      ),
      [THREADS_MENU_ITEMS.MOVE]: (
        <MenuItem key={THREADS_MENU_ITEMS.MOVE}>
          <Icon name={Icons.MOVE} opacity={0.5} />
          Move thread
        </MenuItem>
      ),
      [THREADS_MENU_ITEMS.DELETE]: (
        <MenuItem
          key={THREADS_MENU_ITEMS.DELETE}
          onClick={this.props.onDeleteThread}
        >
          <Icon name={Icons.REMOVE} opacity={0.5} />
          Delete...
        </MenuItem>
      )
    };

    return Object.keys(menuItemsMapper)
      .filter(key => memberAllowedItems.includes(key))
      .map(key => menuItemsMapper[key]);
  };

  renderMenu = (memberAllowedItems: string[]) => {
    return (
      <MenuWrapper>
        <StyledMenu onClickOutside={this.handleClickOutside}>
          {this.renderMenuItems(memberAllowedItems)}
        </StyledMenu>
      </MenuWrapper>
    );
  };

  handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({ showSettingsMenu: true });
  };

  handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({ showSettingsMenu: false });
  };

  render() {
    const {
      isFavourite = false,
      isUnread = false,
      isImportant = false,
      onMarkThreadReadClick,
      onFavouritesClick,
      isAnnouncement
    } = this.props;
    const memberAllowedItems = this.getAllowedMenuItemsForMember;
    return (
      <Wrapper>
        <ToolTip
          id="read"
          place="top"
          effect="solid"
          delayShow={100}
          delayHide={100}
          suppressClassNameWarning={true}
        >
          Mark as {isUnread ? "read" : "unread"}
        </ToolTip>
        <Action data-tip={true} data-for="read" onClick={onMarkThreadReadClick}>
          <StyledIcon
            name={Icons.READ}
            inverted={isUnread}
            opacity={isUnread ? 1 : 0.5}
          />
        </Action>

        {!isAnnouncement && (
          <>
            <ToolTip
              id="favourites"
              place="top"
              effect="solid"
              suppressClassNameWarning={true}
            >
              Add to favourites
            </ToolTip>
            <Action
              data-tip={true}
              data-for="favourites"
              onClick={onFavouritesClick}
            >
              <StyledIcon
                name={Icons.FAVOURITES}
                inverted={isFavourite}
                opacity={isFavourite ? 1 : 0.5}
              />
            </Action>
          </>
        )}
        <ToolTip
          id="important"
          place="top"
          effect="solid"
          suppressClassNameWarning={true}
        >
          Important
        </ToolTip>
        <Action data-tip={true} data-for="important">
          <StyledIcon
            name={Icons.IMPORTANT}
            inverted={isImportant}
            opacity={isImportant ? 1 : 0.5}
          />
        </Action>
        {!isEmpty(memberAllowedItems) && (
          <>
            <Action role="button" onClick={this.handleSettingsClick}>
              <StyledIcon name={Icons.MORE} opacity={0.5} />
            </Action>
            {this.state.showSettingsMenu && this.renderMenu(memberAllowedItems)}
          </>
        )}
      </Wrapper>
    );
  }
}
// suppressClassNameWarning
export default ThreadActions;
