import { get } from "lodash";

import { FETCH_XHQ_USER } from "../../constants/actions/xhqUser";
import {
  POST_CHANNEL,
  UPDATE_CHANNEL,
  UPDATE_ARCHIVE_CHANNEL,
  UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE,
  POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE
} from "../../constants/actions/channels";

const SUCCESS = `${FETCH_XHQ_USER}_SUCCESS`;
const LOADING = `${FETCH_XHQ_USER}_LOADING`;
const ERROR = `${FETCH_XHQ_USER}_ERROR`;

const POST_CHANNEL_SUCCESS = `${POST_CHANNEL}_SUCCESS`;
const UPDATE_CHANNEL_SUCCESS = `${UPDATE_CHANNEL}_SUCCESS`;
const UPDATE_ARCHIVE_CHANNEL_SUCCESS = `${UPDATE_ARCHIVE_CHANNEL}_SUCCESS`;
const UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE_SUCCESS = `${UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_SUCCESS`;
const POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE_SUCCESS = `${POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE}_SUCCESS`;

type ActionTypes =
  | IActionType<typeof SUCCESS, IXHQMember>
  | IActionType<typeof POST_CHANNEL_SUCCESS, IChannel>
  | IActionType<typeof UPDATE_CHANNEL_SUCCESS, IChannel>
  | IActionType<typeof UPDATE_ARCHIVE_CHANNEL_SUCCESS, IChannel>
  | IActionType<typeof LOADING, boolean>
  | IActionType<typeof ERROR, string | null>;

const initialState: IAsyncEntityState<TXHQMember> = {
  data: null,
  isFetching: null,
  error: null
};

const xhqUser = (
  state: IAsyncEntityState<TXHQMember> = initialState,
  action: ActionTypes
): IAsyncEntityState<TXHQMember> => {
  switch (action.type) {
    case POST_CHANNEL_SUCCESS:
      return {
        ...state,
        data: {
          ...(state.data as IXHQMember),
          channels: {
            ...(state.data as IXHQMember).channels,
            items: [
              ...(state.data as IXHQMember).channels!.items,
              action.payload as IChannel
            ]
          }
        }
      };
    case UPDATE_CHANNEL_SUCCESS:
    case UPDATE_ARCHIVE_CHANNEL_SUCCESS:
      const payload = action.payload as IChannel;
      const channels = get(state, "data.channels.items", []).map(
        (channel: IChannel) => {
          if (payload && channel.id === payload.id) {
            return {
              ...channel,
              ...payload
            };
          }
          return channel;
        }
      );
      return {
        ...state,
        data: {
          ...(state.data as IXHQMember),
          channels: {
            ...(state.data as IXHQMember).channels,
            items: channels
          }
        }
      };
    case POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE_SUCCESS:
    case UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE_SUCCESS:
      const updatedChannelPayload = action.payload as TChannelWithMembersAndAudience;
      const stateChannels = get(state, "data.channels.items", []).map(
        (channel: IChannel) => {
          if (channel.id === updatedChannelPayload!.channelId) {
            return {
              ...channel,
              audiences: updatedChannelPayload!.audiences
            };
          }
          return channel;
        }
      );
      return {
        ...state,
        data: {
          ...(state.data as IXHQMember),
          channels: {
            ...(state.data as IXHQMember).channels,
            items: stateChannels
          }
        }
      };
    case SUCCESS:
      return {
        ...state,
        data: action.payload
          ? {
              ...(action.payload as IXHQMember)
            }
          : (action.payload as null),
        isFetching: false,
        error: null
      };
    case LOADING:
      return {
        ...state,
        isFetching: action.payload as boolean,
        error: null
      };
    case ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload as string | null
      };
    default:
      return state;
  }
};

export default xhqUser;
