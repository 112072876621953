export const MAX_TITLE_SIZE = 200;
export const MAX_BODY_SIZE = 5000;
export const THREADS_MENU_ITEMS = {
  EDIT: "EDIT",
  GET_EMAIL: "GET_EMAIL",
  MOVE: "MOVE",
  DELETE: "DELETE",
  FORWARD: "FORWARD"
};
export const CREATE_THREAD_TITLE_PLACEHOLDER = "Thread title";
export const CREATE_ANNOUNCEMENT_TITLE_PLACEHOLDER = "Start new announcement";
export const CREATE_THREAD_TOGGLE_SWITCH_LABEL = "Mark as important";
export const CREATE_THREAD_TOGGLE_SWITCH_DESC =
  "this action means all channel members will be notified";
export const CREATE_THREAD_POST_BUTTON = "POST THREAD";
export const CREATE_ANNOUNCEMENT_POST_BUTTON = "POST ANNOUNCEMENT";
export const UPDATE_THREAD_POST_BUTTON = "UPDATE THREAD";
export const UPDATE_ANNOUNCEMENT_POST_BUTTON = "UPDATE ANNOUNCEMENT";
export const CREATE_THREAD_SAVING_BUTTON = "SAVING...";
export const PREVIEW = "PREVIEW";
export const CANCEL = "Cancel";
export const POST = "Post";
export const IMPORTANT_MARK = "Important Mark";
export const CREATE_THREAD_AS = "Create thread as...";
export const CREATE_ANNOUNCEMENT_AS = "Create announcement as...";
export const THREAD_EDITOR_ERROR_MESSAGES = {
  LENGTH_TOO_SHORT: "The thread must be at least 2 characters long"
};
export const EMPTY_THREADS_LIST_MESSAGE = "There are no threads to display.";
export const EMPTY_INBOX_MESSAGE = "You're all caught up!";
export const EMPTY_INBOX_SUBMESSAGE =
  "You have 0 unread threads in your Inbox filter.";
export const EMPTY_IMPORTANT_SUBMESSAGE =
  "You have no threads marked as important.";
export const EMPTY_FAVOURITES_SUBMESSAGE =
  "You have no threads marked as favourite.";
export const UNABLE_VIEW_CHANNEL_MESSAGE =
  "Sorry but you aren’t able to view this channel!";
