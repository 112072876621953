import * as React from "react";
import { get, times, upperFirst, isEmpty } from "lodash";
import { isMobile } from "react-device-detect";
import PullToRefresh from "pulltorefreshjs";

import MessagesPlaceholderImage from "../../assets/messages_placeholder.svg";
import ThreadList from "../../ui/ThreadList";
import { ThreadListLoading } from "../../ui/Loading";
import {
  THREAD_LIST_LOADING_PLACEHOLDERS_AMOUNT,
  FILTERS
} from "../../constants";
import {
  userContainsChannelCapability,
  containsXhqCapability
} from "../../utils/helpers";
import {
  EMPTY_THREADS_LIST_MESSAGE,
  EMPTY_INBOX_MESSAGE,
  EMPTY_INBOX_SUBMESSAGE,
  EMPTY_FAVOURITES_SUBMESSAGE,
  EMPTY_IMPORTANT_SUBMESSAGE,
  UNABLE_VIEW_CHANNEL_MESSAGE
} from "../../constants/threads";
import {
  EDITOR_VIEW_MODE,
  MOBILE_COMMUNITY_SCREENS,
  XHQ_CHANNEL_CAPABILITIES,
  XHQ_ROLE_CAPABILITIES
} from "../../constants/community";
import * as S from "./styles";

interface IProps {
  className?: string;
  xhqUser: IXHQMember;
  selectedThreadId?: string;
  selectedChannel?: IChannel;
  threads: IThread[];
  isLoadingThreads: boolean;
  isLoadingMoreThreads?: boolean;
  hasDraft?: boolean;
  filter?: string;
  channelName?: string;
  viewAccessByChannel: IChannelViewAccess;
  archivedChannelId?: string;
  archivedChannelError: string;
  onNewThreadClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFilterMenuClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onThreadClick?: (
    event: React.MouseEvent<HTMLElement>,
    threadId: string
  ) => void;
  onLoadMoreThreads?: () => void;
  onHandleRefresh?: () => void;
  selectedChannelData?: IAsyncEntityState<TChannel>;
  actions?: {
    // This prop is meant to come from connect
    setDefaultArchivedChannelState: () => void;
    updateChannelWithCallback: (
      channel: IUpdateChannelProps,
      callback: TVoidFunc
    ) => void;
    setDefaultSelectedChannelState: () => void;
    archiveChannel: (channelId: string) => void;
    fetchAnnouncements: (
      xhqUser: IXHQMember,
      filter: "inbox",
      withFreshList: boolean
    ) => void;
    setAnnouncementSearchPhrase: (searchPhrase: string) => void;
    subscribeToCreateMessage: (id: string) => void;
    subscribeToCreateNotification: (xhqUser: IXHQMember) => void;
    setEditorViewMode: (editorViewMode: TEditorViewMode) => void;
    setMobileFocusedColumn: (
      focusedColumnView: TFocusColumnView,
      callback?: TVoidFunc
    ) => void;
    removeChannelDataFromState: TVoidFunc;
  };
  isAnnouncement: boolean;
  inboxAnnouncementsData: IAsyncEntityState<IThreadsData>;
}

interface IScrollableColumn {
  elem?: HTMLElement;
  scrollbarRef: {
    viewScrollTop: number;
  };
}

class Threads extends React.PureComponent<IProps> {
  scrollableColumnElem?: IScrollableColumn;

  subscribeToChannels = () => {
    if (!this.props.selectedChannel) {
      return;
    }

    this.props.actions!.subscribeToCreateMessage(this.props.selectedChannel.id);
  };

  componentDidMount() {
    const { onHandleRefresh } = this.props;

    PullToRefresh.init({
      mainElement: ".threadsScrollBar",
      onRefresh() {
        if (onHandleRefresh) {
          onHandleRefresh();
        }
      },
      shouldPullToRefresh: () => {
        if (!this.scrollableColumnElem) {
          return false;
        }

        return !this.scrollableColumnElem!.scrollbarRef.viewScrollTop;
      }
    });

    this.props.actions!.subscribeToCreateNotification(this.props.xhqUser);
  }

  componentWillUnmount() {
    PullToRefresh.destroyAll();
  }

  componentDidUpdate(prevProps: IProps) {
    const { filter, channelName } = this.props;

    // TODO REVISE THIS LOGIC WHEN FILTERS ARE FIXED
    if (
      filter !== prevProps.filter ||
      (filter === FILTERS.CHANNEL && channelName !== prevProps.channelName)
    ) {
      if (this.scrollableColumnElem && this.scrollableColumnElem.elem) {
        this.scrollableColumnElem.elem.scrollTop = 0;
      }
    }

    this.subscribeToChannels();
  }

  handleScrollToBottom = () => {
    const { isLoadingThreads, onLoadMoreThreads } = this.props;
    if (!isLoadingThreads && onLoadMoreThreads) {
      onLoadMoreThreads();
    }
  };

  handleThreadClick = (threadId?: string) => (
    event: React.MouseEvent<HTMLElement>
  ) => {
    const { onThreadClick } = this.props;
    if (onThreadClick) {
      onThreadClick(event, threadId!);
    }
  };

  handleUpdateChannelClick = () => {
    const { selectedChannel, actions } = this.props;
    if (selectedChannel && actions) {
      actions.removeChannelDataFromState();
      actions.setEditorViewMode(EDITOR_VIEW_MODE.UPDATE_CHANNEL);
      actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.THREAD_VIEW);
    }
  };

  handleRepliesToggle = (callback: () => void) => {
    const { selectedChannel, actions } = this.props;
    if (selectedChannel && actions) {
      actions.updateChannelWithCallback(
        {
          id: selectedChannel.id,
          allowThreadReplies: !selectedChannel.allowThreadReplies
        },
        callback
      );
    }
  };

  handleRepliesDialogCancel = () => {
    const { selectedChannel, actions } = this.props;
    if (selectedChannel && actions) {
      actions.setDefaultSelectedChannelState();
    }
  };

  get renderEmptyState() {
    const { channelName, filter, viewAccessByChannel } = this.props;
    /* const capitalizedFilter = filter ? filter.charAt(0).toUpperCase() + filter.slice(1) : ""; */
    const isChannel = Boolean(channelName);
    let message = "";
    let subMessage = "";
    if (isChannel) {
      const canViewChannel = channelName && viewAccessByChannel[channelName];
      subMessage = canViewChannel
        ? EMPTY_THREADS_LIST_MESSAGE
        : UNABLE_VIEW_CHANNEL_MESSAGE;
    }
    switch (filter) {
      case FILTERS.INBOX:
        message = isMobile ? EMPTY_INBOX_MESSAGE : "";
        subMessage = EMPTY_INBOX_SUBMESSAGE;
        break;
      case FILTERS.IMPORTANT:
        subMessage = EMPTY_IMPORTANT_SUBMESSAGE;
        break;
      case FILTERS.FAVOURITES:
        subMessage = EMPTY_FAVOURITES_SUBMESSAGE;
        break;
      default:
        break;
    }
    return (
      <S.StyledEmptyScreenPlaceholder
        imageSrc={isMobile ? MessagesPlaceholderImage : ""}
        title={message}
        description={subMessage}
      />
    );
  }

  renderThreadList = () => {
    const {
      isLoadingThreads,
      threads,
      selectedThreadId,
      filter = FILTERS.INBOX,
      channelName,
      hasDraft,
      isLoadingMoreThreads
    } = this.props;

    return (
      <>
        {(!isLoadingThreads || isLoadingMoreThreads) &&
          threads &&
          threads.length > 0 && (
            <ThreadList
              filter={filter}
              channelName={channelName}
              threadItems={threads}
              selectedThreadId={selectedThreadId}
              hasDraft={hasDraft}
              onThreadItemClick={this.handleThreadClick}
            />
          )}
        {!isLoadingThreads && isEmpty(threads) && this.renderEmptyState}
        {isLoadingThreads &&
          times(THREAD_LIST_LOADING_PLACEHOLDERS_AMOUNT, index => (
            <ThreadListLoading key={index} />
          ))}
      </>
    );
  };

  setScrollableColumnElemRef = (element: any) => {
    this.scrollableColumnElem = element;
  };

  get shouldHideNewThreadButton() {
    const { selectedChannel, xhqUser, isAnnouncement, filter } = this.props;

    if (filter === FILTERS.SENT) {
      return true;
    }

    if (isAnnouncement) {
      return !containsXhqCapability(
        xhqUser,
        XHQ_ROLE_CAPABILITIES.CREATE_ANNOUNCEMENT
      );
    }

    if (!selectedChannel) {
      return true;
    }

    return !userContainsChannelCapability(
      selectedChannel!,
      xhqUser,
      XHQ_CHANNEL_CAPABILITIES.CREATE_MESSAGE
    );
  }

  render() {
    const {
      xhqUser,
      className,
      filter = FILTERS.INBOX,
      onNewThreadClick,
      onFilterMenuClick,
      selectedChannel,
      selectedChannelData,
      archivedChannelId,
      archivedChannelError,
      actions,
      isAnnouncement,
      inboxAnnouncementsData
    } = this.props;
    const title = selectedChannel
      ? selectedChannel.title || selectedChannel.name
      : filter;
    const shouldHideNewThreadButton = this.shouldHideNewThreadButton;
    const scrollHeight = shouldHideNewThreadButton ? "9.15" : "11.7";

    return (
      <S.Wrapper className={className}>
        <S.StyledThreadListHeader
          xhqUser={xhqUser}
          title={upperFirst(title)}
          filter={filter}
          onFilterMenuClick={onFilterMenuClick}
          onUpdateChannelClick={this.handleUpdateChannelClick}
          onNewThreadClick={onNewThreadClick}
          hideNewThreadButton={shouldHideNewThreadButton}
          allowReplies={get(selectedChannel, "allowThreadReplies") || false}
          onRepliesConfirmationAccept={this.handleRepliesToggle}
          onRepliesConfirmationCancel={this.handleRepliesDialogCancel}
          isUpdating={selectedChannelData && selectedChannelData.isFetching}
          errorUpdating={selectedChannelData && selectedChannelData.error}
          onArchiveChannel={actions!.archiveChannel}
          onSetDefaultArchiveChannel={actions!.setDefaultArchivedChannelState}
          archivedChannelError={archivedChannelError}
          selectedChannel={selectedChannel}
          archiveChannelSuccess={Boolean(archivedChannelId)}
          isAnnouncement={isAnnouncement}
          fetchAnnouncements={actions!.fetchAnnouncements}
          setAnnouncementSearchPhrase={actions!.setAnnouncementSearchPhrase}
          searchPhrase={inboxAnnouncementsData.data.searchPhrase}
        />
        <S.StyledScrollableColumn
          className="threadsScrollBar"
          isButtonHidden={shouldHideNewThreadButton}
          onScrollToBottom={this.handleScrollToBottom}
          ref={this.setScrollableColumnElemRef}
          scrollHeight={`calc(100vh - ${scrollHeight}rem)`}
        >
          <S.ThreadListWrapper>{this.renderThreadList()}</S.ThreadListWrapper>
        </S.StyledScrollableColumn>
      </S.Wrapper>
    );
  }
}

export default Threads;
