import {
  FETCH_XHQ_USERS,
  REMOVE_XHQ_USERS
} from "../../constants/actions/xhqUsers";

const SUCCESS = `${FETCH_XHQ_USERS}_SUCCESS`;
const LOADING = `${FETCH_XHQ_USERS}_LOADING`;
const ERROR = `${FETCH_XHQ_USERS}_ERROR`;

type ActionTypes =
  | IActionType<typeof SUCCESS, any>
  | IActionType<typeof REMOVE_XHQ_USERS, any>
  | IActionType<typeof LOADING, boolean>
  | IActionType<typeof ERROR, string | null>;

const initialState: IAsyncEntityState<TXHQMemberItems> = {
  data: {
    items: []
  },
  isFetching: false,
  error: null
};

const searchUsers = (
  state: IAsyncEntityState<TXHQMemberItems> = initialState,
  action: ActionTypes
): IAsyncEntityState<TXHQMemberItems> => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        error: null
      };
    case LOADING:
      return {
        ...state,
        isFetching: action.payload as boolean,
        error: null
      };
    case ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload as string | null
      };
    case REMOVE_XHQ_USERS:
      return {
        ...state,
        data: {
          items: []
        }
      };
    default:
      return state;
  }
};

export default searchUsers;
