import * as React from "react";
import { get, isEmpty, debounce, upperFirst } from "lodash";
import { OptionProps } from "react-select/lib/types";
import CKEditor from "@ckeditor/ckeditor5-react";
import CGSEditor from "@ckeditor/ckeditor5-build-cgs";
import { Feature } from "@paralleldrive/react-feature-toggles";
import moment from "moment";
import { History } from "history";

import Chip from "../../ui/Chip";
import ToggleSwitch from "../../ui/ToggleSwitch";
import Icon from "../../ui/Icon";
import * as Icons from "../../constants/icons";
import Attachment from "../../ui/Attachment";
import { TEXTFIELD_VARIANTS } from "../../constants/textField";
import {
  CREATE_THREAD_TITLE_PLACEHOLDER,
  CREATE_THREAD_TOGGLE_SWITCH_LABEL,
  CREATE_THREAD_TOGGLE_SWITCH_DESC,
  CREATE_THREAD_POST_BUTTON,
  CREATE_THREAD_SAVING_BUTTON,
  MAX_TITLE_SIZE,
  UPDATE_THREAD_POST_BUTTON,
  CANCEL,
  POST,
  IMPORTANT_MARK,
  THREAD_EDITOR_ERROR_MESSAGES,
  CREATE_THREAD_AS,
  CREATE_ANNOUNCEMENT_AS,
  CREATE_ANNOUNCEMENT_TITLE_PLACEHOLDER,
  CREATE_ANNOUNCEMENT_POST_BUTTON,
  UPDATE_ANNOUNCEMENT_POST_BUTTON,
  MAX_BODY_SIZE,
  PREVIEW
} from "../../constants/threads";
import { XHQ_SEND_AS_ROLES } from "../../constants";
import Button from "../../ui/Button";
import { BUTTON_VARIANTS } from "../../constants/button";

import {
  ANNOUNCEMENT_SCHEDULER_ERROR_MESSAGE,
  EDITOR_VIEW_MODE,
  MESSAGES_LABEL_KEYS,
  MOBILE_COMMUNITY_SCREENS,
  XHQ_ROLE_CAPABILITIES,
  XHQ_CHANNEL_CAPABILITIES
} from "../../constants/community";
import {
  getRoleLabel,
  containsXhqRole,
  containsXhqCapability,
  getThreadChannel,
  replaceTagsWithCSVValues,
  removeHTMLTags,
  ComponentNoop,
  getSelectedAudienceFromThread,
  getCSVRow,
  isAnnouncementFilter,
  userContainsChannelCapability
} from "../../utils/helpers";
import ErrorMessage from "../../ui/ErrorMessage";
import * as S from "./styles";
import PreviewModal from "../../ui/PreviewModal";
import s3FileUpload from "../../utils/s3FileUpload";
import { SCHEDULED_ANNOUNCEMENTS_TOGGLE } from "../../constants/featureToggles";
import DateTimePicker from "../../ui/DateTimePicker";
import AudienceSelector from "../../ui/AudienceSelector";
import CommunityEditorLayout from "../../ui/CommunityEditorLayout";

type TProps = IThreadEditorStateProps &
  IThreadEditorActions &
  IThreadEditorOwnProps;

interface IProps extends TProps {
  history?: History;
}

interface IState {
  title: string;
  body: string;
  excerpt: string;
  attachments: IAttachment[];
  shouldShowCreateAsMenu: boolean;
  selectedMember: IOptionType | null;
  isImportantThread: boolean;
  selectedAudiences: IAudienceSelection[];
  bodyWords: number;
  bodyCharacters: number;
  isLoadingContent: boolean;
  selectedCSVFile?: File;
  csvHeader: string;
  CSVContent: string;
  showPreviewModal: boolean;
  showAnnouncementScheduler: boolean;
  selectedScheduleTime: string | undefined;
  schedulerError: string | null;
}

class ThreadEditor extends React.PureComponent<IProps, IState> {
  emptyState: IState = {
    title: "",
    body: "",
    excerpt: "",
    attachments: [],
    shouldShowCreateAsMenu: false,
    selectedMember: null,
    isImportantThread: false,
    selectedAudiences: [],
    bodyWords: 0,
    bodyCharacters: 0,
    isLoadingContent: false,
    csvHeader: "",
    CSVContent: "",
    showPreviewModal: false,
    showAnnouncementScheduler: false,
    selectedScheduleTime: undefined,
    schedulerError: null
  };

  constructor(props: IProps) {
    super(props);

    const { editedThread, editingMode } = props;

    this.state = {
      ...this.emptyState,
      title: editedThread ? editedThread.title! : this.emptyState.title,
      body: editedThread ? editedThread.body! : this.emptyState.body,
      selectedScheduleTime:
        editedThread && editedThread.scheduledAt && editingMode
          ? editedThread.scheduledAt
          : this.emptyState.selectedScheduleTime,
      showAnnouncementScheduler: Boolean(
        editedThread && editedThread.scheduledAt && editingMode
      ),
      selectedAudiences:
        editedThread && editingMode
          ? getSelectedAudienceFromThread(editedThread)
          : [],
      excerpt: this.getExcerptValue
    };
  }

  get getExcerptValue() {
    const { editedThread } = this.props;
    return editedThread
      ? editedThread.excerpt ||
          removeHTMLTags(editedThread.body!.replace(/&nbsp;/g, ""))
      : this.emptyState.excerpt;
  }

  handleTitleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({ title: event.target.value });
  };

  handleNotifyDropDownClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState(() => ({ shouldShowCreateAsMenu: false }));
  };

  componentDidUpdate(prevProps: TProps) {
    if (prevProps.editingMode && !this.props.editingMode) {
      this.resetFields();
    }
  }

  resetFields = () => {
    this.setState({
      ...this.emptyState
    });
  };

  handleUpdateClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { actions, editedThread, updateThreadData } = this.props;
    const {
      title,
      body,
      excerpt,
      showAnnouncementScheduler,
      selectedScheduleTime
    } = this.state;

    if (!this.isButtonEnabled || !editedThread || updateThreadData!.isLoading) {
      return;
    }

    actions!.updateThreadAndRedirect(
      {
        title,
        body,
        excerpt,
        id: editedThread.id as string,
        parentId: editedThread.parentId,
        targets: this.getUpdateTargetsForEditingAnnouncement,
        scheduledAt:
          this.isAnnouncement && showAnnouncementScheduler
            ? selectedScheduleTime
            : undefined
      },
      this.handleThreadSubmitted(true)
    );
  };

  get getUpdateTargetsForEditingAnnouncement() {
    const { editingMode } = this.props;
    const { selectedAudiences, CSVContent } = this.state;

    if (editingMode && this.shouldShowAnnouncementOptions) {
      return this.getTargetForAnnouncement(selectedAudiences, CSVContent);
    }

    return undefined;
  }

  getTargetForAnnouncement = (
    selectedAudiences: IAudienceSelection[],
    CSVContent: string
  ) => {
    if (CSVContent) {
      return [{ base64EncodedCSV: btoa(CSVContent) }];
    }

    return selectedAudiences.map(selectedAudience => ({
      role: upperFirst(selectedAudience.role.toLowerCase()),
      audienceId: selectedAudience.audienceId
    }));
  };

  handlePostClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { xhqUser, actions, createThreadData } = this.props;
    const {
      title,
      body,
      excerpt,
      selectedMember,
      selectedAudiences,
      CSVContent,
      showAnnouncementScheduler,
      selectedScheduleTime
    } = this.state;
    const selectedChannel = this.getSelectedChannel();

    if (!this.isButtonEnabled || createThreadData!.isLoading) {
      return;
    }

    const postData = {
      title,
      body,
      excerpt,
      createdBy: selectedMember ? selectedMember.value.id : xhqUser!.id,
      labels: [
        {
          key: MESSAGES_LABEL_KEYS.ROLE,
          value: getRoleLabel("STAFF")
        }
      ]
    };

    if (this.isAnnouncement) {
      const targets = this.getTargetForAnnouncement(
        selectedAudiences,
        CSVContent
      );
      return actions.postAnnouncementAndRedirect(
        {
          ...postData,
          targets,
          scheduledAt: showAnnouncementScheduler
            ? selectedScheduleTime
            : undefined
        },
        this.handleThreadCreated
      );
    }

    actions!.postThreadAndRedirect(
      {
        ...postData,
        parent: selectedChannel!.id
      },
      this.handleThreadCreated
    );
  };

  mapErrorMessage = (message: string | null) => {
    if (!message) {
      return;
    }

    if (message.includes("2 characters long")) {
      return THREAD_EDITOR_ERROR_MESSAGES.LENGTH_TOO_SHORT;
    }
    return message;
  };

  get isButtonEnabled() {
    const { title, body, bodyCharacters, isLoadingContent } = this.state;
    return (
      !isEmpty(title) &&
      !isEmpty(body) &&
      bodyCharacters <= MAX_BODY_SIZE &&
      !isLoadingContent
    );
  }

  handleImportantNotificationChange = () => {
    this.setState(currentState => ({
      isImportantThread: !currentState.isImportantThread
    }));
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { actions } = this.props;
    event.preventDefault();
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    const file = event.target.files[0];

    actions.putAttachmentToS3(file);
  };

  handleThreadCreated = async (thread: IThread) => {
    const { actions, attachments } = this.props;
    const selectedChannel = this.getSelectedChannel();
    const { isImportantThread } = this.state;

    if (isImportantThread) {
      actions!.postNotification(thread, selectedChannel!.id);
    }

    if (!attachments!.data.length) {
      this.handleThreadSubmitted(false)(thread.id!);
      return;
    }

    await actions!.postThreadAttachments(thread);
    this.handleThreadSubmitted!(false)(thread.id!);
  };

  handleShowCreateAsMenu = () => {
    this.setState({
      shouldShowCreateAsMenu: !this.state.shouldShowCreateAsMenu
    });
  };

  get hasCreateAsAliasCapability() {
    const { xhqUser } = this.props;
    const {
      ALIAS_CREATE_MESSAGE,
      ALIAS_CREATE_ANNOUNCEMENT
    } = XHQ_ROLE_CAPABILITIES;

    if (this.isAnnouncement) {
      return containsXhqCapability(
        xhqUser as IXHQMember,
        ALIAS_CREATE_ANNOUNCEMENT
      );
    }

    return containsXhqCapability(xhqUser as IXHQMember, ALIAS_CREATE_MESSAGE);
  }

  getCreateThreadAsButton = () => {
    const { editingMode } = this.props;
    const { selectedMember } = this.state;

    if (!this.hasCreateAsAliasCapability || editingMode) {
      return null;
    }

    return (
      <>
        <S.StyledNoteHeader>
          <Icon name={Icons.PROFILE} />{" "}
          {this.isAnnouncement ? CREATE_ANNOUNCEMENT_AS : CREATE_THREAD_AS}
        </S.StyledNoteHeader>
        <S.SelectMemberButton
          iconName={Icons.ADD}
          onClick={this.handleShowCreateAsMenu}
        >
          {!selectedMember ? "Select" : "Change"}
        </S.SelectMemberButton>
      </>
    );
  };

  handleMemberInputSelectChange = (value: string) => {
    const { actions } = this.props;
    if (isEmpty(value)) {
      actions.removeXhqUsers!();

      return;
    }

    actions.fetchXHQSearchMembers!(value);
  };

  handleMemberSelectorChange = (selectedOption: any) => {
    this.setState(() => ({
      selectedMember: selectedOption,
      shouldShowCreateAsMenu: false
    }));
  };

  filterOption = (option: OptionProps) => {
    const {
      data: {
        roles: { items: roleItems }
      },
      value
    } = option;

    const containsRole = containsXhqRole(roleItems, XHQ_SEND_AS_ROLES.OBJECT);
    const isAllowedToPost = this.isAnnouncement
      ? containsXhqCapability(
          value,
          XHQ_ROLE_CAPABILITIES.ALIAS_CREATE_ANNOUNCEMENT
        )
      : userContainsChannelCapability(
          this.getSelectedChannel()!,
          value,
          XHQ_CHANNEL_CAPABILITIES.CREATE_MESSAGE
        );

    return containsRole && isAllowedToPost;
  };

  getCreateThreadAsModal = () => {
    if (!this.state.shouldShowCreateAsMenu) {
      return null;
    }

    const { isFetchingXhqUserOptions, xhqUserOptionsResult } = this.props;

    return (
      <S.StyledModal
        title="Select member"
        onCloseClick={this.handleNotifyDropDownClickOutside}
      >
        <S.MembersSelect
          isLoading={isFetchingXhqUserOptions}
          autoFocus={true}
          placeholder="Find a member"
          onInputChange={debounce(this.handleMemberInputSelectChange, 500)}
          onChange={this.handleMemberSelectorChange}
          filterOption={this.filterOption}
          options={xhqUserOptionsResult}
        />
      </S.StyledModal>
    );
  };

  handleSelectedMemberRemove = () => {
    this.setState({ selectedMember: null });
  };

  handleAudienceCloseClick = (audienceRolePair: string) => () => {
    this.setState(currentState => ({
      selectedAudiences: currentState.selectedAudiences.filter(
        selectedAudience =>
          selectedAudience.audienceRolePair !== audienceRolePair
      )
    }));
  };

  renderTopWrapper = () => {
    const { actions, audiencesState, audiencesOptions } = this.props;
    const { selectedAudiences } = this.state;
    const selectedChannel = this.getSelectedChannel();
    return (
      <>
        {selectedChannel && (
          <S.ChipWrapper>
            <Chip># {selectedChannel.title || selectedChannel.name}</Chip>
          </S.ChipWrapper>
        )}
        {this.shouldShowAnnouncementOptions && (
          <AudienceSelector
            title="audience"
            selectedAudiences={selectedAudiences}
            selectedMembers={[]}
            fetchAudiencesSearch={actions.fetchAudiencesSearch}
            removeAudiencesDataFromState={actions.removeAudiencesDataFromState}
            audiencesState={audiencesState}
            audiencesOptions={audiencesOptions}
            onAudiencesSelectionChange={this.handleAudiencesSelectionChange}
            showGroupSelector={true}
            showCSVSelector={true}
          />
        )}
      </>
    );
  };

  handlePreviewModalToggle = () => {
    this.setState(({ showPreviewModal }) => ({
      showPreviewModal: !showPreviewModal
    }));
  };

  handleAudiencesSelectionChange = ({
    selectedAudiences,
    selectedMembers,
    CSVContent,
    csvHeader,
    selectedCSVFile
  }: IAudienceSelectorValue) => {
    this.setState({
      selectedAudiences,
      selectedCSVFile,
      csvHeader,
      CSVContent
    });
  };

  handleScheduleAnnouncementToggle = () => {
    this.setState(({ showAnnouncementScheduler }) => ({
      showAnnouncementScheduler: !showAnnouncementScheduler
    }));
  };

  handleSchedulerChange = (selectedScheduleTime: string | undefined) => {
    const hasInvalidTime = moment(selectedScheduleTime).isBefore(moment());
    this.setState({
      selectedScheduleTime: !hasInvalidTime ? selectedScheduleTime : undefined,
      schedulerError: !hasInvalidTime
        ? null
        : ANNOUNCEMENT_SCHEDULER_ERROR_MESSAGE
    });
  };

  getSelectedChannel = () => {
    const { allUserChannelsMap, editedThread } = this.props;

    return getThreadChannel(
      allUserChannelsMap,
      editedThread,
      this.getSelectedChannelFromRoute
    );
  };

  get isSubmitDisabled() {
    const {
      createThreadData,
      isPostingThreadAttachments,
      updateThreadData
    } = this.props;
    const {
      selectedAudiences,
      selectedCSVFile,
      showAnnouncementScheduler,
      selectedScheduleTime
    } = this.state;
    const isAudienceRequired =
      this.isAnnouncement &&
      this.shouldShowAnnouncementOptions &&
      selectedAudiences.length === 0 &&
      (this.isAnnouncement && !selectedCSVFile);

    if (showAnnouncementScheduler && !selectedScheduleTime) {
      return true;
    }

    return (
      !this.isButtonEnabled ||
      createThreadData!.isLoading ||
      isPostingThreadAttachments ||
      updateThreadData!.isLoading ||
      isAudienceRequired
    );
  }

  get renderPostButton() {
    const { editingMode, createThreadData } = this.props;
    const postLabel = !this.isAnnouncement
      ? CREATE_THREAD_POST_BUTTON
      : CREATE_ANNOUNCEMENT_POST_BUTTON;
    const updateLabel = !this.isAnnouncement
      ? UPDATE_THREAD_POST_BUTTON
      : UPDATE_ANNOUNCEMENT_POST_BUTTON;
    const buttonLabel = editingMode ? updateLabel : postLabel;
    const onClick = editingMode ? this.handleUpdateClick : this.handlePostClick;
    return (
      <Button
        variant={BUTTON_VARIANTS.ROUNDED}
        disabled={this.isSubmitDisabled}
        onClick={onClick}
      >
        {!createThreadData!.isLoading
          ? buttonLabel
          : CREATE_THREAD_SAVING_BUTTON}
      </Button>
    );
  }

  renderPreviewButton = () => {
    const { title, body } = this.state;
    const isDisabled = !body || !title;

    return (
      <S.MarginedButton
        variant={BUTTON_VARIANTS.ROUNDED}
        disabled={isDisabled}
        onClick={this.handlePreviewModalToggle}
      >
        {PREVIEW}
      </S.MarginedButton>
    );
  };

  formatMailPreview = (body: string, CSVContent: string) => {
    const CSVFirstRow = getCSVRow(CSVContent, 1);
    const CSVContentRow = getCSVRow(CSVContent, 2); // number here will be replaced with state

    return replaceTagsWithCSVValues(body, CSVFirstRow, CSVContentRow);
  };

  renderPreviewModal() {
    const { body, title, CSVContent } = this.state;

    return (
      <PreviewModal
        onCloseClick={this.handlePreviewModalToggle}
        title="Announcement preview"
        contentTitle={title}
        text={this.formatMailPreview(body, CSVContent)}
      />
    );
  }

  // the first argument of this fuction is to just pass something and not use the this value as part of the fix for https://github.com/ckeditor/ckeditor5-word-count/issues/16
  getTheWordAndCharCount = (wordCountContainer: any, editor: any) => {
    const { characters, words } = editor.plugins.get("WordCount");

    return { characters, words };
  };

  onCKEditorChange = (event: HTMLInputElement, editor: any) => {
    const data = editor.getData();
    const { characters, words } = this.getTheWordAndCharCount("", editor);

    this.setState({
      body: data,
      bodyWords: words,
      bodyCharacters: characters,
      excerpt: removeHTMLTags(data.replace(/&nbsp;/g, ""))
    });
  };

  onCKEditorInit = (editor: any) => {
    const wordCountContainer = editor.plugins.get("WordCount")
      .wordCountContainer; // to prevent error from here: https://github.com/ckeditor/ckeditor5-word-count/issues/16

    const { characters, words } = this.getTheWordAndCharCount(
      wordCountContainer,
      editor
    );

    this.setState({ bodyWords: words, bodyCharacters: characters });
  };

  setLoadingContent = (isLoading: boolean) => {
    this.setState({ isLoadingContent: isLoading });
  };

  renderAnnouncementScheduler = () => {
    const { editingMode } = this.props;
    const {
      showAnnouncementScheduler,
      selectedScheduleTime,
      schedulerError
    } = this.state;

    const isToggleDisabled =
      this.isAnnouncement && editingMode && showAnnouncementScheduler;
    return (
      <S.SchedulerWrapper>
        <S.Label>SCHEDULING</S.Label>
        <S.SchedulerContent>
          <ToggleSwitch
            onCheckedChange={this.handleScheduleAnnouncementToggle}
            defaultValue={showAnnouncementScheduler}
            disabled={isToggleDisabled}
          />
          {showAnnouncementScheduler ? (
            <DateTimePicker
              onDateTimeChange={this.handleSchedulerChange}
              defaultValue={selectedScheduleTime}
              errorMessage={schedulerError}
            />
          ) : (
            <div>
              <S.SchedulerTitle>
                Schedule announcement for later
              </S.SchedulerTitle>
              <S.SchedulerDescription>
                Switch on to post your announcement at another time
              </S.SchedulerDescription>
            </div>
          )}
        </S.SchedulerContent>
      </S.SchedulerWrapper>
    );
  };

  handleCancelClick = () => {
    const { actions } = this.props;
    actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.THREAD_LIST);
    actions.setEditorViewMode(EDITOR_VIEW_MODE.READ_THREAD);
  };

  handleThreadSubmitted = (isUpdating: boolean = false) => (
    threadIdFromResponse: string
  ) => {
    const {
      actions,
      match: {
        params: { threadId }
      }
    } = this.props;
    actions.setEditorViewMode(EDITOR_VIEW_MODE.READ_THREAD);
    const selectedChannel = this.getSelectedChannelFromRoute;
    if (selectedChannel && isUpdating) {
      this.props.history!.push(
        `/community/channel/${selectedChannel.name}/threads/${threadId}`
      );

      return;
    }

    if (selectedChannel && !isUpdating) {
      this.props.history!.push(
        `/community/channel/${selectedChannel.name}/threads/${threadIdFromResponse}`
      );

      return;
    }

    const selectedFilter = get(this.props, "match.params.filter");
    if (selectedFilter && isUpdating) {
      this.props.history!.push(
        `/community/${selectedFilter}/threads/${threadId}`
      );

      return;
    }

    if (selectedFilter && !isUpdating) {
      this.props.actions.removeThreadDataFromState();
      this.props.history!.push(`/community/${selectedFilter}`);

      return;
    }
  };

  get shouldShowAnnouncementOptions() {
    const { editingMode, editedThread } = this.props;
    if (!this.isAnnouncement) {
      return false;
    }

    if (!editingMode) {
      return true;
    }

    return (
      editedThread &&
      !isEmpty(editedThread.scheduledAt) &&
      isEmpty(editedThread.sentAt)
    );
  }

  get renderMarkAsImportantToggle() {
    return (
      <div>
        <S.NoteHeader>
          <Icon name={Icons.IMPORTANT} /> {IMPORTANT_MARK}
        </S.NoteHeader>
        <ToggleSwitch
          label={CREATE_THREAD_TOGGLE_SWITCH_LABEL}
          description={CREATE_THREAD_TOGGLE_SWITCH_DESC}
          onCheckedChange={this.handleImportantNotificationChange}
          defaultValue={false}
        />
      </div>
    );
  }

  renderCreatedAs(withMargin: boolean) {
    const { selectedMember } = this.state;
    return (
      <S.CreateThreadAsWrapper withMargin={withMargin}>
        {this.getCreateThreadAsButton()}
        {this.getCreateThreadAsModal()}
        {selectedMember && (
          <S.SelectMemberChip onClose={this.handleSelectedMemberRemove}>
            {selectedMember.label}
          </S.SelectMemberChip>
        )}
      </S.CreateThreadAsWrapper>
    );
  }

  get getSelectedChannelFromRoute() {
    const {
      match: {
        params: { channelName }
      },
      allUserChannelsMap
    } = this.props;

    return allUserChannelsMap[`Channel-${channelName}`];
  }

  get isAnnouncement() {
    const {
      match: {
        params: { filter }
      }
    } = this.props;
    return isAnnouncementFilter(filter);
  }

  renderActionsHeader = () => {
    const { editingMode } = this.props;
    return (
      <>
        <S.TopActionButton role="button" onClick={this.handleCancelClick}>
          {CANCEL}
        </S.TopActionButton>
        <S.HeaderLabel>Post Your Thread</S.HeaderLabel>
        <S.TopPostActionButton
          role="button"
          onClick={editingMode ? this.handleUpdateClick : this.handlePostClick}
          disabled={this.isSubmitDisabled}
        >
          {POST}
        </S.TopPostActionButton>
      </>
    );
  };

  renderMiddleWrapper = () => {
    const { title, body, bodyWords, bodyCharacters, csvHeader } = this.state;
    const {
      attachments,
      updateThreadData,
      createThreadData,
      editingMode,
      actions,
      xhqUser
    } = this.props;

    const errorMessage =
      attachments!.error ||
      this.mapErrorMessage(updateThreadData!.error) ||
      this.mapErrorMessage(createThreadData!.error);

    const showMarkAsImportantToggle =
      containsXhqCapability(
        xhqUser as IXHQMember,
        XHQ_ROLE_CAPABILITIES.NOTIFY_MEMBERS
      ) && !this.isAnnouncement;

    return (
      <>
        <S.StyledTextField
          variant={TEXTFIELD_VARIANTS.LARGE}
          placeholder={
            !this.isAnnouncement
              ? CREATE_THREAD_TITLE_PLACEHOLDER
              : CREATE_ANNOUNCEMENT_TITLE_PLACEHOLDER
          }
          value={title}
          onChange={this.handleTitleChange}
          maxLength={MAX_TITLE_SIZE}
        />
        <S.CKEditorWrapper>
          <CKEditor
            editor={CGSEditor({
              uploadAction: s3FileUpload,
              setLoadingAction: this.setLoadingContent
            })}
            data={body}
            onChange={this.onCKEditorChange}
            onInit={this.onCKEditorInit}
          />
        </S.CKEditorWrapper>
        <S.WordCounter isRed={bodyCharacters > MAX_BODY_SIZE}>
          Words: {bodyWords} | Characters: {bodyCharacters}
        </S.WordCounter>
        {csvHeader && (
          <S.CSVHeaders>
            Available mail merge fields: <strong>{csvHeader}</strong>
          </S.CSVHeaders>
        )}
        {!editingMode && (
          <S.BelowBodyActionsWrapper>
            {showMarkAsImportantToggle && this.renderMarkAsImportantToggle}
            {this.renderCreatedAs(showMarkAsImportantToggle)}
          </S.BelowBodyActionsWrapper>
        )}
        {this.shouldShowAnnouncementOptions && (
          <Feature
            name={SCHEDULED_ANNOUNCEMENTS_TOGGLE}
            inactiveComponent={ComponentNoop}
            activeComponent={this.renderAnnouncementScheduler}
          />
        )}
        <S.AttachmentsWrapper hasAttachments={!!attachments!.data.length}>
          {!!attachments!.data.length && (
            <S.StyledScrollable scrollheight={"6rem"}>
              <Attachment
                inChips={true}
                attachments={attachments!.data}
                onRemoveAttachment={actions.removeAttachmentFromState}
              />
            </S.StyledScrollable>
          )}
        </S.AttachmentsWrapper>
        {!attachments!.isUploading && !!errorMessage && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
      </>
    );
  };

  renderBottomWrapper = () => {
    const { csvHeader } = this.state;
    const { attachments, editingMode } = this.props;

    return (
      <>
        <S.FileUploadWrapper>
          {!editingMode &&
            (!attachments!.isUploading ? (
              <>
                <S.FileInput
                  type="file"
                  onChange={this.handleFileChange}
                  id="attachmentInput"
                  value=""
                />
                <label htmlFor="attachmentInput">
                  <S.AttachmentIcon name={Icons.ATTACHMENT} opacity={0.5} />
                </label>
              </>
            ) : (
              <div>Uploading...</div>
            ))}
        </S.FileUploadWrapper>

        <S.BottomButtonsWrapper>
          <S.MarginedButton
            outlined={true}
            variant={BUTTON_VARIANTS.ROUNDED}
            onClick={this.handleCancelClick}
          >
            CANCEL
          </S.MarginedButton>
          {this.isAnnouncement && csvHeader && this.renderPreviewButton()}
          {this.renderPostButton}
        </S.BottomButtonsWrapper>
      </>
    );
  };

  render() {
    const { editingMode } = this.props;
    const { showPreviewModal } = this.state;

    if (!this.getSelectedChannel() && !this.isAnnouncement && !editingMode) {
      return <S.StyledLoadingPanel />;
    }

    const actionsHeader = this.renderActionsHeader();
    const topWrapper = this.renderTopWrapper();
    const middleWrapper = this.renderMiddleWrapper();
    const bottomWrapper = this.renderBottomWrapper();

    return (
      <>
        <CommunityEditorLayout
          actionsHeaderContent={actionsHeader}
          topWrapperContent={topWrapper}
          middleWrapperContent={middleWrapper}
          bottomWrapperContent={bottomWrapper}
        />
        {showPreviewModal && this.renderPreviewModal()}
      </>
    );
  }
}

export default ThreadEditor;
