import gql from "graphql-tag";
import { TARGETS_FRAGMENT } from "./targets";

export const THREAD_FRAGMENT = gql`
  ${TARGETS_FRAGMENT}

  fragment ThreadFields on Message {
    id
    title
    body
    excerpt
    read
    createdBy {
      id
      firstName
      lastName
      avatar
    }
    aliasCreatedBy {
      id
    }
    children {
      items {
        id
        title
        body
        createdBy {
          id
          firstName
          lastName
          avatar
        }
        labels {
          key
          value
        }
      }
    }
    labels {
      key
      value
    }
    parentId
    createdAt
    lastEditedAt
    sentAt
    scheduledAt
    targets {
      ...TargetFields
    }
  }
`;
