import * as React from "react";
import styled from "styled-components";
import { Component, MouseEvent } from "react";
import { MdClose } from "react-icons/md";

import Avatar from "../Avatar";

interface IProps {
  className?: string;
  avatar: string;
  label: string;
  value: any;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onDelete?: (event: MouseEvent<HTMLDivElement>, value?: string) => void;
}

const Wrapper = styled.div`
  align-items: center;
  border: 0.09rem solid ${props => props.theme.colors.tertiary.main};
  border-radius: 0.4rem;
  color: ${props => props.theme.colors.secondary.main};
  cursor: ${props => (props.onClick ? "pointer" : "initial")};
  display: inline-flex
  font-family: ${props => props.theme.fonts.main};;
  padding: 0.5rem;
  user-select: none;
`;

const MemberAvatar = styled(Avatar)`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
`;

const Label = styled.div`
  font-size: 0.9rem;
`;

const RemoveButton = styled.div`
  color: ${props => props.theme.colors.tertiary.main};
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  line-height: 0;
  margin-left: 0.8rem;

  :hover {
    color: ${props => props.theme.colors.primary.main};
  }
`;

class MemberChip extends Component<IProps> {
  handleDelete = (event: MouseEvent<HTMLDivElement>) => {
    const { onDelete, value } = this.props;
    if (event && onDelete) {
      event.stopPropagation();
      onDelete(event, value);
    }
  };

  render() {
    const { className, avatar, label, onClick, onDelete } = this.props;
    return (
      <Wrapper className={className} role="button" onClick={onClick}>
        <MemberAvatar src={avatar} />
        <Label>{label}</Label>
        {onDelete && (
          <RemoveButton role="button" onClick={this.handleDelete}>
            <MdClose />
          </RemoveButton>
        )}
      </Wrapper>
    );
  }
}

export default MemberChip;
