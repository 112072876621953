import styled from "styled-components";

import media from "../../lib/media";
import TextField from "../../ui/TextField";
import LoadingPanel from "../../ui/Loading/LoadingPanel";
import Icon from "../../ui/Icon";
import Chip from "../../ui/Chip";
import ScrollableColumn from "../../ui/ScrollableColumn";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding-top: 2rem;
  height: calc(100% - 2rem);

  ${media.lessThan("medium")`
    padding-top: 1rem;
    height: calc(100% - 1rem);
  `};
`;

export const StyledScrollableColumn = styled(ScrollableColumn)`
  ${media.lessThan("medium")`
    min-height: 100%;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ActionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.main};
  min-height: 3.6875rem;

  ${media.greaterThan("medium")`
    display: none;
  `};
`;

export const HeaderLabel = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.secondary.secondary};
  font-size: 1.1rem;
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  padding-bottom: 2rem;
  margin: 0 3rem 2rem;
  flex-shrink: 0;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.pearl};
`;

export const MiddleWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0 3rem;
  margin-bottom: 3rem;

  ${media.lessThan("medium")`
    padding: 1rem;
  `};
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
  max-height: 3rem;
  padding: 1.5rem 3rem;
  box-shadow: 0 0.1875rem 1.5rem 0
    ${({ theme }) => theme.colors.secondary.main}14;

  ${media.lessThan("medium")`
    display: none;
  `};

  ${media.between("small", "large")`
    display: flex;
  `};
`;

export const StyledLoadingPanel = styled(LoadingPanel)`
  display: flex;
  flex: 1;
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 1.75rem;

  input {
    width: 100%;
  }

  ${media.lessThan("medium")`
    input {
      border: 0;
      box-sizing: border-box;
    }
  `};

  ${media.lessThan("small")`
    input {
      font-size: 1.5rem;
    }
  `};
`;

export const StyledIcon = styled(Icon)`
  padding: 0.6875rem;
`;

export const StyledChip = styled(Chip)`
  margin: 0 0.5rem 0.5rem;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.secondary.semilight};
  font-size: 0.6875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;
