import { upperFirst } from "lodash";

import * as Icons from "./icons";
import { FILTERS } from ".";

const announcementFilters: ICommunityFilter[] = [
  {
    name: upperFirst(FILTERS.INBOX),
    route: FILTERS.INBOX,
    icon: Icons.INBOX,
    selected: true
  },
  {
    name: upperFirst(FILTERS.SCHEDULED),
    route: FILTERS.SCHEDULED,
    icon: Icons.CALENDAR_SUBSCRIBE,
    selected: false
  },
  {
    name: upperFirst(FILTERS.SENT),
    route: FILTERS.SENT,
    icon: Icons.ARCHIVE,
    selected: false
  }
];

export default announcementFilters;
