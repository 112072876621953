const fontFamily = "'Lato', sans-serif";

export const faces = {
  normal: `
    font-family: ${fontFamily};
    line-height: 1rem;
  `,

  smallBold: `
    font-family: ${fontFamily};
    font-size: .6rem;
    font-weight: 800;
    letter-spacing: .02rem;
    text-transform: uppercase;
  `
};
