export const EDITOR_VIEW_MODE = {
  READ_THREAD: "READ_THREAD",
  CREATE_THREAD: "CREATE_THREAD",
  UPDATE_THREAD: "UPDATE_THREAD",
  FORWARD_THREAD: "FORWARD_THREAD",
  CREATE_CHANNEL: "CREATE_CHANNEL",
  UPDATE_CHANNEL: "UPDATE_CHANNEL"
};

export const XHQ_ID_PREFIXES = {
  MEMBER: "Member-",
  CHANNEL: "Channel-"
};

export const COMMENTS_INPUT_MIN_LENGTH = 2;
export const COMMENTS_INPUT_MAX_LENGTH = 1000;

export const MOBILE_COMMUNITY_SCREENS = {
  FILTERS: "FILTERS",
  THREAD_LIST: "THREAD_LIST",
  THREAD_VIEW: "THREAD_VIEW"
};

export const MESSAGES_LABEL_KEYS = {
  ROLE: "ROLE"
};

export const XHQ_ROLE_CAPABILITIES = {
  ADD_CHANNEL: "Capability-add-channel",
  UPDATE_CHANNEL: "Capability-update-channel",
  UPDATE_ANY_CHANNEL: "Capability-update-any-channel",
  ARCHIVE_CHANNEL: "Capability-archive-channel",
  ARCHIVE_ANY_CHANNEL: "Capability-archive-any-channel",
  DELETE_MESSAGE: "Capability-delete-message",
  UPDATE_MESSAGE: "Capability-update-message",
  ALIAS_CREATE_MESSAGE: "Capability-alias-create-message",
  ALIAS_CREATE_ANNOUNCEMENT: "Capability-alias-create-announcement",
  NOTIFY_MEMBERS: "Capability-notify-members",
  CREATE_ANNOUNCEMENT: "Capability-create-announcement",
  DELETE_ANY_ANNOUNCEMENT: "Capability-delete-any-announcement"
};

export const XHQ_CHANNEL_CAPABILITIES = {
  CREATE_MESSAGE: "Capability-create-message",
  CREATE_REPLY: "Capability-create-reply",
  DELETE_MESSAGE: "Capability-delete-message",
  UPDATE_MESSAGE: "Capability-update-message",
  DELETE_ANY_MESSAGE: "Capability-delete-any-message",
  UPDATE_CHANNEL: "Capability-update-channel",
  LIST_MESSAGES: "Capability-list-messages",
  ARCHIVE_CHANNEL: "Capability-archive-channel"
};

export const IS_CHANNEL_OR_FILTER_ROUTE_REGEX = "^.*community/.*$";

export const ANNOUNCEMENT_SCHEDULER_ERROR_MESSAGE =
  "Selected time must be on the future.";
