import * as React from "react";
import styled from "styled-components";

import theme from "../../theme";
import Icon from "../Icon";
import * as Icons from "../../constants/icons";
import HorizontalRule from "../HorizontalRule";
import { SNOOZE_OPTIONS } from "../../constants";
import { Menu, MenuItem } from "../Menu";

interface IProps {
  onClickOutside?: (event: React.MouseEvent<HTMLElement>) => void;
  onToggleSnooze?: (
    snoozeTime: number
  ) => (event: React.MouseEvent<HTMLElement>) => void;
  isSnoozed?: boolean;
  snoozedUntil?: string;
  className?: string;
}

const MenuText = styled.div`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.secondary.semilight};
  font-size: 0.75rem;
  margin: 1rem 0.3rem;
`;

const MenuItemDropdown = styled(MenuItem)`
  padding: 0;
`;

const MenuItemText = styled.div`
  flex: 1;
`;

const StyledHorizontalRule = styled(HorizontalRule)`
  margin: 0.5rem 0;
`;

class NotificationsDropdown extends React.PureComponent<IProps> {
  renderSnoozeOptions = () => {
    const { isSnoozed, snoozedUntil, onToggleSnooze } = this.props;

    if (!isSnoozed) {
      return (
        <>
          <MenuText>Snooze Notifications</MenuText>
          {SNOOZE_OPTIONS.map(snoozeOption => (
            <MenuItem
              key={snoozeOption.hours}
              onClick={onToggleSnooze && onToggleSnooze(snoozeOption.hours)}
            >
              <Icon name={snoozeOption.icon} />
              {snoozeOption.hours === 0.5
                ? `30 minutes`
                : `${snoozeOption.hours} hour${
                    snoozeOption.hours !== 1 ? "s" : ""
                  }`}
            </MenuItem>
          ))}
        </>
      );
    }

    return (
      <>
        {snoozedUntil && <MenuText>Turned Off Until {snoozedUntil}</MenuText>}
        <MenuItem onClick={onToggleSnooze && onToggleSnooze(0)}>
          <Icon
            name={Icons.SNOOZED_NOTIFICATION}
            color={theme.colors.danger.main}
          />
          Turn Off Snooze
        </MenuItem>
      </>
    );
  };

  render() {
    const { className, isSnoozed, onClickOutside } = this.props;
    return (
      <Menu className={className} onClickOutside={onClickOutside}>
        {this.renderSnoozeOptions()}
        <StyledHorizontalRule />
        {isSnoozed && (
          <MenuItemDropdown>
            <Icon name={Icons.CLOCK} opacity={0.5} />
            <MenuItemText>Adjust time</MenuItemText>
            <Icon name={Icons.RIGHT_ARROW} opacity={0.5} />
          </MenuItemDropdown>
        )}
        <MenuItem>
          <Icon name={Icons.NOTIFICATION} opacity={0.5} />
          Scheduled Snoozing
        </MenuItem>
        <MenuItem>
          <Icon name={Icons.SETTINGS} opacity={0.5} />
          Your notifications settings
        </MenuItem>
      </Menu>
    );
  }
}

export default NotificationsDropdown;
