import * as React from "react";
import { withTheme } from "styled-components";
import { isEmpty, isBoolean } from "lodash";

import { MEMBER_COMPONENT_SIZES } from "../../constants";
import ThreadHeader from "../ThreadHeader";
import Member from "../Member";
import xss from "../../utils/xss";
import {
  Wrapper,
  ThreadInfoWrapper,
  Title,
  Content,
  Timestamp,
  DesktopTimestamp,
  EditedMessage,
  AttachmentWrapper,
  StyledChip,
  ChipRole,
  AudiencesWrapper,
  DesktopAudiencesWrapper,
  StyledScrollableWrapper,
  TimeStampContent
} from "./styles";
import Icon from "../Icon";
import { CLOCK } from "../../constants/icons";

interface IProps {
  theme: any;
  author: {
    id: string;
    firstName: string;
    lastName: string;
    details?: string;
    avatar: string;
  }
  xhqUser: IXHQMember;
  selectedChannel?: IChannel;
  isChannelOwner?: boolean;
  isChannelModerator?: boolean;
  isThreadCreator?: boolean;
  isAnnouncement: boolean;
  showAudienceList: boolean;
  targets?: INotificationTarget[];
  title: string;
  content: string;
  timestamp: string;
  editedMessage?: string;
  attachments?: IAttachment[];
  isFavourite?: boolean;
  isImportant?: boolean;
  isUnread?: boolean;
  isScheduled?: boolean;
  onBackClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMarkThreadReadClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFavouritesClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onUpdateClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onForwardClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onDeleteThread?: (event: React.MouseEvent<HTMLElement>) => void;
}

const renderAudiencesList = (targets?: INotificationTarget[]) => {
  if (!targets || isEmpty(targets)) {
    return;
  }
  return (
    <AudiencesWrapper>
      {targets
        .filter(
          ({ audience, role, fromCSV }) =>
            isBoolean(fromCSV) || (!isEmpty(audience) && !isEmpty(role))
        )
        .map(
          ({ audience, role, fromCSV }: INotificationTarget, index: number) => (
            <StyledChip key={index}>
              {fromCSV ? (
                <div>
                  <div>Custom</div>
                  <ChipRole>From CSV</ChipRole>
                </div>
              ) : (
                <div>
                  <div>{audience.name}</div>
                  <ChipRole>{role.toUpperCase()}</ChipRole>
                </div>
              )}
            </StyledChip>
          )
        )}
    </AudiencesWrapper>
  );
};

const renderTimestampContent = (props: IProps) => {
  const { timestamp, isScheduled, theme } = props;
  return isScheduled ? (
    <TimeStampContent>
      <Icon name={CLOCK} color={theme.colors.secondary.semilight} />
      Scheduled: {timestamp}
    </TimeStampContent>
  ) : (
    <div>{timestamp}</div>
  );
};

const Thread = (props: IProps) => {
  const {
    title,
    content,
    attachments,
    isUnread,
    isFavourite,
    isImportant,
    onBackClick,
    onUpdateClick,
    onForwardClick,
    editedMessage,
    onMarkThreadReadClick,
    onFavouritesClick,
    onDeleteThread,
    isChannelOwner,
    isChannelModerator,
    isThreadCreator,
    xhqUser,
    author,
    selectedChannel,
    isAnnouncement,
    showAudienceList,
    targets
  } = props;

  const { firstName, lastName, avatar } = author;

  const shouldRenderAudiences =
    isAnnouncement && isThreadCreator && showAudienceList;

  const timestampContent = renderTimestampContent(props);
  return (
    <Wrapper>
      <ThreadHeader
        xhqUser={xhqUser}
        selectedChannel={selectedChannel}
        isChannelOwner={isChannelOwner}
        isChannelModerator={isChannelModerator}
        isThreadCreator={isThreadCreator}
        isAnnouncement={isAnnouncement}
        isUnread={isUnread}
        isFavourite={isFavourite}
        isImportant={isImportant}
        onBackClick={onBackClick}
        onUpdateClick={onUpdateClick}
        onForwardClick={onForwardClick}
        onMarkThreadReadClick={onMarkThreadReadClick}
        onFavouritesClick={onFavouritesClick}
        onDeleteThread={onDeleteThread}
      />
      {shouldRenderAudiences && (
        <DesktopAudiencesWrapper>
          {renderAudiencesList(targets)}
        </DesktopAudiencesWrapper>
      )}
      <Title>{title}</Title>
      <ThreadInfoWrapper>
        <Member
          vertically={true}
          firstName={firstName}
          lastName={lastName}
          details=""
          avatar={avatar}
          size={MEMBER_COMPONENT_SIZES.TINY}
        />
        <Timestamp>{timestampContent}</Timestamp>
      </ThreadInfoWrapper>
      {shouldRenderAudiences && (
        <StyledScrollableWrapper>
          {renderAudiencesList(targets)}
        </StyledScrollableWrapper>
      )}
      <Content dangerouslySetInnerHTML={{ __html: xss(content) }} />
      <DesktopTimestamp>{timestampContent}</DesktopTimestamp>
      <EditedMessage>{editedMessage}</EditedMessage>
      {attachments && !!attachments.length && (
        <AttachmentWrapper inChips={true} attachments={attachments} />
      )}
    </Wrapper>
  );
};

export default withTheme(Thread);
