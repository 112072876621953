import * as React from "react";
import styled from "styled-components";
import { get } from "lodash";

import Avatar from "../Avatar";
import DefaultAvatar from "../../assets/avatar.svg";
import { MESSAGES_LABEL_KEYS } from "../../constants/community";
import { getLabelByKey } from "../../utils/helpers";

const Wrapper = styled.div`
  display: flex;
  font-family: ${props => props.theme.fonts.main};
`;

const Header = styled.div`
  display: flex;
`;

const Member = styled.div`
  color: ${props => props.theme.colors.secondary.main};
  font-weight: bold;
`;

const MemberDetails = styled.div`
  color: ${props => props.theme.colors.secondary.semilight};
  flex: 1;
  font-style: italic;
  margin: 0 0.5rem;
`;

const Text = styled.div`
  color: ${props => props.theme.colors.secondary.main};
  line-height: 1.4rem;
  margin: 0.8rem 0;
  padding-right: 0.8rem;
  white-space: pre-wrap;
`;

const CommentDetails = styled.div`
  padding: 0.6rem 0 0 0.5rem;
  flex: 1;
`;

const Comment = (props: IComment) => {
  const { createdBy, body, labels } = props;

  const { firstName = "", lastName = "", avatar = null } = createdBy || {};
  const label = getLabelByKey(MESSAGES_LABEL_KEYS.ROLE, labels);
  const details = get(label, "value");

  return (
    <Wrapper>
      <Avatar src={avatar || DefaultAvatar} />
      <CommentDetails>
        <Header>
          <Member>{`${firstName} ${lastName}`}</Member>
          <MemberDetails>{details}</MemberDetails>
        </Header>
        <Text>{body}</Text>
      </CommentDetails>
    </Wrapper>
  );
};

export default Comment;
