import { combineReducers } from "redux";
import { orderBy } from "lodash";

import {
  FETCH_ANNOUNCEMENTS,
  POST_ANNOUNCEMENT,
  CLEAR_ANNOUNCEMENTS
} from "../../constants/actions/announcements";
import { NOTIFICATION_CREATED } from "../../constants/actions/notifications";
import {
  UPDATE_THREAD,
  UPDATE_THREAD_AS_READ,
  DELETE_THREAD
} from "../../constants/actions/threads";
import { NOTIFABLE_STATUS } from "../../constants";

type payloads =
  | INotificationObject
  | IThread
  | INotifiableObject
  | IMarkAsReadPayload
  | INotifiable
  | ISearchable
  | boolean
  | string
  | null
  | void;
type ActionTypes = IActionType<string, payloads>;

const initialState: IAsyncEntityState<INotificationObject> = {
  data: {
    items: [],
    nextPage: null,
    hasBeenFetched: false,
    searchPhrase: "",
    withFreshList: false,
    hasBeenSubscribed: false
  },
  isFetching: null,
  error: null
};

const updateAnnouncement = (
  notificationChunk: IThread | IMarkAsReadPayload,
  state: IAsyncEntityState<INotificationObject>
) => {
  return state.data.items.map((item: INotification) => {
    if (item.object && item.object.id === notificationChunk.id) {
      return {
        ...item,
        object: {
          ...item.object,
          ...notificationChunk
        }
      };
    }
    return item;
  });
};

const addAnnouncement = (
  announcement: INotification,
  state: IAsyncEntityState<INotificationObject>,
  filter: INBOX | SENT | SCHEDULED
): IAsyncEntityState<INotificationObject> => {
  if (
    !announcement ||
    (filter === "scheduled" && !announcement.object!.scheduledAt) ||
    (filter !== "scheduled" && !announcement.object!.sentAt)
  ) {
    return {
      ...state,
      isFetching: false,
      error: null
    };
  }

  const hasItem = state.data.items
    .filter((item: INotification) => item && item.object)
    .some((item: INotification) => item.object!.id === announcement.object!.id);

  const announcements =
    filter === "scheduled"
      ? orderBy(
          [announcement, ...state.data.items],
          [announcement => announcement.object!.scheduledAt],
          ["asc"]
        )
      : [announcement, ...state.data.items];

  return {
    ...state,
    data: {
      ...state.data,
      items: hasItem ? state.data.items : announcements
    },
    isFetching: false,
    error: null
  };
};

const announcements = (filter: INBOX | SENT | SCHEDULED) => (
  state: IAsyncEntityState<
    INotificationObject
  > = initialState as IAsyncEntityState<INotificationObject>,
  action: ActionTypes
) => {
  const upperFilter = filter.toUpperCase();

  switch (action.type) {
    case `${FETCH_ANNOUNCEMENTS}_${upperFilter}_SUCCESS`:
      const payload = action.payload as INotificationObject;
      return {
        data: {
          ...state.data,
          ...payload,
          items: payload.withFreshList
            ? [...payload.items]
            : [...state.data.items, ...payload.items],
          hasBeenFetched: true
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    case `${FETCH_ANNOUNCEMENTS}_${upperFilter}_LOADING`:
      return {
        ...state,
        isFetching: action.payload as boolean,
        error: null
      };
    case `${FETCH_ANNOUNCEMENTS}_${upperFilter}_ERROR`:
      return {
        ...state,
        isFetching: false,
        error: action.payload as string | null
      };
    case CLEAR_ANNOUNCEMENTS:
      if (filter === action.payload) {
        return {
          ...state,
          data: {
            ...state.data,
            items: [],
            nextPage: null,
            hasBeenFetched: false,
            withFreshList: false
          }
        };
      }
      return {
        ...state
      };
    case `SET_${upperFilter}_ANNOUNCEMENT_SEARCH_PHRASE`:
      return {
        ...state,
        data: {
          ...state.data,
          searchPhrase: action.payload as string
        }
      };
    case `${POST_ANNOUNCEMENT}_SUCCESS`:
      if (filter === "inbox") {
        return {
          ...state,
          isFetching: false,
          error: null
        };
      }
      return {
        ...addAnnouncement(
          {
            id: (action.payload as INotifiable).id,
            status: NOTIFABLE_STATUS.ANNOUNCEMENT,
            object: { ...(action.payload as INotifiable) }
          } as INotification,
          state,
          filter
        )
      };
    case `${NOTIFICATION_CREATED}_ANNOUNCEMENT_SUCCESS`:
      if (upperFilter !== "INBOX") {
        return { ...state };
      }

      return {
        ...addAnnouncement(action.payload as ISearchable, state, filter)
      };
    case `${DELETE_THREAD}_SUCCESS`:
      return {
        data: {
          ...state.data,
          items: [
            ...state.data.items.filter((announcement: INotification) => {
              return announcement.object!.id !== (action.payload as IThread).id;
            })
          ]
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    case `${UPDATE_THREAD}_SUCCESS`:
    case `${UPDATE_THREAD_AS_READ}_SUCCESS`:
      return {
        data: {
          ...state.data,
          items: updateAnnouncement(
            action.payload as IThread | IMarkAsReadPayload,
            state
          )
        } as INotificationObject,
        isFetching: false,
        error: null
      };
    default:
      return state;
  }
};

export default combineReducers({
  inbox: announcements("inbox"),
  sent: announcements("sent"),
  scheduled: announcements("scheduled")
});
