import styled from "styled-components";

import media from "../../lib/media";
import Icon from "../Icon";

export const TopWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const MobileMenuButton = styled.div`
  position: relative;
  left: 0;

  ${media.greaterThan("small")`
    left: 0.9375rem;
    top: 0.3175rem;
  `};

  ${media.greaterThan("medium")`
    display: none;
  `};
`;

export const Title = styled.div`
  flex: 1;
  color: ${props => props.theme.colors.secondary.main};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 1.5rem;
  margin: 0 2.5rem 0 0;
  text-align: center;
  word-break: break-word;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "text")};

  ${media.greaterThan("medium")`
    text-align: left;
    width: auto;
    margin: 0;
  `};
`;

export const ThreadSettingsButton = styled.div`
  cursor: pointer;

  ${media.between("small", "large")`
    position: relative;
    right: 0.9375rem;
    top: 0.3175rem;
  `};
`;

export const StyledSearchIcon = styled(Icon)`
  cursor: pointer;
  opacity: 0.5;
`;

export const Italic = styled.span`
  font-style: italic;
`;
