import * as React from "react";
import { isEmpty, upperFirst, isBoolean, get } from "lodash";

import { TEXTFIELD_VARIANTS } from "../../constants/textField";
import { CANCEL, POST } from "../../constants/threads";
import Button from "../../ui/Button";
import { BUTTON_VARIANTS } from "../../constants/button";

import ErrorMessage from "../../ui/ErrorMessage";
import * as S from "./styles";
import ToggleSwitch from "../../ui/ToggleSwitch";
import {
  EDITOR_VIEW_MODE,
  MOBILE_COMMUNITY_SCREENS
} from "../../constants/community";
import AudienceSelector from "../../ui/AudienceSelector";
import { XHQ_CHANNEL_MEMBER_ROLES } from "../../constants";
import CommunityEditorLayout from "../../ui/CommunityEditorLayout";
import {
  NAME_INPUT_LABEL,
  NAME_INPUT_MAX_LENGTH,
  NAME_INPUT_PLACEHOLDER,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  DESCRIPTION_BOTTOM_LABEL,
  DESCRIPTION_MAX_LENGTH,
  REPLIES_TOGGLE_LABEL,
  REPLIES_TOGGLE_DESCRIPTION
} from "../../constants/channelEditor";

type TProps = IChannelEditorStateProps &
  IChannelEditorActions &
  IChannelEditorOwnProps;

interface IState {
  name: string;
  description: string;
  isRepliesChecked: boolean;
  formHasChanged: boolean;
  audienceHasChanged: boolean;
  selectedAudiences: IAudienceSelection[];
  selectedMembers: IXHQSearchMemberOptionType[];
}

class ChannelEditor extends React.PureComponent<TProps, IState> {
  constructor(props: TProps) {
    super(props);

    this.state = this.getInitialStateValues();
  }

  componentDidUpdate(prevProps: TProps) {
    if (
      prevProps.editorViewMode !== this.props.editorViewMode ||
      (prevProps.channelState.isFetching && !this.props.channelState.isFetching)
    ) {
      this.setState(this.getInitialStateValues());
    }
  }

  getInitialStateValues = () => {
    const {
      selectedChannel,
      actions,
      channelState,
      individualMembers,
      channelAudiences
    } = this.props;
    const isEditing = this.isEditingChannel;

    if (
      selectedChannel &&
      selectedChannel.type &&
      isEditing &&
      !isBoolean(channelState.isFetching)
    ) {
      actions.fetchChannel(selectedChannel.name, selectedChannel.type);
    }

    const editingChannel = get(channelState, "data");

    const initialState = {
      name: "",
      description: "",
      isRepliesChecked: false,
      selectedAudiences: [],
      selectedMembers: [],
      formHasChanged: false,
      audienceHasChanged: false
    };

    if (isEditing && editingChannel) {
      return {
        ...initialState,
        name: editingChannel.title || editingChannel.name,
        description: editingChannel.description || "",
        isRepliesChecked: Boolean(editingChannel.allowThreadReplies),
        selectedAudiences: channelAudiences,
        selectedMembers: individualMembers
      };
    }

    return initialState;
  };

  get isEditingChannel() {
    const { selectedChannel, editorViewMode } = this.props;
    return (
      editorViewMode === EDITOR_VIEW_MODE.UPDATE_CHANNEL &&
      !isEmpty(selectedChannel)
    );
  }

  handleNameChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({ name: event.target.value, formHasChanged: true });
  };

  handleDescriptionChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({ description: event.target.value, formHasChanged: true });
  };

  handleRepliesToggle = (isRepliesChecked: boolean) => {
    this.setState({ isRepliesChecked, formHasChanged: true });
  };

  handleCancelClick = () => {
    const { actions } = this.props;
    actions.setEditorViewMode(EDITOR_VIEW_MODE.READ_THREAD);
    actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.FILTERS);
  };

  handlePostClick = () => {
    const { name, description, isRepliesChecked } = this.state;
    const { xhqUser, actions } = this.props;

    const channelToCreate: ICreateChannelProps = {
      name,
      title: name,
      description,
      allowThreadReplies: isRepliesChecked,
      ownerId: xhqUser!.id
    };

    actions.postChannelWithMembersAndAudiences(
      channelToCreate,
      this.getMembersToAdd(),
      this.getMappedAudiences(),
      this.handleChannelSubmitted
    );
  };

  handleUpdateClick = () => {
    const { actions, channelState } = this.props;
    const {
      name,
      description,
      isRepliesChecked,
      audienceHasChanged
    } = this.state;

    if (channelState && channelState.data) {
      const editingChannel = channelState.data;
      this.setState({ formHasChanged: false }, () => {
        const channelToUpdate = {
          id: editingChannel.id,
          title: name,
          description,
          type: editingChannel.type!,
          allowThreadReplies: isRepliesChecked
        };

        if (!audienceHasChanged) {
          actions.updateChannelWithCallback(
            channelToUpdate,
            this.handleChannelSubmitted
          );
        } else {
          actions.updateChannelWithMembersAndAudiences(
            channelToUpdate,
            this.getMembersToAdd(),
            this.getMappedAudiences(),
            this.handleChannelSubmitted
          );
        }
      });
    }
  };

  getMembersToAdd = () => {
    const { selectedMembers } = this.state;
    return selectedMembers.map(member => ({
      memberId: member.value.id,
      role: XHQ_CHANNEL_MEMBER_ROLES.GENERAL
    }));
  };

  getMappedAudiences = () => {
    const { selectedAudiences } = this.state;
    return selectedAudiences.map(audience => ({
      audienceId: audience.audienceId,
      audienceContext: upperFirst(audience.role.toLowerCase())
    }));
  };

  handleChannelSubmitted = () => {
    const { actions } = this.props;
    actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.FILTERS);
    actions.setEditorViewMode(EDITOR_VIEW_MODE.READ_THREAD);
  };

  get isButtonEnabled() {
    const { isFetchingCreatedChannel } = this.props;
    const {
      name,
      description,
      selectedAudiences,
      selectedMembers
    } = this.state;
    if (isFetchingCreatedChannel) {
      return false;
    }
    return (
      !isEmpty(name) &&
      !isEmpty(description) &&
      (!isEmpty(selectedAudiences) || !isEmpty(selectedMembers))
    );
  }

  get isSaveEnabled() {
    const { updatingChannelState, isFetchingUpdatedChannel } = this.props;
    const {
      name,
      description,
      formHasChanged,
      selectedAudiences,
      selectedMembers
    } = this.state;
    if (updatingChannelState.isFetching || isFetchingUpdatedChannel) {
      return false;
    }
    return (
      !isEmpty(name) &&
      !isEmpty(description) &&
      formHasChanged &&
      (!isEmpty(selectedAudiences) || !isEmpty(selectedMembers))
    );
  }

  handleAudiencesSelectionChange = ({
    selectedAudiences,
    selectedMembers
  }: IAudienceSelectorValue) => {
    this.setState({
      selectedMembers,
      selectedAudiences,
      formHasChanged: true,
      audienceHasChanged: true
    });
  };

  renderActionsHeader = () => {
    const {
      updatingChannelState,
      isFetchingUpdatedChannel,
      isFetchingCreatedChannel
    } = this.props;
    const isEditing = this.isEditingChannel;
    return (
      <>
        <S.TopActionButton role="button" onClick={this.handleCancelClick}>
          {CANCEL}
        </S.TopActionButton>
        <S.HeaderLabel>Create Channel</S.HeaderLabel>
        <S.TopPostActionButton
          role="button"
          onClick={isEditing ? this.handleUpdateClick : this.handlePostClick}
          disabled={isEditing ? !this.isSaveEnabled : !this.isButtonEnabled}
        >
          {updatingChannelState.isFetching ||
          isFetchingUpdatedChannel ||
          isFetchingCreatedChannel
            ? "Saving"
            : POST}
        </S.TopPostActionButton>
      </>
    );
  };

  renderTopWrapper = () => {
    const {
      xhqUser,
      actions,
      audiencesState,
      audiencesOptions,
      xhqUserOptionsResult,
      isFetchingXhqUserOptions
    } = this.props;
    const { selectedAudiences, selectedMembers } = this.state;

    return (
      <AudienceSelector
        title="members"
        fetchAudiencesSearch={actions.fetchAudiencesSearch}
        removeAudiencesDataFromState={actions.removeAudiencesDataFromState}
        audiencesState={audiencesState}
        audiencesOptions={audiencesOptions}
        removeXhqUsers={actions.removeXhqUsers}
        fetchXHQSearchMembers={actions.fetchXHQSearchMembers}
        xhqUserOptionsResult={xhqUserOptionsResult}
        isFetchingXhqUserOptions={isFetchingXhqUserOptions}
        selectedAudiences={selectedAudiences}
        selectedMembers={selectedMembers}
        onAudiencesSelectionChange={this.handleAudiencesSelectionChange}
        showMembersSelector={true}
        showGroupSelector={true}
        filterUserIds={[xhqUser!.id]}
      />
    );
  };

  renderMiddleWrapper = () => {
    const { name, description, isRepliesChecked } = this.state;
    const {
      errorAddingChannelWithMembersAndAudience,
      errorUpdatingChannelWithMembersAndAudience,
      updatingChannelState
    } = this.props;

    const errorMessage =
      errorAddingChannelWithMembersAndAudience ||
      errorUpdatingChannelWithMembersAndAudience ||
      updatingChannelState.error;
    return (
      <>
        <S.StyledTextField
          bottomLabel={NAME_INPUT_LABEL}
          variant={TEXTFIELD_VARIANTS.LARGE}
          placeholder={NAME_INPUT_PLACEHOLDER}
          value={name}
          onChange={this.handleNameChange}
          maxLength={NAME_INPUT_MAX_LENGTH}
        />
        <S.StyledBodyTextField
          label={DESCRIPTION_LABEL}
          bottomLabel={DESCRIPTION_BOTTOM_LABEL}
          placeholder={DESCRIPTION_PLACEHOLDER}
          value={description}
          onChange={this.handleDescriptionChange}
          maxLength={DESCRIPTION_MAX_LENGTH}
        />
        <S.BelowBodyActionsWrapper>
          <S.Label>REPLIES</S.Label>
          <ToggleSwitch
            label={REPLIES_TOGGLE_LABEL}
            description={REPLIES_TOGGLE_DESCRIPTION}
            value={isRepliesChecked}
            onCheckedChange={this.handleRepliesToggle}
          />
        </S.BelowBodyActionsWrapper>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    );
  };

  renderBottomWrapper = () => {
    const isEditing = this.isEditingChannel;
    return (
      <>
        <S.MarginedButton
          outlined={true}
          variant={BUTTON_VARIANTS.ROUNDED}
          onClick={this.handleCancelClick}
        >
          CANCEL
        </S.MarginedButton>
        <Button
          variant={BUTTON_VARIANTS.ROUNDED}
          disabled={isEditing ? !this.isSaveEnabled : !this.isButtonEnabled}
          onClick={isEditing ? this.handleUpdateClick : this.handlePostClick}
        >
          {this.getSubmitButtonLabel()}
        </Button>
      </>
    );
  };

  getSubmitButtonLabel = () => {
    const {
      updatingChannelState,
      isFetchingCreatedChannel,
      isFetchingUpdatedChannel
    } = this.props;
    if (
      updatingChannelState.isFetching ||
      isFetchingCreatedChannel ||
      isFetchingUpdatedChannel
    ) {
      return "SAVING...";
    }

    return this.isEditingChannel ? "SAVE CHANNEL" : "CREATE CHANNEL";
  };

  render() {
    const { channelState } = this.props;

    if (channelState.isFetching) {
      return <S.StyledLoadingPanel />;
    }

    const actionHeader = this.renderActionsHeader();
    const topWrapper = this.renderTopWrapper();
    const middleWrapper = this.renderMiddleWrapper();
    const bottomWrapper = this.renderBottomWrapper();

    return (
      <CommunityEditorLayout
        actionsHeaderContent={actionHeader}
        topWrapperContent={topWrapper}
        middleWrapperContent={middleWrapper}
        bottomWrapperContent={bottomWrapper}
      />
    );
  }
}

export default ChannelEditor;
