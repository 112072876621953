import {
  FETCH_CHANNEL,
  REMOVE_CHANNEL_DATA
} from "../../constants/actions/channels";

const FETCH_CHANNEL_SUCCESS = `${FETCH_CHANNEL}_SUCCESS`;
const FETCH_CHANNEL_LOADING = `${FETCH_CHANNEL}_LOADING`;
const FETCH_CHANNEL_ERROR = `${FETCH_CHANNEL}_ERROR`;

type ActionTypes =
  | IActionType<typeof FETCH_CHANNEL_SUCCESS, IChannel | null>
  | IActionType<typeof FETCH_CHANNEL_LOADING, boolean>
  | IActionType<typeof FETCH_CHANNEL_ERROR, string | null>;

const initialState: IAsyncEntityState<TChannel> = {
  data: null,
  isFetching: null,
  error: null
};

const channel = (
  state: IAsyncEntityState<TChannel> = initialState,
  action: ActionTypes
): IAsyncEntityState<TChannel> => {
  switch (action.type) {
    case FETCH_CHANNEL_SUCCESS:
      return {
        ...state,
        data: action.payload as IChannel,
        isFetching: false,
        error: null
      };
    case FETCH_CHANNEL_LOADING:
      return {
        ...state,
        data: null,
        isFetching: true,
        error: null
      };
    case FETCH_CHANNEL_ERROR:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.payload as string
      };
    case REMOVE_CHANNEL_DATA:
      return {
        ...state,
        data: null,
        isFetching: null,
        error: null
      };
    default:
      return state;
  }
};

export default channel;
