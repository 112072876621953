import styled from "styled-components";

import media from "../../lib/media";
import ScrollableColumn from "../../ui/ScrollableColumn";
import EmptyScreenPlaceholder from "../../ui/EmptyScreenPlaceholder/EmptyScreenPlaceholder";
import ThreadListHeader from "../../ui/ThreadListHeader/ThreadListHeader";

interface IStyledColumn {
  isButtonHidden: boolean;
}

export const Wrapper = styled.div`
  width: 100%;

  ${media.greaterThan("medium")`
    width: 17rem;
  `};

  ${media.greaterThan("large")`
    width: 20.5rem;
  `};
`;

export const StyledEmptyScreenPlaceholder = styled(EmptyScreenPlaceholder)`
  ${media.greaterThan("medium")`
    margin: 0 2rem;
  `};
`;

export const StyledThreadListHeader = styled(ThreadListHeader)`
  border: 0;

  ${media.greaterThan("medium")`
    border-right: 0.08rem solid ${({ theme }: any) =>
      theme.colors.tertiary.main}66;
  `};
`;

export const ThreadListWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledScrollableColumn = styled(ScrollableColumn)<IStyledColumn>`
  width: 100%;

  ${media.lessThan("medium")`
    ${({ isButtonHidden }: IStyledColumn) => `
        height: calc(100% - ${isButtonHidden ? "5" : "8"}rem) !important;
    `};
  `};
`;
