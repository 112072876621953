export const FETCH_PUBLIC_CHANNELS = "FETCH_PUBLIC_CHANNELS";
export const POST_CHANNEL = "POST_CHANNEL";
export const UPDATE_CHANNEL = "UPDATE_CHANNEL";
export const UPDATE_ARCHIVE_CHANNEL = "UPDATE_ARCHIVE_CHANNEL";
export const POST_CHANNEL_MEMBERS = "POST_CHANNEL_MEMBERS";
export const SET_DEFAULT_SELECTED_CHANNEL_STATE =
  "SET_DEFAULT_SELECTED_CHANNEL_STATE";
export const SET_DEFAULT_STATE_CHANNEL_MEMBERS =
  "SET_DEFAULT_STATE_CHANNEL_MEMBERS";
export const SET_DEFAULT_ARCHIVED_CHANNEL_STATE =
  "SET_DEFAULT_ARCHIVE_CHANNEL_STATE";
export const SET_DEFAULT_STATE_POST_CHANNEL = "SET_DEFAULT_STATE_POST_CHANNEL";
export const POST_CHANNEL_AUDIENCE = "POST_CHANNEL_AUDIENCE";
export const POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE =
  "POST_CHANNEL_WITH_MEMBERS_AND_AUDIENCE";
export const UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE =
  "UPDATE_CHANNEL_WITH_MEMBERS_AND_AUDIENCE";
export const DELETE_ALL_CHANNEL_MEMBERS = "DELETE_ALL_CHANNEL_MEMBERS";
export const FETCH_CHANNEL = "FETCH_CHANNEL";
export const REMOVE_CHANNEL_DATA = "REMOVE_CHANNEL_DATA";
