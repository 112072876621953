import * as React from "react";
import { get, uniqBy } from "lodash";
import { UnregisterCallback } from "history";
import { isMobile } from "react-device-detect";
import { Feature } from "@paralleldrive/react-feature-toggles";
import { isActiveFeatureName } from "@paralleldrive/feature-toggles";

import { IOwnProps } from "./";

import Threads from "../Threads";
import CreateChannelModal from "../../ui/CreateChannelModal";
import {
  MOBILE_COMMUNITY_SCREENS,
  IS_CHANNEL_OR_FILTER_ROUTE_REGEX,
  XHQ_ROLE_CAPABILITIES,
  EDITOR_VIEW_MODE
} from "../../constants/community";
import withXhqMemberUpdateSubscription from "../../hocs/withXhqMemberUpdateSubscription";
import { FILTERS, MESSAGE_XHQ_CHANNELS_NOT_FOUND } from "../../constants";
import {
  getLocalStorageXhqUser,
  saveLocalStorageXhqUserChannels,
  saveLocalStorageXhqUserFilters
} from "../../utils/localStorageHelpers";
import {
  formatChannels,
  mergeChannels,
  containsXhqCapability,
  getChannelNameFromId,
  isAnnouncementFilter
} from "../../utils/helpers";

import * as Icons from "../../constants/icons";
import { COMMUNITY_NEW_CHANNEL_CREATION } from "../../constants/togglableFeatures";

import * as S from "./styles";
import Alert from "../../ui/Alert";
import { ALERT_VARIANTS } from "../../constants/alert";
import EditorView from "../EditorView";
import { CREATE_CHANNEL_WITH_AUDIENCE_TOGGLE } from "../../constants/featureToggles";

interface IProps extends IOwnProps, ICommunityActions, ICommunityDataProps {
  xhqUser: IXHQMember;
}

interface IState {
  showCreateChannelModal: boolean;
  editingThread?: IThread;
}

export class Community extends React.PureComponent<IProps, IState> {
  // TODO: remove once CREATE_CHANNEL_WITH_AUDIENCE passes UAT
  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (state.showCreateChannelModal && props.addedChannelWithMembers) {
      return {
        showCreateChannelModal: false
      };
    }
    return null;
  }

  historyUnlisten?: UnregisterCallback;

  // TODO: remove once CREATE_CHANNEL_WITH_AUDIENCE passes UAT
  constructor(props: IProps) {
    super(props);

    this.state = {
      showCreateChannelModal: false
    };
  }

  async componentDidMount() {
    const {
      actions,
      history,
      publicChannelsData,
      xhqUser,
      messagesData: {
        data: { hasBeenFetched }
      },
      match: {
        params: { filter, threadId, channelName }
      }
    } = this.props;

    if (!xhqUser) {
      return;
    }

    if (threadId) {
      actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.THREAD_VIEW);
    }

    if (!publicChannelsData.data.hasBeenFetched) {
      actions.fetchPublicChannels();
    }

    if (!hasBeenFetched) {
      this.fetchNotificationsAndThreads(filter, undefined, false);
    }

    this.historyUnlisten = history.listen(this.handleHistoryListen);

    actions.fetchThreadAndUpdateAsRead(threadId, filter, channelName); // TODO: Move it to Thread
  }

  fetchNotificationsAndThreads = async (
    status: INBOX | IMPORTANT | FAVOURITES | CHANNEL | SENT | SCHEDULED,
    channelId?: string,
    withFreshList = false,
    isLoadingMore = false
  ) => {
    const { xhqUser, actions } = this.props;
    const fetchAnnouncementSuffix = status === "inbox" ? "" : "ByType";

    if (status === "inbox" || status === "sent" || status === "scheduled") {
      return actions[`fetchAnnouncements${fetchAnnouncementSuffix}`](
        xhqUser,
        status,
        withFreshList,
        isLoadingMore
      );
    }

    if (status === FILTERS.CHANNEL) {
      const channelName =
        channelId || get(this.props, "match.params.channelName");
      return actions.fetchThreads(channelName, isLoadingMore, withFreshList);
    }

    // TODO: it will only fetch favorites important will be deleted
    return actions.fetchNotifications(
      xhqUser,
      status as IMPORTANT | FAVOURITES,
      isLoadingMore
    );
  };

  // Automagically changes view to `thread column` when redirect for `create thread/announcement` happens.
  // URL is updated, hence location changes and this method is triggered. Find places where redirect should happen and trigger change there.
  // Remove this method once it's done.
  handleHistoryListen = (location: any, action: string) => {
    if (!isMobile && action !== "POP") {
      return;
    }

    if (location.pathname.match(IS_CHANNEL_OR_FILTER_ROUTE_REGEX)) {
      this.handleShowThreadsColumn();
    }
  };

  // will not be needed if work from comment for handleHistoryListen method will be done.
  componentWillUnmount() {
    if (this.historyUnlisten) {
      this.historyUnlisten();
    }
  }

  handleNewThreadClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { actions } = this.props;
    actions.removeThreadDraftFromState();
    actions.removeThreadOperationDataFromState();
    actions.setEditorViewMode(EDITOR_VIEW_MODE.CREATE_THREAD);
    actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.THREAD_VIEW);
  };

  handleThreadActionClick = (thread: IThread, editorViewMode: string) => {
    const { actions } = this.props;
    this.setState(
      () => ({
        editingThread: thread
      }),
      () => {
        actions.removeThreadDraftFromState();
        actions.setEditorViewMode(editorViewMode);
        actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.THREAD_VIEW);
      }
    );
  };

  handleThreadUpdateClick = (thread: IThread) => {
    this.handleThreadActionClick(thread, EDITOR_VIEW_MODE.UPDATE_THREAD);
  };

  handleThreadForwardClick = (thread: IThread) => {
    this.handleThreadActionClick(thread, EDITOR_VIEW_MODE.FORWARD_THREAD);
  };

  handleShowThreadsColumn = () => {
    const { actions } = this.props;
    actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.THREAD_LIST);
    actions.setEditorViewMode(EDITOR_VIEW_MODE.READ_THREAD);
  };

  handleFilterMenuClick = () => {
    this.props.actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.FILTERS);
  };

  handleCloseFilters = () => {
    this.props.actions.setMobileFocusedColumn(
      MOBILE_COMMUNITY_SCREENS.THREAD_LIST
    );
  };

  handleThreadClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    const { actions } = this.props;

    actions.setEditorViewMode(EDITOR_VIEW_MODE.READ_THREAD);
    actions.setMobileFocusedColumn(
      MOBILE_COMMUNITY_SCREENS.THREAD_VIEW,
      this.fetchThreadAndMarkAsRead(id)
    );
  };

  fetchThreadAndMarkAsRead = (threadId: string) => async () => {
    const {
      actions,
      match: {
        params: { filter }
      }
    } = this.props;
    const { id, parentId } = this.getSelectedThread(threadId)!;

    actions.fetchThreadAndUpdateAsRead(id!, filter, parentId);
  };

  handleLoadMore = async () => {
    const {
      match: {
        params: { filter }
      }
    } = this.props;

    await this.fetchNotificationsAndThreads(filter, "", false, true);

    return;
  };

  handleRefresh = async () => {
    const {
      match: {
        params: { filter, channelName }
      },
      actions
    } = this.props;

    if (filter === FILTERS.CHANNEL) {
      await actions.clearThreads(channelName);
    } else {
      await actions.clearAnnouncements(filter);
    }

    await this.fetchNotificationsAndThreads(filter, undefined, true, false);
    return;
  };

  get getSelectedChannel() {
    const {
      match: {
        params: { channelName }
      },
      allUserChannelsMap
    } = this.props;

    return allUserChannelsMap[`Channel-${channelName}`];
  }

  markChannelsUnread = (channels: IChannel[]): IChannelWithBold[] => {
    return channels.map((channel: IChannel) => {
      return {
        ...channel,
        bold: true
      };
    });
  };

  conditionallyMarkChannelRead = ({ id }: IChannel) => {
    const {
      messagesData: {
        data: { items }
      }
    } = this.props;

    const everyThreadRead = items.every((thread: IThread) => thread.read);

    return {
      id,
      bold: !everyThreadRead
    };
  };

  getSelectedThread(threadId: string) {
    return this.props.messagesData.data.items.find(
      ({ id }: IThread) => id === threadId
    );
  }

  // TODO: do it in action fetching XHQ user (with channels) and Public channels.
  // This way there will be only 1 read from LS
  get getChannelsWithBold() {
    const {
      xhqUser,
      allUserChannels,
      match: {
        params: { channelName }
      }
    } = this.props;
    const formattedChannels = formatChannels(allUserChannels, channelName);

    const channelsFromLocalStorage = getLocalStorageXhqUser(xhqUser.id).channels
      .items;

    return channelsFromLocalStorage.length
      ? mergeChannels(channelsFromLocalStorage, formattedChannels)
      : this.markChannelsUnread(formattedChannels);
  }

  // TODO: this will be redundant once channel will be marked as read and saved to LS in fetchThreadAndUpdateAsRead.
  // Dispatch action setting channel as bold in the store.
  markChannelsAsRead = (isLoadingMessages: boolean | null) => {
    const { xhqUser, allUserChannels } = this.props;

    const selectedChannel = this.getSelectedChannel;

    if (xhqUser && selectedChannel && !isLoadingMessages) {
      const visibleChannels = allUserChannels;
      const formattedChannel = this.conditionallyMarkChannelRead(
        selectedChannel
      );
      const channelsFromLocalStorage = getLocalStorageXhqUser(xhqUser.id)
        .channels.items;

      const channels = mergeChannels(
        uniqBy([formattedChannel, ...channelsFromLocalStorage], "id"),
        visibleChannels
      );
      const channelsWithBoldedValuesOnly = channels.map(
        ({ id, bold }: IChannelWithBold) => {
          return { id, bold };
        }
      );
      saveLocalStorageXhqUserChannels(xhqUser.id, channelsWithBoldedValuesOnly);
    }
  };

  // TODO: this is stateful operation - move filters to reducer and set it as bold via action
  // with the same principle as channels
  markFiltersAsRead = (isLoadingMessages: boolean | null) => {
    const { xhqUser } = this.props;

    const selectedFilter: string = get(this.props, "match.params.filter");

    if (xhqUser && selectedFilter && !isLoadingMessages) {
      const availableFilters = [
        FILTERS.INBOX,
        FILTERS.IMPORTANT,
        FILTERS.FAVOURITES
      ];
      const filtersFromLocalStorage = getLocalStorageXhqUser(xhqUser.id)
        .filters;

      const filtersToSave = availableFilters.filter(filter => {
        if (filter === selectedFilter) {
          const {
            messagesData: {
              data: { items }
            }
          } = this.props;

          const hasAllThreadsRead = items.every(
            (thread: IThread) => thread.read
          );
          return !hasAllThreadsRead;
        }
        return filtersFromLocalStorage.includes(filter);
      });

      saveLocalStorageXhqUserFilters(xhqUser.id, filtersToSave);
    }
  };

  // TODO: same principle as in case of getChannelsWithBold - except instead of fetching - get it from the store
  getBoldFilters() {
    const { xhqUser } = this.props;

    return getLocalStorageXhqUser(xhqUser.id).filters;
  }

  get renderNewChannelButton() {
    const { xhqUser } = this.props;
    if (
      COMMUNITY_NEW_CHANNEL_CREATION &&
      containsXhqCapability(xhqUser, XHQ_ROLE_CAPABILITIES.ADD_CHANNEL)
    ) {
      return (
        <Feature>
          {({ features }) => (
            <S.StyledActionButton
              iconName={Icons.ADD}
              onClick={this.handleNewChannelButtonClick(features)}
            >
              New Channel
            </S.StyledActionButton>
          )}
        </Feature>
      );
    }
    return undefined;
  }

  handleNewChannelButtonClick = (features: ReadonlyArray<string>) => (
    event: React.MouseEvent<HTMLElement>
  ) => {
    const { actions } = this.props;

    actions.resetChannelCreation();

    if (isActiveFeatureName(CREATE_CHANNEL_WITH_AUDIENCE_TOGGLE, features)) {
      actions.setMobileFocusedColumn(MOBILE_COMMUNITY_SCREENS.THREAD_VIEW);
      actions.setEditorViewMode(EDITOR_VIEW_MODE.CREATE_CHANNEL);
    } else {
      this.setState(() => ({
        showCreateChannelModal: true
      }));
    }
  };

  handleCloseCreateChannelModal = () => {
    this.setState(() => ({
      showCreateChannelModal: false
    }));
  };

  handleSwitchThreadsColumn = async (navItem: {
    route: INBOX | IMPORTANT | FAVOURITES | CHANNEL | SENT | SCHEDULED;
    id?: string;
  }) => {
    const {
      actions,
      match: {
        params: { filter, channelName }
      }
    } = this.props;
    const channel = navItem.id && getChannelNameFromId(navItem.id);

    if (filter === FILTERS.CHANNEL && channel === channelName) {
      return;
    }
    if (filter !== FILTERS.CHANNEL && filter === navItem.route) {
      return;
    }

    this.handleShowThreadsColumn();

    actions.removeThreadDataFromState();

    await this.fetchNotificationsAndThreads(navItem.route, channel, true);
  };

  get renderCreateChannel() {
    const {
      xhqUser,
      actions,
      xhqUserOptionsResult,
      isFetchingXhqUserOptions,
      errorAddingChannel
    } = this.props;
    return (
      <CreateChannelModal
        isFetchingXhqUserOptions={isFetchingXhqUserOptions}
        error={errorAddingChannel}
        xhqUser={xhqUser}
        onCloseClick={this.handleCloseCreateChannelModal}
        fetchXHQSearchMembers={actions.fetchXHQSearchMembers}
        onCreateChannel={actions.postChannel}
        removeXhqUsers={actions.removeXhqUsers}
        xhqUserOptionsResult={xhqUserOptionsResult}
      />
    );
  }

  get threadsWithChannels() {
    const {
      allUserChannelsMap,
      messagesData: {
        data: { items }
      }
    } = this.props;
    return items.map(item => ({
      ...item,
      channel: allUserChannelsMap[item.parentId]
    }));
  }

  render() {
    const { xhqUser, xhqUserError, isXhqUserFetching } = this.props;

    if (isXhqUserFetching) {
      return <S.StyledLoadingPanel />;
    }

    if (!xhqUser || xhqUserError) {
      return (
        <Alert
          message={MESSAGE_XHQ_CHANNELS_NOT_FOUND}
          variant={ALERT_VARIANTS.DANGER}
        />
      );
    }

    const {
      publicChannelsData: {
        isFetching: isFetchingPublicChannels,
        error: errorPublicChannels
      },
      focusedColumnView,
      editorViewMode,
      viewAccessByChannel,
      messagesData: { isFetching: isLoadingMessages },
      match: {
        params: { filter, channelName }
      },
      selectedThread
    } = this.props;

    const { showCreateChannelModal } = this.state;
    const selectedChannel = this.getSelectedChannel;

    this.markChannelsAsRead(isLoadingMessages);
    this.markFiltersAsRead(isLoadingMessages);

    return (
      <S.Wrapper>
        <S.ContentWrapper>
          <S.StyledFilters
            xhqUser={xhqUser}
            filter={filter}
            boldFilters={this.getBoldFilters()}
            selectable={true}
            isHeaderDisabled={false}
            channels={this.getChannelsWithBold}
            isLoadingChannels={isFetchingPublicChannels}
            errorLoadingChannels={errorPublicChannels}
            show={focusedColumnView === MOBILE_COMMUNITY_SCREENS.FILTERS}
            onCloseFilters={this.handleCloseFilters}
            onItemClick={this.handleSwitchThreadsColumn}
            actionButton={this.renderNewChannelButton}
            onFilterItemClick={this.handleSwitchThreadsColumn}
          />
          <Threads
            filter={filter}
            xhqUser={xhqUser}
            selectedThreadId={get(selectedThread, "id")}
            selectedChannel={selectedChannel}
            channelName={channelName}
            hasDraft={editorViewMode === EDITOR_VIEW_MODE.CREATE_THREAD}
            onNewThreadClick={this.handleNewThreadClick}
            threads={this.threadsWithChannels}
            isLoadingThreads={isLoadingMessages || isLoadingMessages === null}
            onFilterMenuClick={this.handleFilterMenuClick}
            onThreadClick={this.handleThreadClick}
            onLoadMoreThreads={this.handleLoadMore}
            onHandleRefresh={this.handleRefresh}
            viewAccessByChannel={viewAccessByChannel}
            isLoadingMoreThreads={
              isLoadingMessages || isLoadingMessages === null
            }
            isAnnouncement={isAnnouncementFilter(filter)}
          />
          <S.StyledViewer
            show={focusedColumnView === MOBILE_COMMUNITY_SCREENS.THREAD_VIEW}
          >
            <EditorView
              editingThread={this.state.editingThread}
              selectedChannel={this.getSelectedChannel}
              onThreadUpdateClick={this.handleThreadUpdateClick}
              onThreadForwardClick={this.handleThreadForwardClick}
            />
          </S.StyledViewer>
        </S.ContentWrapper>
        {showCreateChannelModal && this.renderCreateChannel}
      </S.Wrapper>
    );
  }
}

export default withXhqMemberUpdateSubscription(Community);
