import * as React from "react";
import styled, { withTheme } from "styled-components";
import { truncate } from "lodash";
import moment from "moment";

import media from "../../lib/media";
import * as Icons from "../../constants/icons";
import Badge from "../Badge";
import Avatar from "../Avatar/Avatar";
import EventIcon from "../EventIcon";
import Icon from "../Icon/Icon";
import { faces } from "../../styles";
import {
  THREAD_ITEM_TITLE_MAX_LENGTH,
  THREAD_ITEM_CONTENT_MAX_LENGTH,
  TIME_FORMAT_THREAD_LIST,
  NOTIFABLE_STATUS
} from "../../constants";
import { XHQ_ID_PREFIXES } from "../../constants/community";
import { removeHTMLTags } from "../../utils/helpers";

interface IProps {
  badge?: string;
  body?: string;
  parentId: string;
  highlighted?: boolean;
  title?: string;
  avatar?: string;
  hasAttachment?: boolean;
  type?: string;
  read?: TStringOrNull;
  theme: any;
  isDraft?: boolean;
  sentAt?: TStringOrNull;
  scheduledAt?: TStringOrNull;
  createdAt?: TStringOrNull;
  createdBy?: IXHQMember;
  channel?: IChannel;
}

interface ITitle {
  isRead: boolean;
}

interface IDescription {
  isRead: boolean;
}

const Root = styled.div`
  ${faces.normal};
  align-items: flex-start;
  display: flex;
  width: 100%;
  padding: 2rem 1.1rem 1.1rem 1.1rem;
  box-sizing: border-box;

  ${(props: any) =>
    props.highlighted
      ? `
    background: linear-gradient(270deg, ${props.theme.colors.info.light}99 0%, #FFFFFF 100%);
  `
      : ""};

  ${media.greaterThan("medium")`
    padding: 1rem 0.8rem 1rem 0.8rem;
  `};

  ${media.greaterThan("large")`
    padding: 1.3rem 1.3rem 0.7rem;
  `};
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 1rem;
  margin-top: 0.5rem;
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 1rem;
  margin-bottom: 0.56rem;
  width: 100%;
`;

const Title = styled.div<ITitle>`
  color: ${({ theme }) => theme.colors.secondary.main};
  font-size: .87rem
  flex: 1;
  font-weight: ${({ isRead }) => (isRead ? "normal" : "bold")}
`;

const Description = styled.div<IDescription>`
  color: ${({ theme, isRead }) =>
    isRead ? theme.colors.secondary.semilight : theme.colors.secondary.main};
  font-size: .87rem
  line-height: 1.38rem;
  margin-bottom: 0.1rem;
  max-height: 3rem;
  overflow: hidden;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  font-weight: ${({ isRead }) => (isRead ? "normal" : "bold")}
`;

const Hashtag = styled.div`
  ${faces.smallBold}
  color: ${props => props.theme.colors.primary.dark};
  font-size: .68rem;
  align-items: center;
  display: flex;
  margin-left: -0.625rem;

  > svg {
    height: 2.375rem;
    width: 	2.3125rem;
  }
`;

const Timestamps = styled.div`
  ${faces.smallBold}
  color: ${props => props.theme.colors.primary.light};
  font-size: .68rem;
`;

const BottomLabel = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 900;
  font-size: 0.625rem;
  background-color: ${({ theme }) => theme.colors.secondary.light};
  color: ${({ theme }) => theme.colors.white};
  width: 2.625rem;
  height: 1.125rem;
  border-radius: 0.125rem;
`;

const getTagName = (
  parentId: string,
  createdBy?: IXHQMember,
  channel?: IChannel
) => {
  if (parentId === NOTIFABLE_STATUS.ANNOUNCEMENT && createdBy) {
    const { firstName, lastName } = createdBy;
    return `${firstName} ${lastName}`.toUpperCase();
  }

  if (channel && channel.title) {
    return channel.title;
  }

  return parentId.replace(XHQ_ID_PREFIXES.CHANNEL, "").replace("-", "");
};

const ThreadListItem = (props: IProps) => {
  const {
    badge,
    body,
    channel,
    parentId,
    title,
    avatar,
    hasAttachment,
    type,
    read,
    theme,
    isDraft,
    sentAt,
    scheduledAt,
    createdAt,
    createdBy
  } = props;

  const icon =
    type === "event" ? (
      <EventIcon month="NOV" day={13} />
    ) : (
      <Avatar src={avatar} />
    );
  const threadTimestamp = sentAt || scheduledAt || createdAt!;
  const timestampSanitized = /^\d+$/.test(threadTimestamp)
    ? parseInt(threadTimestamp, 10)
    : threadTimestamp;
  const tagIconName =
    parentId === NOTIFABLE_STATUS.ANNOUNCEMENT
      ? Icons.ANNOUNCEMENT
      : Icons.HASHTAG;

  return (
    <Root {...props}>
      <IconWrapper>{icon}</IconWrapper>
      <Main>
        <Header>
          <Title data-id="threadItemTitle" isRead={Boolean(read)}>
            {truncate(title, {
              length: THREAD_ITEM_TITLE_MAX_LENGTH,
              separator: " "
            })}
          </Title>
          {hasAttachment && (
            <Icon
              data-id="threadItemAttachmentIcon"
              name={Icons.ATTACHMENT}
              color={theme.colors.primary.light}
            />
          )}
          <Badge text={badge} />
        </Header>
        <Description isRead={Boolean(read)}>
          {truncate(removeHTMLTags(body!.replace(/&nbsp;/g, "")), {
            length: THREAD_ITEM_CONTENT_MAX_LENGTH,
            separator: " "
          })}
        </Description>
        {!isDraft ? (
          <Hashtag data-id="threadItemHashtag">
            <Icon
              data-id="threadItemTagIcon"
              name={tagIconName}
              color={theme.colors.primary.dark}
            />
            {getTagName(parentId, createdBy, channel)}
          </Hashtag>
        ) : (
          <BottomLabel data-id="threadItemLabel">DRAFT</BottomLabel>
        )}
        <Timestamps data-id="threadItemTimestamp">
          {moment(timestampSanitized!).format(TIME_FORMAT_THREAD_LIST)}
        </Timestamps>
      </Main>
    </Root>
  );
};

export default withTheme(ThreadListItem);
