import { createSelector } from "reselect";
import { get, uniqBy } from "lodash";
import { getXHQUserFromState } from "./xhqUser";
import { sortChannelsByDisplayName } from "../utils/helpers";

const getPublicChannelsFromState = (state: IAppState) =>
  state.channels.publicChannels;

export const getPublicChannelsData = createSelector(
  getPublicChannelsFromState,
  (publicChannels: IAsyncEntityState<TChannelData>) => publicChannels
);

const getSelectedChannelFromState = (state: IAppState) =>
  state.channels.selectedChannel;

export const getSelectedChannelData = createSelector(
  getSelectedChannelFromState,
  (selectedChannel: IAsyncEntityState<TChannel>) => selectedChannel
);

const getCreatedChannelFromState = (state: IAppState) =>
  state.channels.createdChannel;

export const getCreatedChannel = createSelector(
  getCreatedChannelFromState,
  (createdChannel: IAsyncEntityState<TChannel>) => createdChannel
);

const getCreatedChannelWithMemberAndAudienceFromState = (state: IAppState) =>
  state.channels.addChannelWithMembersAndAudience;

export const getIsFetchingCreatedChannelWithMembersAndAudience = createSelector(
  getCreatedChannelWithMemberAndAudienceFromState,
  (
    createdChannelWithMembersAndAudience: IAsyncEntityState<
      TChannelWithMembersAndAudience
    >
  ) => createdChannelWithMembersAndAudience.isFetching
);

const getUpdatedChannelWithMemberAndAudienceFromState = (state: IAppState) =>
  state.channels.updateChannelWithMembersAndAudience;

export const getIsFetchingUpdatedChannelWithMembersAndAudience = createSelector(
  getUpdatedChannelWithMemberAndAudienceFromState,
  (
    updatedChannelWithMembersAndAudience: IAsyncEntityState<
      TChannelWithMembersAndAudience
    >
  ) => updatedChannelWithMembersAndAudience.isFetching
);

const getAddedChannelMembersFromState = (state: IAppState) =>
  state.channels.addedChannelMembers;

export const getWereChannelMembersAdded = createSelector(
  getAddedChannelMembersFromState,
  ({ data }: IAsyncEntityState<TChannelMembers>) => {
    return data === null ? false : data.every(Boolean);
  }
);

export const getErrorAddingChannel = createSelector(
  getCreatedChannelFromState,
  ({ error }: IAsyncEntityState<TChannel>) =>
    error!.replace("GraphQL error: ", "")
);

const getArchivedChannelFromState = (state: IAppState) =>
  state.channels.archivedChannel;

export const getArchivedChannelId = createSelector(
  getArchivedChannelFromState,
  ({ data }: IAsyncEntityState<TChannel>): string | undefined => get(data, "id")
);

export const getArchivedChannelError = createSelector(
  getArchivedChannelFromState,
  ({ error }: IAsyncEntityState<TChannel>): string => error!
);

export const getAllUserChannels = createSelector(
  [getPublicChannelsFromState, getXHQUserFromState],
  (
    publicChannels: IAsyncEntityState<TChannelData>,
    xhqMember: IAsyncEntityState<TXHQMember>
  ) => {
    // We must show the sorted public channels first, then the sorted non public channels
    return uniqBy(
      [
        ...sortChannelsByDisplayName(get(publicChannels, "data.items", [])),
        ...sortChannelsByDisplayName(get(xhqMember, "data.channels.items", []))
      ],
      "id"
    ).filter(channel => !channel.isArchived);
  }
);

export const getAllUserChannelsMap = createSelector(
  getAllUserChannels,
  (allChannels): { [key: string]: IChannel } => {
    return allChannels.reduce((acc, channel) => {
      acc[channel.id] = channel;

      return acc;
    }, {});
  }
);

export const getErrorAddingChannelWithMembersAndAudience = createSelector(
  getCreatedChannelWithMemberAndAudienceFromState,
  ({ error }: IAsyncEntityState<TChannelWithMembersAndAudience>) =>
    error ? error.replace("GraphQL error: ", "") : ""
);

export const getErrorUpdatingChannelWithMembersAndAudience = createSelector(
  getUpdatedChannelWithMemberAndAudienceFromState,
  ({ error }: IAsyncEntityState<TChannelWithMembersAndAudience>) =>
    error ? error.replace("GraphQL error: ", "") : ""
);
