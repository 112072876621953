import * as React from "react";
import enhanceWithClickOutside from "react-click-outside";
import styled from "styled-components";

import Panel from "../Panel";
import { CLOSE } from "../../constants/icons";
import media from "../../lib/media";
import Icon from "../Icon/Icon";

interface IProps {
  showCloseButton?: boolean;
  onClickOutside?: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
  className?: string;
  title?: string;
}

const Wrapper = styled(Panel)`
  position: absolute;

  ${media.lessThan("medium")`
    left: 70%;
  `};

  ${media.lessThan("small")`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  `};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  ${media.greaterThan("small")`
    display: none;
  `};
`;

const IconWrapper = styled.div`
  position: fixed;
  right: 1.5rem;
  top: 0.1rem;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  display: inline-block;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.secondary.main};
  font-size: 1rem;
`;

class Menu extends React.PureComponent<IProps> {
  handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    const { onClickOutside } = this.props;
    if (onClickOutside) {
      onClickOutside(event);
    }
  };

  render() {
    const {
      className,
      children,
      onClickOutside,
      title,
      showCloseButton = true
    } = this.props;
    return (
      <Wrapper className={className}>
        <HeaderWrapper>
          {title && <Title>{title}</Title>}
          {showCloseButton && (
            <IconWrapper role="button" onClick={onClickOutside}>
              <CloseIcon name={CLOSE} opacity={0.5} />
            </IconWrapper>
          )}
        </HeaderWrapper>
        {children}
      </Wrapper>
    );
  }
}

export default enhanceWithClickOutside(Menu);
