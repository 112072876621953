import * as React from "react";
import styled from "styled-components";

import { BUTTON_VARIANTS } from "../../constants/button";
import Modal from "../Modal";
import Button from "../Button";

interface IProps {
  title?: string;
  description?: React.ReactNode;
  acceptButtonLabel?: string;
  declineButtonLabel?: string;
  acceptButtonDisabled?: boolean;
  declineButtonDisabled?: boolean;
  vertically?: boolean;
  onClose?: () => void;
  onCancel?: (event: React.MouseEvent<HTMLElement>) => void;
  onAccept?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface IVerticallyProp {
  vertically?: boolean;
}

const StyledModal = styled(Modal)`
  text-align: center;
`;

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 20rem;
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.secondary.main};
  margin-top: 1rem;
`;

const Description = styled.div`
  font-size: 0.875rem;
  line-height: 1.57;
  text-align: center;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)<IVerticallyProp>`
  display: block;
  margin: ${({ vertically }) => (vertically ? "0.5rem auto" : "1rem 0.5rem 0")};
  width: 16rem;
`;

const ButtonsWrapper = styled.div<IVerticallyProp>`
  display: flex;
  flex-direction: ${({ vertically }) => (vertically ? "column" : "row")};
`;

const ConfirmationDialog = ({
  title,
  description,
  vertically,
  onAccept,
  onCancel,
  onClose,
  acceptButtonLabel = "ACCEPT",
  declineButtonLabel = "CANCEL",
  acceptButtonDisabled = false,
  declineButtonDisabled = false
}: IProps) => (
  <StyledModal title={title} onCloseClick={onClose}>
    <Wrapper>
      <Description>{description}</Description>
      <ButtonsWrapper vertically={vertically}>
        <StyledButton
          vertically={vertically}
          onClick={onAccept}
          variant={BUTTON_VARIANTS.ROUNDED}
          disabled={acceptButtonDisabled}
        >
          {acceptButtonLabel}
        </StyledButton>
        <StyledButton
          vertically={vertically}
          onClick={onCancel}
          variant={BUTTON_VARIANTS.ROUNDED}
          disabled={declineButtonDisabled}
          outlined={true}
        >
          {declineButtonLabel}
        </StyledButton>
      </ButtonsWrapper>
    </Wrapper>
  </StyledModal>
);

export default ConfirmationDialog;
