import { get } from "lodash";

import {
  FETCH_XHQ_USERS,
  REMOVE_XHQ_USERS
} from "../../constants/actions/xhqUsers";
import { GET_XHQ_USERS_SEARCH } from "../../graphql/tags/search";

export const fetchXHQSearchMembers = (
  inputValue: string
) => (/*{ getState, dispatch }*/): TFetchSearchXHQUser => {
  return {
    actionType: FETCH_XHQ_USERS,
    api: "XHQ",
    operationType: "query",
    operation: GET_XHQ_USERS_SEARCH,
    variables: {
      searchAttribute: inputValue.toLowerCase()
    },
    getter: response => {
      return get(response, "data.search", []);
    }
  };
};

export const removeXhqUsers = () => ({
  type: REMOVE_XHQ_USERS
});
