import {
  FETCH_PUBLIC_CHANNELS,
  UPDATE_CHANNEL
} from "../../constants/actions/channels";

const SUCCESS = `${FETCH_PUBLIC_CHANNELS}_SUCCESS`;
const LOADING = `${FETCH_PUBLIC_CHANNELS}_LOADING`;
const ERROR = `${FETCH_PUBLIC_CHANNELS}_ERROR`;

const UPDATE_CHANNEL_SUCCESS = `${UPDATE_CHANNEL}_SUCCESS`;

type ActionTypes =
  | IActionType<typeof SUCCESS, IChannelData>
  | IActionType<typeof UPDATE_CHANNEL_SUCCESS, IChannel>
  | IActionType<typeof LOADING, boolean>
  | IActionType<typeof ERROR, string | null>;

const initialState: IAsyncEntityState<TChannelData> = {
  data: {
    items: [],
    hasBeenFetched: false
  },
  isFetching: false,
  error: null
};

const publicChannels = (
  state: IAsyncEntityState<TChannelData> = initialState,
  action: ActionTypes
): IAsyncEntityState<TChannelData> => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        data: {
          ...(action.payload as IChannelData),
          hasBeenFetched: true
        },
        isFetching: false,
        error: null
      };
    case UPDATE_CHANNEL_SUCCESS:
      const payload = action.payload as IChannel;
      const channels = state.data.items.map((channel: IChannel) => {
        if (payload && channel.id === payload.id) {
          return {
            ...channel,
            ...payload
          };
        }
        return channel;
      });
      return {
        ...state,
        data: {
          ...state.data,
          items: channels
        }
      };
    case LOADING:
      return {
        ...state,
        isFetching: action.payload as boolean,
        error: null
      };
    case ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload as string | null
      };
    default:
      return state;
  }
};

export default publicChannels;
