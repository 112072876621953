export const READ = "read";
export const INBOX = "inbox";
export const CALENDAR = "calendar";
export const CALENDAR_SUBSCRIBE = "calendar-subscribe";
export const BADGE = "badge";
export const ATTACHMENT = "attachment";
export const SHIELD = "shield";
export const CIRCLE = "circle";
export const SUBJECT = "subject";
export const CHANNEL = "channel";
export const LOCK = "lock";
export const BELL = "bell";
export const SEARCH = "search";
export const FAVOURITES = "favourites";
export const IMPORTANT = "important";
export const MORE = "more";
export const EDIT = "edit";
export const PENCIL = "pencil";
export const ARCHIVE = "archive";
export const LEAVE = "leave";
export const BIGCALENDAR = "big-calendar";
export const DISCUSSION = "discussion";
export const PEOPLE = "people";
export const MOVE = "move";
export const REMOVE = "remove";
export const HEART = "heart";
export const COFFEE = "coffee";
export const CLOCK = "clock";
export const CLOCK_ONE = "clock-one";
export const CLOCK_TWO = "clock-two";
export const CLOCK_FOUR = "clock-four";
export const CLOCK_EIGHT = "clock-eight";
export const SETTINGS = "settings";
export const NOTIFICATION = "notification";
export const SNOOZED_NOTIFICATION = "snoozed-notification";
export const RIGHT_ARROW = "right-arrow";
export const LEFT_ARROW = "left-arrow";
export const DOWN_ARROW = "down-arrow";
export const LEFT_ANGLE = "left-angle";
export const RIGHT_ANGLE = "right-angle";
export const ADD = "add";
export const HOURGLASS = "hourglass";
export const MEDICAL = "medical";
export const PROFILE = "profile";
export const PAYMENT = "payment";
export const ANNOUNCEMENT = "announcement";
export const CLOSE = "close";
export const CLOSE_SMALL = "close-small";
export const EMOJI = "emoji";
export const STUDENTS = "students";
export const CONTACT = "contact";
export const DOCTOR = "doctor";
export const INSURANCE = "insurance";
export const CONDITION = "condition";
export const BIG_HEART = "big-heart";
export const ALLERGIES = "allergies";
export const LUNGS = "lungs";
export const SYRINGE = "syringe";
export const PAPER = "paper";
export const PAPER_BIG = "paper-big";
export const BIG_STAR = "big-star";
export const DOCUMENT_CHECK = "document-check";
export const CHECK = "check";
export const SIMPLE_CHECKMARK = "simple-checkmark";
export const HALF_CIRCLE = "half-circle";
export const EVENTS = "events";
export const ABSENCES = "absences";
export const NOTES = "notes";
export const STREAM = "stream";
export const CONSENT = "consent";
export const MEDICAL_BIG = "medical-big";
export const PAYMENTS = "payments";
export const POLICIES = "policies";
export const BEREAVEMENT = "bereavement";
export const MEDICAL_SMALL = "medical-small";
export const PIN = "pin";
export const PILL = "pill";
export const STUDENTS_SMALL = "students-small";
export const RELIGION = "religion";
export const ALERT = "alert";
export const LOCATION_PIN = "location-pin";
export const INFO_LOCKER = "info-locker";
export const DASHBOARD = "dashboard";
export const PAPER_PLANE = "paper-plane";
export const SCHOOL = "school";
export const LINK = "link";
export const HAMBURGUER = "hamburguer";
export const TERM_COUNTER = "term-counter";
export const HASHTAG = "hashtag";
export const HELP = "help";
export const STAFF = "staff";
export const BACKPACK = "backpack";
export const TRANSFER = "transfer";
export const TEAM_SHEETS = "team-sheets";
export const DOWNLOAD = "download";
