import React, { ReactNode, Ref, SyntheticEvent, KeyboardEvent } from "react";

import * as S from "./styles";

type TEventCallback<T> = (event: T) => any;

interface IProps {
  icon: ReactNode;
  className?: string;
  placeholder?: string;
  inputRef?: Ref<HTMLInputElement>;
  onEnter?: TEventCallback<KeyboardEvent>;
  onChange: TEventCallback<SyntheticEvent<HTMLInputElement>>;
  value: string;
}

const handleOnEnter = (onEnter?: TEventCallback<KeyboardEvent>) => (
  event: KeyboardEvent
) => {
  const eventObject = event;

  if (!onEnter) {
    return;
  }

  if (eventObject.key !== "Enter") {
    return;
  }

  onEnter(eventObject);
};

const Input = ({
  icon: Icon,
  className,
  placeholder,
  onChange,
  inputRef,
  value,
  onEnter
}: IProps) => {
  return (
    <S.Wrapper className={className}>
      <input
        value={value}
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        onKeyDown={handleOnEnter(onEnter)}
        onChange={onChange}
      />
      {Icon}
    </S.Wrapper>
  );
};

export default Input;
