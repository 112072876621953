import {
  SET_FOCUSED_COLUMN_VIEW,
  SET_EDITOR_VIEW_MODE
} from "../../constants/actions/community";

type TSetMobileFocusedColumn = (
  focusedColumnView: TFocusColumnView,
  callback?: () => void
) => ({ dispatch }: { dispatch: IThunkDispatch<void, void, any> }) => void;

type TSetEditorViewMode = (
  editorViewMode: TEditorViewMode
) => { payload: TEditorViewMode; type: string };

export const setMobileFocusedColumn: TSetMobileFocusedColumn = (
  focusedColumnView,
  callback
) => ({ dispatch }) => {
  dispatch({ payload: focusedColumnView, type: SET_FOCUSED_COLUMN_VIEW });

  if (callback) {
    callback();
  }
};

export const setEditorViewMode: TSetEditorViewMode = editorViewMode => ({
  payload: editorViewMode,
  type: SET_EDITOR_VIEW_MODE
});
