import { connect } from "react-redux";

import Threads from "./Threads";
import {
  setDefaultSelectedChannelState,
  archiveChannel,
  setDefaultArchivedChannelState,
  updateChannelWithCallback,
  removeChannelDataFromState
} from "../../actions/channels";
import { subscribeToCreateMessage } from "../../actions/threads";
import { subscribeToCreateNotification } from "../../actions/notifications";
import {
  fetchAnnouncements,
  setAnnouncementSearchPhrase
} from "../../actions/announcements";
import {
  getSelectedChannelData,
  getArchivedChannelId,
  getArchivedChannelError
} from "../../selectors/channels";
import { getAnnouncements } from "../../selectors/announcements";
import {
  setEditorViewMode,
  setMobileFocusedColumn
} from "../../actions/community";

export const mapStateToProps = (state: IAppState) => {
  return {
    selectedChannelData: getSelectedChannelData(state),
    archivedChannelId: getArchivedChannelId(state),
    archivedChannelError: getArchivedChannelError(state),
    inboxAnnouncementsData: getAnnouncements("inbox")(state)
  };
};

export const mapDispatchToProps = (dispatch: IThunkDispatch<{}, {}, any>) => ({
  actions: {
    setDefaultSelectedChannelState: () =>
      dispatch(setDefaultSelectedChannelState()),
    setDefaultArchivedChannelState: () =>
      dispatch(setDefaultArchivedChannelState()),
    updateChannelWithCallback: (
      channel: IUpdateChannelProps,
      callback: TVoidFunc
    ) => dispatch(updateChannelWithCallback(channel, callback)),
    archiveChannel: (channelId: string) => dispatch(archiveChannel(channelId)),
    fetchAnnouncements: (
      xhqUser: IXHQMember,
      filter: "inbox",
      withFreshList: boolean
    ) => dispatch(fetchAnnouncements(xhqUser, filter, withFreshList)),
    setAnnouncementSearchPhrase: (searchPhrase: string) =>
      dispatch(setAnnouncementSearchPhrase(searchPhrase)),
    subscribeToCreateMessage: (id: string) =>
      dispatch(subscribeToCreateMessage(id)),
    subscribeToCreateNotification: (xhqUser: IXHQMember) =>
      dispatch(subscribeToCreateNotification(xhqUser)),
    setEditorViewMode: (editorViewMode: TEditorViewMode) =>
      dispatch(setEditorViewMode(editorViewMode)),
    setMobileFocusedColumn: (
      focusedColumnView: TFocusColumnView,
      callback?: TVoidFunc
    ) => dispatch(setMobileFocusedColumn(focusedColumnView, callback)),
    removeChannelDataFromState: () => dispatch(removeChannelDataFromState())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Threads);
