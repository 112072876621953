import * as React from "react";
import styled from "styled-components";

interface IProps {
  src?: string;
  children?: React.ReactNode;
  className?: string;
  alt?: string;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface IWrapperProps {
  backgroundColor?: string;
}

const Wrapper = styled.div<IWrapperProps>`
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  overflow: hidden;
  user-select: none;
  ${(props: any) =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`};
`;

const Image = styled.img`
  height: 100%;
  text-align: center;
  width: 100%;
`;

const Avatar = (props: IProps) => {
  const { src, alt, children, className, backgroundColor, onClick } = props;
  return (
    <Wrapper
      className={className}
      backgroundColor={backgroundColor}
      onClick={onClick}
    >
      {src ? <Image src={src} alt={alt || "Avatar"} /> : children}
    </Wrapper>
  );
};

export default Avatar;
