import * as React from "react";
import styled from "styled-components";

import media from "../../lib/media";
import datePickerStyles from "./datePickerStyles";

const Wrapper = styled.div`
  display: inline-block;
  min-width: 24.06rem;
  position: relative;
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.secondary.main};

  ${media.lessThan("small")`
    min-width: 16rem;
  `} * {
    box-sizing: border-box;
  }

  ${props => datePickerStyles(true, props)};
`;

interface IProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const DatePickerWrapper = ({ className, children, onClick }: IProps) => (
  <Wrapper className={className} onClick={onClick}>
    {children}
  </Wrapper>
);

export default DatePickerWrapper;
