import * as React from "react";
import styled from "styled-components";

interface IProps {
  className?: string;
  text?: string;
}

const HR = styled.hr`
  border: 0;
  height: 0.0625rem;
  margin: 1rem 0;
  background: ${(props: any) => props.theme.colors.secondary.lighter};
`;

const HRWithText = styled.hr`
  font-family: ${props => props.theme.fonts.main};
  position: relative;
  outline: 0;
  border: 0;
  text-align: center;

  :before {
    content: "";
    background: ${(props: any) => props.theme.colors.secondary.lighter};
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
  }

  :after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    font-weight: bold;
    color: ${(props: any) => props.theme.colors.secondary.light};
    font-size: 0.7rem;
    padding: 0 1.12rem;
    background-color: ${(props: any) => props.theme.colors.white};
  }
`;

const HorizontalRule = ({ className, text }: IProps) =>
  text ? (
    <HRWithText className={className} data-content={text} />
  ) : (
    <HR className={className} />
  );

export default HorizontalRule;
