import { connect } from "react-redux";

import ThreadEditor from "./ThreadEditor";
import {
  updateThreadAndRedirect,
  postThreadAndRedirect,
  removeThreadDataFromState
} from "../../actions/threads";
import {
  putAttachmentToS3,
  removeAttachmentFromState,
  postThreadAttachments
} from "../../actions/attachments";
import {
  getUpdateThreadData,
  getCreateThreadData,
  getIsPostingThreadAttachments
} from "../../selectors/threads";
import { getAttachments } from "../../selectors/attachments";
import { postNotification } from "../../actions/notifications";
import { postAnnouncementAndRedirect } from "../../actions/announcements";
import {
  fetchAudiencesSearch,
  removeAudiencesDataFromState
} from "../../actions/audiences";
import {
  getAudiencesState,
  getAudiencesOptions
} from "../../selectors/audiences";
import { getXHQUserData } from "../../selectors/xhqUser";
import { getAllUserChannelsMap } from "../../selectors/channels";
import {
  setEditorViewMode,
  setMobileFocusedColumn
} from "../../actions/community";
import {
  getXhqUserOptionsResult,
  getXhqUsersLoadingState
} from "../../selectors/xhqSearchUsers";
import { fetchXHQSearchMembers, removeXhqUsers } from "../../actions/xhqUsers";

export const mapStateToProps = (state: IAppState): IThreadEditorStateProps => {
  return {
    xhqUser: getXHQUserData(state),
    updateThreadData: getUpdateThreadData(state),
    createThreadData: getCreateThreadData(state),
    attachments: getAttachments(state),
    isPostingThreadAttachments: getIsPostingThreadAttachments(state),
    audiencesState: getAudiencesState(state),
    audiencesOptions: getAudiencesOptions(state),
    allUserChannelsMap: getAllUserChannelsMap(state),
    xhqUserOptionsResult: getXhqUserOptionsResult(state),
    isFetchingXhqUserOptions: getXhqUsersLoadingState(state)
  };
};

export const mapDispatchToProps = (
  dispatch: IThunkDispatch<{}, {}, any>
): IThreadEditorActions => {
  return {
    actions: {
      updateThreadAndRedirect: (threadData, callback) =>
        dispatch(updateThreadAndRedirect(threadData, callback)),
      postThreadAndRedirect: (threadData, callback) =>
        dispatch(postThreadAndRedirect(threadData, callback)),
      putAttachmentToS3: (file: File) => dispatch(putAttachmentToS3(file)),
      removeAttachmentFromState: (attachment: IAttachment) =>
        dispatch(removeAttachmentFromState(attachment)),
      postThreadAttachments: (thread: IThread) =>
        dispatch(postThreadAttachments(thread)),
      postNotification: (thread: IThread, selectedChannelId: string) =>
        dispatch(postNotification(thread, selectedChannelId)),
      postAnnouncementAndRedirect: (
        announcementData: IPostAnnouncementData,
        callback: (thread: IThread) => void
      ) => dispatch(postAnnouncementAndRedirect(announcementData, callback)),
      fetchAudiencesSearch: (value: string) =>
        dispatch(fetchAudiencesSearch(value)),
      removeAudiencesDataFromState: () =>
        dispatch(removeAudiencesDataFromState()),
      setMobileFocusedColumn: (
        focusedColumnView: string,
        callback?: () => void
      ) => dispatch(setMobileFocusedColumn(focusedColumnView, callback)),
      setEditorViewMode: (editorViewMode: string) =>
        dispatch(setEditorViewMode(editorViewMode)),
      fetchXHQSearchMembers: (searchTerm: string) =>
        dispatch(fetchXHQSearchMembers(searchTerm)),
      removeXhqUsers: () => dispatch(removeXhqUsers()),
      removeThreadDataFromState: () => dispatch(removeThreadDataFromState())
    }
  };
};

export default connect<
  IThreadEditorStateProps,
  IThreadEditorActions,
  IThreadEditorOwnProps,
  IAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(ThreadEditor);
